import React, { useState, useEffect, useGlobal } from "reactn";
import PropTypes from "prop-types";
import { coupon_discount, applyGift } from "./utils";

const EventTotal = props => {
  const [eventTotal, setEventTotal] = useState("Loading");
  const [_, setPreGiftTotal] = useGlobal("preGiftTotal");
  const giftBalance = useGlobal("giftBalance")[0];
  const calculateEventTotal = () => {
    const seleted_ticket = JSON.parse(props.program.tickets[props.selected]);
    const parsed_ticket_price = parseFloat(seleted_ticket.ticket_price || 0);

    const discount_amount = props.coupon
      ? coupon_discount(props.coupon, parsed_ticket_price)
      : 0;
    const taxRate = seleted_ticket.isTaxEnabled
      ? parseFloat(seleted_ticket.taxInfo[0].percentage) / 100
      : 0;
    const tax = Math.max(0, parsed_ticket_price - discount_amount) * taxRate;
    let total =
      parsed_ticket_price === 0
        ? 0
        : Math.max(0, parsed_ticket_price - discount_amount) + tax;

    setPreGiftTotal(total);
    ({ amount: total } = applyGift(
      props.program.manual_invoices,
      giftBalance,
      total
    ));
    setEventTotal(total.toFixed(2));
    props.totalAmount(total.toFixed(2));
  };

  useEffect(() => {
    calculateEventTotal();
  }, [props.coupon, giftBalance]);

  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif",
          fontWeight: 600
        }}
      >
        Total
      </p>
      <span style={{ fontWeight: "bold" }}>$ {eventTotal}</span>
    </li>
  );
};

EventTotal.propTypes = {
  program: PropTypes.shape({
    tickets: PropTypes.array.isRequired
  }).isRequired,
  selected: PropTypes.number.isRequired,
  coupon: PropTypes.object
};

export default EventTotal;
