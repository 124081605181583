import React from "reactn";
import PropTypes from "prop-types";
import { Route, Redirect, withRouter } from "react-router-dom";
import Header from "../components/front/Header";
import Footer from "../components/front/Footer";
import NavBarParent from "../components/common/NavBarParent";

class AuthParent extends React.PureComponent {
  constructor(props) {
    super(props);
    document.getElementsByTagName("html")[0].className = "new-heaven";
    document.getElementsByTagName("body")[0].style.margin = "0";
    document.getElementsByTagName("html")[0].style.fontSize = "10px";
    document.getElementsByTagName("html")[0].style.lineHeight = "11.5px";
  }

  async componentDidMount() {
    try {
      if (localStorage.getItem("ds_token") && !this.global.dsUser) {
        await this.dispatch.validateToken();
      }
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const {
      component: Component,
      isAuthenticated,
      iFrame,
      to,
      ...rest
    } = this.props;
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            <>
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  zIndex: 500,
                  backgroundColor: "#fff"
                }}
              >
                <div className="main-pages">{!iFrame && <Header />}</div>
                {!iFrame && <NavBarParent />}
              </div>
              <div id="parent-background" style={{ marginTop: "198px" }}>
                <div
                  id="wrapper"
                  className="console-pages"
                  style={{ width: "85%", margin: "0 auto", minHeight: "580px" }}
                >
                  <Component {...props} />
                </div>
              </div>
              <div className="main-pages">{!iFrame && <Footer />}</div>
            </>
          ) : (
            <Redirect to={to} />
          )
        }
      />
    );
  }
}

AuthParent.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  to: PropTypes.string
};

AuthParent.defaultProps = {
  to: "/private"
};

export default withRouter(AuthParent);
