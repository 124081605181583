/*
NOTE:
The following 4 files have a common structure and a lot of shared code:
  - ProgramBookingDetail.jsx
  - OnlineBookingDetail.jsx
  - MembershipBookingDetail.jsx
  - EventBookingDetail.jsx
For any changes to this file, please also keep all 4 similarly updated.
Future task is to consolidate as much of the shared code as possible.
*/

import React, { useContext, useGlobal, useDispatch } from "reactn";

import PropTypes from "prop-types";
import { toast } from "react-toastify";
import axios from "axios";

import {
  PromotionContext,
  setSelected,
  setCheckout,
  setSignIn,
  setIsOpenSignUpModal
} from "../PromotionsDetail.context";

import { PERCENT, FIXED_AMOUNT } from "../../Checkout/utils";

import BookingDetailPromotionCard from "./BookingDetailPromotionCard";
import Radio from "@material-ui/core/Radio";
import { withStyles } from "@material-ui/core/styles";
import SummarySubTotalEvents from "../../SpecificPageUtils/SummarySubTotalEvents";
import { withRouter } from "react-router-dom";
import { DateTime } from "luxon";

const styles = {
  radio: {
    color: "#ccc",
    "&$checked": {
      color: "#FF6666"
    },
    "& .MuiButtonBase-root": {
      padding: "0px !important",
      marginRight: "0px !important"
    }
  },
  checkedRadio: {
    color: "#FF6666"
  }
};

const PartyBookingDetail = props => {
  const { state, dispatch } = useContext(PromotionContext);
  // global state
  const [global, _] = useGlobal();
  const globalDispatch = useDispatch();
  const { selected, promotion } = state;

  const isPlanSelected = () =>
    state.selected !== null && state.selected !== undefined; //do not use -1 to represent a non-selection. And id=0 is a valid price plan!
  const isShowPromoOnly = promotion?.show_promo_only;
  const getAccountType = user => user.accountType;
  const isUserParent = user => getAccountType(user) === "Parent";
  const isUserVolunteer = user => getAccountType(user) === "Volunteer";
  const isUserStudent = user => getAccountType(user) === "Student";
  const canUserVisitCheckout = user =>
    isUserParent(user) || isUserVolunteer(user) || isUserStudent(user);

  const failedLocalStorageDsUserToken = () => {
    try {
      if (localStorage.getItem("ds_token")) {
        return false;
      }
      return false;
    } catch {
      console.log("FAILED TO FETCH FROM LOCAL STORAGE");
      return true;
    }
  };
  console.log("failedLocalStorageDsUserToken", failedLocalStorageDsUserToken());

  const checkUserAndLoggedInAccountType = () =>
    global.dsUser && canUserVisitCheckout(global.dsUser);

  //===========================================================================
  // click handler for Enroll Now button
  //===========================================================================
  const onEnrollNow = async () => {
    if (!isPlanSelected()) {
      return toast.error(`Please select a plan!`);
    }
    const planId = state.selected;

    if (failedLocalStorageDsUserToken()) {
      console.log("Failed to access local storage enroll OPEN NEW PAGE");
      window.open(
        `${process.env.REACT_APP_URL}/programs/${props.match.params.slug}/promotion/${props.match.params.id}?plan=${planId}`
      );
      return;
    }

    if (localStorage.getItem("ds_token") && !global.dsUser) {
      await globalDispatch.validateToken();
      if (checkUserAndLoggedInAccountType()) {
        return setCheckout(dispatch, { checkout: true });
      }
      globalDispatch.logout();
      return setIsOpenSignUpModal(dispatch, true);
    }
    if (!localStorage.getItem("ds_token")) {
      return setIsOpenSignUpModal(dispatch, true);
    } else {
      if (checkUserAndLoggedInAccountType()) {
        return setCheckout(dispatch, { checkout: true });
      }
      globalDispatch.logout();
      return setIsOpenSignUpModal(dispatch, true);
    }
  };

  //===========================================================================
  // click handler for Add to Cart button
  //===========================================================================
  const onAddToCart = async () => {
    if (!isPlanSelected()) {
      return toast.error(`Please select a plan!`);
    }
    const planId = state.selected;

    if (failedLocalStorageDsUserToken()) {
      console.log("Failed to access local storage enroll OPEN NEW PAGE");
      window.open(
        `${process.env.REACT_APP_URL}/programs/${props.match.params.slug}/promotion/${props.match.params.id}?plan=${planId}`
      );
      return;
    }

    console.log("state: ", state);

    const data = {
      partnerId: state.party.partnerId,
      productId: state.party.id,
      planId: planId,
      quantity: 1,
      productTable: "partner_party",
      promotionId: state.promotion.plan_price_ticketId.includes(planId)
        ? state.promotion.id
        : null
    };

    const addToCart = async () => {
      console.log("dispatch: ", dispatch);
      await globalDispatch.getCart({ partnerSlug: props.match.params.slug });
      const ep = `${process.env.REACT_APP_API}/par/cart`;
      const res = await axios.put(ep, data);
      if (res.data.success) {
        toast.success("Added to cart!");
      } else {
        toast.error(res.data.message);
      }
    };

    if (localStorage.getItem("ds_token") && !global.dsUser) {
      await globalDispatch.validateToken();
      if (checkUserAndLoggedInAccountType()) {
        await addToCart();
        return;
      }
      globalDispatch.logout();
      return setIsOpenSignUpModal(dispatch, true);
    }
    if (!localStorage.getItem("ds_token")) {
      return setIsOpenSignUpModal(dispatch, true);
    } else {
      if (checkUserAndLoggedInAccountType()) {
        await addToCart();
        return;
      }
      globalDispatch.logout();
      return setIsOpenSignUpModal(dispatch, true);
    }
  };

  console.log("STATEPROPS", state, props);
  const partyStart = DateTime.fromISO(state.promotion.expiry_date);
  // const programStart = DateTime.fromISO(state.promotion.expiry_date).plus({
  //   minutes: minutes,
  //   hours: hours
  // });
  // const rezonedProgramStart = programStart.setZone(this.state.program.timezone);

  const currTime = DateTime.local();
  // const rezonedCurrTime = currTime.setZone(this.state.program.timezone);
  const diff = partyStart.diff(currTime);
  const duration = diff.valueOf();
  const promoDatePassed = duration < 0;

  console.log("TIMING", currTime.toISO(), partyStart.toISO(), duration);
  return (
    <>
      <h2 className="right-cont-name">Select a Plan</h2>

      <div style={{ width: "80%", margin: "0 auto" }}>
        {promoDatePassed && (
          <div>
            <p>No plans available - End Date has passed</p>
          </div>
        )}
        {!promoDatePassed &&
          state.party &&
          state.party.tickets &&
          state.party.tickets.map((e, i) => {
            const element = parseTicketPlanFromStringToJSON(e);

            if (element.archived || element.active === false) {
              return null;
            }

            if (isShowPromoOnly) {
              if (!isTicketPartOfPromotion(promotion, element)) {
                return null;
              }
            }

            //=====================================================================================
            // Pricing Card.
            //=====================================================================================
            //next line indented for sake of matching with the 4 xxxxBookingDetail.jsx files.
            return (
              <div
                className={
                  state.selected === element.id
                    ? props.classes.planSelected + " pricingcard"
                    : props.classes.plan + " pricingcard"
                }
                onClick={() => setSelected(dispatch, { id: element.id })}
                key={i}
              >
                <div className="radio-btn-positioning-container">
                  <Radio
                    checked={state.selected === element.id}
                    // onChange={handleChange}
                    value="d"
                    color="default"
                    name="radio-button-demo"
                    inputProps={{ "aria-label": "D" }}
                    size="large"
                    classes={{
                      root: props.classes.radio,
                      checked: props.classes.checkedRadio
                    }}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <p className="pricingcard-name">
                    {element.ticket_name} <br />{" "}
                    {isTicketPartOfPromotion(promotion, element) && (
                      <div>
                        <BookingDetailPromotionCard promotion={promotion} />
                      </div>
                    )}
                    <span className="pricingcard-days">
                      {element.ticket_status === "Paid"
                        ? "Standard"
                        : element.ticket_status}{" "}
                      Ticket
                    </span>
                  </p>
                </div>

                <div style={{ width: "50px" }}>
                  <p className="pricingcard-amount">
                    {isTicketPartOfPromotion(promotion, element) ? (
                      <>
                        {element.ticket_price
                          ? `$${getPromotionTicketPrice(
                              state.promotion,
                              element.ticket_price
                            )}`
                          : "Free"}
                      </>
                    ) : (
                      <>
                        {element.ticket_price
                          ? `$${parseFloat(element.ticket_price).toFixed(2)}`
                          : "Free"}
                      </>
                    )}
                  </p>
                </div>
              </div>
            );
          })}

        {isPlanSelected() && (
          <SummarySubTotalEvents
            plans={state.party?.tickets}
            selected={state.selected}
            isPromo={isTicketPartOfPromotion(
              promotion,
              JSON.parse(state.party?.tickets[state.selected])
            )}
            promotion={promotion}
          />
        )}
        {state.party.isCartDisabled ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <button
              style={{
                width: `70%`,
                padding: `10px`,
                backgroundColor: `rgb(52, 63, 100)`,
                color: `white`,
                fontWeight: `bold`,
                border: 0,
                marginTop: "10px",
                cursor:
                  !isPlanSelected() ||
                  state.isBookingFull ||
                  (state.planWaitlist && !state.isProgramWaitlist)
                    ? "not-allowed"
                    : "pointer"
              }}
              disabled={
                !isPlanSelected() ||
                state.isBookingFull ||
                (state.planWaitlist && !state.isProgramWaitlist)
              }
              onClick={onEnrollNow}
            >
              Register
            </button>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            {/* <button
              style={{
                width: `70%`,
                padding: `10px`,
                backgroundColor: `rgb(52, 63, 100)`,
                color: `white`,
                fontWeight: `bold`,
                border: 0,
                marginTop: "40px",
                cursor:
                  !isPlanSelected() ||
                  state.isBookingFull ||
                  (state.planWaitlist && !state.isProgramWaitlist)
                    ? "not-allowed"
                    : "pointer"
              }}
              disabled={
                !isPlanSelected() ||
                state.isBookingFull ||
                (state.planWaitlist && !state.isProgramWaitlist)
              }
              onClick={onAddToCart}
            >
              Add to Cart
            </button>
            <span>-------- OR --------</span> */}
            <button
              style={{
                width: `70%`,
                padding: `10px`,
                backgroundColor: `rgb(211,52,49)`,
                color: `white`,
                fontWeight: `bold`,
                border: 0,
                marginTop: "10px",
                cursor:
                  !isPlanSelected() ||
                  state.isBookingFull ||
                  (state.planWaitlist && !state.isProgramWaitlist)
                    ? "not-allowed"
                    : "pointer"
              }}
              disabled={
                !isPlanSelected() ||
                state.isBookingFull ||
                (state.planWaitlist && !state.isProgramWaitlist)
              }
              onClick={onEnrollNow}
            >
              Register
            </button>
          </div>
        )}
      </div>
    </>
  );
};

function getPromotionTicketPrice(promotion, ticketPrice) {
  if (promotion.discount_type === PERCENT) {
    return Math.max(
      0,
      (1 - promotion.discount_percent / 100) * ticketPrice
    ).toFixed(2);
  } else if (promotion.discount_type === FIXED_AMOUNT) {
    return Math.max(0, ticketPrice - promotion.discount_fixed / 100).toFixed(2);
  }
}

function parseTicketPlanFromStringToJSON(e) {
  return typeof e === "string" ? JSON.parse(e) : e;
}

function isTicketPartOfPromotion(promotion, ticket) {
  return promotion.plan_price_ticketId.indexOf(ticket.id) !== -1;
}

PartyBookingDetail.propTypes = {
  classes: PropTypes.object
};

export default withRouter(withStyles(styles)(PartyBookingDetail));
