import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { getSelectedProgramPlan } from "./utils";

const ProgramSubtotal = props => {
  const [subtotal, setSubtotal] = useState("Loading");

  const calculateSubTotal = () => {
    const plan = getSelectedProgramPlan(props.program, props.selected);
    const tuition_rate = parseFloat(plan.tuition_rate);
    const deposit = parseFloat(plan.deposit ? plan.deposit : 0);
    const amnt = parseFloat(tuition_rate + deposit).toFixed(2);
    setSubtotal(amnt);
  };

  useEffect(() => {
    calculateSubTotal();
  }, []);
  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px",
        borderBottom: "1px solid #dedede"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif",
          fontWeight: 600
        }}
      >
        Subtotal
      </p>
      <span style={{ fontWeight: "bold" }}>$ {subtotal}</span>
    </li>
  );
};

ProgramSubtotal.propTypes = {
  program: PropTypes.shape({
    plans: PropTypes.array.isRequired
  }).isRequired,
  selected: PropTypes.number.isRequired
};

export default ProgramSubtotal;
