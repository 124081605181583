import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import {
  getSelectedProgramPlan,
  promotion_discount,
  coupon_discount,
  MINIMUM_CHARGE
} from "./utils";

const TaxablePromotionOnlineProgram = props => {
  const [tax, setTax] = useState("Loading");
  const [taxName, setTaxName] = useState("Loading");

  const getTaxName = () => {
    const plan = getSelectedProgramPlan(props.program, props.selected);
    const displayname = plan.taxInfo[0].display_name;
    const percent = plan.taxInfo[0].percentage;
    const name = `${displayname} (${percent}%)`;
    setTaxName(name);
  };

  const calculateTaxAmount = () => {
    const plan = getSelectedProgramPlan(props.program, props.selected);
    const tuition_rate = parseFloat(plan.tuition_rate);
    // const deposit = parseFloat(plan.deposit ? plan.deposit : 0)
    // const subtotal = tuition_rate + deposit
    if (props.promotion) {
      const discount_amount = promotion_discount(props.promotion, tuition_rate);
      const tax_amnt = plan.isTaxEnabled ? (Math.max(0, tuition_rate - discount_amount) *
      parseFloat(plan.taxInfo[0].percentage)) /
    100 : (Math.max(0, tuition_rate - discount_amount) *
          parseFloat(plan.taxInfo[0].percentage)) /
        100;

      props.taxAmount(tax_amnt.toFixed(2));

      setTax(tax_amnt.toFixed(2));
    } else {
      // deposit is not taxed (it is a seperate charge, then the recurring semester is taxed)
      const tax_percent = parseFloat(plan.taxInfo[0].percentage) / 100;
      const discount_amount = 0;
      // const discount_amount = props.coupon
      //   ? coupon_discount(props.coupon, tuition_rate)
      //   : 0;
      const tax_amnt =
        Math.max(0, tuition_rate - discount_amount) * tax_percent;

      props.taxAmount(tax_amnt.toFixed(2));

      setTax(tax_amnt.toFixed(2));
    }
  };

  useEffect(() => {
    calculateTaxAmount();
    getTaxName();
  }, []);

  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px",
        borderBottom: "1px solid #dedede"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif",
          fontWeight: 500,
          fontSize: "14px"
        }}
      >
        {taxName}
      </p>
      <span style={{ fontWeight: 500, fontSize: "14px" }}>${tax}</span>
    </li>
  );
};

TaxablePromotionOnlineProgram.propTypes = {
  program: PropTypes.shape({
    plans: PropTypes.array.isRequired
  }).isRequired,
  selected: PropTypes.number.isRequired,
  promotion: PropTypes.object,
  coupon: PropTypes.object
};

export default TaxablePromotionOnlineProgram;
