import React from "react";

const PERCENT = "Percentage";
const FIXED_AMOUNT = "Fixed Amount";
const displayPromotionMembershipValue = (promotion, currentPrice) => {
  if (promotion.discount_type === PERCENT) {
    return ((1 - promotion.discount_percent / 100) * currentPrice).toFixed(2);
  } else if (promotion.discount_type === FIXED_AMOUNT) {
    return Math.max(currentPrice - promotion.discount_fixed / 100, 0).toFixed(
      2
    );
  }
};

const SummarySubTotalMembership = ({ plans, selected, isPromo, promotion }) => {
  console.log("isPromo", isPromo);

  const displayAmount = () => {
    const selectedPlan = JSON.parse(plans[selected]);
    console.log("selectedPlan", selectedPlan);

    if (isPromo) {
      console.log(
        "promoPrice",
        displayPromotionMembershipValue(promotion, selectedPlan.price_per_term)
      );
      return displayPromotionMembershipValue(
        promotion,
        selectedPlan.price_per_term
      );
    }

    return selectedPlan.price_per_term;
  };

  return (
    <div style={{ marginTop: "25px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flexStart",
          fontWeight: 600,
          color: "#828282",
          fontFamily: "Open Sans"
        }}
      >
        SUMMARY
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "7px",
          color: "darkslategrey",
          fontWeight: 600
        }}
      >
        <div>Subtotal</div>
        <div>${displayAmount()}</div>
      </div>
    </div>
  );
};

export default SummarySubTotalMembership;
