import React from "react";
import ModalFormLabel from "./ModalFormLabel";

import "./ModalFormGroup.css";

/**
 * Input Form Group component.
 *
 * @param label Display Label of the form group
 * @param name Input attribute and label htmlFor attribute.
 *
 * @param tooltip Text of tooltip (optional).
 *
 * @param type Type attribute of input element.
 * @param value Value of input element.
 * @param onChange OnChange handler of element.
 * @param required Whether the field is required.
 */
const ModalFormGroup = ({
  label,
  name,
  tooltip,
  type,
  value,
  onChange,
  required,
  ...options
}) => (
  <div className="form-group modal-form-group">
    <ModalFormLabel
      label={label}
      name={name}
      tooltip={tooltip}
      required={required}
    />
    <input
      name={name}
      id={name}
      type={type}
      className={options.className || "form-control"}
      style={options.style || { width: "70%" }}
      value={value}
      onChange={onChange}
      required={required}
      {...options}
    />
  </div>
);

export default ModalFormGroup;
