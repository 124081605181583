import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";

const StepIndicator = props => {
  return (
    <div className="completeline ml-0 pl-0">
      <div className="cont">
        <ProgressBar label={props.value + "% Complete"} now={props.value} />
      </div>
    </div>
  );
};
export default StepIndicator;
