import React from "reactn";
import "./StatusForm.css";

class StatusForm extends React.PureComponent {
  constructor() {
    super();
    this.statuses = {
      0: <span className="grey-circle mr-2" />,
      1: <span className="orange-circle mr-2" />,
      2: <span className="green-circle mr-2" />
    };
  }
  render() {
    const { fields, statuses, handleChange } = this.props;
    return (
      <>
        <div className="formbody">
          <form>
            {Object.keys(fields).map(field => (
              <div className="row" key={field}>
                <div className="col-9">
                  <h3>
                    <label htmlFor={field}>
                      {this.statuses[statuses[field]]}
                      {fields[field].name}
                      {fields[field].required ? (
                        <span className="requiredField">*</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </h3>
                </div>
                <div
                  className="col-3 d-flex"
                  style={{ alignItems: "flex-end" }}
                >
                  {fields[field].customField
                    ? fields[field].customField()
                    : fields[field].type &&
                      React.createElement(fields[field].type, {
                        onChange: handleChange,
                        valueOnly: true,
                        name: field,
                        value: fields[field].val
                      })}
                </div>
              </div>
            ))}
          </form>
        </div>
        <div className="footer pt-3 pb-3">
          <div className="row">
            <div className="col-md-12 text-center">
              <span className="grey-circle mr-2" />
              Not Applicable
              <span className="green-circle mr-2 ml-4" />
              Verified
              <span className="orange-circle ml-4 mr-2" />
              Pending Verification
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default StatusForm;
