import React, { useState, useEffect } from "react";
import "../fieldComponent.css";
import "./ItemContent.css";
import Title from "../Title";
import { Dropdown } from "semantic-ui-react";

const ItemContent = props => {
  const { name, content, handleChange } = props;
  const [currentSelection, setCurrentSelection] = useState(content?.[0]?.value);
  useEffect(() => {
    handleChange(currentSelection);
  }, [currentSelection]);

  const getImageWidth = () => {
    const numofImages = content.length;
    return `${220 / numofImages - 0}px`;
  };

  return (
    <div>
      <Title name={name} />
      <div style={{ textAlign: "center" }}>
        {content.map(({ src, content, value }, index) => (
          <div
            className="text-image-list-title"
            style={{ display: "inline-block", cursor: "pointer" }}
            onClick={() => setCurrentSelection(value)}
          >
            <p>{content}</p>
            <div
              key={index}
              className={
                currentSelection === value ? "with_border" : "no_border"
              }
            >
              <img
                style={{ width: getImageWidth(), height: getImageWidth() }}
                className="text-image-list-image"
                src={src}
                alt=""
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ItemContent;
