import React from "reactn";
import { StepIndicator } from "../../../components/UI";
import Setup from "./Setup";
import AdditionalDetails from "./AdditionalDetails";
import Customization from "./Customization";
import Tags from "./Tags";
import StepBar from "../../../components/UI/StepBar";
import Validators from "../../../validators";
import axios from "axios";
import { toast } from "react-toastify";
import "../../../assets/css/componentSpecificCss/kidProgram.css";

class OrganizationalProfile extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      step: 0,
      submitted: false,
      isAdmin: false
    };

    this.titles = [
      "Organization Profile",
      "Additional Details",
      "Descriptor Tag Details",
      "Program Details"
    ];
    this.validate = [
      () =>
        Validators.Partners.Profile.ValidateOrganization(
          this.global.organizationInfo
        ),
      () =>
        Validators.Partners.Profile.ValidateAddDetails(
          this.global.organizationInfo
        ),
      () =>
        Validators.Partners.Profile.ValidateTags(this.global.organizationInfo),
      () =>
        Validators.Partners.Profile.ValidatePrograms(
          this.global.organizationInfo
        )
    ];
    this.steps = [
      { name: "Organization Profile", validateFn: this.validate[0] },
      { name: "Additional Details", validateFn: this.validate[1] },
      { name: "Descriptor Tag Details", validateFn: this.validate[2] },
      { name: "Program Details", validateFn: this.validate[3] }
    ];
    /*this.setGlobal({
      pathway: [
        ...this.global.pathway.slice(0, 1),
        "Family Profile",
        this.titles[this.state.step]
      ]
    });*/
  }

  async componentDidMount() {
    const key = this.props.match.params.access_key;
    const id = this.props.match.params.id;
    if (key) {
      const ep = `${process.env.REACT_APP_API}/frnt/program/kidprofile/${key}`;
      const res = await axios.get(ep);
      if (res.data.success) {
        console.log("/src/pages/partners/profile/Profile.jsx:",
          `/frnt/program/kidprofile/${key} ==> [id=${res.data.data.id}; address=${res.data.data.address}]`);
        this.setGlobal(
          {
            organizationInfo: {
              ...res.data.data,
              age_range: res.data.data.age_range
                .split(",")
                .map(e => ({ name: e, value: e })),

              program_structures: res.data.data.program_structures
                .split(",")
                .map(e => ({ name: e, value: e })),
              city: res.data.data.address.split(",")[1],
              province: res.data.data.address
                .split(",")[2]
                .trim()
                .split(" ")[0],
              postalcode:
                res.data.data.address.split(",")[2].trim().split(" ")[1] +
                res.data.data.address.split(",")[2].trim().split(" ")[2],
              country: "Canada"
            }
          },
          _ => this.forceUpdate()
        );
      } else {
        this.props.history.push("/");
      }
    } else if (id) {
      const ep = `${process.env.REACT_APP_API}/admin/kidprograms/${id}`;
      const res = await axios.get(ep);

      if (res.data.success) {
        console.log("/src/pages/partners/profile/Profile.jsx:",
          `/admin/kidprograms/${id} ==> [id=${res.data.data.id}; address=${res.data.data.address}]`);
        this.setGlobal(
          {
            organizationInfo: {
              ...res.data.data,
              age_range: res.data.data.age_range
                .split(",")
                .map(e => ({ name: e, value: e })),
              program_structures: res.data.data.program_structures
                .split(",")
                .map(e => ({ name: e, value: e })),
              city: res.data.data.address.split(",")[1],
              province: res.data.data.address
                .split(",")[2]
                .trim()
                .split(" ")[0],
              postalcode:
                res.data.data.address.split(",")[2].trim().split(" ")[1] +
                res.data.data.address.split(",")[2].trim().split(" ")[2],
              country: "Canada"
            }
          },
          _ => this.forceUpdate()
        );
      } else {
        this.props.history.push("/admin/kid-programs");
      }
    }

    const isAdmin = this.props.match.path.includes("/admin/kids")
      ? true
      : false;

    this.setState({ isAdmin: isAdmin });
  }

  saveProfile = async e => {
    const { step } = this.state;
    this.setGlobal({
      organizationInfo: {
        ...this.global.organizationInfo,
        profile_completion: this.state.profile_completion
      }
    });

    switch (e.target.name) {
      case "next":
        this.setState({ step: step + 1 });
        await this.dispatch.saveKids(true);
        break;
      case "back":
        this.setState({ step: step - 1 });
        await this.dispatch.saveKids(true);
        break;
      case "save":
        await this.dispatch.saveKids(sl => {
          this.props.history.push(`/kids/${sl}`);
        });
        break;
      default:
        return;
    }
  };
  small_save = async _ => {
    await this.dispatch.saveKids(true);
    toast.success(`Save Successful`);
  };

  changePage = async page => {
    /*await this.dispatch.saveProfile();
    this.setGlobal({
      pathway: [...this.global.pathway.slice(0, 2), this.titles[page]]
    });*/
    this.setState({ step: page });
  };

  getPage = () => {
    switch (this.state.step) {
      case 0:
        return <Setup />;
      case 1:
        return <AdditionalDetails />;
      case 2:
        return <Tags />;
      default:
        return <Customization match={this.props.match} />;
    }
  };

  checkValid() {
    const { step } = this.state;
    return this.validate[step] && !this.validate[step]();
  }

  getCompletion() {
    let completed = 0;

    for (let v of this.validate) {
      completed += v();
    }

    this.setState({
      profile_completion: (completed / this.validate.length) * 100
    });

    return (completed / this.validate.length) * 100;
  }

  handleChange = (e, v) => {
    this.setGlobal({
      organizationInfo: {
        ...this.global.organizationInfo,
        [e]: v
      }
    });
  };

  render() {
    return (
      <div className="container-fluid">
        {this.global.organizationInfo && (
          <>
            <div className="cont">
              <h1 id="page-title">Organization Profile</h1>
            </div>
            {!this.state.submitted && (
              <>
                <div
                  style={{
                    background: this.state.isAdmin
                      ? "#dfe4f4"
                      : "rgb(247, 247, 248)"
                  }}
                >
                  <StepBar
                    {...{
                      steps: this.steps,
                      changeFn: this.changePage,
                      current: this.state.step
                    }}
                  />
                  <StepIndicator value={this.getCompletion()} />
                </div>
                {this.getPage()}
                <div className="cont mb-5">
                  <div className="buttons w-100">
                    {/* {this.global.dsUser &&
                      this.global.dsUser.accountType === "Admin" && ()} */}
                    <button
                      onClick={this.small_save}
                      name="save"
                      className="savebtn"
                    >
                      SAVE
                    </button>
                    <button
                      onClick={this.saveProfile}
                      disabled={this.state.step === 0}
                      name="back"
                      className="nextbtn"
                    >
                      BACK
                    </button>
                    <button
                      onClick={this.saveProfile}
                      disabled={
                        this.state.step === this.titles.length - 1 ||
                        this.checkValid()
                      }
                      name="next"
                      className="nextbtn"
                    >
                      NEXT
                    </button>
                    <button
                      onClick={this.saveProfile}
                      name="save"
                      className="savebtn"
                      disabled={this.getCompletion() !== 100}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </>
            )}
            {this.state.submitted && (
              <p style={{ textAlign: "center" }}>
                Thank you for updating your profile! We will review your
                information and let you know when your profile goes live!
              </p>
            )}
          </>
        )}
      </div>
    );
  }
}

export default OrganizationalProfile;
