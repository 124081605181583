import { setGlobal } from "reactn";

const enrollment_details = {
  start_date: "Feb 2 2019",
  end_date: "Sep 21 2019",
  plan: "Plan A",
  days_of_week: "Tues, Thurs",
  available_spot: "Yes"
};

setGlobal({ enrollment_details: enrollment_details });

const contacts = {
  full_name: "Johnny Johnson",
  Address: "183 Spadina Ave",
  cell_phone: "905 372 3277",
  work_phone: "647 282 4837",
  company: "None"
};

setGlobal({ contacts: contacts });

const student = {
  full_name: "Johnny Johnson",
  birth_date: "Sep 21 2018",
  gender: "Male",
  language: "English, Mandarin",
  allergies: "None",
  immunized: "Yes",
  medication: "None"
};

setGlobal({ student: student });
