import React from "react";
import PropTypes from "prop-types";
import { getSelectedProgramPlan } from "./utils";

const ProgramSemesterInstallmentsAllFee = props => {
  const plan = getSelectedProgramPlan(props.program, props.selected);
  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px",
        borderBottom: "1px solid #dedede"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif",
          fontWeight: 500,
          fontSize: "14px"
        }}
      >
        Semester Fee
      </p>
      <span style={{ fontWeight: 500 }}>
        ${parseFloat(plan.total_price).toFixed(2)}
      </span>
    </li>
  );
};

ProgramSemesterInstallmentsAllFee.propTypes = {
  program: PropTypes.shape({
    plans: PropTypes.array.isRequired
  }).isRequired,
  selected: PropTypes.number.isRequired
};

export default ProgramSemesterInstallmentsAllFee;
