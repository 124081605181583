const Periods = [
  "June 1 - June 8, 2019",
  "August 3 - August 10, 2019",
  "July 8 - July 15, 2019",
  "November 4 - November 11, 2019",
  "December 18 - December 25, 2019"
];

const getPeriods = amt => {
  return [...Array(amt).keys()].map(
    n => Periods[Math.floor(Math.random() * Periods.length)]
  );
};

export { Periods, getPeriods };
