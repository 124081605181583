import React, { Component } from "react";
class becomeAnInstructor extends Component {
  render() {
    return (
      <>
        <iframe
          src="https://form.typeform.com/to/MKkatFT1?typeform-medium=embed-snippet"
          width="100%"
          height="600px"
          title="Instructor"
        />
      </>
    );
  }
}

export default becomeAnInstructor;

//"https://contact701272.typeform.com/to/MKkatFT1"
