import React from "reactn";
import { Mail, Schedule, SliderImg2, SchoolSolid } from "../../../assets";
import RequestInformationModal from "../../modals/RequestInformationModal";
import RequestTourModal from "../../modals/RequestTourModal";
import ApplyEnrollModal from "../../modals/ApplyEnrollModal";
import Plan from "../../modals/DifferentPlanModal";
import { withRouter, Redirect } from "react-router-dom";
import DifferentPlanModal from "../../modals/DifferentPlanModal";
class MainBanner extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      applyModal: false,
      tourModal: false
    };
    this.showModal = this.showModal.bind(this);
    this.showApplyModal = this.showApplyModal.bind(this);
  }

  showModal = () => {
    this.setState({ modalShow: true });
  };
  showApplyModal = () => {
    const slug = this.props.match.params.slug;
    if (this.global.dsUser && this.global.dsUser.accountType === "Parent") {
      this.props.history.push(
        `/parents/interested-programs/${slug}/choose-child`
      );
    } else {
      this.setState({ applyModal: true });
    }
  };

  render() {
    const vals = this.global.school;

    return (
      <div>
        {!this.state.tourModal ? null : (
          <RequestTourModal
            onClose={() =>
              this.setState({
                tourModal: false
              })
            }
          />
        )}
        {this.state.plan && (
          <DifferentPlanModal
            onClose={_ =>
              this.setState({
                plan: false
              })
            }
            title="Sign-up to be the first to know when this program opens!"
          />
        )}
        {/*!this.state.modalShow ? null : (
          <RequestInformationModal
            onClose={() =>
              this.setState({
                modalShow: false
              })
            }
          />
          )*/}

        {!this.state.applyModal ? null : (
          <ApplyEnrollModal
            onClose={() => this.setState({ applyModal: false })}
          />
        )}

        <div
          className="container-fluid teacherhero"
          style={{
            backgroundImage: `url(${
              vals.programCoverPhoto
                ? `${process.env.REACT_APP_DS_FILES_S3}/${vals.programCoverPhoto}`
                : SliderImg2
            })`
          }}
        >
          {vals.publishStage == 2 ? (
            <div className="profile-container">
              <div className="col-md-12">
                <button
                  className="btn1"
                  data-remote="true"
                  onClick={() => this.setState({ tourModal: true })}
                >
                  <img src={Schedule} alt="" />
                  Schedule a Tour
                </button>
                {/*
                <button className="btn2" onClick={this.showModal}>
                  <img src={Mail} alt="" />
                  Request Information
                </button>
                */}
                <button
                  className="btn1"
                  data-remote="true"
                  onClick={() =>
                    this.global.dsUser &&
                    this.global.dsUser.accountType === "Parent"
                      ? this.setState({
                          redirect: {
                            to: `/user/interested-programs/${vals.slug}/choose-child`
                          }
                        })
                      : this.setState({
                          redirect: {
                            to: `/sign-in?follow=/user/interested-programs/${vals.slug}/choose-child`
                          }
                        })
                  }
                >
                  <img src={SchoolSolid} style={{ maxHeight: 25 }} alt="" />
                  Apply to Enroll
                </button>
              </div>
            </div>
          ) : null}
          {vals.publishStage == "1" && !this.global.dsUser ? (
            <div className="profile-container">
              <div className="col-md-12">
                <button
                  className="btn1"
                  data-remote="true"
                  onClick={() => this.setState({ plan: true })}
                >
                  <img src={Schedule} alt="" />
                  Sign Up!
                </button>
              </div>
            </div>
          ) : null}
          {this.props.location.pathname.includes("/preview/") ? (
            <div className="profile-container">
              <div className="col-md-12">
                <button className="btn1" data-remote="true">
                  <img src={Schedule} alt="" />
                  Schedule a Tour
                </button>
                <button className="btn2">
                  <img src={Mail} alt="" />
                  Request Information
                </button>
                <button className="btn1" data-remote="true">
                  <img src={Schedule} alt="" />
                  Apply to Enroll
                </button>
              </div>
            </div>
          ) : null}
          {this.state.redirect && <Redirect to={this.state.redirect.to} />}
        </div>
      </div>
    );
  }
}
export default withRouter(MainBanner);
