import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { promotion_discount, coupon_discount, getSelectedProgramPlan } from "./utils";

const TaxableOnlineOneTimeSemester = props => {
  const [taxName, setTaxName] = useState("Loading");
  const [taxAmount, setTaxAmount] = useState("Loading");

  const calculateTaxAmount = (plan) => {
    let total_price = parseFloat(plan.total_price);
    if (plan.addOnItems) {
      plan.addOnItems.map(item => {
        total_price += parseFloat(item.value);
      });
    }
    if (props.installments) {
      const tot = parseFloat(
        parseFloat(
          total_price *
            (props.installments
              ? 1 +
                parseInt(
                  plan
                    .installments_interest
                ) /
                  100
              : 1) || 0
        ) *
          (plan.taxInfo[0]
            .percentage /
            100)
      ).toFixed(2);
      props.taxAmount(tot);
      setTaxAmount(tot);
      return;
    } else {
      // no installment
      let tot = 0;
      if (props.promotion) {
        const discount_amount = promotion_discount(
          props.promotion,
          total_price
        );
        tot =
          Math.max(0, total_price) * plan.isTaxEnabled
            ? parseFloat(plan.taxInfo[0].percentage) / 100
            : 1;
      } else {
        const discount_amount = props.coupon
          ? coupon_discount(props.coupon, total_price)
          : 0;

        tot =
          Math.max(0, total_price - discount_amount) *
          (plan.isTaxEnabled
            ? parseFloat(plan.taxInfo[0].percentage) / 100
            : 1);
      }
      console.log("setting ov tax amount to be", tot.toFixed(2));
      props.taxAmount(tot.toFixed(2));
      setTaxAmount(tot.toFixed(2));
    }
  };
  const getTaxName = (plan) => {
    const displayName = plan
      .taxInfo[0].display_name;
    const percent = plan.taxInfo[0]
      .percentage;
    setTaxName(`${displayName} on subtotal (${percent}%)`);
  };

  useEffect(() => {
    const plan = getSelectedProgramPlan( props.program, props.selected );
    calculateTaxAmount(plan);
    getTaxName(plan);
  }, [props.coupon]);

  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px",
        borderBottom: "1px solid #dedede"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif"
        }}
      >
        {taxName}
      </p>
      <span>${taxAmount}</span>
    </li>
  );
};

TaxableOnlineOneTimeSemester.propTypes = {
  program: PropTypes.shape({
    plans: PropTypes.array.isRequired
  }).isRequired,
  selected: PropTypes.number.isRequired,
  installments: PropTypes.number.isRequired,
  promotion: PropTypes.object,
  coupon: PropTypes.object
};

export default TaxableOnlineOneTimeSemester;
