import React, { useEffect, useState, useGlobal } from "reactn";
import PropTypes from "prop-types";

import {
  getSelectedProgramPlan,
  isProgramPlanTaxEnabled,
  coupon_discount,
  applyGift
} from "./utils";

const ProgramSingleSessionsTotal = props => {
  const [total, setTotal] = useState("Loading");
  const [_, setPreGiftTotal] = useGlobal("preGiftTotal");
  const giftBalance = useGlobal("giftBalance")[0];

  const calculateTotal = (plan) => {
    const total_price = plan.total_price * props.singleSessionQuantity;
    console.log("props", props);

    // not an installment
    let tot = 0;

    const taxRate = plan.isTaxEnabled
      ? parseFloat(plan.taxInfo[0].percentage / 100)
      : 0;

    const discount_amount = props.coupon
      ? coupon_discount(props.coupon, total_price)
      : 0;

    // const tax_amount = Math.max(0, total_price) * taxRate;
    const tax_amount = Math.max(0, total_price - discount_amount) * taxRate;

    console.log("discount_amount", discount_amount);

    const creditCardPercent = props.program.transaction_cost
      ? parseFloat(1.5) / 100
      : 1;
    // const creditCardFee = props.program.transaction_cost
    //   ? Math.max(0.5, total_price - discount_amount) * creditCardPercent
    //   : 0;
    const creditCardFee = 0;

    setPreGiftTotal(
      (total_price !== 0 ? Math.max(0, total_price - discount_amount) : 0) +
        tax_amount
    );
    console.log("taxAmount", tax_amount);
    tot =
      (total_price !== 0 ? Math.max(0, total_price - discount_amount) : 0) +
      tax_amount +
      creditCardFee;

    console.log("tot", tot);

    ({ amount: tot } = applyGift(
      props.program.manual_invoices,
      giftBalance,
      tot
    ));
    console.log("tot", tot);
    props.totalAmount(tot.toFixed(2));

    setTotal(tot.toFixed(2));
  };

  useEffect(() => {
    const plan = getSelectedProgramPlan(props.program, props.selected);
    if (
      plan.method === "Online" ||
      plan.type === "Free"
    ) {
      setTotal("0.00");
    } else {
      calculateTotal(plan);
    }
  }, [props.coupon, giftBalance]);

  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px",
        fontSize: "14px"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif",
          fontWeight: 600
        }}
      >
        Total
      </p>
      <span style={{ fontWeight: "bold" }}>${total}</span>
    </li>
  );
};

ProgramSingleSessionsTotal.propTypes = {
  program: PropTypes.shape({
    plans: PropTypes.array.isRequired
  }).isRequired,
  selected: PropTypes.number.isRequired,
  installments: PropTypes.number.isRequired,
  coupon: PropTypes.object
};

export default ProgramSingleSessionsTotal;
