import React from "react";
import PropTypes from "prop-types";

import { isProgramPlanTaxEnabled, getSelectedProgramPlan } from "./utils";
import CheckoutPromotionDisplay from "./CheckoutPromotionDisplay";

//  Regular Semester Program Components
import OnlineOneTimeSemesterPromotionTotal from "./OnlineOneTimeSemesterPromotionTotal";
import TaxableOnlineOneTimeSemester from "./TaxableOnlineOneTimeSemester";
import TaxableOnlineOneTimePromotionSemester from "./TaxableOnlineOneTimePromotionSemester";
import OnlineOneTimeSemesterSubtotal from "./OnlineOneTimeSemesterSubtotal";
import OnlineOneTimeSemesterTotal from "./OnlineOneTimeSemesterTotal";
import OnlineOneTimeSemester from "./OnlineOneTimeSemester";
import ProgramSemesterAddOnItem from "./ProgramSemesterAddOnItem";
// import OnlineOneTimeSemesterAddOnItem from "./OnlineOneTimeSemesterAddOnItem";

// Recurring Program Components
import ProgramDeposit from "./ProgramDeposit";
import ProgramTuitionRate from "./ProgramTuitionRate";
import ProgramSubtotal from "./ProgramSubtotal";
import ProgramTotal from "./ProgramTotal";
import TaxablePromotionOnlineProgram from "./TaxablePromotionOnlineProgram";
import TaxableProgram from "./TaxableProgram";
import ProgramPromotionsTotal from "./ProgramPromotionsTotal";
import TransactionCostProgram from "./TransactionCostProgram";

import ProgramRegularSemesterSubtotal from "./ProgramRegularSemesterSubtotal";
import ProgramRegularSemesterTotal from "./ProgramRegularSemesterTotal";
import ProgramFreePlan from "./ProgramFreePlan";
import ProgramRegularSemesterPromotionTotal from "./ProgramRegularSemesterPromotionTotal";

// Installment components
import ProgramSemesterInstallmentsAllFee from "./ProgramSemesterInstallmentsAllFee";
import ProgramSemesterInstallmentsAllSubtotal from "./ProgramSemesterInstallmentsAllSubtotal";
import ProgramSemesterInstallmentsAllTotal from "./ProgramSemesterInstallmentsAllTotal";
import ProgramSemesterInstallmentsAllTotalPromotion from "./ProgramSemesterInstallmentsAllTotalPromotion";
import TaxableProgramSemesterInstallmentsAll from "./TaxableProgramSemesterInstallmentsAll";
import ProgramSemesterInstallmentsAddOnItem from "./ProgramSemesterInstallmentsAddOnItem";
import ProgramSemesterInstallmentDeposit from "./ProgramSemesterInstallmentDeposit";
import ProgramSemesterInstallmentsFee1 from "./ProgramSemesterInstallmentsFee1";
import ProgramSemesterInstallmentsSubtotal from "./ProgramSemesterInstallmentsSubtotal";
import ProgramSemesterInstallmentsTotal from "./ProgramSemesterInstallmentsTotal";
import ProgramInstallmentsFuturePayments from "./ProgramInstallmentsFuturePayments";
import TaxableProgramSemesterInstallments from "./TaxableProgramSemesterInstallments";
import ProgramSemesterInstallmentsTotalPromotion from "./ProgramSemesterInstallmentsTotalPromotion";

// Dropins Components
import ProgramSemesterDropinsSubtotal from "./ProgramDropInsSubtotal";
import ProgramDropinsTotal from "./ProgramDropinsTotal";
import ProgramDropinsPromotionsTotal from "./ProgramDropinsPromotionsTotal";
import CheckoutDropInsSelectedDays from "./CheckoutDropInsSelectedDays";
import TaxableProgramDropIns from "./TaxableProgramDropIns";
import TransactionCostProgramDropIn from "./TransactionCostProgramDropIn";

// Coupons
import CheckoutCouponDisplay from "./CheckoutCouponDisplay";

import TransactionCostOnline from "./TransactionCostOnline";

import CheckoutGiftCard from "./CheckoutGiftCard";

//Program Single Sessions
import ProgramSingleSessions from "./ProgramSingleSessions";
import ProgramSingleSessionsSubtotal from "./ProgramSingleSessionsSubtotal";
import TaxableProgramSingleSessions from "./TaxableProgramSingleSessions";
import TransactionCostProgramSingleSessions from "./TransactionCostProgramSingleSessions";
import ProgramSingleSessionsTotal from "./ProgramSingleSessionsTotal";
import ProgramSingleSessionsPromotionTotal from "./ProgramSingleSessionsPromotionsTotal";

const CheckoutOnline = props => {
  const selected_plan = getSelectedProgramPlan(props.program, props.selected);

  console.log("props", props);

  let giftBalance = props.giftBalance;
  if (!props.giftBalance) {
    giftBalance = 0;
  }
  // Recuring Program
  if (!selected_plan.type) {
    return (
      <>
        {selected_plan.deposit && !props.isWaiveDeposit && (
          <>
            <ProgramDeposit
              data-testid="deposit-plan"
              program={props.program}
              selected={props.selected}
              depositAmount={e => props.depositAmount(e)}
            />
          </>
        )}
        {selected_plan.tuition_rate && (
          <ProgramTuitionRate
            displayDate={props.displayDate}
            program={props.program}
            selected={props.selected}
            itemAmount={e => props.itemAmount(e)}
          />
        )}
        {isProgramPlanTaxEnabled(props.program, props.selected) && (
          <>
            {props.promotion ? (
              <TaxablePromotionOnlineProgram
                promotion={props.promotion}
                program={props.program}
                selected={props.selected}
                taxAmount={e => props.taxAmount(e)}
              />
            ) : (
              <TaxableProgram
                coupon={props.coupon}
                program={props.program}
                selected={props.selected}
                displayDate={props.displayDate}
                taxAmount={e => props.taxAmount(e)}
              />
            )}
          </>
        )}
        {/* <ProgramSubtotal program={props.program} selected={props.selected} /> */}
        {props.promotion && (
          <CheckoutPromotionDisplay
            promotion={props.promotion}
            selected={props.selected}
            program={props.program}
            promotionAmount={e => props.promotionAmount(e)}
          />
        )}
        {props.coupon && (
          <CheckoutCouponDisplay
            coupon={props.coupon}
            selected={props.selected}
            program={props.program}
            type={props.type}
            couponAmount={e => props.couponAmount(e)}
          />
        )}
        {!!giftBalance && (
          <CheckoutGiftCard
            giftBalance={giftBalance}
            manual={props.program.manual_invoices}
          />
        )}
        {
          // calculate different totals depending of existance of promotion
          props.promotion ? (
            <ProgramPromotionsTotal
              promotion={props.promotion}
              program={props.program}
              selected={props.selected}
              displayDate={props.displayDate}
              totalAmount={e => props.totalAmount(e)}
            />
          ) : (
            <ProgramTotal
              coupon={props.coupon}
              program={props.program}
              selected={props.selected}
              displayDate={props.displayDate}
              paymentDate={props.paymentDate}
              totalAmount={e => props.totalAmount(e)}
              isWaiveDeposit={props.isWaiveDeposit}
            />
          )
        }
      </>
    );
  }

  // Semestered Dropins
  if (props.dropins) {
    return (
      <>
        {/* Semester Drop Ins */}
        <CheckoutDropInsSelectedDays
          selected={props.selected}
          program={props.program}
          selected_days={props.selected_days}
        />
        <ProgramSemesterDropinsSubtotal
          program={props.program}
          selected={props.selected}
          selected_days_length={
            !props.selected_days ? 0 : props.selected_days?.length
          }
          itemAmount={e => props.itemAmount(e)}
        />

        {props.promotion && (
          <CheckoutPromotionDisplay
            selected_days_length={props?.selected_days?.length}
            program_type="Semester-Dropins"
            promotion={props.promotion}
            program={props.program}
            selected={props.selected}
            promotionAmount={e => props.promotionAmount(e)}
          />
        )}
        {props.coupon && (
          <CheckoutCouponDisplay
            type={props.type}
            selected_days_length={props?.selected_days?.length}
            program_type="Semester-Dropins"
            coupon={props.coupon}
            program={props.program}
            selected={props.selected}
            couponAmount={e => props.couponAmount(e)}
          />
        )}
        {isProgramPlanTaxEnabled(props.program, props.selected) && (
          <>
            {props.promotion ? (
              <>
                <TaxableProgramDropIns
                  promotion={props.promotion}
                  program={props.program}
                  selected={props.selected}
                  selected_days_length={props?.selected_days?.length}
                  taxAmount={e => props.taxAmount(e)}
                />
              </>
            ) : (
              <>
                <TaxableProgramDropIns
                  coupon={props.coupon}
                  program={props.program}
                  selected={props.selected}
                  selected_days_length={props?.selected_days?.length}
                  taxAmount={e => props.taxAmount(e)}
                />
              </>
            )}
          </>
        )}
        {!!giftBalance && (
          <CheckoutGiftCard
            giftBalance={giftBalance}
            manual={props.program.manual_invoices}
          />
        )}
        {props.promotion ? (
          <>
            <ProgramDropinsPromotionsTotal
              promotion={props.promotion}
              program={props.program}
              selected={props.selected}
              selected_days_length={props?.selected_days?.length}
              totalAmount={e => props.totalAmount(e)}
            />{" "}
          </>
        ) : (
          <>
            <ProgramDropinsTotal
              coupon={props.coupon}
              program={props.program}
              selected={props.selected}
              selected_days_length={props?.selected_days?.length}
              totalAmount={e => props.totalAmount(e)}
            />{" "}
          </>
        )}
      </>
    );
  }

  // Regular Free Program
  if (selected_plan.type === "Free") {
    return (
      <>
        <ProgramFreePlan program={props.program} selected={props.selected} />
        <ProgramRegularSemesterSubtotal
          program={props.program}
          selected={props.selected}
          installments={props.installments}
        />
        {/* {
          // if there is a promotion show it
          props.promotion && (
            <>
              <CheckoutPromotionDisplay
                program_type="Regular-Semester"
                promotion={props.promotion}
                program={props.program}
                selected={props.selected}
              />
            </>
          )
        }
        {props.coupon && (
          <>
            <CheckoutCouponDisplay
              program_type="Regular-Semester"
              type={props.type}
              program={props.program}
              coupon={props.coupon}
              selected={props.selected}
            />
          </>
        )} */}

        {/* {
          // if there is a tax enabled then show it depending the promotion present
          isProgramPlanTaxEnabled(props.program, props.selected) && (
            <>
              {props.promotion ? (
                <>
                  <TaxableProgramRegularSemester
                    promotion={props.promotion}
                    program={props.program}
                    selected={props.selected}
                    installments={props.installments}
                  />
                </>
              ) : (
                <>
                  <TaxableProgramRegularSemester
                    coupon={props.coupon}
                    program={props.program}
                    selected={props.selected}
                    installments={props.installments}
                  />
                </>
              )}
            </>
          )
        } */}

        {!!giftBalance && (
          <CheckoutGiftCard
            giftBalance={giftBalance}
            manual={props.program.manual_invoices}
          />
        )}
        {props.promotion ? (
          <>
            <ProgramRegularSemesterPromotionTotal
              promotion={props.promotion}
              program={props.program}
              selected={props.selected}
              installments={props.installments}
            />{" "}
          </>
        ) : (
          <>
            <ProgramRegularSemesterTotal
              coupon={props.coupon}
              program={props.program}
              selected={props.selected}
              installments={props.installments}
            />{" "}
          </>
        )}
      </>
    );
  }

  // Online One-Time / Semester Online
  if (selected_plan.type === "Semester" || selected_plan.type === "One-Time") {
    let totalAddOnFee = 0;
    if (selected_plan.addOn && selected_plan.addOnItems) {
      selected_plan.addOnItems.map(item => {
        totalAddOnFee += parseFloat(item.value);
      });
    }
    console.log("Add on Fee", totalAddOnFee);

    // Installments
    if (props.installments) {
      // console.log("SELECTED PLAN", selected_plan);
      // let totalAddOnFee = 0;
      // if (selected_plan.addOn && selected_plan.addOnItems) {
      //   selected_plan.addOnItems.map(item => {
      //     totalAddOnFee += parseFloat(item.value);
      //   });
      // }
      // console.log("TOTAL ADD ON FEE", totalAddOnFee);
      return (
        <>
          {/* Semester Installments */}
          <ProgramSemesterInstallmentsAllFee
            program={props.program}
            selected={props.selected}
          />
          {selected_plan.addOnItems &&
            selected_plan.addOnItems.map((item, index) => {
              return (
                <ProgramSemesterInstallmentsAddOnItem
                  program={props.program}
                  selected={props.selected}
                  // addOnSemesterItemAmount={e => props.addOnSemesterItemAmount(e)}
                  item={item}
                // totalAddOnFee={totalAddOnFee}
                />
              );
            })}
          <ProgramSemesterInstallmentsAllSubtotal
            installments={props.installments}
            program={props.program}
            selected={props.selected}
            itemAmount={e => props.itemAmount(e)}
          />
          {props.coupon && (
            <CheckoutCouponDisplay
              type={props.type}
              selected_days_length={props.selected_days?.length || 0}
              program_type="Installments"
              installments={props.installments}
              coupon={props.coupon}
              program={props.program}
              selected={props.selected}
              couponAmount={e => props.couponAmount(e)}
            />
          )}
          {isProgramPlanTaxEnabled(props.program, props.selected) && (
            <>
              {props.promotion ? (
                <>
                  <TaxableProgramSemesterInstallmentsAll
                    promotion={props.promotion}
                    installments={props.installments}
                    program={props.program}
                    selected={props.selected}
                    taxAmount={e => props.taxAmount(e)}
                  />
                </>
              ) : (
                <>
                  <TaxableProgramSemesterInstallmentsAll
                    coupon={props.coupon}
                    installments={props.installments}
                    program={props.program}
                    selected={props.selected}
                    taxAmount={e => props.taxAmount(e)}
                  />
                </>
              )}
            </>
          )}
          {props.promotion ? (
            <>
              <ProgramSemesterInstallmentsAllTotalPromotion
                promotion={props.promotion}
                installments={props.installments}
                program={props.program}
                selected={props.selected}
                totalAmount={e => props.totalAmount(e)}
              />
            </>
          ) : (
            <>
              <ProgramSemesterInstallmentsAllTotal
                coupon={props.coupon}
                installments={props.installments}
                program={props.program}
                selected={props.selected}
                totalAmount={e => props.totalAmount(e)}
              />
            </>
          )}
          {/* Payment Info */}
          {selected_plan.includeDeposit &&
            selected_plan.deposit &&
            selected_plan.deposit !== "0" ? (
            <>
              <ProgramSemesterInstallmentDeposit
                program={props.program}
                selected={props.selected}
                depositAmount={e => props.depositAmount(e)}
              />
            </>
          ) : (
            <>
              <ProgramSemesterInstallmentsFee1
                promotions={props.promotions}
                coupon={props.coupon}
                installments={props.installments}
                program={props.program}
                selected={props.selected}
                totalAmount={e => props.totalAmount(e)}
              />
            </>
          )}
          {/* <ProgramSemesterInstallmentsSubtotal
          installments={props.installments}
          program={props.program}
          selected={props.selected}
          itemAmount={e => props.itemAmount(e)}
        /> */}
          {/* {
          // if there is a promotion the display the values
          props.promotion && (
            <CheckoutPromotionDisplay
              program_type="Installments"
              installments={props.installments}
              promotion={props.promotion}
              selected={props.selected}
              program={props.program}
              promotionAmount={e => props.promotionAmount(e)}
            />
          )
        }
        {props.coupon && (
          <>
            <CheckoutCouponDisplay
              program_type="Installments"
              coupon={props.coupon}
              program={props.program}
              type={props.type}
              selected={props.selected}
              installments={props.installments}
              couponAmount={e => props.couponAmount(e)}
            />
          </>
        )}
        {isProgramPlanTaxEnabled(props.program, props.selected) && (
          <>
            {props.promotion ? (
              <>
                <TaxableProgramSemesterInstallments
                  promotion={props.promotion}
                  installments={props.installments}
                  program={props.program}
                  selected={props.selected}
                  taxAmount={e => props.taxAmount(e)}
                />
              </>
            ) : (
              <>
                <TaxableProgramSemesterInstallments
                  coupon={props.coupon}
                  installments={props.installments}
                  program={props.program}
                  selected={props.selected}
                  taxAmount={e => props.taxAmount(e)}
                />
              </>
            )}
          </>
        )}
        {props.program.transaction_cost && (
          <TransactionCostProgramInstallments
            promotion={props.promotion}
            coupon={props.coupon}
            installments={props.installments}
            program={props.program}
            selected={props.selected}
            creditCardFeeAmount={e => props.creditCardFeeAmount(e)}
          />
        )}
        {!!giftBalance && (
          <CheckoutGiftCard
            giftBalance={giftBalance}
            manual={props.program.manual_invoices}
          />
        )}
        {props.promotion ? (
          <>
            <ProgramSemesterInstallmentsTotalPromotion
              promotion={props.promotion}
              installments={props.installments}
              program={props.program}
              selected={props.selected}
              totalAmount={e => props.totalAmount(e)}
            />
          </>
        ) : (
          <>
            <ProgramSemesterInstallmentsTotal
              coupon={props.coupon}
              installments={props.installments}
              program={props.program}
              selected={props.selected}
              totalAmount={e => props.totalAmount(e)}
            />
          </>
        )}{" "} */}
          <ProgramInstallmentsFuturePayments
            coupon={props.coupon}
            program={props.program}
            selected={props.selected}
          />
        </>
      );
    } else {
      return (
        <>
          <OnlineOneTimeSemester
            program={props.program}
            selected={props.selected}
            itemAmount={e => props.itemAmount(e)}
          />
          {selected_plan.addOnItems &&
            selected_plan.addOnItems.map((item, index) => {
              return (
                <ProgramSemesterAddOnItem
                  program={props.program}
                  selected={props.selected}
                  addOnSemesterItemAmount={e => props.addOnSemesterItemAmount(e)}
                  item={item}
                  totalAddOnFee={totalAddOnFee}
                />
              );
            })}
          <OnlineOneTimeSemesterSubtotal
            program={props.program}
            selected={props.selected}
            installments={props.installments}
          />
          {
            // if there is a tax enabled then show it depending the promotion present
            isProgramPlanTaxEnabled(props.program, props.selected) && (
              <>
                {props.promotion ? (
                  <>
                    <TaxableOnlineOneTimePromotionSemester
                      promotion={props.promotion}
                      program={props.program}
                      selected={props.selected}
                      installments={props.installments}
                      taxAmount={e => props.taxAmount(e)}
                    />
                  </>
                ) : (
                  <>
                    <TaxableOnlineOneTimeSemester
                      coupon={props.coupon}
                      program={props.program}
                      selected={props.selected}
                      installments={props.installments}
                      taxAmount={e => props.taxAmount(e)}
                    />
                  </>
                )}
              </>
            )
          }
          {
            // if there is a promotion show it
            props.promotion && (
              <>
                <CheckoutPromotionDisplay
                  program_type={selected_plan.type}
                  promotion={props.promotion}
                  program={props.program}
                  selected={props.selected}
                  promotionAmount={e => props.promotionAmount(e)}
                />
              </>
            )
          }
          {props.coupon && (
            <>
              <CheckoutCouponDisplay
                program_type={"Regular-Semester"}
                type={props.type}
                program={props.program}
                taxes={props.taxes}
                coupon={props.coupon}
                selected={props.selected}
                couponAmount={e => props.couponAmount(e)}
              />
            </>
          )}

          {!!giftBalance && (
            <CheckoutGiftCard
              giftBalance={giftBalance}
              manual={props.program.manual_invoices}
            />
          )}
          {props.promotion ? (
            <>
              <OnlineOneTimeSemesterPromotionTotal
                promotion={props.promotion}
                program={props.program}
                selected={props.selected}
                installments={props.installments}
                totalAmount={e => props.totalAmount(e)}
              />{" "}
            </>
          ) : (
            <>
              <OnlineOneTimeSemesterTotal
                coupon={props.coupon}
                program={props.program}
                selected={props.selected}
                totalAmount={e => props.totalAmount(e)}
              />{" "}
            </>
          )}
        </>
      );
    }
  }

  // Single Sessions Program
  if (selected_plan.type === "Single Sessions") {
    return (
      <>
        <ProgramSingleSessions
          program={props.program}
          selected={props.selected}
          itemAmount={e => props.itemAmount(e)}
          singleSessionQuantity={props.singleSessionQuantity}
        />
        <ProgramSingleSessionsSubtotal
          program={props.program}
          selected={props.selected}
          installments={props.installments}
          singleSessionQuantity={props.singleSessionQuantity}
        />
        {
          // if there is a promotion show it
          props.promotion && (
            <>
              <CheckoutPromotionDisplay
                program_type="Single-Sessions"
                promotion={props.promotion}
                program={props.program}
                selected={props.selected}
                promotionAmount={e => props.promotionAmount(e)}
                singleSessionQuantity={props.singleSessionQuantity}
              />
            </>
          )
        }
        {props.coupon && (
          <>
            <CheckoutCouponDisplay
              program_type="Single-Sessions"
              type={props.type}
              program={props.program}
              coupon={props.coupon}
              selected={props.selected}
              taxes={props.taxes}
              couponAmount={e => props.couponAmount(e)}
              singleSessionQuantity={props.singleSessionQuantity}
            />
          </>
        )}

        {
          // if there is a tax enabled then show it depending the promotion present
          isProgramPlanTaxEnabled(props.program, props.selected) && (
            <>
              {props.promotion ? (
                <>
                  <TaxableProgramSingleSessions
                    promotion={props.promotion}
                    program={props.program}
                    selected={props.selected}
                    taxAmount={e => props.taxAmount(e)}
                    singleSessionQuantity={props.singleSessionQuantity}
                  />
                </>
              ) : (
                <>
                  <TaxableProgramSingleSessions
                    coupon={props.coupon}
                    program={props.program}
                    selected={props.selected}
                    taxAmount={e => props.taxAmount(e)}
                    singleSessionQuantity={props.singleSessionQuantity}
                  />
                </>
              )}
            </>
          )
        }

        {!!giftBalance && (
          <CheckoutGiftCard
            giftBalance={giftBalance}
            manual={props.program.manual_invoices}
          />
        )}

        {props.promotion ? (
          <>
            <ProgramSingleSessionsPromotionTotal
              promotion={props.promotion}
              program={props.program}
              selected={props.selected}
              totalAmount={e => props.totalAmount(e)}
              singleSessionQuantity={props.singleSessionQuantity}
            />{" "}
          </>
        ) : (
          <>
            <ProgramSingleSessionsTotal
              coupon={props.coupon}
              program={props.program}
              selected={props.selected}
              installments={props.installments}
              totalAmount={e => props.totalAmount(e)}
              singleSessionQuantity={props.singleSessionQuantity}
            />{" "}
          </>
        )}
      </>
    );
  }
};

CheckoutOnline.propTypes = {
  program: PropTypes.object.isRequired,
  selected: PropTypes.number.isRequired,
  promotion: PropTypes.object,
  coupon: PropTypes.object, // global
  type: PropTypes.string.isRequired,
  installments: PropTypes.any, // state
  dropins: PropTypes.any,
  displayDate: PropTypes.any, // state
  selected_days: PropTypes.any // state
};

export default CheckoutOnline;
