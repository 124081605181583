import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import {
  getSelectedProgramPlan,
  promotion_discount,
  coupon_discount,
  MINIMUM_CHARGE
} from "./utils";
const TaxableProgramDropIns = props => {
  const [tax, setTax] = useState("Loading");
  const calculateTax = () => {
    const plan = getSelectedProgramPlan(props.program, props.selected);
    const total_price = parseFloat(plan.total_price) * (plan.premium ? 1 + parseFloat(plan.premium) / 100 : 1);

    const subtotal = total_price * props.selected_days_length;
    if (props.promotion) {
      const discount_amount = promotion_discount(props.promotion, subtotal);
      const txt =
        Math.max(MINIMUM_CHARGE, subtotal - discount_amount) *
        (parseFloat(plan.taxInfo[0].percentage) / 100);

      props.taxAmount(txt.toFixed(2));
      setTax(txt.toFixed(2));
    } else {
      const discount_amnout = props.coupon
        ? coupon_discount(props.coupon, subtotal)
        : 0;
      const taxCalc =
        Math.max(MINIMUM_CHARGE, subtotal - discount_amnout) *
        (plan.taxInfo[0].percentage / 100);

      props.taxAmount(taxCalc.toFixed(2));
      setTax(taxCalc.toFixed(2));
    }
  };

  useEffect(() => {
    calculateTax();
  }, []);

  const plan = getSelectedProgramPlan(props.program, props.selected);
  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px",
        borderBottom: "1px solid #dedede"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif",
          fontWeight: 500,
          fontSize: "14px"
        }}
      >
        {
          plan.taxInfo[0]
            .display_name
        }{" "}
        ({plan.taxInfo[0].percentage}
        %)
      </p>
      <span style={{ fontWeight: 500 }}>${tax}</span>
    </li>
  );
};

TaxableProgramDropIns.propTypes = {
  program: PropTypes.shape({
    plans: PropTypes.array.isRequired
  }).isRequired,
  selected: PropTypes.number.isRequired,
  selected_days_length: PropTypes.number.isRequired,
  coupon: PropTypes.object
};

export default TaxableProgramDropIns;
