import React from "reactn";
import AddProgram from "../../../components/modals/AddProgramModal";
import { el } from "date-fns/esm/locale";
import { ModalBanner } from "../../../assets";
import "../../../assets/css/componentSpecificCss/organizationProfile.css";

class Setup extends React.Component {
  state = {};

  componentDidMount() {
    const isAdmin = this.props.match.path.includes("/admin/kids")
      ? true
      : false;

    this.setState({ isAdmin: isAdmin });
  }

  render() {
    return (
      <div
        className="cont boxes-row mt-50px"
        style={{ display: "flex", flexDirection: "column" }}
      >
        {this.state.modalOpen && (
          <AddProgram
            onClose={_ => this.setState({ modalOpen: false })}
            addProgram={r =>
              this.setGlobal({
                organizationInfo: {
                  ...this.global.organizationInfo,
                  programs: [...this.global.organizationInfo.programs, r]
                }
              })
            }
          />
        )}
        <button
          className="addchild"
          style={{
            minHeight: "unset",
            minWidth: "unset",
            maxWidth: "unset",
            maxHeight: "unset",
            flexGrow: 0,
            maxWidth: "430px",
            height: "100px",
            marginBottom: "15px",
            marginTop: "20px"
          }}
          onClick={_ => this.setState({ modalOpen: true })}
        >
          <h3>Add Programs</h3>
        </button>
        {!this.global.organizationInfo.programs.length && (
          <div
            className={
              this.state.isAdmin ? "skip-programs-admin" : "skip-programs"
            }
            // style={{
            //   display: "flex",
            //   alignItems: "center",
            //   marginLeft: "8px",
            //   maxWidth: "430px",
            //   position: "absolute",
            //   top: this.state.isAdmin ? "235px" : "181px",
            //   left: this.state.isAdmin ? "450px" : "581px",
            //   fontWeight: 600,
            //   fontFamily: "Open Sans"
            // }}
          >
            <input
              type="checkbox"
              style={{ marginRight: "20px" }}
              checked={this.global.organizationInfo.skip_program}
              onChange={e =>
                this.setGlobal({
                  organizationInfo: {
                    ...this.global.organizationInfo,
                    skip_program: e.target.checked
                  }
                })
              }
            />
            Skip for Now (I will add them later)
          </div>
        )}

        {this.global.organizationInfo.programs.map((e, key) => {
          const element = typeof e === "string" ? JSON.parse(e) : e;
          return (
            <div
              style={{
                maxWidth: "430px",
                backgroundColor: "#FFF",
                borderRadius: "10px",
                boxShadow: "0 2px 15px 0 rgba(204,204,204,0.5)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px",
                margin: "0px 8px 20px",
                height: "100px",
                position: "relative"
              }}
              key={key}
            >
              {
                <img
                  src={ModalBanner}
                  alt="Modal Banner"
                  style={{
                    height: "10px",
                    width: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px"
                  }}
                />
              }
              <p
                style={{
                  textAlign: "justify",
                  color: "#7B7B90",
                  marginBottom: 0
                }}
              >
                <span style={{ fontWeight: "bold" }}>
                  {element.program_name}
                </span>{" "}
                <br />
                <span style={{ fontWeight: "normal", fontSize: "12px" }}>
                  {element.description.substr(0, 120) + "..."}
                </span>
              </p>
              <span
                style={{
                  fontSize: "14px",
                  color: "#2880FF",
                  fontWeight: "bold",
                  position: "absolute",
                  right: "5px",
                  top: "10px",
                  cursor: "pointer"
                }}
                onClick={_ =>
                  this.setGlobal({
                    organizationInfo: {
                      ...this.global.organizationInfo,
                      programs: this.global.organizationInfo.programs.filter(
                        (e, i) => i !== key
                      )
                    }
                  })
                }
              >
                X
              </span>
            </div>
          );
        })}
      </div>
    );
  }
}

export default Setup;
