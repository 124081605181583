import { format } from "date-fns";

/**
 * Takes an hour value converted to a number and converts back to a textual representation.
 * ex: 8.5 => "8:30 AM", 16.5 => "4:30 PM"
 */
export const numToHours = hour => {
  const d = new Date();
  return format(
    new Date(
      d.getFullYear(),
      d.getMonth(),
      d.getDate(),
      Math.floor(hour),
      Math.ceil((hour % 1) * 60)
    ),
    "h:mm a"
  );
};

const functionStore = {
  isNotEmpty: e => e.length > 0,
  isValidEmail: e => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e),
  isValidPhone: e =>
    /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/.test(e),
  isNumber: e => e.length > 0 && !isNaN(e),
  validDays: e => Object.keys(e).length > 1
};
/**
 *
 * @param {{}} state
 * @param {HTMLFormElement} form
 */
export const validateForm = (state, form) => {
  for (let element in state) {
    let formele = form[element] ? form[element] : form.children[element];
    if (formele) {
      if (formele.length) formele = formele[0];
      const validation = formele.getAttribute("validation");
      if (validation) {
        if (!functionStore[validation](state[element])) {
          formele.focus();
          formele.style.border = "1px solid red";
          return false;
        } else formele.style.border = null;
      }
    }
  }
  return true;
};
