import React from "reactn";
import { BoxForm, formTypes } from "../../../../components/UI/form";
import { MiniPlus } from "../../../../assets";
import Geosuggest from "react-geosuggest";
import ProgramSearchInput from "../../../../components/common/ProgramSearchInput";
import "../../../../assets/css/componentSpecificCss/kidProgram.css";

class SectionFour extends React.PureComponent {
  constructor(props) {
    super(props);
    /*this.setAddress = this.setAddress.bind(this);
    this.customForm = this.customForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this._geoSuggest = null;
    this._geoSuggestPrev = null;*/
    this.state = { days: {} };
  }
  handleChange = (e, v) => {
    /*if (
      !this.global.profile.applicationSubmitted ||
      this.global.dsUser.accountType === "Admin"
    ) {
      this.setGlobal({
        myApplicationProfileAddress: {
          ...this.global.myApplicationProfileAddress,
          [e]: v
        }
      });
    }*/
  };

  customForm = () => {
    return (
      <div className="formbody">
        <form>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">New Locations</h3>
            </div>
            <div className="col-7 d-flex">
              <button
                type="button"
                className="btn btn-primary"
                onClick={e =>
                  this.setGlobal({
                    organizationInfo: {
                      ...this.global.organizationInfo,
                      other_locations: [
                        ...this.global.organizationInfo.other_locations,
                        { address: "", lat: null, lng: null }
                      ]
                    }
                  })
                }
              >
                Add New
              </button>
            </div>
          </div>
          {this.global.organizationInfo.other_locations &&
            this.global.organizationInfo.other_locations.map((e, i) => (
              <div className="row">
                <div className="col-5">
                  <h3>Additional Location #{i + 2}</h3>
                </div>
                <div className="col-7 d-flex">
                  <ProgramSearchInput
                    placeholder="Address1"
                    noicon={true}
                    name="search"
                    style={{ marginTop: "0px" }}
                    value={e.address}
                    locationBias={{
                      lat: this.global.organizationInfo.lat,
                      long: this.global.organizationInfo.long
                    }}
                    gLoc={(e, loc) => {
                      const other_locs = [
                        ...this.global.organizationInfo.other_locations
                      ];
                      other_locs[i].address = e;
                      other_locs[i].lat = loc?.lat || null;
                      other_locs[i].lng = loc?.lng || null;
                      this.setGlobal({
                        organizationInfo: {
                          ...this.global.organizationInfo,
                          other_locations: [...other_locs]
                        }
                      });
                    }}
                  />
                </div>
              </div>
            ))}
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">Organization Contact</h3>
            </div>
            <div className="col-7 d-flex">
              <button
                type="button"
                className="btn btn-primary"
                onClick={e =>
                  this.setGlobal({
                    organizationInfo: {
                      ...this.global.organizationInfo,
                      main_contacts: [
                        ...this.global.organizationInfo.main_contacts,
                        { "Full Name": "", Email: null, "Phone Number": null }
                      ]
                    }
                  })
                }
              >
                Add New
              </button>
            </div>
          </div>
          {this.global.organizationInfo.main_contacts &&
            this.global.organizationInfo.main_contacts.map((e, i) => (
              <>
                <div className="row">
                  <div className="col-12">
                    <h3 className="label-names">
                      Contact #{i + 1}{" "}
                      {i === 0 && <span className="requiredField">*</span>}
                    </h3>
                  </div>
                </div>
                {Object.keys(this.global.organizationInfo.main_contacts[i]).map(
                  key => (
                    <div className="row">
                      <div className="col-5 label-names">
                        {key}{" "}
                        {i === 0 && <span className="requiredField">*</span>}
                      </div>
                      <div className="col-7 d-flex">
                        <input
                          name={key}
                          className="input-fields"
                          value={
                            this.global.organizationInfo.main_contacts[i][key]
                          }
                          onChange={ev => {
                            const other_contacts = [
                              ...this.global.organizationInfo.main_contacts
                            ];
                            other_contacts[i][key] = ev.target.value;
                            this.setGlobal({
                              organizationInfo: {
                                ...this.global.organizationInfo,
                                main_contacts: [...other_contacts]
                              }
                            });
                          }}
                        />
                      </div>
                    </div>
                  )
                )}
              </>
            ))}
        </form>
      </div>
    );
  };

  render() {
    return (
      <BoxForm
        handleChange={this.handleChange}
        name={"Additional Locations"}
        customForm={this.customForm()}
      />
    );
  }
}

export default SectionFour;
/*<input
                  style={{ width: "100%" }}
                  value={e}
                  onChange={v => {
                    const other_locs = [
                      ...this.global.organizationInfo.other_locations
                    ];

                    other_locs[i] = v.target.value;

                    this.setGlobal({
                      organizationInfo: {
                        ...this.global.organizationInfo,
                        other_locations: [...other_locs]
                      }
                    });
                  }}
                />*/
