import { setGlobal, addReducer } from "reactn";

/**
 * Initial State for Tuition Payments
 */
const tuitionPayments = {
  tuitionPayments: {
    recentPayment: 0.0,
    nextPayment: 0.0,
    amountPending: 0.0,
    amountOverdue: 0.0
  },
  tuitionPaymentsDeposits: [],
  tuitionPaymentsEnrollments: []
};

/**
 * Global State: Tuition Payments
 *
 * Set the Tuition Payments for parents to an empty state.
 */
setGlobal({
  ...tuitionPayments
});

/**
 * Reducer: getTuitionPayments
 *
 * Retrieve tuition payments for parent from server
 * (or dev if in DEV environment)
 */
addReducer("getTuitionPayments", (global, dispatch) => {
  //If the process environment variable REACT_APP_MDOE is DEV:
  if (process.env.REACT_APP_MODE === "dev") {
    return devTuitionPayments();
  }
});

/**
 * DEV ENVIRONMENT STORE INITIALIZATION
 */
const devTuitionPayments = () => {
  let numEnrollments = Math.floor(Math.random() * 20),
    numAccept = Math.floor(Math.random() * 20),
    dollarAmounts = require("../dev/dollarAmounts"),
    names = require("../dev/names"),
    periods = require("../dev/periods"),
    statuses = require("../dev/paymentStatus");

  return {
    tuitionPayments: {
      recentPayment: dollarAmounts.getAmounts(1),
      nextPayment: dollarAmounts.getAmounts(1),
      amountPending: dollarAmounts.getAmounts(1),
      amountOverdue: dollarAmounts.getAmounts(1)
    },
    tuitionPaymentsEnrollments: [...Array(numEnrollments).keys()].map(n => ({
      child: names.getNames(1),
      amount: dollarAmounts.getAmounts(1),
      servicePeriod: periods.getPeriods(1),
      status: statuses.getStatuses(1)
    })),
    tuitionPaymentsDeposits: [...Array(numAccept).keys()].map(n => ({
      child: names.getNames(1),
      amount: dollarAmounts.getAmounts(1),
      servicePeriod: periods.getPeriods(1),
      status: statuses.getStatuses(1)
    }))
  };
};
