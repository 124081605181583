const timeRanges = [
  { name: "Please Select", value: -1 },
  { name: "5:00 AM", value: 5 },
  { name: "5:30 AM", value: 5.5 },
  { name: "6:00 AM", value: 6 },
  { name: "6:30 AM", value: 6.5 },
  { name: "7:00 AM", value: 7 },
  { name: "7:30 AM", value: 7.5 },
  { name: "8:00 AM", value: 8 },
  { name: "8:30 AM", value: 8.5 },
  { name: "9:00 AM", value: 9 },
  { name: "9:30 AM", value: 9.5 },
  { name: "10:00 AM", value: 10 },
  { name: "10:30 AM", value: 10.5 },
  { name: "11:00 AM", value: 11 },
  { name: "11:30 AM", value: 11.5 },
  { name: "12:00 PM", value: 12 },
  { name: "12:30 PM", value: 12.5 },
  { name: "1:00 PM", value: 13 },
  { name: "1:30 PM", value: 13.5 },
  { name: "2:00 PM", value: 14 },
  { name: "2:30 PM", value: 14.5 },
  { name: "3:00 PM", value: 15 },
  { name: "3:30 PM", value: 15.5 },
  { name: "4:00 PM", value: 16 },
  { name: "4:30 PM", value: 16.5 },
  { name: "5:00 PM", value: 17 },
  { name: "5:30 PM", value: 17.5 },
  { name: "6:00 PM", value: 18 },
  { name: "6:30 PM", value: 18.5 },
  { name: "7:00 PM", value: 19 },
  { name: "7:30 PM", value: 19.5 },
  { name: "8:00 PM", value: 20 },
  { name: "8:30 PM", value: 20.5 },
  { name: "9:00 PM", value: 21 },
  { name: "9:30 PM", value: 21.5 },
  { name: "10:00 PM", value: 22 },
  { name: "10:30 PM", value: 22.5 },
  { name: "11:00 PM", value: 23 }
];

const daysOfWeekRanges = [
  { name: "Please Select", value: "" },
  { name: "Monday - Friday", value: "Monday - Friday" },
  { name: "Monday - Saturday", value: "Monday - Saturday" },
  { name: "Monday - Sunday", value: "Monday - Sunday" },
  { name: "Weekend Only", value: "Weekend Only" }
];

const programTypeOptions = [
  { name: "Please Select", value: "" },
  {
    name: "Independent Program (Unlicensed)",
    value: "Independent Program Unlicensed"
  },
  {
    name: "Daycare Center (Licensed)",
    value: "Licensed Daycare Center"
  }
  // { name: "Independent Program (Licensed)", value: "Independent Program Licensed" },
  // { name: "Day Care Center", value: "Day Care" }
];

const yesNoOptions = [
  { name: "Please Select", value: "" },
  { name: "Yes", value: "Yes" },
  { name: "No", value: "No" }
];

const homeTypeOptions = [
  { name: "Please Select", value: "" },
  { name: "Detached", value: "Detached" },
  { name: "Semi-Detached", value: "Semi-Detached" },
  { name: "Townhouse", value: "Townhouse" },
  { name: "Condo", value: "Condo" },
  { name: "Apartment", value: "Apartment" }
];

const ownershipOptions = [
  { name: "Please Select", value: "" },
  { name: "Rent", value: "Rent" },
  { name: "Own", value: "Own" }
];

const SelectOptions = {
  timeRanges,
  daysOfWeekRanges,
  programTypeOptions,
  yesNoOptions,
  homeTypeOptions,
  ownershipOptions
};

export default SelectOptions;
