import React, { useState, useEffect } from "reactn";
import PropTypes from "prop-types";

import { getSelectedProgramPlan } from "./utils";

const ProgramSemesterInstallmentsSubtotal = props => {
  const [subtotal, setSubtotal] = useState("Loading");
  const [depositSubTotal, setDepositSubTotal] = useState("0");

  const calculateSubTotal = () => {
    const plan = getSelectedProgramPlan(props.program, props.selected);
    const installments_breakdown = plan.installments_breakdown;
    const total_price = parseFloat(plan.total_price) - parseFloat(plan.deposit);
    const installments_interest = parseInt(plan.installments_interest);

    let amnt = parseFloat(
      parseFloat(
        (total_price / installments_breakdown) *
          (props.installments ? 1 + installments_interest / 100 : 1) || 0
      )
    ).toFixed(2);
    props.itemAmount(amnt);
    setSubtotal(amnt);

    if (plan.includeDeposit && plan.deposit && plan.deposit !== "0") {
      const depositAmount = parseFloat(plan.deposit).toFixed(2);
      setDepositSubTotal(depositAmount);
    }
  };
  useEffect(() => {
    calculateSubTotal();
  }, []);

  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px",
        borderBottom: "1px solid #dedede"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif",
          fontWeight: 500,
          fontSize: "14px"
        }}
      >
        Subtotal
      </p>
      <span style={{ fontWeight: 500 }}>${depositSubTotal === "0" ? subtotal : depositSubTotal}</span>
    </li>
  );
};

ProgramSemesterInstallmentsSubtotal.propTypes = {
  program: PropTypes.shape({
    plans: PropTypes.array.isRequired
  }).isRequired,
  selected: PropTypes.number.isRequired,
  installments: PropTypes.number.isRequired
};

export default ProgramSemesterInstallmentsSubtotal;
