import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import "../../assets/css/componentSpecificCss/signInModal.css";

class SignInModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: this.props.email ? this.props.email : "",
      password: "",
      connection: true,
      partnerId: props.program.partnerId,
      came_from:
        props.program.membership_name ||
        props.program.event_title ||
        props.program.program_name,
      showPassword: false
    };
    this.required = [
      { name: "email", validation: e => !e },
      { name: "password", validation: e => !e }
    ];
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async performLogin(e) {
    e.preventDefault();
    let cancel = false;
    for (let requiredField of this.required) {
      if (requiredField.validation(this.state[requiredField.name])) {
        document.getElementsByName(requiredField.name)[0].style.boxShadow =
          "none";
        document.getElementsByName(requiredField.name)[0].style.border =
          "1px solid red";
        cancel = true;
      }
    }
    if (cancel) return;
    await this.dispatch.performLogin(this.state);
    console.log("global login", this.global);
    if (
      this.global.dsUser &&
      (this.global.dsUser.accountType === "Parent" ||
        this.global.dsUser.accountType === "Volunteer" ||
        this.global.dsUser.accountType === "Student")
    ) {
      console.log("checkout prop fired");
      this.props.checkout(true);
    } else {
      // this.props.onClose();
    }
  }

  render() {
    return (
      <div className="request-modal-cont">
        <div className="request-modal signin-modal" style={{ padding: 0 }}>
          <div className="first-level-div">
            <h4 className="modal-title">Sign In</h4>
            <p className="intro-text">
              <b>{this.props.program.organization_title}</b> partners with
              Dreamschools for registration so that you can safely and quickly
              book programs & activities.
            </p>
            <form onSubmit={e => this.performLogin(e)}>
              <div className="form-group">
                <input
                  name="email"
                  id="email"
                  type="email"
                  className="form-control"
                  placeholder="Email Address"
                  value={this.state.email !== "" ? this.state.email : ""}
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value.toLowerCase()
                    });
                  }}
                />
              </div>
              <div className="form-group" style={{ position: "relative" }}>
                <input
                  name="password"
                  id="password"
                  type={this.state.showPassword ? "text" : "password"}
                  className="form-control"
                  placeholder="Password"
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                />
                <button
                  onClick={e => {
                    e.preventDefault();
                    this.setState({ showPassword: !this.state.showPassword });
                  }}
                  className="btn show-password-toggle"
                >
                  <i
                    className={`bi ${
                      this.state.showPassword ? "bi-eye-slash" : "bi-eye"
                    }`}
                  />
                </button>
              </div>
              <div style={{ textAlign: "center" }}>
                <button type="submit" className="btn profile-btn submit-button">
                  Sign-in
                </button>
              </div>
            </form>
            <div className="signup-terms">
              <p>
                {/*empty squiggly brackets are used to fool React's trimming of white space before or after a line of text.*/}
                By performing this action, you agree to our {}
                <a href="https://dreamschools.com/terms-of-use" target="blank">
                  terms of use
                </a>
                {} and {}
                <a
                  href="https://dreamschools.com/privacy-policy"
                  target="blank"
                >
                  privacy policy
                </a>
                . If you forgot your password, then click {}
                <a
                  href={`${process.env.REACT_APP_URL}/forgot-password`}
                  target="blank"
                >
                  here
                </a>
                .
              </p>
            </div>
            {
              <IconButton
                className="close-button"
                onClick={_ => this.props.onClose()}
              >
                <Close />
              </IconButton>
            }
          </div>
          <div className="switch-to-signup">
            Not a member? {}
            <span
              className="clickable"
              onClick={_ => {
                this.props.onClose();
                this.props.openSignUpModal();
              }}
            >
              Create your account
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(SignInModal);
