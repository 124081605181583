import React from "react";
import "../fieldComponent.css";
import "./TextImageList.css";
import Title from "../Title";

const TextImageList = props => {
  const { name, content } = props;

  return (
    <div>
      <Title name={name} />

      {content.map(({ src, content }, index) => (
        <div key={index} style={{ display: "flex" }}>
          <img className="text-image-list-image" src={src} alt="" />
          <p className="field-component-small-text">{content}</p>
        </div>
      ))}
    </div>
  );
};

export default TextImageList;
