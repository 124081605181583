import React, { useGlobal } from "reactn";
import PropTypes from "prop-types";
import Form from "./Form";
import StatusForm from "./StatusForm";
import "../../../assets/css/componentSpecificCss/kidProgram.css";
import { withRouter } from "react-router-dom";

const BoxForm = props => {
  const [dsUser] = useGlobal("dsUser");
  const {
    fields,
    name,
    handleChange,
    removable,
    handleRemove,
    object,
    removeAfterSaved,
    formType,
    statuses,
    customForm,
    hasCheck,
    completionPercentage
  } = props;
  const canRemove = () => {
    return (
      removeAfterSaved || (!removeAfterSaved && typeof object.id !== "number")
    );
  };
  return (
    <div className="box">
      <div className="bgofbox">
        <div className="header">
          <div className="row">
            <div
              className="col-12 d-flex"
              style={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <h2 className="box-header">
                {/*Added a span here to work around Google Translate error.*/}
                <span>{name}</span>
                {completionPercentage && (
                  <span
                    style={{
                      marginLeft: "8px",
                      fontSize: "15px",
                      color: "#59d03e",
                      fontWeight: 500,
                      fontStyle: "italic"
                    }}
                  >
                    ({completionPercentage}% Completed)
                  </span>
                )}
              </h2>
              {(removable && canRemove()) ||
              (removable && dsUser.accountType === "Admin") ? (
                <>
                  <span className="spacer"></span>
                  <button
                    className="remove-box"
                    onClick={() => handleRemove(object)}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </>
              ) : null}
              {dsUser && dsUser.accountType === "Parent" && hasCheck && (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "10px",
                      fontSize: "12px"
                    }}
                  >
                    <input
                      type="checkbox"
                      checked
                      style={{ marginRight: "10px" }}
                    />{" "}
                    Same as{" "}<span onClick={_ => props.history.push("/user/family-profile")} style={{ textDecoration: "underline", color: "blue", cursor: "pointer", marginLeft: "3px" }}>User</span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {!customForm ? (
          formType === "normal" ? (
            <Form handleChange={handleChange} fields={fields} />
          ) : (
            <StatusForm
              handleChange={handleChange}
              fields={fields}
              statuses={statuses}
            />
          )
        ) : (
          customForm
        )}
      </div>
    </div>
  );
};

BoxForm.propTypes = {
  fields: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  removable: PropTypes.bool,
  handleRemove: PropTypes.func,
  object: PropTypes.object,
  removeAfterSaved: PropTypes.bool,
  formType: PropTypes.string,
  statuses: PropTypes.object,
  customForm: PropTypes.any
};

BoxForm.defaultProps = {
  removable: false,
  handleRemove: () => false,
  object: {},
  removeAfterSaved: true,
  formType: "normal",
  customForm: null
};

export default withRouter(BoxForm);
