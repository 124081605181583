import React from "reactn";
import "./RequestInfo.css";
import { withRouter, Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ModalBanner, LiveView2 } from "../../assets";
import BaseModal from "./baseModal/BaseModal";
import { GET_HTML } from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE, RED_BUTTON } from "./baseModal/colours";
import { validateForm } from "../../lib/util";

class ParentResetPasswordModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      resetPassword: false
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  addnewmodal = () => {
    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "12px"
          }}
        >
          <img
            src={LiveView2}
            alt="Faces"
            style={{
              height: "38px",
              width: "38px"
            }}
          />
        </div>

        <h5
          style={{
            fontSize: "2.2rem",
            fontFamily: "futura-pt, sans-serif",
            color: "#626262",
            fontWeight: 500,
            textAlign: "center",
            marginTop: "12px"
          }}
        >
          This cell phone already has an account associated with it. Organizers
          need to use this phone number to reach you for emergencies. Please
          click{" "}
          {/*           <a
            href={`${process.env.REACT_APP_URL}/forgot-password`}
            target="blank"
            style={{ color: "rgb(34, 152, 255)" }}
          >
            here
          </a> */}
{/*           <Link
            className="nav-link"
            to={`${process.env.REACT_APP_URL}/forgot-password`}
            style={{ color: "rgb(34, 152, 255)" }}
            target="_blank"
            onChange={_ => this.setState({ resetPassword: true })}
          >
            here
          </Link> */}
        <a
            href={`${process.env.REACT_APP_URL}/forgot-password`}
            //target="blank"
            style={{ color: "rgb(34, 152, 255)" }}
            onClick={() => {
                window.scrollTo(0, 0);
                this.dispatch.logout();
              }}
          >
            here {" "}
          </a>
          to reset password.{" "}
        </h5>
      </div>
    );
  };

  getBaseModalFields() {
    const allContent = {
      type: GET_HTML,
      data: {
        gethtml: this.addnewmodal
      }
    };

    const fields = [];
    fields.push(allContent);

    return fields;
  }

  getBaseModalButtons = () => {
    const closeButton = {
      name: "Close",
      buttonColour: RED_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: this.props.onClose
    };

    const fieldsbutton = [];
    fieldsbutton.push(closeButton);

    return fieldsbutton;
  };

  getBaseModalProps = () => {
    return {
      title: "",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      handleClose: this.props.onClose,
      height: "48vh",
      width: "440px"
    };
  };

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
    return (
      <div className="request-modal-cont">
        <div className="request-modal" style={{ maxWidth: "440px" }}>
          <img
            src={ModalBanner}
            alt="Modal Banner"
            style={{
              height: "30px",
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0
            }}
          />
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "12px"
              }}
            >
              <img
                src={LiveView2}
                alt="Faces"
                style={{
                  height: "38px",
                  width: "38px"
                  // position: "absolute",
                  // top: 40,
                  // left: 205
                }}
              />
            </div>
            <h5
              style={{
                fontSize: "22px",
                fontFamily: "'Open Sans', sans-serif",
                color: "#5e5e60",
                fontWeight: 500,
                textAlign: "center",
                marginTop: "12px"
              }}
            >
              This program is pending verification. Please make sure to visit
              Dreamschools in the near future!
            </h5>

            <div className="modal-footer" style={{ marginTop: "5px" }}>
              <button
                onClick={_ => this.setGlobal({ openVerificationModal: false })}
                className="mx-2 backbtn btn profile-btn"
                style={{
                  background: "#FF6663",
                  border: "1px solid #FF6663",
                  padding: "5px",
                  width: "75px"
                }}
              >
                Close
              </button>
            </div>

            <IconButton
              style={{ position: "absolute", right: "0", top: "0" }}
              // style={{
              //   borderRadius: "50%",
              //   color: "#2880FF",
              //   marginBottom: "1rem"
              // }}
              onClick={_ => this.setGlobal({ openVerificationModal: false })}
            >
              <Close />
            </IconButton>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ParentResetPasswordModal);
