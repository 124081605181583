import { setGlobal, addReducer } from "reactn";

setGlobal({
  educatorFamilyPaymentsCurrent: [],
  educatorFamilyPaymentsAccepted: []
});
addReducer("getEducatorfamilyPayments", (global, dispatch) => {
  if (process.env.REACT_APP_MODE === "dev") {
    return devFamilyPayments();
  }
});

/**
 * DEV ENVIRONMENT STORE INITIALIZATION
 */
const devFamilyPayments = () => {
  let numEnrollments = Math.floor(Math.random() * 20),
    dollarAmounts = require("../dev/dollarAmounts"),
    names = require("../dev/names");

  return {
    educatorFamilyPaymentsCurrent: [...Array(numEnrollments).keys()].map(n => ({
      child: names.getNames(1),
      amountPaid: dollarAmounts.getAmounts(1),
      amountOverdue: dollarAmounts.getAmounts(1),
      amountPending: dollarAmounts.getAmounts(1),
      action: "View"
    })),
    educatorFamilyPaymentsAccepted: [...Array(numEnrollments).keys()].map(
      n => ({
        child: names.getNames(1),
        amountPaid: dollarAmounts.getAmounts(1),
        amountOverdue: dollarAmounts.getAmounts(1),
        amountPending: dollarAmounts.getAmounts(1),
        action: "View"
      })
    )
  };
};
