import axios from "axios";
import axiosRetry from 'axios-retry'
import React from "reactn";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./store";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { MuiThemeProvider } from "@material-ui/core";
import { theme } from "./theme";
import "./consoleCapture";

/**
 * 30secs should be more than enough time to receive some kind of feedback
 * from server, but maybe too long for the user to patiently wait.
 * It's 0secs in development, simply because debugging might take forever!
 */
axios.defaults.timeout = (process.env.NODE_ENV==='development') ? 0 : 30_000;

/**
 * this adds an interceptor that will add a custom header with the current URL to all http calls.
 * The normal "Referer" header doesn't do that.
 * Purpose is to trace internal server calls to the requesting pages/locations in the front-end.
 */
axios.interceptors.request.use(function (config) {
  config.headers['X-Axios-Referrer'] = window.location.href;
  config.metadata = { startTime: new Date().valueOf() };   //used later to determine connection time.
  config.onDownloadProgress = (evt) => {    //download progress handler tracks amount of data downloaded.
    if (evt.lengthComputable) {
      config.metadata.loaded = evt.loaded;  //contains number of bytes downloaded
      config.metadata.total = evt.total;    //contains number of bytes total to be downloaded
    }
  };

  if (config.url instanceof URL) {
    if (config.url.hostname==="localhost") config.url.hostname=window.location.hostname;   //@@REMOVE@@
  } else {
    config.url = config.url.replace("localhost",window.location.hostname);   //@@REMOVE@@
  }
  return config;
});


/**
 * This response interceptor is used for logging purposes - successes, failures, errors, etc.
 */
axios.interceptors.response.use(function (response) {
  if (response.config?.metadata) {
    response.config.metadata.endTime = new Date().valueOf();  //used to determine connection time.
  }
  if (response instanceof Error)   //this can happen if another axios error interceptor somewhere in the code
    throw response;                //returns the error instead of returning a Promise.reject(error).

  if (console.logAxiosResponse)
    console.logAxiosResponse(response);
  
  return response;  //this is required for all interceptors!
},
function (error) { //Handle timeouts and any return status code not in 2xx range.
  if (error.config?.metadata) {
    error.config.metadata.endTime = new Date().valueOf();  //used to determine connection time.
  }
  console.debug(`ERROR: ${error.message}. [during axios.${error.config?.method}("${error.config?.url}")]`);
  return Promise.reject(error);   //this must do a Promise.reject() to allow other error interceptors to work!
});


/**
 * Retry Axios requests after failed attempts
 */
axiosRetry(axios, {
  retries: 5,
  retryCondition: (error) => {
    if (error.config?.url) {
      const url = error.config.url;
      if (url.includes("/par/orders/create-payment-intent-credit")) {
        return false;
      } else if (url.includes("/par/orders/public/create-payment-intent-credit")) {
        return false;
      } else if (url.includes("/par/orders/create-payment-intent-bank")) {
        return false;
      } else if (url.includes("/par/orders/public/create-payment-intent-bank")) {
        return false;
      } else if (url.includes("/partners/stripe/application-checkout/manual")) {
        return false;
      } else if (url.includes("/partners/stripe/register")) {
        return false
      } else if (url.includes("/partners/stripe/application-checkout/register")) {
        return false
      }
    }
    return true;
  },
  shouldResetTimeout: true,
  retryDelay: (retryCount) => {
    return Math.pow(2, retryCount) * 1000
  }, onRetry: (retryCount, error, requestConfig) => {
    console.log("Axios Retry Count:", retryCount, error.config?.url, requestConfig)
  }
});


ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <App />
  </MuiThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
