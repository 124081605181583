import React from "reactn";
import { Modal } from "../UI";
import DateTimePicker from "react-datetime-picker";

const gradients = [
  "gra-blue-purple",
  "gra-yellow-pink",
  "gra-purple-blue",
  "gra-teal-green",
  "gra-yellow-orange"
];

const eventModel = {
  id: null,
  category: "time",
  calendarId: 0,
  title: "",
  child: null,
  startTime: "",
  endTime: "",
  isAllDay: false,
  isPrivate: false,
  colour: 0,
  body: "",
  type: "Attendance (Student)",
  parent: null
};

class CalendarAddModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...eventModel
    };
    this.addEvent = this.addEvent.bind(this);
    this.setChild = this.setChild.bind(this);
    this.changeType = this.changeType.bind(this);
  }

  addEvent = async () => {
    try {
      this.props.onClose({
        ...this.state
      });
      this.setState({ ...eventModel });
    } catch (err) {}
  };

  setChild = val => {
    if (val.target.value) {
      const child = this.props.children.find(
        c => c.childId === parseInt(val.target.value)
      );
      this.setState({ child: child.childId, parent: child.parent });
    } else {
      this.setState({ child: null, parent: null });
    }
  };

  changeType = e => {
    let colour = 0;
    switch (e.target.value) {
      case "Appointment (Student)":
        colour = 0;
        break;
      case "Attendance (Student)":
        colour = 0;
        break;
      case "Personal (Educator)":
        colour = 1;
        break;
      case "Vacation (Educator)":
        colour = 1;
        break;
      case "Medication (Student)":
        colour = 3;
        break;
      case "Reminder (Student)":
        colour = 2;
        break;
      case "Alternate Pickup (Student)":
        colour = 4;
        break;
      default:
        colour = 0;
    }
    this.setState({ colour: colour, type: e.target.value });
  };

  componentWillReceiveProps(newProps) {
    if (newProps.open) {
      this.setState({ ...eventModel, ...newProps.selectedEvent });
    }
  }

  render() {
    const { open, onClose, children } = this.props;
    return (
      <div>
        <Modal
          open={open}
          heading="Calendar Event"
          onClose={() => onClose(false)}
        >
          <div className="row">
            <div className="col-5">
              <p className="font-weight-bold">Event Title *</p>
            </div>
            <div className="col-7">
              <input
                type="text"
                placeholder="Event title..."
                name="title"
                value={this.state.title}
                onChange={e => {
                  this.setState({
                    [e.target.name]: e.target.value
                  });
                }}
              />
            </div>
            <div className="col-5">
              <p className="font-weight-bold">Event Type</p>
            </div>
            <div className="col-7">
              <select value={this.state.type} onChange={this.changeType}>
                <option value="Appointment (Student)">
                  Appointment (Student)
                </option>
                <option value="Attendance (Student)">
                  Attendance (Student)
                </option>
                <option value="Personal (Educator)">Personal (Educator)</option>
                <option value="Vacation (Educator)">Vacation (Educator)</option>
                <option value="Medication (Student)">
                  Medication (Student)
                </option>
                <option value="Reminder (Student)">Reminder (Student)</option>
                <option value="Alternate Pickup (Student)">
                  Alternate Pickup (Student)
                </option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <p className="font-weight-bold">Student</p>
            </div>
            <div className="col-7">
              <select onChange={this.setChild} value={this.state.child}>
                <option value={null}>Select a student...</option>
                {children.map((c, i) => (
                  <option value={c.childId} key={i}>
                    {c.childName}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <p className="font-weight-bold">All Day?</p>
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="isAllDay"
                className="mt-2"
                style={{ width: 18, height: 18 }}
                checked={this.state.isAllDay}
                onChange={e => {
                  this.setState({
                    [e.target.name]: e.target.checked
                  });
                }}
              />
            </div>
            <div className="col-4">
              <p className="font-weight-bold">Private?</p>
            </div>
            <div className="col-2">
              <input
                type="checkbox"
                name="isPrivate"
                className="mt-2"
                style={{ width: 18, height: 18 }}
                checked={this.state.isPrivate}
                onChange={e => {
                  this.setState({
                    [e.target.name]: e.target.checked
                  });
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div
                style={{ width: "100%", height: "4px" }}
                className={gradients[this.state.colour]}
              ></div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <p className="font-weight-bold">Start *</p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <DateTimePicker
                onChange={e =>
                  this.setState({ startTime: e ? new Date(e) : "" })
                }
                value={this.state.startTime}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <p className="font-weight-bold">End</p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <DateTimePicker
                onChange={e => this.setState({ endTime: e ? new Date(e) : "" })}
                value={this.state.endTime}
                minDate={
                  this.state.startTime ? new Date(this.state.startTime) : null
                }
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <p className="font-weight-bold">Notes</p>
            </div>
            <div className="col-12">
              <textarea
                style={{ minHeight: 80 }}
                value={this.state.body}
                onChange={e => this.setState({ body: e.target.value })}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="buttons">
                <button
                  onClick={this.addEvent}
                  className="nextbtn"
                  disabled={!this.state.title || !this.state.startTime}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default CalendarAddModal;
