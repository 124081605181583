import React from "reactn";
import { Am2, Am3, Am4, Am5 } from "../../../assets";
import { numToHours } from "../../../lib/util";
import { MenuBook } from "@material-ui/icons";
import "./shrinkIcon.css";

class LeftColumn extends React.PureComponent {
  getNumDays() {
    const excluded = this.global.school.excludedDays.length;
    switch (this.global.school.daysOfWeek) {
      case "Monday - Friday":
        return 5 - excluded;
      case "Monday - Saturday":
        return 6 - excluded;
      case "Monday - Sunday":
        return 7 - excluded;
      case "Saturday - Sunday":
        return 2 - excluded;
      default:
        return 30;
    }
  }
  render() {
    const vals = this.global.school;
    return (
      <div className="col-lg-2 col-lg-pull-8" id="leftside">
        <div className="col-lg-12">
          <div className="leftside">
            <div className="amenities">
              <h2>AMENITIES</h2>
              <ul>
                <li>
                  <img src={Am2} alt="" />
                  {vals.mealsProvided
                    .sort((a, b) => {
                      if (a.order < b.order) return -1;
                      if (a.order > b.order) return 1;
                      return 0;
                    })
                    .map(meal => meal.name)
                    .join(", ")}
                </li>
                <li>
                  <img src={Am3} alt="" />
                  {this.global.school.daysOfWeek ? this.getNumDays() : 7} days
                  per week
                </li>
                <li>
                  <img src={Am4} alt="" />
                  {vals.snacksProvided} snacks per day
                </li>
                <li>
                  <img src={Am5} alt="" />
                  Potty training required: {vals.pottyTrainedRequired || "No"}
                </li>

                <li>
                  <MenuBook
                    className="iconchange"
                    style={{
                      fontSize: "47px",
                      position: "absolute",
                      marginTop: "-15px",
                      marginLeft: "-70px",
                      color: "lightgrey"
                    }}
                  />
                  Curriculum Inspiration : {vals.curriculum}
                </li>
              </ul>
            </div>

            <div className="DAILYSCHEDULE">
              <h2>DAILY SCHEDULE</h2>
              <ul>
                {vals.schedule
                  .sort((a, b) => {
                    let at = parseFloat(a.time);
                    let bt = parseFloat(b.time);
                    if (at < bt) return -1;
                    if (at > bt) return 1;
                    return 0;
                  })
                  .map((g, i) => (
                    <li key={i}>
                      <p>{numToHours(g.time)}</p>
                      <p>
                        <span>{g.activity}</span>
                      </p>
                    </li>
                  ))}
                <div className="silverline"></div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LeftColumn;
