/*
NOTE:
The following 4 files have a common structure and a lot of shared code:
  - ProgramBookingDetail.jsx
  - OnlineBookingDetail.jsx
  - MembershipBookingDetail.jsx
  - EventBookingDetail.jsx
For any changes to this file, please also keep all 4 similarly updated.
Future task is to consolidate as much of the shared code as possible.
*/
//TODO: consolidate "isPromotionAppliedToProgramPlan"[from ../utils] and "isPlanPartOfPromotion"[from this file].

import React, { useContext, useGlobal, useDispatch } from "reactn";

import PropTypes from "prop-types";
import { toast } from "react-toastify";
import axios from "axios";

import { format, addWeeks } from "date-fns";

import {
  PromotionContext,
  setSelected,
  setPlanQuantity,
  setCheckout,
  setSignIn,
  setIsOpenSignUpModal
} from "../PromotionsDetail.context";

import {
  getFormattedProgramDate,
  isPromotionAppliedToProgramPlan,
  getDiscountPrice
} from "../utils";

import {
  promotion_discount,
  getSelectedProgramPlan
} from "../../Checkout/utils";

import BookingDetailPromotionCard from "./BookingDetailPromotionCard";
import Radio from "@material-ui/core/Radio";
import { withStyles } from "@material-ui/core/styles";
import SummarySubTotal from "../../SpecificPageUtils/SummarySubTotal";
import { withRouter } from "react-router-dom";
import { DateTime } from "luxon";

const styles = {
  radio: {
    color: "#ccc",
    "&$checked": {
      color: "#FF6666"
    },
    "& .MuiButtonBase-root": {
      padding: "0px !important",
      marginRight: "0px !important"
    }
  },
  checkedRadio: {
    color: "#FF6666"
  }
};

const OnlineBookingDetail = props => {
  const { state, dispatch } = useContext(PromotionContext);
  // global state
  const [global, _] = useGlobal();
  const globalDispatch = useDispatch();
  const { online, promotion, selected } = state;

  const isPlanSelected = () =>
    state.selected !== null && state.selected !== undefined; //do not use -1 to represent a non-selection. And id=0 is a valid price plan!
  const isShowPromoOnly = promotion?.show_promo_only;
  const getAccountType = user => user.accountType;
  const isUserParent = user => getAccountType(user) === "Parent";
  const isUserVolunteer = user => getAccountType(user) === "Volunteer";
  const isUserStudent = user => getAccountType(user) === "Student";
  const canUserVisitCheckout = user =>
    isUserParent(user) || isUserVolunteer(user) || isUserStudent(user);

  const boundToRange = (num, min, max) => Math.min(max, Math.max(min, num));

  const failedLocalStorageDsUserToken = () => {
    try {
      if (localStorage.getItem("ds_token")) {
        return false;
      }
      return false;
    } catch {
      console.log("FAILED TO FETCH FROM LOCAL STORAGE");
      return true;
    }
  };
  console.log("failedLocalStorageDsUserToken", failedLocalStorageDsUserToken());

  const checkUserAndLoggedInAccountType = () =>
    global.dsUser && canUserVisitCheckout(global.dsUser);

  //===========================================================================
  // click handler for Enroll Now button
  //===========================================================================
  const onEnrollNow = async () => {
    if (!isPlanSelected()) {
      return toast.error(`Please select a plan!`);
    }
    const planId = state.selected;
    const singleSessionQuantity = state.planQuantities[state.selected] || 1;

    if (failedLocalStorageDsUserToken()) {
      console.log("Failed to access local storage enroll OPEN NEW PAGE");
      window.open(
        `${process.env.REACT_APP_URL}/programs/${props.match.params.slug}/promotion/${props.match.params.id}?plan=${planId}`
      );
      return;
    }

    if (localStorage.getItem("ds_token") && !global.dsUser) {
      await globalDispatch.validateToken();
      if (checkUserAndLoggedInAccountType()) {
        return setCheckout(dispatch, { checkout: true, singleSessionQuantity });
      }
      globalDispatch.logout();
      return setIsOpenSignUpModal(dispatch, true);
    }
    if (!localStorage.getItem("ds_token")) {
      return setIsOpenSignUpModal(dispatch, true);
    } else {
      if (checkUserAndLoggedInAccountType()) {
        return setCheckout(dispatch, { checkout: true, singleSessionQuantity });
      }
      globalDispatch.logout();
      return setIsOpenSignUpModal(dispatch, true);
    }
  };

  //===========================================================================
  // click handler for Add to Cart button
  //===========================================================================
  const onAddToCart = async () => {
    if (!isPlanSelected()) {
      return toast.error(`Please select a plan!`);
    }
    const planId = state.selected;
    const singleSessionQuantity = state.planQuantities[state.selected] || 1;

    if (failedLocalStorageDsUserToken()) {
      console.log("Failed to access local storage enroll OPEN NEW PAGE");
      window.open(
        `${process.env.REACT_APP_URL}/programs/${props.match.params.slug}/promotion/${props.match.params.id}?plan=${planId}`
      );
      return;
    }

    console.log("state: ", state);

    const data = {
      partnerId: state.online.partnerId,
      productId: state.online.id,
      planId: planId,
      quantity: singleSessionQuantity,
      productTable: "partner_online",
      promotionId: state.promotion.plan_price_ticketId.includes(planId)
        ? state.promotion.id
        : null
    };

    const addToCart = async () => {
      console.log("dispatch: ", dispatch);
      await globalDispatch.getCart({ partnerSlug: props.match.params.slug });
      const ep = `${process.env.REACT_APP_API}/par/cart`;
      const res = await axios.put(ep, data);
      if (res.data.success) {
        toast.success("Added to cart!");
      } else {
        toast.error(res.data.message);
      }
    };

    if (localStorage.getItem("ds_token") && !global.dsUser) {
      await globalDispatch.validateToken();
      if (checkUserAndLoggedInAccountType()) {
        await addToCart();
        return;
      }
      globalDispatch.logout();
      return setIsOpenSignUpModal(dispatch, true);
    }
    if (!localStorage.getItem("ds_token")) {
      return setIsOpenSignUpModal(dispatch, true);
    } else {
      if (checkUserAndLoggedInAccountType()) {
        await addToCart();
        return;
      }
      globalDispatch.logout();
      return setIsOpenSignUpModal(dispatch, true);
    }
  };

  // TODO: expiry view
  // TODO: limit view
  console.log("props", props);

  console.log("STATEPROPS", state, props);
  const onlineStart = DateTime.fromISO(state.promotion.expiry_date);
  // const programStart = DateTime.fromISO(state.promotion.expiry_date).plus({
  //   minutes: minutes,
  //   hours: hours
  // });
  // const rezonedProgramStart = programStart.setZone(this.state.program.timezone);

  const currTime = DateTime.local();
  // const rezonedCurrTime = currTime.setZone(this.state.program.timezone);
  const diff = onlineStart.diff(currTime);
  const duration = diff.valueOf();
  const promoDatePassed = duration < 0;

  console.log("TIMING", currTime.toISO(), onlineStart.toISO(), duration);

  return (
    <>
      <h2 className="right-cont-name">Booking Details</h2>

      <div style={{ width: "80%", margin: "0 auto" }}>
        {promoDatePassed && (
          <div>
            <p>No plans available - End Date has passed</p>
          </div>
        )}
        {!promoDatePassed &&
          state.online &&
          state.online.plans &&
          state.online.plans.map((e, i) => {
            const element = parseProgramPlanFromStringToJSON(e);
            console.log("element", element);

            if (element.archived || element.active === false) {
              return null;
            }

            if (isShowPromoOnly) {
              if (!isPlanPartOfPromotion(promotion, element)) {
                return null;
              }
            }

            //=====================================================================================
            // Pricing Card. type = undefined
            //=====================================================================================
            if (!element.type) {
              return (
                <div
                  className={
                    state.selected === element.id
                      ? props.classes.planSelected + " pricingcard"
                      : props.classes.plan + " pricingcard"
                  }
                  onClick={() => {
                    setSelected(dispatch, {
                      id: element.id,
                      dropIns: false,
                      installments: false
                    });
                  }}
                  key={i}
                >
                  <div className="radio-btn-positioning-container">
                    <Radio
                      checked={
                        state.selected === element.id &&
                        !state.dropIns &&
                        !state.installments
                      }
                      // onChange={handleChange}
                      value="d"
                      color="default"
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "D" }}
                      size="large"
                      classes={{
                        root: props.classes.radio,
                        checked: props.classes.checkedRadio
                      }}
                    />
                  </div>
                  <div style={{ width: "90%" }}>
                    <p className="pricingcard-name">
                      Recurring ({element.billing_cycle}){" "}
                      {isPlanPartOfPromotion(promotion, element) && (
                        <>
                          {" "}
                          {"- "}
                          <BookingDetailPromotionCard promotion={promotion} />
                        </>
                      )}
                    </p>

                    <p className="pricingcard-days">
                      {element.days_per_week} days / week
                      {displayElementTiming(element)}
                    </p>
                  </div>
                  <div style={{ width: "60px" }}>
                    <p className="pricingcard-amount">
                      {isPromotionAppliedToProgramPlan(promotion, element) ? (
                        <>
                          ${getDiscountPrice(promotion, element).toFixed(2)} /
                          {element.billing_cycle}
                        </>
                      ) : (
                        <>
                          {" "}
                          ${parseFloat(element.tuition_rate).toFixed(2)} /
                          {element.billing_cycle}
                        </>
                      )}
                    </p>
                  </div>
                </div>
              );
            }

            //=====================================================================================
            // Pricing Card. type = "Single Sessions"
            //=====================================================================================
            if (element.type === "Single Sessions") {
              const currentQuantity = state.planQuantities?.[element.id];
              return (
                <>
                  <div
                    className={
                      state.selected === element.id &&
                      !state.dropIns &&
                      !state.installments
                        ? props.classes.planSelected + " pricingcard"
                        : props.classes.plan + " pricingcard"
                    }
                    onClick={() => {
                      setSelected(dispatch, {
                        id: element.id,
                        dropIns: false,
                        installments: false
                      });
                    }}
                    key={i}
                  >
                    <div className="radio-btn-positioning-container">
                      <Radio
                        checked={state.selected === element.id}
                        // onChange={handleChange}
                        value="d"
                        color="default"
                        name="radio-button-demo"
                        inputProps={{ "aria-label": "D" }}
                        size="large"
                        classes={{
                          root: props.classes.radio,
                          checked: props.classes.checkedRadio
                        }}
                      />
                    </div>
                    <div style={{ width: "90%" }}>
                      <p className="pricingcard-name">
                        Single Sessions x({currentQuantity || "-error-"})
                        {isPlanPartOfPromotion(promotion, element) && (
                          <>
                            {" "}
                            {"- "}
                            <BookingDetailPromotionCard promotion={promotion} />
                          </>
                        )}
                      </p>
                      <p className="pricingcard-days">
                        {state?.online?.number_of_weeks !== 0 &&
                        state?.online?.number_of_weeks !== -1
                          ? getFormattedProgramDate(
                              state.online.program_start,
                              state.online.number_of_weeks
                            )
                          : ""}
                      </p>
                      <span
                        style={{ fontWeight: "normal", fontSize: "12px" }}
                      ></span>
                    </div>

                    <div style={{ width: "60px" }}>
                      <p className="pricingcard-amount">
                        {isPromotionAppliedToProgramPlan(promotion, element) ? (
                          <>
                            $
                            {Math.max(
                              0,
                              element.total_price -
                                promotion_discount(
                                  promotion,
                                  element.total_price
                                )
                            ).toFixed(2)}
                          </>
                        ) : (
                          <>${element.total_price}</>
                        )}

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            color: "#36b9cc",
                            marginLeft: "22px"
                          }}
                        >
                          <i
                            className="fas fa-minus-circle"
                            style={{ fontSize: "13px" }}
                            onClick={_ => {
                              const maxQuantity =
                                parseInt(element.quantity) || Infinity;
                              // const currentQuantity = state.planQuantities?.[element.id];
                              const newQuantity = boundToRange(
                                currentQuantity - 1,
                                1,
                                maxQuantity
                              );
                              setPlanQuantity(dispatch, {
                                id: element.id,
                                val: newQuantity
                              });
                            }}
                          ></i>
                          <i
                            className="fas fa-plus-circle"
                            style={{ fontSize: "13px" }}
                            onClick={_ => {
                              const maxQuantity =
                                parseInt(element.quantity) || Infinity;
                              // const currentQuantity = state.planQuantities?.[element.id];
                              const newQuantity = boundToRange(
                                currentQuantity + 1,
                                1,
                                maxQuantity
                              );
                              setPlanQuantity(dispatch, {
                                id: element.id,
                                val: newQuantity
                              });
                            }}
                          ></i>
                        </div>
                      </p>
                    </div>
                  </div>
                </>
              );
            }

            //=====================================================================================
            // Pricing Card. type = something, maybe "Semester", maybe "One-Time", but definitely not "Single Sessions"!
            //=====================================================================================
            return (
              <>
                <div
                  className={
                    state.selected === element.id &&
                    !state.dropIns &&
                    !state.installments
                      ? props.classes.planSelected + " pricingcard"
                      : props.classes.plan + " pricingcard"
                  }
                  onClick={() => {
                    setSelected(dispatch, {
                      id: element.id,
                      dropIns: false,
                      installments: false
                    });
                  }}
                  key={i}
                >
                  <div className="radio-btn-positioning-container">
                    <Radio
                      checked={
                        state.selected === element.id &&
                        !state.dropIns &&
                        !state.installments
                      }
                      // onChange={handleChange}
                      value="d"
                      color="default"
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "D" }}
                      size="large"
                      classes={{
                        root: props.classes.radio,
                        checked: props.classes.checkedRadio
                      }}
                    />
                  </div>
                  <div style={{ width: "90%" }}>
                    <p className="pricingcard-name">
                      {element.description &&
                      element.description !== null &&
                      (element.type === "Semester" ||
                        element.type === "One-Time") ? (
                        <span style={{ fontStyle: "italic" }}>
                          {element.description}
                        </span>
                      ) : (
                        element.type
                      )}
                      {isPlanPartOfPromotion(promotion, element) && (
                        <>
                          {" "}
                          {"- "}
                          <BookingDetailPromotionCard promotion={promotion} />
                        </>
                      )}
                    </p>
                    <p className="pricingcard-days">
                      {getFormattedProgramDate(
                        online.program_start,
                        online.number_of_weeks
                      )}
                    </p>
                    <span
                      style={{ fontWeight: "normal", fontSize: "12px" }}
                    ></span>
                  </div>

                  <div style={{ width: "60px" }}>
                    <p className="pricingcard-amount">
                      {isPromotionAppliedToProgramPlan(promotion, element) ? (
                        <>
                          $
                          {Math.max(
                            0,
                            element.total_price -
                              promotion_discount(promotion, element.total_price)
                          ).toFixed(2)}
                        </>
                      ) : (
                        <>${element.total_price}</>
                      )}
                    </p>
                  </div>
                </div>

                {
                  //=====================================================================================
                  // Pricing Card (alternates).  plan.dropIns=true
                  //=====================================================================================
                }
                {element.dropIns && (
                  <div
                    className={
                      state.selected === element.id &&
                      state.dropIns &&
                      !state.installments
                        ? props.classes.planSelected + " pricingcard"
                        : props.classes.plan + " pricingcard"
                    }
                    onClick={() => {
                      setSelected(dispatch, {
                        id: element.id,
                        dropIns: true,
                        installments: false
                      });
                    }}
                    key={
                      i +
                      "drop" /*as alternate pricing cards, there is a non-dropIn version that is just key=i.*/
                    }
                  >
                    <div className="radio-btn-positioning-container">
                      <Radio
                        checked={
                          state.selected === element.id &&
                          state.dropIns &&
                          !state.installments
                        }
                        // onChange={handleChange}
                        value="d"
                        color="default"
                        name="radio-button-demo"
                        inputProps={{ "aria-label": "D" }}
                        size="large"
                        classes={{
                          root: props.classes.radio,
                          checked: props.classes.checkedRadio
                        }}
                      />
                    </div>
                    <div style={{ width: "90%" }}>
                      <p className="pricingcard-name">Semester - Drop-in</p>
                      <p className="pricingcard-name">
                        {isPlanPartOfPromotion(promotion, element) && (
                          <>
                            {/* {" "} */}
                            {/* {"- "} */}
                            <BookingDetailPromotionCard promotion={promotion} />
                          </>
                        )}
                      </p>

                      <p className="pricingcard-days">
                        {getFormattedProgramDate(
                          online.program_start,
                          online.number_of_weeks
                        )}
                      </p>
                      <span
                        style={{ fontWeight: "normal", fontSize: "12px" }}
                      ></span>
                    </div>

                    <div style={{ width: "60px" }}>
                      <p className="pricingcard-amount">
                        {isPlanPartOfPromotion(promotion, element) ? (
                          <>
                            $
                            {getDropinsPromotionAmount(
                              promotion,
                              element
                            ).toFixed(2)}
                          </>
                        ) : (
                          <>
                            $
                            {parseFloat(
                              (element.total_price / element.dayCount) *
                                (1 + parseInt(element.dropin_interest) / 100)
                            ).toFixed(0)}
                          </>
                        )}
                      </p>
                    </div>
                  </div>
                )}
              </>
            );
          })}

        {isPlanSelected() && (
          <SummarySubTotal
            // plans={state.online?.plans}
            // selected={state.selected}
            selectedPlan={getSelectedProgramPlan(state.online, state.selected)}
            dropIns={state.dropIns}
            installments={state.installments}
            quantity={state.planQuantities[state.selected] || 1}
            isPromo={isPromotionAppliedToProgramPlan(
              promotion,
              getSelectedProgramPlan(state.online, state.selected)
            )}
            promotion={promotion}
          />
        )}

        {state.online.isCartDisabled ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <button
              style={{
                width: `70%`,
                padding: `10px`,
                backgroundColor: `rgb(52, 63, 100)`,
                color: `white`,
                fontWeight: `bold`,
                border: 0,
                marginTop: "10px",
                cursor:
                  !isPlanSelected() ||
                  state.isBookingFull ||
                  (state.planWaitlist && !state.isProgramWaitlist)
                    ? "not-allowed"
                    : "pointer"
              }}
              disabled={
                !isPlanSelected() ||
                state.isBookingFull ||
                (state.planWaitlist && !state.isProgramWaitlist)
              }
              onClick={onEnrollNow}
            >
              Enroll Here Now
            </button>
            <div>
              <i className="bi bi-info-circle" /> This online program must be
              checked out individually.
            </div>
          </div>
        ) : state.online.isRecurring ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <button
                style={{
                  width: `70%`,
                  padding: `10px`,
                  backgroundColor: `rgb(52, 63, 100)`,
                  color: `white`,
                  fontWeight: `bold`,
                  border: 0,
                  marginTop: "10px",
                  cursor:
                    !isPlanSelected() ||
                    state.isBookingFull ||
                    (state.planWaitlist && !state.isProgramWaitlist)
                      ? "not-allowed"
                      : "pointer"
                }}
                disabled={
                  !isPlanSelected() ||
                  state.isBookingFull ||
                  (state.planWaitlist && !state.isProgramWaitlist)
                }
                onClick={onEnrollNow}
              >
                Enroll Here Now
              </button>
            </div>
            <div>
              <i className="bi bi-info-circle" /> Recurring programs must be
              checked out individually.
            </div>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <button
              style={{
                width: `70%`,
                padding: `10px`,
                backgroundColor: `rgb(52, 63, 100)`,
                color: `white`,
                fontWeight: `bold`,
                border: 0,
                marginTop: "40px",
                cursor:
                  !isPlanSelected() ||
                  state.isBookingFull ||
                  (state.planWaitlist && !state.isProgramWaitlist)
                    ? "not-allowed"
                    : "pointer"
              }}
              disabled={
                !isPlanSelected() ||
                state.isBookingFull ||
                (state.planWaitlist && !state.isProgramWaitlist)
              }
              onClick={onAddToCart}
            >
              Add to Cart
            </button>
            <span>-------- OR --------</span>
            <button
              style={{
                width: `70%`,
                padding: `10px`,
                backgroundColor: `rgb(211,52,49)`,
                color: `white`,
                fontWeight: `bold`,
                border: 0,
                marginTop: "10px",
                cursor:
                  !isPlanSelected() ||
                  state.isBookingFull ||
                  (state.planWaitlist && !state.isProgramWaitlist)
                    ? "not-allowed"
                    : "pointer"
              }}
              disabled={
                !isPlanSelected() ||
                state.isBookingFull ||
                (state.planWaitlist && !state.isProgramWaitlist)
              }
              onClick={onEnrollNow}
            >
              Enroll Here Now
            </button>
          </div>
        )}
      </div>
    </>
  );
};

function getDropinsPromotionAmount(promotion, element) {
  const amnt = parseFloat(
    (element.total_price / element.dayCount) *
      (1 + parseInt(element.dropin_interest) / 100)
  ).toFixed(0);

  return Math.max(0.5, amnt - promotion_discount(promotion, amnt));
}

function parseProgramPlanFromStringToJSON(e) {
  return typeof e === "string" ? JSON.parse(e) : e;
}

function isPlanPartOfPromotion(promotion, plan) {
  return promotion.plan_price_ticketId.indexOf(plan.id) !== -1;
}

function displayElementTiming(element) {
  if (!element.isReplaceTimeEnabled) {
    return (
      element.timing && (
        <span style={{ fontStyle: "italic" }}>
          {" ("}
          {element.timing[0].replace(/\s/g, "").toLowerCase()} -{" "}
          {element.timing[1].replace(/\s/g, "").toLowerCase()})
        </span>
      )
    );
  } else {
    return (
      <span style={{ fontStyle: "italic" }}>
        {" ("}
        {element.description}
        {")"}
      </span>
    );
  }
}

OnlineBookingDetail.propTypes = {
  classes: PropTypes.object
};

export default withRouter(withStyles(styles)(OnlineBookingDetail));
