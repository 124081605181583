import Validators from "../validators";

const ValidateOrganization = children => {
  if (!children) return false;
  const fields = {
    organization: [Validators.isEmpty],
    address: [Validators.isEmpty],
    description: [Validators.isEmpty],
    reopening: [Validators.isEmpty],
    program_name: [Validators.isEmpty]
  };
  for (let key of Object.keys(fields)) {
    for (let validator of fields[key]) {
      if (!validator(children[key])) return false;
    }
  }
  return true;
};

const ValidateAddDetails = children => {
  if (!children) return false;
  const fields = {
    main_contacts: [Validators.validateFirst],
    format: [Validators.isEmpty]
  };
  for (let key of Object.keys(fields)) {
    for (let validator of fields[key]) {
      if (!validator(children[key])) return false;
    }
  }
  return true;
};

const ValidatePrograms = children => {
  if (!children) return false;
  const fields = {
    programs: [Validators.isEmpty]
  };
  for (let key of Object.keys(fields)) {
    for (let validator of fields[key]) {
      if (!validator(children[key]) && !validator(children.skip_program))
        return false;
    }
  }
  return true;
};
const ValidateTags = children => {
  if (!children) return false;
  const fields = {
    program_structures: [Validators.isEmpty],
    main_tags: [Validators.isEmpty]
  };
  for (let key of Object.keys(fields)) {
    for (let validator of fields[key]) {
      if (!validator(children[key])) return false;
    }
  }
  return true;
};
const Profile = {
  ValidateOrganization,
  ValidateAddDetails,
  ValidatePrograms,
  ValidateTags
};

export default Profile;
