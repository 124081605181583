import { setGlobal } from "reactn";

const myEarnings = {
  meAllRecentDeposits: [],
  meLastDeposit: 0.0,
  meLastDepositDate: "",
  meNextDeposit: 0.0,
  meNextDepositDate: "",
  meCumulativeAmount: 0.0,
  meCumulativeAmountDate: ""
};
setGlobal({ myEarnings });
