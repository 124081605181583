import React, { Component } from "reactn";
import { Tabs, Tab } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import {
  FamilyProfile,
  PaymentMethod,
  AccountSettings,
  IntProg,
  ApplicationsIcon,
  MyEnroll,
  TourRequests,
  PaymentSettings
} from "../../assets";
import "../../assets/css/componentSpecificCss/NavBarParent.css";

const styles = theme => ({
  plan: {
    transition: "all 300ms",
    "&:hover": {
      transform: "scale(1.001) translateY(-3px)",
      boxShadow: "0 2px 15px 0 rgba(204,204,204,0.5)"
    }
  },
  planSelected: {
    transform: "scale(1.02) translateY(-3px)",
    boxShadow: "0 7px 12px 0 rgba(0,0,0,.3)"
  },
  tabRoot: {
    color: "gray !important",
    fontFamily: "Montserrat,sans-serif !important",
    fontWeight: "400 !important",
    fontSize: "12px !important",
    marginBottom: "0px !important",
    "&:hover": {
      backgroundColor: "transparent !important",
      color: "gray !important"
    },
    "&:focus": {
      backgroundColor: "transparent !important",
      color: "gray !important"
    }
  },
  tabSelected: {
    color: "black !important",
    "&:hover": {
      backgroundColor: "transparent !important",
      color: "black !important"
    },
    "&:focus": {
      backgroundColor: "transparent !important",
      color: "black !important"
    }
  },
  tabIndicator: {
    backgroundColor: "#ff6663",
    height: "3px"
  },
  input: {
    height: "3em",
    border: "1px solid #E8E8E8",
    borderRadius: "3px",
    fontFamily: "Montserrat, sans-serif !important",
    paddingLeft: "0.5em"
  },
  textArea: {
    height: "6em",
    border: "1px solid #E8E8E8",
    borderRadius: "3px",
    fontFamily: "Montserrat, sans-serif !important",
    paddingLeft: "0.5em"
  },
  datePicker: {
    height: "3em",
    border: "1px solid #E8E8E8",
    width: "100%",
    borderRadius: "3px",
    fontFamily: "Montserrat, sans-serif !important",
    paddingLeft: "0.5em"
  },
  tabsHeadings: {
    height: "50px",
    minHeight: "40px",
    paddingLeft: "15px",
    borderTop: "1px solid gainsboro",
    borderBottom: "1px solid gainsboro"
  },
  subHeadings: {
    height: "45px",
    minHeight: "40px",
    paddingLeft: "10px",
    paddingBottom: "0px",
    display: "flex",
    alignItems: "center !important",
    borderBottom: "1px solid gainsboro",
    textAlign: "center !important",
    background: "#FAFAFA"
  },
  subMenuText: {
    textTransform: "capitalize !important"
  }
});

class ParentNav extends Component {
  constructor(props) {
    super(props);

    this.sublinks = {
      "/user/family-profile": [
        {
          name: "Family Profile",
          icon: FamilyProfile,
          to: "/user/family-profile"
        },
        {
          name: "My Children",
          icon: FamilyProfile, //TODO: Icon to be updated to correct one
          to: "/user/my-children" //TODO: Link to be updated to new component route
        },
        {
          name: "Calendar",
          icon: FamilyProfile, //TODO: Icon to be updated to correct one
          to: "/user/calendar" //TODO: Link to be updated to new component route
        }
      ],
      "/user/account-settings": [
        {
          name: "Account Settings",
          icon: AccountSettings,
          to: "/user/account-settings"
        },
        {
          name: "My Orders",
          icon: PaymentMethod,
          to: "/user/orders"
        },
        {
          name: "Payment Settings",
          icon: PaymentMethod,
          to: "/user/payment-settings",
          disabled: "payment"
        }
        // {
        //   name: "Password Reset",
        //   icon: AccountSettings, //TODO: Icon to be updated to correct one
        //   to: "/user" //TODO: Link to be updated to new component route
        // },
        // {
        //   name: "Referrals",
        //   icon: AccountSettings, //TODO: Icon to be updated to correct one
        //   to: "/user" //TODO: Link to be updated to new component route
        // }
      ],
      "/user/interested-programs": [
        {
          name: "Interested Programs",
          icon: IntProg,
          to: "/user/interested-programs"
        },
        {
          name: "Appointments",
          icon: TourRequests,
          to: "/user/tour-requests"
        },
        {
          name: "Applications",
          icon: ApplicationsIcon,
          to: "/user/applications"
        }
        // {
        //   name: "Waitlists",
        //   icon: IntProg, //TODO: Icon to be updated to correct one
        //   to: "/user/interested-programs" //TODO: Link to be updated to new component route
        // }
      ],
      "/user/my-enrollments": [
        {
          name: "My Enrollments",
          icon: MyEnroll,
          to: "/user/my-enrollments"
        },
        {
          name: "My Events",
          icon: MyEnroll,
          to: "/user/my-events"
        },
        {
          name: "Gift Cards",
          icon: MyEnroll, //TODO: Icon to be updated to correct one
          to: "/user/gifts" //TODO: Link to be updated to new component route
        },
        {
          name: "Memberships",
          icon: MyEnroll, //TODO: Icon to be updated to correct one
          to: "/user/memberships" //TODO: Link to be updated to new component route
        },
        {
          name: "Forms",
          icon: MyEnroll,
          to: "/user/forms"
        },
        {
          name: "Parties",
          icon: MyEnroll,
          to: "/user/parties"
        }

        // {
        //   name: "News Feed",
        //   icon: PaymentSettings,
        //   to: "/user/newsfeed"
        // }
      ]
    };

    this.state = {
      active: "/user",
      activeSubLink: "",
      displayedSubTabs: "",
      selectedSubLink: 0,
      displayOrgEnrollment: false,
      links: [
        {
          name: "Dashboard",
          icon: PaymentSettings,
          to: "/user",
          hideDashboard: 262, // Hiding dashboard for Liz from Alice's program
          hideDashboard2: 578 // 578 Hide dashboard for Roopa from Shanaz's program
        },
        {
          name: "Profile",
          icon: FamilyProfile,
          to: "/user/family-profile"
        },
        {
          name: "Account",
          icon: AccountSettings,
          to: "/user/account-settings"
        },
        {
          name: "Interests",
          icon: IntProg,
          to: "/user/interested-programs"
        },
        {
          name: "Bookings",
          icon: PaymentMethod,
          to: "/user/my-enrollments"
        }
        // {
        //   name: "Messages",
        //   icon: PaymentMethod,
        //   to: "/user/messages"
        // }
      ],
      sublinks: {
        "/user/family-profile": [
          {
            name: "Profile",
            icon: FamilyProfile,
            to: "/user/family-profile"
          },
          {
            name: "My Children",
            icon: FamilyProfile, //TODO: Icon to be updated to correct one
            to: "/user/my-children" //TODO: Link to be updated to new component route
          },
          {
            name: "Calendar",
            icon: FamilyProfile, //TODO: Icon to be updated to correct one
            to: "/user/calendar" //TODO: Link to be updated to new component route
          }
        ],
        "/user/account-settings": [
          {
            name: "Account Settings",
            icon: AccountSettings,
            to: "/user/account-settings"
          },
          {
            name: "My Orders",
            icon: PaymentMethod,
            to: "/user/orders"
          },
          {
            name: "Payment Settings",
            icon: PaymentMethod,
            to: "/user/payment-settings",
            disabled: "payment"
          }
          // {
          //   name: "Password Reset",
          //   icon: AccountSettings, //TODO: Icon to be updated to correct one
          //   to: "/user/password-reset" //TODO: Link to be updated to new component route
          // },
          // {
          //   name: "Referrals",
          //   icon: AccountSettings, //TODO: Icon to be updated to correct one
          //   to: "/user/referrals" //TODO: Link to be updated to new component route
          // }
        ],
        "/user/interested-programs": [
          {
            name: "Interested Programs",
            icon: IntProg,
            to: "/user/interested-programs"
          },
          {
            name: "Appointments",
            icon: TourRequests,
            to: "/user/tour-requests"
          },
          {
            name: "Applications",
            icon: ApplicationsIcon,
            to: "/user/applications"
          }
          // {
          //   name: "Waitlists",
          //   icon: IntProg, //TODO: Icon to be updated to correct one
          //   to: "/user/waitlists" //TODO: Link to be updated to new component route
          // }
        ],
        "/user/my-enrollments": [
          {
            name: "My Enrollments",
            icon: MyEnroll,
            to: "/user/my-enrollments"
          },
          {
            name: "My Events",
            icon: MyEnroll,
            to: "/user/my-events"
          },
          {
            name: "Gift Cards",
            icon: MyEnroll, //TODO: Icon to be updated to correct one
            to: "/user/gifts" //TODO: Link to be updated to new component route
          },
          {
            name: "Memberships",
            icon: MyEnroll, //TODO: Icon to be updated to correct one
            to: "/user/memberships" //TODO: Link to be updated to new component route
          },
          {
            name: "Forms",
            icon: MyEnroll,
            to: "/user/forms"
          },
          {
            name: "Parties",
            icon: MyEnroll,
            to: "/user/parties"
          }

          // {
          //   name: "News Feed",
          //   icon: PaymentSettings,
          //   to: "/user/newsfeed"
          // }
        ]
      }
    };
  }

  /*
  links: [
        {
          name: "Dashboard",
          icon: PaymentSettings,
          to: "/parents"
        },
        {
          name: "Family Profile",
          icon: FamilyProfile,
          to: "/parents/family-profile"
        },
        {
          name: "Payment Settings",
          icon: PaymentMethod,
          to: "/parents/payment-settings",
          disabled: "payment"
        },
        {
          name: "Account Settings",
          icon: AccountSettings,
          to: "/parents/account-settings"
        },
        {
          name: "Interested Programs",
          icon: IntProg,
          to: "/parents/interested-programs"
        },
        {
          name: "Appointments",
          icon: TourRequests,
          to: "/parents/tour-requests"
        },
        {
          name: "Applications",
          icon: ApplicationsIcon,
          to: "/parents/applications"
        },
        {
          name: "My Orders",
          icon: PaymentMethod,
          to: "/parents/orders"
        },
        {
          name: "My Enrollments",
          icon: MyEnroll,
          to: "/parents/my-enrollments"
        },
        {
          name: "News Feed",
          icon: PaymentSettings,
          to: "/parents/newsfeed"
        }
      ], 
  */

  componentDidMount() {
    // if(this.global.parentGuardians){
    //   this.setState({
    //     sublinks: {
    //       ...this.state.sublinks[this.state.active].filter(e => !this.global.parentGuardians[0].has_child === )
    //      }
    //   })
    // }
    this.setState({
      active: this.props.location.pathname,
      displayOrgEnrollment: this.props.location.pathname.includes(
        "/user/org-enrollments/Program"
      )
    });
  }

  render() {
    if (!this.global.dsUser) {
      return null;
    }
    if (this.state.displayOrgEnrollment) {
      return null;
    }
    return (
      <div>
        <Tabs
          value={this.state.active}
          onChange={(event, newValue) => {
            this.setState({
              active: newValue,
              activeSublink: this.sublinks[newValue]
                ? this.sublinks[newValue][0].to
                : ""
            });
            this.props.history.push(newValue);
          }}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          style={{
            paddingLeft: "10px",
            marginTop: "104px",
            paddingTop: "0px",
            borderBottom: "1px solid gainsboro"
          }}
          TabIndicatorProps={{ className: this.props.classes.tabIndicator }}
        >
          {this.state.links
            .filter(
              l =>
                l.hideDashboard !== this.global.dsUser.id &&
                l.hideDashboard2 !== this.global.dsUser.id
            )
            .map(e => (
              <Tab
                label={e.name}
                value={e.to}
                disableRipple
                disableFocusRipple
                classes={{
                  root: this.props.classes.tabRoot,
                  selected: this.props.classes.tabSelected
                }}
                key={e.to}
              />
            ))}
        </Tabs>

        {this.sublinks[this.state.active] && (
          <Tabs
            value={
              this.state.activeSubLink || this.sublinks[this.state.active][0].to
            }
            onChange={(event, newValue) => {
              this.setState({ activeSubLink: newValue });
              this.props.history.push(newValue);
            }}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            TabIndicatorProps={{ className: this.props.classes.tabIndicator }}
            classes={{ root: this.props.classes.subHeadings }}
          >
            {this.state.sublinks[this.state.active].map((sublink, i) => {
              if (
                this.global.parentGuardians &&
                sublink.name === "My Children"
              ) {
                if (this.global.parentGuardians.length > 0) {
                  if (!this.global.parentGuardians[0].has_child) {
                    return null;
                  }
                }
              }
              return (
                <Tab
                  label={sublink.name}
                  value={sublink.to}
                  disableRipple
                  disableFocusRipple
                  // TabIndicatorProps={{
                  //   className: this.props.classes.tabIndicator
                  // }}
                  classes={{
                    root: `${this.props.classes.tabRoot} ${this.props.classes.subMenuText}`,
                    selected: this.props.classes.tabSelected
                  }}
                  key={sublink.to}
                />
              );
            })}
          </Tabs>
        )}
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(ParentNav));
