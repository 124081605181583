import React from "react";

import ModalFormLabel from "./ModalFormLabel";

import "./ModalFormGroup.css";

/**
 * Custom Form Group component, if no other component fits the use case.
 * Pass in the user input interface as children.
 *
 * @param label Display Label of the form group
 * @param name Input attribute and label htmlFor attribute.
 *
 * @param tooltip Text of tooltip (optional).
 * @param required Whether the field is required.
 *
 * @param children Custom input.
 */
const ModalFormGroup = ({ label, name, tooltip, children, required, titleStyle }) => (
  <div className="form-group modal-form-group" >
    <ModalFormLabel
      label={label}
      name={name}
      tooltip={tooltip}
      required={required}
      titleStyle={titleStyle}
    />
    {children}
  </div>
);

export default ModalFormGroup;
