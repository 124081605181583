import React from "react";
import "../fieldComponent.css";
import "./ImageContent.css";
import Title from "../Title";

const ImageContent = props => {
  const { name, src, titleStyles } = props;

  return (
    <div>
      {/* <p className="field-component-title">{name}</p> */}
      <Title name={name} titleStyle={titleStyles}/>
      <img className="image-content-image" src={src} alt="" />
    </div>
  );
};

export default ImageContent;
