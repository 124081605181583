import React from "react";
import PropTypes from "prop-types";
import { WhatIcon } from "../../assets";

const InfoBalloon = ({ body }) => {
  return (
    <span className="whatisit">
      <img src={WhatIcon} alt="" />

      <div className="infobox">{body}</div>
    </span>
  );
};

InfoBalloon.propTypes = {
  body: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

InfoBalloon.defaultProps = {
  body: ""
};

export default InfoBalloon;
