import React from "react";
import { withRouter, Link } from "react-router-dom";
import { useEffect, useState } from "reactn";
// import PropTypes from 'prop-types'
import { CUnisex, CheckSuc, Checkmark } from "../../../assets";

const CheckoutSubmission = props => {
  const [type, setType] = useState();

  useEffect(() => {
    let value;
    if (props.type === "Program") {
      value = "program";
    } else if (props.type === "Event") {
      value = "event";
    } else if (props.type === "Online") {
      value = "virtual";
    } else if (props.type === "Membership") {
      value = "membership";
    }
    setType(value);
  }, []);

  return (
    <div className="cont mt-5 pt-5" style={{ textAlign: "center" }}>
      <div className="contbox_5">
        <div className="imgdiv">
          <div className="img">
            <img
              style={{ maxWidth: 245, maxHeight: 245 }}
              src={Checkmark}
              alt=""
            />
            {/* <img src={CheckSuc} className="check" alt="" /> */}
          </div>
          <h1 style={{ color: "green" }}>
            Congratulations, your request has been submitted!
          </h1>
          <span
            style={{ color: "mediumblue", cursor: "pointer" }}
            onClick={_ => {
              // props.history.push(
              //   `/programs/${props.match.params.slug}/${type}/${props.match.params.id}`
              // );
              window.open("/user", "_blank");
            }}
          >
            Proceed onto Dashboard
          </span>
        </div>
      </div>
    </div>
  );
};

CheckoutSubmission.propTypes = {};

export default withRouter(CheckoutSubmission);
