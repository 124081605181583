import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { getSelectedPartyTicket } from "./utils";

const PartyAddOnItem = props => {
  const [addOnFee, setAddOnFee] = useState("Loading");
  const [addOnItem, setAddOnItem] = useState("Loading");
  const [isAddOn, setIsAddOn] = useState(false);
  const calculateFee = () => {
    const plan = getSelectedPartyTicket(props.program, props.selected);

    if (plan.addOn) {
      // props.addOnSemesterItemAmount(parseFloat(props.totalAddOnFee));
      // setAddOnFee(parseFloat(plan.addOnItems[0].addOnFee).toFixed(2));
      // setAddOnItem(plan.addOnItems[0].addOnDetails);
      setIsAddOn(true);
    }
  };
  useEffect(() => {
    calculateFee();
  }, []);

  if (!isAddOn) return null;
  
  const addOnData = props.program.partyDetails.addOnAmounts[props.item]
  const amount = parseInt(addOnData.amount) * parseInt(addOnData.price);

  const formattedMoney = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD", // Replace with the appropriate currency code
    minimumFractionDigits: 2 // Number of decimal places
  }).format(amount);

  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px",
        borderBottom: "1px solid #dedede"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif",
          fontWeight: 500,
          fontSize: "14px"
        }}
      >
        Add-On Fee ({props.item}, ${((parseInt(addOnData.price) * 100) / 100).toFixed(2)} x {addOnData.amount})
      </p>
      <span style={{ fontWeight: 500, fontSize: "14px" }}>
        {formattedMoney}
      </span>
    </li>
  );
};

PartyAddOnItem.propTypes = {
  program: PropTypes.shape({
    tickets: PropTypes.array.isRequired
  }).isRequired,
  selected: PropTypes.number.isRequired
};

export default PartyAddOnItem;
