import React, { useEffect, useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import Form from "./Form";
import ConfirmArchiveModal from "../../modals/ConfirmArchiveModal";

const BoxFormTabbed = props => {
  const [tab, setTab] = useState("0");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [notAllowedToRemove, setNotAllowedToRemove] = useState(false);
  const {
    fields,
    tabs,
    name,
    removable,
    object,
    handleChange,
    handleRemove,
    removeAfterSaved
  } = props;

  const canRemove = () => {
    return (
      removeAfterSaved || (!removeAfterSaved && typeof object.id !== "number")
    );
  };

  const getCount = async () => {
    const ep = `${process.env.REACT_APP_API}/partners/programs/enrollments/count/child/${object.id}`;
    const programCount = await axios.get(ep);
    // console.log(`${object.fullName}: Programs ${programCount.data.data[0].count}`);

    if (parseInt(programCount.data.data[0].count)) {
      setNotAllowedToRemove(true);
      return;
    }

    const ep2 = `${process.env.REACT_APP_API}/partners/virtual/enrollments/count/child/${object.id}`;
    const virtualCount = await axios.get(ep2);
    // console.log(`${object.fullName}: Virtuals ${programCount.data.data[0].count}`);

    if (parseInt(virtualCount.data.data[0].count)) {
      setNotAllowedToRemove(true);
      return;
    }

    const ep3 = `${process.env.REACT_APP_API}/partners/events/enrollments/count/child/${object.id}`;
    const eventCount = await axios.get(ep3);
    // console.log(`${object.fullName}: Events ${programCount.data.data[0].count}`);

    if (parseInt(eventCount.data.data[0].count)) {
      setNotAllowedToRemove(true);
      return;
    }
  }

  useEffect(() => {
    getCount();
  }, []);

  return (
    <>
    {
      showConfirmModal && (
        <ConfirmArchiveModal 
          onSubmit={_ => handleRemove(object)}
          onClose={_ => setShowConfirmModal(false)}
          modalBody={
            notAllowedToRemove
              ? "Sorry, children that have enrolled or applied into a program cannot be deleted."
              : ""
          }
          notAllowed={notAllowedToRemove}
          toRemove="child"
          toArchive={true}
        />
      )
    }
    <div className="box">
      <div className="bgofbox">
        <div className="header">
          <div className="row">
            <div className="col-4">
              <h2>{name}</h2>
            </div>
            <div className="col-8 d-flex">
              {tabs.map((t, i) => (
                <button
                  key={t.name}
                  className={parseInt(i) === parseInt(tab) ? "active" : ""}
                  onClick={() => setTab(i.toString())}
                >
                  {t.name}
                </button>
              ))}
              {removable && canRemove() ? (
                <>
                  <span className="spacer"></span>
                  <button
                    className="remove-box"
                    onClick={() => { 
                      setShowConfirmModal(true);
                    }}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </>
              ) : null}
            </div>
          </div>
        </div>
        {Object.keys(fields).map(fieldTab => {
          return fields[fieldTab].customForm ? (
            fields[fieldTab].customForm
          ) : fieldTab === tab ? (
            <Form
              key={fieldTab}
              handleChange={handleChange}
              fields={fields[fieldTab]}
            />
          ) : (
            ""
          );
        })}
      </div>
    </div>
    </>
  );
};

BoxFormTabbed.propTypes = {
  tabs: PropTypes.array.isRequired,
  fields: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  removable: PropTypes.bool,
  handleRemove: PropTypes.func,
  object: PropTypes.object,
  removeAfterSaved: PropTypes.bool
};

BoxFormTabbed.defaultProps = {
  removable: false,
  handleRemove: () => false,
  object: {},
  removeAfterSaved: true
};

export default BoxFormTabbed;
