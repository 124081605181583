import React, { useEffect, useDispatch, useGlobal, useState } from "reactn";
import { useHistory, useLocation } from "react-router-dom";
import "../../assets/css/componentSpecificCss/CartModal.css";
import { MemberShip1, MemberShip2 } from "../../assets";
import ShoppingCartIcon from "../../assets/img/ShoppingCartIcon.png";
import axios from "axios";

async function getCartItemData(slug) {
  const ep = `${process.env.REACT_APP_API}/par/cart?slug=${slug}`;
  const res = await axios.get(ep);

  const cartItemArr = [];
  res.data.data.items.filter(cartItem => {
    cartItemArr.push({
      id: cartItem.id,
      productId: cartItem.productId,
      planId: cartItem.planId,
      productTable: cartItem.productTable,
      quantity: cartItem.quantity,
      promotionId: cartItem.promotionId
    });
  });

  /////////////////
  const duplicates = {};
  const filteredIds = [];
  const filteredArr = cartItemArr.reduce((acc, currentItem) => {
    const key = `${currentItem.planId}-${currentItem.productId}-${currentItem.productTable}-${currentItem.quantity}-${currentItem.promotionId}`;

    if (!duplicates[key]) {
      // If this item is unique, add it to the new array
      acc.push(currentItem);
      duplicates[key] = 1; // initialize the duplicates count to 1
    } else {
      // If this item is a duplicate, increment its duplicates count
      filteredIds.push(currentItem.id);
      duplicates[key]++;
    }

    return acc;
  }, []);

  const cartDataObj = {
    filteredArr,
    duplicates,
    filteredIds,
    nonFilteredArr: cartItemArr
  };

  return cartDataObj;
}

const calculateSubtotal = (partnerSlug, promoData, cart) => {
  if (Object.keys(cart).length === 0) {
    return 0;
  }
  return cart[partnerSlug].items.reduce((acc, item, index) => {
    let discountPrice = 0;
    if (promoData[index]) {
      if (promoData[index].discount_type === "Percentage") {
        discountPrice = (
          parseInt(item.price) -
          parseInt(item.price) * (promoData[index].discount_percent / 100)
        ).toFixed(2);
      } else if (promoData[index].discount_type === "Fixed Amount") {
        const discount = promoData[index].discount_fixed / 100;
        const price = (parseInt(item.price) - discount).toFixed(2);

        if (price < 0) {
          discountPrice = 0;
        } else {
          discountPrice = price;
        }
      }
      return acc + discountPrice * item.quantity;
    } else {
      return acc + item.price * item.quantity;
    }
  }, 0);
};

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD"
});

const CartEntry = ({ cartItem, itemAmount, product }) => {
  const [promoData, setPromoData] = useState(null);

  useEffect(() => {
    async function fetchData() {
      if (product.promotionId) {
        const ep = `${process.env.REACT_APP_API}/par/cart/promotion/${product.promotionId}`;
        const result = await axios.get(ep);
        setPromoData(result.data.data);
      }
    }

    fetchData();
  }, []);
  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  });
  let imgSrc = `${process.env.REACT_APP_DS_FILES_S3}/${cartItem.image}`;
  if (cartItem.image === 0) {
    imgSrc = MemberShip1;
  } else if (cartItem.image === 1) {
    imgSrc = MemberShip2;
  }

  if (promoData) {
    if (promoData.discount_type === "Percentage") {
      cartItem.price = (
        parseInt(cartItem.price) -
        parseInt(cartItem.price) * (promoData.discount_percent / 100)
      ).toFixed(2);
    } else if (promoData.discount_type === "Fixed Amount") {
      const discount = promoData.discount_fixed / 100;
      const price = (parseInt(cartItem.price) - discount).toFixed(2);

      if (price < 0) {
        cartItem.price = 0;
      } else {
        cartItem.price = price;
      }
    }
  }

  return (
    <div className="cart-modal-entry">
      <img src={imgSrc} alt="item" />
      <div className="cart-modal-entry-info">
        <div className="cart-modal-entry-title">
          {cartItem.name} {itemAmount > 1 ? ` x ${itemAmount}` : ""}
        </div>
        <span>
          {cartItem.type}: {currencyFormatter.format(cartItem.price)}
        </span>
        {(cartItem.type === "Classes" ||
          cartItem.type === "Lessons / Sessions") && (
          <span style={{ flexGrow: 1 }}>Quantity: {cartItem.quantity}</span>
        )}
      </div>
    </div>
  );
};

const CartModal = ({ partnerSlug, animation, hideCart, toggleAddToCart }) => {
  const [cart, setCart] = useGlobal("cart");
  const getCart = useDispatch("getCart");
  const history = useHistory();
  const [isLoaded, setIsLoaded] = useState(false);
  const [showCartModal, setShowCartModal] = useState(false);
  const [cartItemData, setCartItemData] = useState({
    filteredArr: [],
    duplicates: [],
    filteredIds: []
  });
  const [promoData, setPromoData] = useState([]);
  let cartItemCount = 0;

  useEffect(() => {
    (async () => {
      await getCart({ partnerSlug });
      const cartItemArr = await getCartItemData(partnerSlug);
      setCartItemData(cartItemArr);

      const tempPromoData = await Promise.all(
        cartItemArr.nonFilteredArr.map(async product => {
          if (product && product.promotionId) {
            const ep = `${process.env.REACT_APP_API}/par/cart/promotion/${product.promotionId}`;
            const result = await axios.get(ep);

            return result.data.data;
          } else {
            return null;
          }
        })
      );
      setPromoData(tempPromoData);

      setIsLoaded(true);
      if (animation) {
        setShowCartModal(true);
        toggleAddToCart();
        setTimeout(() => {
          setShowCartModal(false);
          setTimeout(() => {
            hideCart();
            toggleAddToCart();
          }, 1000);
        }, 3000);
      }
    })();
  }, []);

  const filteredCart = cart?.[partnerSlug]?.items.filter(item => {
    return !cartItemData.filteredIds.includes(item.cartId);
  });

  const location = useLocation();
  const currentLink = location.pathname;

  return (
    <div
      className={`cart-modal-container ${
        animation ? "cart-modal-dropdown" : ""
      } ${showCartModal ? "open" : ""}`}
    >
      <div className="cart-modal-header">
        <img
          //   className="school-logo"
          style={{
            objectFit: "contain",
            height: "28px",
            width: "30px",
            marginRight: "7px",
            marginBottom: "2px"
          }}
          src={ShoppingCartIcon}
          alt="Shopping Cart Icon"
        />
        Your Cart{" "}
        {cart?.[partnerSlug]?.name ? `(${cart?.[partnerSlug].name})` : ""}
      </div>
      {isLoaded ? (
        <>
          <div className="cart-modal-list">
            {!cart?.[partnerSlug] || cart[partnerSlug].items.length === 0 ? (
              <div className="cart-modal-entry">Your cart is empty!</div>
            ) : (
              filteredCart.map((item, i) => {
                let amount = 1;
                let product = item;
                cartItemData.filteredArr.forEach(cartItem => {
                  if (cartItem.id === item.cartId) {
                    const key = `${cartItem.planId}-${cartItem.productId}-${cartItem.productTable}-${cartItem.quantity}-${cartItem.promotionId}`;
                    amount = cartItemData.duplicates[key];
                    cartItemCount += amount;
                    product = cartItem;
                  }
                });
                return (
                  <CartEntry
                    cartItem={item}
                    key={i}
                    itemAmount={amount}
                    product={product}
                  />
                );
              })
            )}
          </div>
          <div className="cart-modal-footer">
            <div className="cart-modal-subtotal">
              Subtotal:{" "}
              {currencyFormatter.format(
                calculateSubtotal(partnerSlug, promoData, cart)
              )}
            </div>
            <button
              className="cart-modal-button"
              onClick={() => {
                const parts = currentLink.split("/");
                const iFrame = parts[1];
                if (iFrame === "iframe" || iFrame === "iframes") {
                  history.push(`/iframe/programs/${partnerSlug}/cart`);
                } else {
                  history.push(`/programs/${partnerSlug}/cart`);
                }
              }}
              disabled={
                !cart?.[partnerSlug] || cart[partnerSlug].items.length === 0
              }
            >
              View Cart ({cartItemCount})
            </button>
          </div>
        </>
      ) : (
        <div className="cart-modal-entry">Loading...</div>
      )}
    </div>
  );
};

export default CartModal;
export { CartEntry };
