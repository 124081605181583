import Validators from "../validators";

const ValidateProgramBasicInfo = basicInfo => {
  if (!basicInfo) return false;
  const fields = {
    programName: [Validators.isEmpty],
    programType: [Validators.isEmpty],
    phoneNumber: [Validators.isEmpty],
    email: [Validators.isEmpty],
    educatorName: [Validators.isEmpty],
    // educatorTitle: [Validators.isEmpty],
    curriculum: [Validators.isEmpty],
    ageGroups: [Validators.isEmpty],
    capacity: []
  };
  try {
    for (let info of Object.keys(basicInfo)) {
      if (fields[info])
        for (let validator of fields[info]) {
          if (!validator(basicInfo[info])) return false;
        }
    }
  } catch (ex) {
    console.error(ex);
    return false;
  }

  return true;
};

const ValidateProgramLocationHours = vals => {
  if (!vals) return false;
  const fields = {
    address: [Validators.isEmpty],
    // majorIntersection: [Validators.isEmpty],
    city: [Validators.isEmpty],
    province: [Validators.isEmpty],
    country: [Validators.isEmpty],
    postalCode: [Validators.isEmpty],
    hoursOfOperationStart: [Validators.isEmpty],
    hoursOfOperationEnd: [Validators.isEmpty],
    daysOfWeek: [Validators.isEmpty],
    excludedDays: []
  };
  try {
    for (let val of Object.keys(vals)) {
      if (fields[val])
        for (let validator of fields[val]) {
          if (!validator(vals[val])) return false;
        }
    }
  } catch (ex) {
    console.error(ex);
    return false;
  }

  return true;
};

const ValidateProgramIntro = vals => {
  if (!vals) return false;
  const fields = {
    about: [Validators.isEmpty, Validators.isNotEnough],
    location: [Validators.isEmpty, Validators.isNotEnough],
    ourEducators: [Validators.isEmpty, Validators.isNotEnough]
  };
  for (let key of Object.keys(fields)) {
    if (fields[key])
      for (let validator of fields[key]) {
        if (!validator(vals[key])) return false;
      }
  }
  return true;
};
const ValidateGallery = programIntro => {
  return true;
};

const ValidateGuardians = vals => {
  if (!vals.length) return false;
  const fields = {
    fullName: [Validators.isEmpty],
    address: [Validators.isEmpty],
    city: [Validators.isEmpty],
    province: [Validators.isEmpty],
    country: [Validators.isEmpty],
    postalCode: [Validators.isEmpty],
    email: [Validators.isEmpty],
    homePhone: [Validators.isEmpty]
  };
  for (let val of vals) {
    for (let key of Object.keys(fields)) {
      if (fields[key])
        for (let validator of fields[key]) {
          if (!validator(val[key])) return false;
        }
    }
  }
  return true;
};

const ValidateEmergencyContacts = vals => {
  if (!vals.length) return false;
  const fields = {
    children: [Validators.isEmpty],
    relationship: [Validators.isEmpty],
    fullName: [Validators.isEmpty],
    address: [Validators.isEmpty],
    phone: [Validators.isEmpty]
  };
  for (let val of vals) {
    for (let key of Object.keys(fields)) {
      if (fields[key])
        for (let validator of fields[key]) {
          if (!validator(val[key])) return false;
        }
    }
  }
  return true;
};

const ValidatePhysicianContacts = vals => {
  if (!vals.length) return false;
  const fields = {
    children: [Validators.isEmpty],
    fullName: [Validators.isEmpty],
    address: [Validators.isEmpty],
    phone: [Validators.isEmpty],
    company: [Validators.isEmpty]
  };
  for (let val of vals) {
    for (let key of Object.keys(fields)) {
      if (fields[key])
        for (let validator of fields[key]) {
          if (!validator(val[key])) return false;
        }
    }
  }
  return true;
};

const Profile = {
  ValidateProgramBasicInfo,
  ValidateProgramLocationHours,
  ValidateProgramIntro: ValidateProgramIntro,
  ValidateGallery: ValidateGallery,
  ValidateGuardians: ValidateGuardians,
  ValidateEmergencyContacts: ValidateEmergencyContacts,
  ValidatePhysicianContacts: ValidatePhysicianContacts
};

export default Profile;
