import React, { useState, useEffect, useGlobal } from "reactn";
import PropTypes from "prop-types";
import { promotion_discount, getSelectedProgramPlan } from "./utils";

const ProgramSemesterInstallmentsAllTotalPromotion = props => {
  const [total, setTotal] = useState("Loading");
  const [depositTotal, setDepositTotal] = useState("0");
  const [_, setPreGiftTotal] = useGlobal("preGiftTotal");
  const giftBalance = useGlobal("giftBalance")[0];

  const calculateTotal = () => {
    const selected_plan = getSelectedProgramPlan(props.program, props.selected);
    const total_price = parseFloat(selected_plan.total_price);
    const installments_breakdown = parseInt(
      selected_plan.installments_breakdown
    );
    const installments_interest = parseInt(selected_plan.installments_interest ? selected_plan.installments_interest : 0);
    const taxRate = selected_plan.isTaxEnabled
      ? parseFloat(selected_plan.taxInfo[0].percentage) / 100
      : 0;
    const installments =
      (props.installments ? 1 + installments_interest / 100 : 1) || 0;

    const total_amount = total_price * installments;
    const discount_amount = promotion_discount(props.promotion, total_amount);
    const tax_amount = Math.max(0, total_amount - discount_amount) * taxRate;

    // Credit Card Processing Fee
    const creditCardPercent = props.program.transaction_cost
      ? parseFloat(1.5) / 100
      : 1;
    const creditCardFee = props.program.transaction_cost
      ? Math.max(0.5, total_amount - discount_amount) * creditCardPercent
      : 0;
    // const creditCardFee = 0;

    let tot = total_amount - discount_amount + tax_amount + creditCardFee;

    tot = giftBalance > tot ? 0 : tot - giftBalance;
    props.totalAmount(tot.toFixed(2));
    setTotal(tot.toFixed(2));

    if (
      selected_plan.includeDeposit &&
      selected_plan.deposit &&
      selected_plan.deposit !== "0"
    ) {
      const depositAmount = parseFloat(selected_plan.deposit);
      const depositDiscount = promotion_discount(
        props.promotion,
        depositAmount
      );
      const depositTax = Math.max(0, depositAmount - depositDiscount) * taxRate;
      let tot = Math.max(0, depositAmount - depositDiscount) + depositTax;
      setDepositTotal(tot.toFixed(2));
    }
    setPreGiftTotal(tot);
  };

  useEffect(() => {
    calculateTotal();
  }, []);
  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px",
        borderBottom: "1px solid black"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif",
          fontWeight: 600
        }}
      >
        Total
      </p>
      <span style={{ fontWeight: "bold" }}>
        $ {depositTotal === "0" ? total : depositTotal}
      </span>
    </li>
  );
};

ProgramSemesterInstallmentsAllTotalPromotion.propTypes = {
  program: PropTypes.shape({
    plans: PropTypes.array.isRequired
  }).isRequired,
  selected: PropTypes.number.isRequired,
  installments: PropTypes.number.isRequired,
  promotion: PropTypes.object.isRequired
};

export default ProgramSemesterInstallmentsAllTotalPromotion;
