import React from "react";
import ReactPaginate from "react-paginate";
import PropTypes from "prop-types";

const Pagination = props => {
  const { onPageChange } = props;
  return (
    <ReactPaginate
      previousLabel={<i className="fas fa-arrow-left"></i>}
      nextLabel={<i className="fas fa-arrow-right"></i>}
      breakLabel={"..."}
      breakClassName={"page"}
      pageRangeDisplayed={4}
      marginPagesDisplayed={0}
      containerClassName={"numb"}
      activeClassName={"activeno"}
      {...{ ...props, onPageChange: e => onPageChange(e.selected) }}
    />
  );
};

Pagination.propTypes = {
  pageCount: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  forcePage: PropTypes.number
};

Pagination.defaultProps = {
  pageCount: 10,
  onPageChange: e => {}
};

export default Pagination;
