import React from "react";
import { PPIcon, PPIcon2 } from "../../assets";
import { format, isSameDay } from "date-fns";

const UpcomingEvents = ({ events }) => {
  return (
    <div className="upcomingeventslist">
      <h1 className="center-margin">Upcoming Events</h1>

      {events.map((evt, i) => (
        <div className="boxofevent" key={i}>
          <div className="row">
            <div className="col-4">
              <h2>
                {format(new Date(evt.startTime), "MMM d").toUpperCase()}
                {evt.endTime &&
                !isSameDay(new Date(evt.startTime), new Date(evt.endTime))
                  ? ` - ${format(new Date(evt.endTime), "d")}`
                  : ""}
              </h2>
              <p className="hours2">
                {evt.allDay ? "" : format(new Date(evt.startTime), "hh:mm aa")}
              </p>
            </div>
            <div className="col-8">
              <h3>{evt.title}</h3>
              <div className="desc">
                <p>
                  <img src={PPIcon} alt="Student" />
                  {evt.childName || "-"}
                </p>
                <p>
                  <img src={PPIcon2} alt="Category" />
                  {evt.type || "-"}
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default UpcomingEvents;
