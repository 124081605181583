import { setGlobal, addReducer } from "reactn";
import axios from "axios";
import { CometChat } from "@cometchat-pro/chat";
import { setEducators, setAdmin, setParents, setPartner } from "../";
import { toast } from "react-toastify";

addReducer("performLogin", async (global, dispatch, user) => {
  setGlobal({ loading: true });
  try {
    const ep = `${process.env.REACT_APP_API}/auth`;
    const res = await axios.post(ep, user);

    if (res.data.success) {
      toast("Successfully logged in");
      localStorage.setItem("ds_token", res.data.data.token);
      localStorage.setItem("franchiser_token", res.data.data.token);
      if (res.data.data.user.accountType === "Educator") {
        setEducators();
      } else if (res.data.data.user.accountType === "Admin") {
        setAdmin();
      } else if (res.data.data.user.accountType === "Parent") {
        setParents();
      } else if (res.data.data.user.accountType === "Volunteer") {
        setParents();
      } else if (res.data.data.user.accountType === "Student") {
        setParents();
      } else if (res.data.data.user.accountType === "Partner") {
        setPartner();
      }
      // const userPromise = CometChat.login(
      //   res.data.data.user.cometchat,
      //   process.env.REACT_APP_COMETCHAT_API
      // );

      /**
       * MESSAGE LISTENER: Listen for new messages
       */

      // (async () =>
      //   CometChat.addMessageListener(
      //     "ds-message-listener",
      //     new CometChat.MessageListener({
      //       onTextMessageReceived: textMessage => {
      //         dispatch.ccReceiveMessage(textMessage);
      //       },
      //       onMediaMessageReceived: mediaMessage => {
      //         // Handle media message
      //       },
      //       onCustomMessageReceived: customMessage => {
      //         console.log(
      //           "Custom message received successfully",
      //           customMessage
      //         );
      //         // Handle custom message
      //       }
      //     })
      //   ))();
      // userPromise.then(_ =>
      //   CometChat.getUnreadMessageCountForAllUsers().then(val =>
      //     setGlobal({ unread: Object.keys(val).length > 0 })
      //   )
      // // },
      // // err => {
      // //   console.error(`CometChat promise rejected in "performLogin":`, err.name, err.message );
      // ).catch( err => {
      //   //catches FAILED_TO_FETCH errors.
      //   console.error(`CometChat promise error in "performLogin":`, err.name, err.message );
      // });

      return {
        ...global,
        pathway: ["Home"],
        loading: false,
        lastAPICall: res,
        dsUser: res.data.data.user,
        ...res.data.data.data,
        // userPromise
      };
    } else {
      toast.error("Failed to login");
    }
    return { ...global, loading: false, lastAPICall: res };
  } catch (ex) {}
  return { ...global, loading: false, lastAPICall: null };
});

addReducer("performLoginPartners", async (global, dispatch, user) => {
  setGlobal({ loading: true });
  try {
    const ep = `${process.env.REACT_APP_API}/auth/partners`;
    const res = await axios.post(ep, user);
    if (res.data.success) {
      toast("Successfully logged in");
      localStorage.setItem("ds_token", res.data.data.token);
      if (res.data.data.user) {
        setPartner();
      }

      return {
        ...global,
        pathway: ["Home"],
        loading: false,
        lastAPICall: res,
        dsUser: res.data.data.user
      };
    } else {
      toast.error("Failed to login");
    }
    return { ...global, loading: false, lastAPICall: res };
  } catch (ex) {}
  return { ...global, loading: false, lastAPICall: null };
});

addReducer("logout", async (global, dispatch) => {
  // CometChat.logout().then(
  //   () => {
  //     //Logout completed successfully
  //   },
  //   error => {
  //     //Logout failed with exception
  //   }
  // );
  localStorage.removeItem("ds_token");
  return {
    ...global,
    dsUser: null,
    ccUser: null,
    ccSelectedContact: {},
    ccSelectedMessages: [],
    unread: false
  };
});

addReducer("validateToken", async (global, dispatch) => {
  setGlobal({ loading: true });
  try {
    const ep = `${process.env.REACT_APP_API}/auth/verify`;
    const res = await axios.post(ep, {
      token: localStorage.getItem("ds_token")
    });

    if (res.data.success) {
      if (res.data.data.user.accountType === "Educator") {
        setEducators();
      } else if (res.data.data.user.accountType === "Admin") {
        setAdmin();
      } else if (res.data.data.user.accountType === "Parent") {
        setParents();
      } else if (res.data.data.user.accountType === "Volunteer") {
        setParents();
      } else if (res.data.data.user.accountType === "Student") {
        setParents();
      } else if (res.data.data.user.accountType === "Partner") {
        setPartner();
      }

      // const userPromise = CometChat.login(
      //   res.data.data.user.cometchat,
      //   process.env.REACT_APP_COMETCHAT_API
      // );

      /**
       * MESSAGE LISTENER: Listen for new messages
       */

      // (async () => {
      //   CometChat.addUserListener(
      //     "ds-user-listener",
      //     new CometChat.UserListener({
      //       onUserOnline: onlineUser => {},
      //       onUserOffline: offlineUser => {}
      //     })
      //   );

      //   CometChat.addMessageListener(
      //     "ds-message-listener",
      //     new CometChat.MessageListener({
      //       onTextMessageReceived: textMessage => {
      //         dispatch.ccReceiveMessage(textMessage);
      //       },
      //       onMediaMessageReceived: mediaMessage => {
      //         // Handle media message
      //       },
      //       onCustomMessageReceived: customMessage => {
      //         console.log(
      //           "Custom message received successfully",
      //           customMessage
      //         );
      //         // Handle custom message
      //       }
      //     })
      //   );
      // })();
      // userPromise.then(_ =>
      //   CometChat.getUnreadMessageCountForAllUsers().then(val =>
      //     setGlobal({ unread: Object.keys(val).length > 0 })
      //   )
      // // },
      // // err => {
      // //   console.error(`CometChat promise rejected in "validateToken":`, err.name, err.message );
      // ).catch( err => {
      //   //catches LOGIN_IN_PROGRESS and FAILED_TO_FETCH errors.
      //   console.error(`CometChat promise error in "validateToken":`, err.name, err.message );
      // });
      // await this.dispatch.ccLogin(res.data.data.email);
      return {
        ...global,
        loading: false,
        pathway: ["Home"],
        lastAPICall: res,
        dsUser: res.data.data.user,
        ...res.data.data.data,
        // userPromise
      };
    } else {
      localStorage.removeItem("ds_token");
      return {
        ...global,
        loading: false,
        lastAPICall: res,
        dsUser: null
      };
    }
  } catch (ex) {
    return { ...global, loading: false, lastAPICall: null };
  }
});
addReducer("validateTokenPartner", async (global, dispatch) => {
  setGlobal({ loading: true });
  try {
    const ep = `${process.env.REACT_APP_API}/auth/verify/partner`;
    const res = await axios.post(ep, {
      token: localStorage.getItem("ds_token")
    });
    if (res.data.success) {
      setPartner();

      /*const userPromise = CometChat.login(
        res.data.data.user.cometchat,
        process.env.REACT_APP_COMETCHAT_API
      );

      /**
       * MESSAGE LISTENER: Listen for new messages
       */

      /*(async () => {
        CometChat.addUserListener(
          "ds-user-listener",
          new CometChat.UserListener({
            onUserOnline: onlineUser => {

            },
            onUserOffline: offlineUser => {

            }
          })
        );

        CometChat.addMessageListener(
          "ds-message-listener",
          new CometChat.MessageListener({
            onTextMessageReceived: textMessage => {

              dispatch.ccReceiveMessage(textMessage);
            },
            onMediaMessageReceived: mediaMessage => {

              // Handle media message
            },
            onCustomMessageReceived: customMessage => {
              console.log(
                "Custom message received successfully",
                customMessage
              );
              // Handle custom message
            }
          })
        );
      })();
      userPromise.then(_ =>
        CometChat.getUnreadMessageCountForAllUsers().then(val =>
          setGlobal({ unread: Object.keys(val).length > 0 })
        )
      );*/
      // await this.dispatch.ccLogin(res.data.data.email);
      return {
        ...global,
        loading: false,
        pathway: ["Home"],
        lastAPICall: res,
        dsUser: res.data.data.user
      };
    } else {
      localStorage.removeItem("ds_token");
      return {
        ...global,
        loading: false,
        lastAPICall: res,
        dsUser: null
      };
    }
  } catch (ex) {
    return { ...global, loading: false, lastAPICall: null };
  }
});
