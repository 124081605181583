import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { getSelectedProgramPlan } from "./utils";

const OnlineOneTimeSemesterSubtotal = props => {
  const [subtotal, setSubtotal] = useState("Loading");
  const calculateSubtotal = () => {
    const plan = getSelectedProgramPlan(props.program, props.selected);
    let total_price = parseFloat(plan.total_price);
    if (plan.addOnItems) {
      plan.addOnItems.map(item => {
        total_price += parseFloat(item.value);
      });
    }
    setSubtotal(
      parseFloat(
        parseFloat(
          total_price *
            (props.installments
              ? 1 + parseInt(plan.installments_interest) / 100
              : 1) || 0
        )
      ).toFixed(2)
    );
  };
  useEffect(() => {
    calculateSubtotal();
  }, []);
  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px",
        borderBottom: "1px solid #dedede"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif",
          fontWeight: 600
        }}
      >
        Subtotal
      </p>
      <span style={{ fontWeight: "bold" }}>${subtotal}</span>
    </li>
  );
};

OnlineOneTimeSemesterSubtotal.propTypes = {
  program: PropTypes.shape({
    plans: PropTypes.array.isRequired
  }).isRequired,
  selected: PropTypes.number.isRequired,
  installments: PropTypes.number.isRequired
};

export default OnlineOneTimeSemesterSubtotal;
