import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { promotion_discount, coupon_discount } from "./utils";

const TaxablePromotionMembership = props => {
  const [taxName, setTaxName] = useState("Loading");
  const [taxAmount, setTaxAmount] = useState("Loading");

  const calculateTaxAmount = () => {
    const price = JSON.parse(props.program.prices[props.selected]);
    const price_per_term = parseFloat(price.price_per_term);
    let amount = 0;
    if (props.promotion) {
      const discount_amount = promotion_discount(
        props.promotion,
        price_per_term
      );
      console.log("price", price);
      amount = price.isTaxEnabled
        ? Math.max(0, price_per_term - discount_amount) *
          parseFloat(price.taxInfo[0].percentage / 100)
        : Math.max(0, price_per_term - discount_amount) *
          parseFloat(price.taxInfo[0].percentage / 100);
    } else {
      const c_dis = props.coupon
        ? coupon_discount(props.coupon, price_per_term)
        : 0;
      amount = parseFloat(
        Math.max(0, price_per_term - c_dis) *
          (price.taxInfo[0].percentage / 100)
      );
    }

    setTaxAmount(amount.toFixed(2));
    props.taxAmount(amount.toFixed(2));
  };

  const getTaxName = () => {
    const displayName = JSON.parse(props.program.prices[props.selected])
      .taxInfo[0].display_name;

    const percent = JSON.parse(props.program.prices[props.selected]).taxInfo[0]
      .percentage;
    const name = `${displayName} (${percent}%)`;

    setTaxName(name);
  };

  useEffect(() => {
    calculateTaxAmount();
    getTaxName();
  }, [props.coupon]);

  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px",
        borderBottom: "1px solid #dedede"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif",
          fontWeight: 600
        }}
      >
        {taxName}
      </p>
      <span style={{ fontWeight: "bold" }}>${taxAmount}</span>
    </li>
  );
};

TaxablePromotionMembership.propTypes = {
  program: PropTypes.shape({
    prices: PropTypes.array.isRequired
  }).isRequired,
  selected: PropTypes.number.isRequired,
  promotion: PropTypes.object,
  coupon: PropTypes.object
};

export default TaxablePromotionMembership;
