import "./accountSettings";
import "./familyProfile";
import "./cart";
import "./application-view";
import "./tuitionPayments";
import { setGlobal } from "reactn";

const promoCode = "";
setGlobal({ promoCode });
const coupon = null;
setGlobal({ coupon });
