import React, { useState, useEffect, useGlobal } from "reactn";
import PropTypes from "prop-types";

import { coupon_discount, MINIMUM_CHARGE, applyGift } from "./utils";

const MembershipTotal = props => {
  const [total, setTotal] = useState("Loading");
  const [_, setPreGiftTotal] = useGlobal("preGiftTotal");
  const giftBalance = useGlobal("giftBalance")[0];

  const calculateTotalAmount = () => {
    const price = JSON.parse(props.program.prices[props.selected]);
    const price_per_term = parseFloat(price.price_per_term);
    const discount_amount = props.coupon
      ? coupon_discount(props.coupon, price_per_term)
      : 0;
    const taxRate = price.isTaxEnabled
      ? parseFloat(price.taxInfo[0].percentage / 100)
      : 0;
    const tax_amount =
      Math.max(0, price_per_term - discount_amount) * taxRate;
    let total =
      price_per_term === 0
        ? 0
        : Math.max(0, price_per_term - discount_amount) +
          tax_amount;
    setPreGiftTotal(total);
    ({ amount: total } = applyGift(
      props.program.manual_invoices,
      giftBalance,
      total
    ));
    setTotal(total.toFixed(2));
    props.totalAmount(total.toFixed(2));
  };

  useEffect(() => {
    calculateTotalAmount();
  }, [props.coupon, giftBalance]);

  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif",
          fontWeight: 600
        }}
      >
        Total
      </p>
      <span style={{ fontWeight: "bold" }}>${total}</span>
    </li>
  );
};

MembershipTotal.propTypes = {
  program: PropTypes.shape({
    prices: PropTypes.array.isRequired
  }).isRequired,
  selected: PropTypes.number.isRequired
};

export default MembershipTotal;
