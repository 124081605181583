import React from "react";
import PropTypes from "prop-types";

import { isPartyPlanTaxEnabled, getSelectedPartyTicket } from "./utils";
import CheckoutPromotionDisplay from "./CheckoutPromotionDisplay";

//  Regular Semester Program Components
import ProgramRegularSemesterPromotionTotal from "./ProgramRegularSemesterPromotionTotal";
import TaxableProgramRegularSemester from "./TaxableProgramRegularSemester";
import TaxableProgramPromotionRegularSemester from "./TaxableProgramPromotionRegularSemester";
import ProgramPromotionsTotal from "./ProgramPromotionsTotal";
import TaxableProgram from "./TaxableProgram";
import ProgramRegularSemesterSubtotal from "./ProgramRegularSemesterSubtotal";
import ProgramSemesterAddOnItem from "./ProgramSemesterAddOnItem";
import ProgramRegularSemesterTotal from "./ProgramRegularSemesterTotal";
import ProgramRegularSemester from "./ProgramRegularSemester";
import ProgramFreePlan from "./ProgramFreePlan";

import CheckoutCouponDisplay from "./CheckoutCouponDisplay";
import CheckoutGiftCard from "./CheckoutGiftCard";

// Party imports
import PartyPrice from "./PartyPrice";
import PartyAddOnItem from "./PartyAddOnItem";
import PartySubtotal from "./PartySubtotal";
import PartyTotal from "./PartyTotal";
import TaxableParty from "./TaxableParty";
import PartyPromotionTotal from "./PartyPromotionTotal";
import TaxablePromotionParty from "./TaxablePromotionParty";

const CheckoutParty = props => {
  console.log("props", props);
  const selected_plan = getSelectedPartyTicket(props.program, props.selected);
  let giftBalance = props.giftBalance;
  if (!props.giftBalance) {
    giftBalance = 0;
  }

  // Regular Semester Program
  // if (selected_plan.type === "Semester") {
  let totalAddOnFee = 0;
  if (selected_plan.addOn && selected_plan.addOnItems) {
    selected_plan.addOnItems.map(item => {
      totalAddOnFee += parseFloat(item.value);
    });
  }
  console.log("TOTAL ADD ON FEE", totalAddOnFee);
  console.log("Selected Plan", selected_plan);
  return (
    <>
      <PartyPrice
        program={props.program}
        selected={props.selected}
        itemAmount={e => props.itemAmount(e)}
      />
      {selected_plan.addOn &&
        Object.entries(props.program.partyDetails.addOnAmounts).map(([key, value], index) => {
          if (parseInt(value.amount) === 0) {
            return null;
          }
          return (
            <PartyAddOnItem
              program={props.program}
              selected={props.selected}
              // addOnSemesterItemAmount={e => props.addOnSemesterItemAmount(e)}
              item={key}
              totalAddOnFee={totalAddOnFee}
            />
          );
        })}
      <PartySubtotal
        program={props.program}
        selected={props.selected}
      />

      {
        // if there is a tax enabled then show it depending the promotion present
        isPartyPlanTaxEnabled(props.program, props.selected) && (
          <>
            {props.promotion ? (
              <>
                <TaxablePromotionParty
                  promotion={props.promotion}
                  program={props.program}
                  selected={props.selected}
                  taxAmount={e => props.taxAmount(e)}
                />
              </>
            ) : (
              <>
                <TaxableParty
                  coupon={props.coupon}
                  program={props.program}
                  selected={props.selected}
                  taxAmount={e => props.taxAmount(e)}
                />
              </>
            )}
          </>
        )
      }

      {
        // if there is a promotion show it
        props.promotion && (
          <>
            <CheckoutPromotionDisplay
              program_type="Regular-Semester"
              promotion={props.promotion}
              program={props.program}
              selected={props.selected}
              promotionAmount={e => props.promotionAmount(e)}
            />
          </>
        )
      }
      {props.coupon && (
        <>
          <CheckoutCouponDisplay
            program_type="Regular-Semester"
            type={props.type}
            program={props.program}
            coupon={props.coupon}
            selected={props.selected}
            taxes={props.taxes}
            couponAmount={e => props.couponAmount(e)}
          />
        </>
      )}

      {!!giftBalance && (
        <CheckoutGiftCard
          giftBalance={giftBalance}
          manual={props.program.manual_invoices}
        />
      )}

      {props.promotion ? (
        <>
          <PartyPromotionTotal
            promotion={props.promotion}
            program={props.program}
            selected={props.selected}
            totalAmount={e => props.totalAmount(e)}
          />{" "}
        </>
      ) : (
        <>
          <PartyTotal
            coupon={props.coupon}
            program={props.program}
            selected={props.selected}
            totalAmount={e => props.totalAmount(e)}
          />{" "}
        </>
      )}
    </>
  );
  // }
};

CheckoutParty.propTypes = {
  program: PropTypes.object.isRequired,
  selected: PropTypes.number.isRequired,
  promotion: PropTypes.object,
  coupon: PropTypes.object, // global
  type: PropTypes.string.isRequired,
  installments: PropTypes.any, // state
  dropins: PropTypes.any,
  displayDate: PropTypes.any, // state
  selected_days: PropTypes.any // state
};

export default CheckoutParty;
