import React from "react";
import "../fieldComponent.css";
import "./TextArea.css";
import Title from "../Title";

const DEFAULT_HEIGHT = "100px";

const TextArea = props => {
  const {
    name,
    required,
    placeholder,
    handleChange,
    value,
    fieldValid,
    setFieldValid,
    isDisabled, 
    info,
    styles,
    wrapperStyles,
    titleStyle,
    iconStyles
  } = props;

  const height = props.height || DEFAULT_HEIGHT;

  return (
    <div style={wrapperStyles}>
      {/* <p className="field-component-title">
        {name}
        {required ? <span className="required-star"> *</span> : ""}
      </p> */}
      <Title name={name} required={required} info={info} titleStyle={titleStyle} iconStyles={iconStyles} />

      <textarea
        value={value}
        onChange={e => {
          e.persist();
          handleChange(e);
          setFieldValid(true);
        }}
        placeholder={placeholder}
        className={`text-area-field ${
          fieldValid ? "" : "string-input-invalid"
        }`}
        style={{ height: height, ...styles }}
        disabled={isDisabled || false}
      />
    </div>
  );
};

export default TextArea;
