import Parents from "./parents";
import Educators from "./educators";
import Partners from "./partners";
const Validators = {
  Parents: Parents,
  Educators,
  Partners
};

export default Validators;
