import React from "reactn";
import { TIcon1, TIcon2, TIcon3, TIcon4 } from "../../../assets";
import { Educator } from "../../../assets";
import { numToHours } from "../../../lib/util";

class TeacherProfile extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ratingsShown: false
    };
    this.showRatings = this.showRatings.bind(this);
  }

  showRatings = async () => {
    this.setState({ ratingsShown: true });
  };

  render() {
    const vals = this.global.school;
    const wRate = Math.ceil(parseFloat(vals.weeklyRate));
    const mRate = Math.ceil(parseFloat(vals.monthlyRate));
    const startRate =
      wRate > 0 && wRate < mRate ? wRate : mRate > 0 ? mRate : wRate;
    return (
      <div
        className="container-fluid teacherprofil"
        style={{ display: "block" }}
      >
        <div className="profile-container">
          <div className="col-lg-3">
            <div className="col-lg-9">
              <div className="profil">
                <img
                  src={
                    vals.profilePhoto
                      ? `${process.env.REACT_APP_DS_FILES_S3}/${vals.profilePhoto}`
                      : Educator
                  }
                  alt=""
                  className="profile-image"
                />
                <h2>{vals.educatorName || vals.displayName}</h2>
                <ul>
                  {vals.docFA === 2 ? (
                    <li>
                      First Aid Certification{" "}
                      {vals.dsUserId === 10 ? "(2019)" : ""}
                    </li>
                  ) : null}
                  {vals.docCPR === 2 ? (
                    <li>
                      CPR-C Certification {vals.dsUserId === 10 ? "(2019)" : ""}
                    </li>
                  ) : null}
                  {vals.docHA === 2 ? <li>Health Assessment</li> : null}
                  {vals.docI === 2 ? <li>Immunized</li> : null}
                  {vals.docECE === 2 ? <li>Registered Educator</li> : null}
                  {vals.docRECE === 2 ? (
                    <li>
                      Registered Early Childhood Educator{" "}
                      {vals.registeredECENumber
                        ? `#${vals.registeredECENumber}`
                        : null}
                    </li>
                  ) : null}
                  {vals.docCC === 2 ? <li>Enhanced Background Check</li> : null}
                  {vals.docVSC === 2 ? <li>Vulnerable Sector Check</li> : null}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <h1>{vals.programName}</h1>
            <div className="data">
              <div className="box">
                <img src={TIcon1} alt="" />
                <h3>AGE:</h3>
                {vals.ageGroups
                  .sort((a, b) => {
                    if (a.order < b.order) return -1;
                    if (a.order > b.order) return 1;
                    return 0;
                  })
                  .map((g, i) => (
                    <p key={i}>{g.name}</p>
                  ))}
              </div>
              <div className="box">
                <img src={TIcon2} alt="" />
                <h3>TUITION:</h3>
                <p>
                  {startRate ? `From $${startRate} / day` : `To be detemined !`}
                </p>
              </div>
              <div className="box">
                <img src={TIcon3} alt="" />
                <h3>OPEN:</h3>
                <p>{vals.daysOfWeek}</p>
                <p>
                  {numToHours(vals.hoursOfOperationStart)} -{" "}
                  {numToHours(vals.hoursOfOperationEnd)}
                </p>
              </div>

              <div className="box" style={{ maxWidth: "180px" }}>
                <img src={TIcon4} alt="" />
                <h3>STAFF TO CHILD RATIO:</h3>
                <p>
                  1:
                  {Math.round(
                    parseFloat(vals.spots) / parseFloat(vals.numStaff)
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 forbtn">
            <button
              className="btn"
              id="ratings"
              onClick={() => this.props.gallery()}
            >
              View Gallery
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default TeacherProfile;
