import React, { useEffect, useState, useGlobal } from "reactn";
import PropTypes from "prop-types";

import { promotion_discount } from "./utils";
const MembershipPromotionsTotal = props => {
  const [total, setTotal] = useState("Loading");
  const [_, setPreGiftTotal] = useGlobal("preGiftTotal");
  const giftBalance = useGlobal("giftBalance")[0];

  const getTotalAmount = () => {
    const price = JSON.parse(props.program.prices[props.selected]);
    const price_per_term = parseFloat(price.price_per_term);
    const discount_amount = promotion_discount(props.promotion, price_per_term);
    const taxRate = price.isTaxEnabled
      ? parseFloat(price.taxInfo[0].percentage / 100)
      : 0;
    const tax_amount = price.isTaxEnabled
      ? Math.max(0, price_per_term - discount_amount) * taxRate
      : Math.max(0, price_per_term - discount_amount) * taxRate;
    let total = Math.max(0, price_per_term - discount_amount) + tax_amount;
    setPreGiftTotal(total);
    total = giftBalance > total ? 0 : total - giftBalance;
    setTotal(total.toFixed(2));
    props.totalAmount(total.toFixed(2));
  };

  useEffect(() => {
    getTotalAmount();
  }, []);
  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif",
          fontWeight: 600
        }}
      >
        Total
      </p>
      <span style={{ fontWeight: "bold" }} data-testid="total-value">
        ${total}
      </span>
    </li>
  );
};

MembershipPromotionsTotal.propTypes = {
  program: PropTypes.shape({
    prices: PropTypes.array.isRequired
  }).isRequired,
  selected: PropTypes.number.isRequired,
  promotion: PropTypes.object.isRequired
};

export default MembershipPromotionsTotal;
