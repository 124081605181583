import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Terms = () => {
  return (
    <>
      <Helmet>
        <title>Dreamschools - Privacy</title>
        <link
          rel="canonical"
          href="https://www.dreamschools.com/terms-of-use"
        />
      </Helmet>
      <div className="section_page_content">
        <div
          className="custom_content_width_constraint"
          style={{
            whiteSpace: "pre-line",
            fontSize: "1rem",
            paddingBottom: "3.5rem"
          }}
        >
          <h3 dir="ltr">Terms of Use</h3>
          <p dir="ltr">Last updated: April 22, 2022</p>
          <br />
          <p dir="ltr">
            Welcome to Dreamschools! Our website serves as an online marketplace
            for families to discover and book children’s activities &amp;
            programs. Please read these Terms of Use (the “Agreement” or
            “Terms”) carefully, as they constitute a legally-binding agreement
            made between you, whether personally or on behalf of an entity
            (“you”) and Dreamschools, (“we,” “us” or “our”), concerning your
            access to and use of the dreamschools.com platform as well as any
            other media form, media channel, or mobile application related,
            linked, or otherwise connected thereto (collectively, the “Site”).
            You agree that by accessing the Site, you have read, understood, and
            agree to be bound by all of these Terms of Use.
          </p>
          <br />
          <p dir="ltr">
            Please read our Terms of Use and{" "}
            <a href="https://dreamschools.com/privacy-policy">
              {" "}
              Privacy Policy
            </a>
            {" "}carefully. By using our Site and Services (such as signing up as a
            user, customer or provider), you agree to be bound by these Terms
            and our Privacy Policy. If you do not agree to or are not willing to
            be bound by these Terms, please refrain from using our Site and
            Services.
          </p>
          <br />
          <p dir="ltr">
            Supplemental terms and conditions or documents that may be posted on
            the Site from time to time are hereby expressly incorporated herein
            by reference. We reserve the right, in our sole discretion, to make
            changes or modifications to these Terms of Use at any time and for
            any reason. It is at your discretion to see any changes by viewing
            the “Last updated” date of these Terms of Use, and you waive any
            right to receive specific notice of each such change. It is your
            responsibility to periodically review these Terms of Use to stay
            informed of updates. You will be subject to and will be deemed to
            have been made aware of and to have accepted, the changes in any
            revised Terms of Use by your continued use of the Site after the
            date such revised Terms of Use are posted.
          </p>
          <br />
          <br />
          <strong dir="ltr">Using Dreamschools</strong>
          <p dir="ltr">
            Dreamschools is an online marketplace for families to discover and
            book children’s activities &amp; programs, and a platform for
            educational and community organizations to manage their listings
            &amp; programs with a suite of digital tools. A person who completes
            the Dreamschools’ account registration process, including Providers
            and Users is a “Member”. When we use the word “you” in these Terms,
            it refers to any Member, while if we use one of those specific terms
            (Provider or User), it only applies to that category of user.
          </p>
          <br />
          <p dir="ltr">
            Provider(s) include those using the Site to create listings, sell
            services &amp; products, or host events and programs of varying
            forms. This phrase includes past mentions of Educators in our Terms
            of Use, and those running home programs such as independent
            daycares.
          </p>
          <br />
          <p dir="ltr">
            Providers can organize programs &amp; activities on premises of
            their choosing, and may list these programs on the Dreamschools Site
            (“Listings”). Users may reserve spots for their child(ren) with a
            Provider via a Listing (“Enrollment”). Providers post Listings on
            Dreamschools Site including but not limited to classes, semester
            programs, camps, workshops, online virtual activities, recurring
            billing programs such as childcare, and other activities for Users
            to register (“Registration). You may view select Listings as an
            unregistered visitor on the Site; however, if you wish to enroll
            your child(ren) in a program or activity you must first register to
            create a Dreamschools Account (defined below).
          </p>
          <br />
          <p dir="ltr">
            While these Terms do place certain restrictions on our Members’ use
            of our Services, you understand that Dreamschools is not a party to
            the relationship or experiences formed between a User and a
            Provider’s Listings. Dreamschools does not provide monitoring for
            any program or activity listed on the Site, and Dreamschools is not
            a school or childcare institution. Dreamschools does not oversee the
            provision or administration of services of Providers. A User may use
            Dreamschools to facilitate its registration and receipt of services
            from a Provider, but Dreamschools is not responsible for making sure
            those Listings programs or activities are actually provided or are
            up to a certain standard of quality, or for mediating disputes
            between Providers and/or Users. Dreamschools similarly cannot and
            will not be responsible for ensuring that information (including
            credentials and qualifications) a Provider or User provides about
            themselves or their related-parties is accurate or up-to-date. We do
            not control the actions of any Provider or User. By using the Site,
            you agree and acknowledge that it is your responsibility to verify
            the information contained in any Listing.
          </p>
          <br />
          <p dir="ltr">
            If you choose to create a listing on Dreamschools, you understand
            and agree that your relationship with Dreamschools’ is limited to
            being a Member and not an employee, agent, or joint venturer of
            Dreamschools for any reason, and you act exclusively on your own
            behalf and for your own benefit, and not on behalf of or for the
            benefit of Dreamschools. Dreamschools does not control and has no
            right to control any premises, program, activities, business or
            operation contained in a Listing, offline dealings associated with
            any Listing, or any other matters related to any Listing that you
            provide, except for the right to allow, limit, or reject Listings
            from the Site. As a Member, you agree not to do anything to create a
            false impression that you are endorsed by, partnering with, or
            acting on behalf of or for the benefit of Dreamschools, including
            the inappropriate use of any Dreamschools intellectual property.
          </p>
          <br />
          <p dir="ltr">
            Dreamschools is an online platform to facilitate registrations,
            enrollments, scheduling, communications, and payments between Users
            and Providers. Dreamschools provides no direct childcare or
            educational services as a Provider, or to any User or other party on
            behalf of any Provider, and makes no representation or guarantee
            that (i) the Site or Services will result in Enrollments or other
            benefits to any Provider, and/or (ii) Listings are suitable for
            Users or their related-parties (i.e., a User’s children).
          </p>
          <br />
          <p dir="ltr">
            At Dreamschools, we are always improving and adding onto the
            Services we provide, thus they may change over time. We may
            discontinue or suspend any part of our Services, we may introduce
            new features, or we may impose certain limit features or restrict
            access to parts or all of the services. If a material change to a
            service will adversely affect you, we will do our best to give you
            notice however, this is not always possible. We reserve the right to
            remove any Content from the Services at any time, for any reason, in
            our sole discretion, and without any notice.
          </p>
          <br />
          <strong dir="ltr">Eligibility Criteria</strong>
          <br />
          <p dir="ltr">
            We only permit individuals who are at least 18 years old and can
            form legally binding contracts with us to use Dreamschools. If you
            are under 18 years old and would like to use Dreamschools, you can,
            but only if a parent or legal guardian who is at least 18 years old
            supervises you. The adult would be the user and is responsible for
            any and all activity. Additionally, if you are under 18 years old
            and would like to use Dreamschools, then it is only to register into
            a Listing or website of a Provider that specializes in youth &amp;
            community services.
          </p>
          <br />
          <p dir="ltr">
            As a Provider and its related-parties, you represent and warrant as
            follows: a) you are legally permitted to work/or and provide in the
            jurisdiction where you are seeking and conducting services; and b)
            neither you nor any member of your household may have ever been the
            subject of a complaint, restraining order or any other legal action
            or criminal offence involving violence, abuse, neglect, fraud, or
            any other offence that involves endangering the health &amp; safety
            of others, and that you are not registered or currently required to
            register as a sex offender with any government agency in any
            jurisdiction.
          </p>
          <br />
          <strong dir="ltr">Account Registration</strong>
          <p dir="ltr">
            By registering for an account, you agree to (i) provide accurate,
            truthful, current and complete account information; (ii) maintain
            and promptly update your account information to maintain its
            accuracy and completeness; (iii) maintain the security of your
            account by keeping your password confidential, not sharing your
            password with others, and restricting access to your account,
            computer and device; (iv) promptly notify us if you discover or
            otherwise suspect any security breaches related to our Site,
            Services or your account; (v) take responsibility for all activities
            that occur with the use of your account and password; (vi) accept
            all risks of unauthorized access and instruction; (vii) not permit
            any use that would damage, interfere with or overload the platform
            and user account; and, (viii) not transfer your account to anyone
            else without our prior written permission.
          </p>
          <br />
          <p dir="ltr">
            By creating an account with Dreamschools, and using our platform,
            you are not creating an agency, partnership, joint venture,
            employment, or franchisee relationship with us. You further
            acknowledge that by registering for a program through Dreamschools,
            no confidential, fiduciary, contractually implied, or other
            relationship is created with us other than pursuant to these Terms.
          </p>
          <br />
          <p dir="ltr">
            We reserve the right to remove, reclaim, or change a username you
            select if we determine, in our sole discretion, that such username
            is inappropriate, obscene, or otherwise objectionable.You accept to
            advise Dreamschools of any changes in information by periodically
            updating the information to maintain its accuracy. The user account
            is provided free of charge to you.
          </p>
          <br />
          <p dir="ltr">
            If you use our Sites and Services, Dreamschools may communicate with
            you via electronic messages, including email, text message/SMS, or
            mobile push notifications in accordance with our privacy policy.
          </p>
          <br />
          <strong dir="ltr">User Representations</strong>
          <p dir="ltr">
            By using the Site, you represent and warrant that: (1) all
            registration information you submit will be true, accurate, current,
            and complete; (2) you will maintain the accuracy of such information
            and promptly update such registration information as necessary; (3)
            you have the legal capacity and you agree to comply with these Terms
            of Use; (4) you are not a minor in the jurisdiction in which you
            reside; (5) you will not access the Site through automated or
            non-human means, whether through a bot, script or otherwise; (6) you
            will not use the Site for any illegal or unauthorized purpose; and
            (7) your use of the Site will not violate any applicable law or
            regulation.
          </p>
          <br />
          <p dir="ltr">
            If you provide any information that is untrue, inaccurate, not
            current, or incomplete, or otherwise breach any of the foregoing
            representations, we have the right to suspend or terminate your
            account and refuse any and all current or future use of the Site (or
            any portion thereof), among other rights and remedies available at
            law and equity. This Agreement is effective when accepted by you,
            and will remain in effect until you or Dreamschools closes your
            account.
          </p>
          <br />
          <br />
          <strong dir="ltr">COVID-19 Measures</strong>
          <p dir="ltr">
            Dreamschools is not responsible for any health risks or prevention
            strategies. We may provide strategies, plans, and recommendations,
            but ultimately it is up to the Providers and Users to implement and
            follow COVID-19 procedures. We are not responsible for any illness
            of 3rd parties, including the failure to comply with COVID-19
            procedures and prevention strategies.
          </p>
          <br />
          <strong dir="ltr">Restriction On Access</strong>
          <p dir="ltr">
            Dreamschools reserves the right to partially or fully restrict your
            access to the Dreamschools Services and Site where any of the
            following occur:
          </p>
          <ul>
            <li dir="ltr">
              <p dir="ltr">
                You maintain a history of many cancelled Listings, no shows,
                refunds, chargebacks, or other transactions that negatively
                affect our Site and Providers;
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                We reasonably suspect fraud or money laundering by you or
                someone using your Dreamschools Account;
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                We reasonably suspect your Dreamschools Account’s security has
                been compromised;
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                We reasonably believe, in our own discretion, that there are
                health &amp; safety concerns involved;
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                You behave inappropriately, abusively, or offensively towards
                the employees of Dreamschools or Users; whether on the phone,
                through email, or any means of communication within the
                Dreamschools Site, or in person at the business place of said
                Provider;
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                You breach the terms set in these Terms and Conditions;
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                We reasonably believe, in our own discretion, that your actions
                are negatively affecting the quality of the Dreamschools
                Services.
              </p>
            </li>
          </ul>
          <br />
          <strong dir="ltr">Fees and Payment To Childcare Providers</strong>
          <p dir="ltr">
            One of the services offered by Dreamschools is the tuition, deposit,
            and fee collection program described here (the “Tuition Program”).
            The Tuition Program provides the ability for Providers to establish
            tuition, deposits, fees, and other miscellaneous payments (“Tuition
            Fees”) and enable Users to make Tuition Fee payments.
          </p>
          <br />
          <p dir="ltr">
            Your use of the Tuition Program in any way means that you agree to
            all of these Terms, and these will remain in effect while you use
            the Tuition Program. These terms include the provisions in this
            document, as well as those in the Privacy Policy. By creating your
            User account and using the Tuition Program, you expressly
            acknowledge that you understand this Agreement and accept all of its
            terms. If you do not agree to be bound by the terms and conditions
            of this Agreement, you may not use or access the Tuition Program.
          </p>
          <br />
          <p dir="ltr">
            Users are solely responsible for determining whether to enroll their
            dependents in a program and whether to make payments to a Provider
            through the Tuition Program. Similarly, Providers are solely
            responsible for determining whether to elicit or accept payments
            from a User through the Tuition Program. Each childcare and
            educational service transaction between a User and a Provider shall
            constitute a separate agreement between such persons.
          </p>
          <br />
          <p dir="ltr">
            As a User you agree that any amounts charged by a Provider (each, a
            “Charge”) are established by the Provider in its sole discretion
            and, in some cases, may be mandatory. Charges include Tuition Fees
            and other applicable processing fees, surcharges, and taxes. You are
            responsible for reviewing the Provider’s tuition and fee policies
            and shall be responsible for all Charges incurred under your User
            account regardless of your awareness of such Charges or the amounts
            thereof. If your childcare and educational service is subject to
            recurring charges, then you consent to our charging your payment
            method on a recurring basis without requiring your prior approval
            for each recurring Charge, until you notify us of your cancellation
            through the Dreamschools platform. A User’s non-termination or
            continued use of an Enrollment reaffirms that we are authorized to
            charge the payment method. Where required by law, Dreamschools will
            collect applicable taxes. As between you and Dreamschools, all
            Charges are non-refundable. This no-refund policy shall apply at all
            times regardless of your decision to terminate usage of the Tuition
            Program, and any claim for a refund of any Charges is solely between
            you and the applicable Provider. Dreamschools may process refunds on
            behalf of Providers, but only if such refunds are initiated and
            approved by the applicable Provider.
          </p>
          <br />
          <p dir="ltr">
            Each time you initiate a new payment for any Charges, Dreamschools
            may seek authorization of your selected payment method to verify
            your payment method, ensure the entire amount of the Charge will be
            covered, and protect against unauthorized behavior. The
            authorization is not a Charge, however, it may reduce your available
            credit by the authorization amount until your bank’s next processing
            cycle. Should the amount of our authorization exceed the total funds
            on deposit in your account, you may be subject to overdraft or other
            charges by the bank issuing your debit or check card. We cannot be
            held responsible for these charges and are unable to assist you in
            recovering them from your issuing bank.
          </p>
          <br />
          <p dir="ltr">
            As a Provider, you will receive applicable Charges (net of
            Dreamschools Service Fee, as discussed below) paid by Users through
            the Tuition Program. Dreamschools will process all payments due to
            you through its third-party payments processor. You acknowledge and
            agree that such amounts shall not include any interest and will be
            net of any amounts that we are required to withhold by law.
            Dreamschools reserves the right to withhold all or a portion of the
            Charges if it believes that you have attempted to defraud or abuse
            Dreamschools (including by taking any action in breach of
            Dreamschools Terms) or Dreamschools payment systems.
          </p>
          <br />
          <p dir="ltr">
            In exchange for permitting you to offer the childcare and
            educational services through the Tuition Program as a Provider, you
            agree to pay to Dreamschools (and permit Dreamschools to retain such
            amount from the Tuition Fees paid by Users) a fee in the amount set
            forth on the Dreamschools website (the “Service Fee”). Service Fees
            are not refundable. In addition, Dreamschools will not refund
            Charges to Users unless such refund is initiated and approved by the
            applicable Provider.
          </p>
          <br />
          <p dir="ltr">
            You agree to provide current, complete, and accurate purchase and
            account information for all payment methods made via the Site. You
            further agree to promptly update account and payment information,
            including email address, payment method, and payment card expiration
            date, so that we can complete your transactions and contact you as
            needed. We may change prices at any time. We reserve the right to
            correct any errors or mistakes in pricing, even if we have already
            requested or received payment. We also reserve the right to refuse
            any order placed through the Site. All payments shall be in CAD
            dollars.
          </p>
          <br />
          <p dir="ltr">
            All Charges are facilitated through a third-party payment processing
            service, (the “Payment Processor”). By registering your account and
            using the Tuition Program, you agree to the Stripe Connected Account
            Agreement. The processing of payments will be subject to the terms
            and conditions and privacy policy of the Payment Processor in
            addition to these Terms. We are not responsible for any error by the
            Payment Processor. You agree to pay, through our Payment Processor,
            the Tuition Fees and Service Fees in accordance with the applicable
            payment terms, and you authorize us, through the Payment Processor,
            to charge your chosen payment provider (a credit or debit card)
            (your “Payment Method”). You agree to make payment using the
            selected Payment Method. We reserve the right to correct any errors
            or mistakes that the Payment Processor makes even if it has already
            requested or received payment. The terms of your payment will be
            based on your Payment Method and may be determined by agreements
            between you and the Financial Institute, credit card issuer, or
            other provider of your chosen Payment Method. We may replace our
            third-party payment processing services with or without notice to
            you.
          </p>
          <br />
          <p dir="ltr">
            In certain circumstances, at our discretion, Dreamschools may offer
            “Credit” to Users when deemed acceptable and/or necessary. Any
            Credits that are provided or outstanding to Users may expire after 3
            months, if not used, regardless of extenuating circumstances.
          </p>
          <br />
          <strong dir="ltr">Providers Are Not Our Employees</strong>
          <p dir="ltr">
            Our Site and Services offer the tools and resources on our platform
            to connect Users and Providers who wish to obtain or provide, as
            applicable, educational services or children programs. All Providers
            available through our Site and Services are independent parties
            (e.g., Independent Contractors), and are not employees, agents, or
            joint ventures of Dreamschols.
          </p>
          <br />
          <p dir="ltr">
            For select Providers, Dreamschools may enact a multi-step approach
            to screen and verify each application received from individuals who
            wish to List as a home program on the Dreamschools Site. Despite our
            best efforts to screen or perform background checks on select
            Providers, as a User you should exercise caution and perform your
            own screening before connecting or engaging with any Provider
            through our Site and Services or obtaining any services.
          </p>
          <br />
          <p dir="ltr">
            Although we hope Providers only offer the highest quality activities
            or programs, we are not responsible for the quality, substance, or
            performance of any of the Listings on the Platform. You understand
            that there are certain inherent risks and dangers in attending or
            participating in these Listings. You acknowledge that some of these
            risks cannot be eliminated and you specifically assume the risk of
            injury or harm to you or your related party (e.g., child). In the
            cases of enrolling children, you acknowledge and agree that it is
            your responsibility to consult with your child’s primary care
            physician prior to participating in any program and to determine
            whether an enrollment or attendance is appropriate for your child.
            Dreamschools hereby expressly disclaims, and you hereby expressly
            release us from, any and all liability whatsoever for any
            controversies, claims, suits, injuries, loss, harm or damages
            arising from or related to our Site and Services or your
            interactions or dealings with other users, including any acts or
            omissions of users online or offline. We shall not be liable to you
            for your reliance on any information obtained through other users,
            the use of the Services or this Site, and Dreamschools disclaims all
            liability in connection with such information. All use of our Site
            and Services is at your sole and exclusive risk.
          </p>
          <br />
          <strong dir="ltr">Fees and Payment To Providers</strong>
          <p dir="ltr">
            One of the services offered by Dreamschools is the billing and
            payment program described here (“Payment Program”). The Payment
            Program provides the ability for Providers to establish billing,
            deposits, fees, recurring transactions, and other miscellaneous
            payments, and enable Users to make payments.
          </p>
          <br />
          <p dir="ltr">
            By booking a Listings on the Dreamschools Site, you are responsible
            for paying all applicable fees related to the program or activity.
            The fees on our site are non-refundable. In certain circumstances,
            at the Provider’s discretion in consultation with Dreamschools, a
            refund may be provided to a customer. For more information, please
            email{" "}
            <a href="mailto:support@dreamschools.com">
              support@dreamschools.com
            </a>
            . If refunded, we reserve the right to charge you a fee to cover the
            cost of processing. Any additional discounts or refunds that we may
            choose to provide are at our sole discretion and do not entitle you
            to any future discounts or refunds for similar instances in the
            future.
          </p>
          <br />
          <p dir="ltr">
            We reserve the right to adjust our prices at any time. If our prices
            are to change, you will be notified through the Dreamschools Site or
            email. All Charges on the Site are facilitated through a third-party
            payment processing service. All organization providers that utilize 
            our software platform are subject to a small transaction fee after 
            6-months from account creation to support us with payment processing. 
            By registering your account and using the Payment Program, you agree 
            to the Stripe Connected Account Agreement. We may replace our 
            third-party payment processing services with or without notice to you.
          </p>
          <br />
          <p dir="ltr">
            You may also register for Listings on a Providers site. These
            Providers may have their own payment system in place, we are not
            responsible or liable for payments that are processed through their
            system.
          </p>
          <br />
          <p dir="ltr">
            Some of our Providers may charge equipment or other amenity fees for
            certain Listings. You are responsible for paying these fees directly
            to the respective Provider, under their own terms, before or when
            you attend the Listing. Any equipment or amenity fees are
            independent of our fees to register for a program, activity, or
            event listed on Dreamschools.
          </p>
          <br />
          <p dir="ltr">
            In certain circumstances, at our discretion, Dreamschools may offer
            “Credit” to Users when deemed acceptable and/or necessary. Any
            Credits that are provided or outstanding to Users may expire after 3
            months, if not used, regardless of extenuating circumstances.
          </p>
          <br />
          <strong dir="ltr">Enrollment For Childcare Programs</strong>
          <p dir="ltr">
            A User may choose to attend a tour or open house, and then enroll
            his or her child(ren) in the childcare program based upon the
            information provided in the Listings. A deposit for a program may be
            required when enrolling in a Listing (“Deposits”), as highlighted in
            the Provider’s “Enrollment Package”. If a User increases Enrollment
            in the program, then they will be responsible for paying any
            additional amounts such that his or her Deposit is equal to that
            specified in the Enrollment Package (and the User will be entitled
            to a refund of the additional amount of Deposit if he or she reduces
            Enrollment in the program). All changes in Enrollment shall be
            subject to the Listing’s applicable cancellation policy.
          </p>
          <br />
          <p dir="ltr">
            When a User requests an Enrollment via the Services, the applicable
            Provider will be required to either confirm or reject the Enrollment
            request within the Enrollment Request Period or the Enrollment
            request will automatically expire. When an Enrollment is requested
            via the Services, we will share with the applicable Provider (i) the
            first and last name of the User requesting the Enrollment, (ii) a
            link to the User’s Dreamschools Account profile page and (iii) an
            indication of whether or not the User has provided other information
            to Dreamschools, such as a verified email address, or a government
            ID. If the Provider is unable to confirm or decides to reject an
            Enrollment request within the Enrollment Request Period, any amounts
            collected by Dreamschools for the requested Enrollment will be
            refunded to the applicable Parent. The Tuition Fee payable will be
            displayed to a User before they accept an Enrollment Invitation from
            a Provider and/or before a User sends an Enrollment request to a
            Provider.
          </p>
          <br />
          <p dir="ltr">
            The applicable Provider, not Dreamschools, is solely responsible for
            ensuring any program-specific handbook is acknowledged by the
            family, and for honoring any confirmed Enrollment(s) and making
            available any childcare and educational service(s) reserved through
            the Services. The User agrees to personally inspect the premises of
            the Childcare and Educational Service via a tour or open house prior
            to the User’s child(ren)’s attendance at any program and
            acknowledges that Dreamschools is not responsible for the nature
            and/or condition of such a program.
          </p>
          <br />
          <p dir="ltr">
            Automatic Renewals for a User’s Enrollments. Unless a User opts out
            of auto-renewal, which can be done through such User’s account
            console, any Enrollments for programs with a recurring billing cycle
            for which the User has signed-up will be automatically extended for
            successive renewal periods of the same duration as the term
            originally selected, at the then-current non-promotional rate for
            such programs as specified in the applicable Listing. To change or
            terminate an Enrollment at any time, a User may go to account
            settings or by contacting the applicable Provider via the Services.
            If a User terminates an Enrollment, the User may use that Enrollment
            until the end of the then-current term and the Enrollment will not
            be renewed after the then-current term expires. However, the User
            will not be eligible for a prorated refund of any portion of the
            program Fee paid for the then-current Enrollment period. If a User
            does not want to continue to be charged on a recurring basis, the
            User must cancel the applicable Enrollment through his or her
            account settings or terminate the User’s Dreamschools account by
            contacting support@dreamschools.com or by contacting the applicable
            Provider via the Services before the end of the recurring term.
            Enrollments cannot be terminated before the end of the period for
            which the User has already paid, and except as expressly provided in
            these terms, Dreamschools will not refund any fees that a User has
            already paid.
          </p>
          <br />
          <strong dir="ltr">
            Registration For Users in Providers Listings
          </strong>
          <p dir="ltr">
            To view or sign-up for a Listings through Dreamschools, you may need
            to create an account. Dreamschools powers Providers registration,
            booking, scheduling, listings, check-out, and payments systems. To
            book a class, you will be adding your account information to the
            Provider’s contacts as well as to Dreamschools. To register on a
            provider’s site who we partner with, you will be adding your name,
            email, child’s name, birthdate, address, primary phone, credit card
            as well as other information requested by the Provider. This
            information is stored in the Dreamschools database. If you use the
            same account to buy class at another provider within the
            Dreamschools ecosystem, all information may be accessible with the
            other provider (except your credit card).
          </p>
          <br />
          <strong dir="ltr">Cancellation Regarding Childcare</strong>
          <p dir="ltr">
            If a User wishes to cancel a confirmed Enrollment made via the
            Services, either prior to or after the User’s child(ren) have begun
            the childcare and educational service, the cancellation policy of
            the Provider as described in the applicable Listing or Enrollment
            Package will apply to such cancellation. The cancellation policy as
            described in the applicable Listing or Enrollment Package will
            specify when the Deposit is forfeit due to a cancellation of a
            confirmed Enrollment. Our ability to refund the Tuition Fee and
            other amounts charged to the User will depend upon the terms of the
            applicable cancellation policy. The Administrative Fee is
            non-refundable. Dreamschools will initiate any refunds due pursuant
            to these Terms.
          </p>
          <br />
          <p dir="ltr">
            If a User cancels a requested Enrollment before the requested
            Enrollment is confirmed by the Provider, Dreamschools will cancel
            any pre-authorization to the User’s Payment Method and/or refund any
            nominal amounts charged to such User’s Payment Method in connection
            with the requested Enrollment within a commercially reasonable time.
          </p>
          <br />
          <p dir="ltr">
            If a User cancels a confirmed Enrollment or Dreamschools decides
            that it is necessary to cancel a confirmed Enrollment, and
            Dreamschools issues a refund to the User in accordance with these
            Terms or other applicable cancellation policies, the Provider agrees
            that in the event the Provider has already been paid, Dreamschools
            will be entitled to recover the amount of any such refund from such
            Provider, including by subtracting such refund amount out from any
            future Provider Disbursements (defined below) due to such Provider.
          </p>
          <br />
          <p dir="ltr">
            If a Provider cancels a confirmed Enrollment made via the Services,
            Dreamschools will refund the Tuition Fees for such Enrollment to the
            applicable User pursuant to these Terms minus any service fees. If
            the User requests an Enrollment from an alternative Listings and the
            Provider associated with such alternative Listing confirms the
            User’s requested Enrollment, then the User agrees to pay
            Dreamschools the Tuition Fees relating to the confirmed Enrollment
            for the Childcare and educational service in the alternative
            Listing, in accordance with these Terms. If a Provider cancelled a
            confirmed Enrollment and you, as a User, have not received a message
            from Dreamschools, please contact support@dreamschools.com.
          </p>
          <br />
          <p dir="ltr">
            A Provider may not cancel a confirmed Enrollment, however, they
            might waitlist and/or delay an enrollment. Providers understand and
            agree that if they cancel a confirmed Enrollment, Dreamschools may
            apply penalties or consequences to such Providers or such Provider’s
            Listing, including (i) publishing an automated review on such
            Provider’s Listing indicating that an Enrollment was cancelled, (ii)
            keeping the availability for such Provider’s Listing unavailable or
            blocked for the dates of the cancelled Enrollment, or (iii) imposing
            a cancellation fee. Providers will be notified of the situations in
            which a cancellation fee applies before deciding to cancel.
          </p>
          <br />
          <strong dir="ltr">Cancellation Regarding Listings</strong>
          <p dir="ltr">
            We do not recommend registering for a Listing or Listings, unless
            you are sure that you can attend. Each Listing is subject to its own
            cancellation policies of the respective Provider. Specific
            cancellation policies can be found under each respective Listing’s
            details. We recommend reviewing the cancellation policy of each
            Listing and Provider before registering for a Listing. Any and all
            cancellations must be made in the manner laid out in the Listing
            description. If you do not cancel a Listing as required by its
            respective cancellation policy, and the class is not attended, we
            reserve the right to charge you the full amount that the Provider
            charges for such class and/or the applicable cancellation fees. We
            also reserve the right to terminate your account.
          </p>
          <br />
          <strong dir="ltr">Service Terms for Providers and Termination</strong>
          <p dir="ltr">
            Service Term for Providers. The initial term of this Agreement
            between a Provider and Dreamschools shall be two (2) year(s) (the
            “Initial Term”). The beginning of the service term is from the date
            the Provider and their program is live on the site and/or from the
            date of their first enrollment, whichever date falls later.
            Following the Initial Term, this Agreement will automatically renew
            for successive renewal terms of two (2) years (each, a “Renewal
            Term”, and together with the Initial Term, the “Service Term”)
            unless you give Dreamschools written notice of non-renewal at least
            sixty (60) days prior to the end of the then-current term. If you
            cancel or otherwise suspend your use of the Services at any time
            prior to the end of the then-current term (“Early Termination”) you
            agree to pay a fee (“Early Termination Fee”). Dreamschools may
            terminate this Agreement freely at any time as otherwise set forth
            herein.
          </p>
          <br />
          <p dir="ltr">
            Before a Provider has confirmed any Enrollments for any Listings,
            the Provider may terminate the Provider’s Dreamschools Account,
            subject to the Early Termination Fee. After the Provider has
            confirmed one or more Enrollments in any Listings, the Provider may
            terminate the Provider's Dreamschools Account, subject to the Early
            Termination Fee, the applicable Deposits shall be returned to the
            applicable User and the Provider will not be able to create another
            Dreamschools Account without contacting support@dreamschools.com. In
            addition, the Provider shall be subject to any applicable
            Post-Termination Provider Fees.
          </p>
          <br />
          <p dir="ltr">
            Post-Termination Provider Fees. For one (1) year (or as otherwise
            agreed between the Provider and Dreamschools in writing) following
            the termination or expiration of this Agreement for any reason, each
            Provider must continue to pay the Administrative Fees for any
            attendee of any of such Provider's programs originally enrolled via
            the Services (“Post-Termination Provider Fees”). Notwithstanding any
            such termination, Dreamschools has the surviving right to inspect
            each Provider's books and records, and to reasonably attend the
            premises of the Provider at any time to ensure that such Provider is
            paying Dreamschools Post-Termination Provider Fees in full.
          </p>
          <br />
          <strong dir="ltr">Non-Disparagement</strong>
          <p dir="ltr">
            As Providers, you hereby covenant and agree that you will not engage
            in any pattern of conduct that involves making or publishing written
            or oral statements or remarks (including without limitation, the
            repetition or distribution of derogatory rumors, allegations,
            negative reports or comments), which are disparaging, deleterious or
            damaging to the integrity, reputation or goodwill of Company and/or
            its team members.
          </p>
          <br />
          <strong dir="ltr">Termination of Registration</strong>
          <p dir="ltr">
            Dreamschools reserves the right, in its sole discretion, to
            terminate your account or access to part or all of Dreamschools’
            Site and/or Services in the event that Dreamschools determines you
            are not eligible to use the Services or have violated any of the
            provisions of the Terms of Use, or have misused the Site or Services
            in any way.
          </p>
          <br />
          <strong dir="ltr">Promotions</strong>
          <p dir="ltr">
            Dreamschools may offer temporary promotions and discounts to Users
            and Providers that may be used within a set promotional period. Any
            widespread or citywide promotional codes or discounts provided by
            Dreamschools is subject to an equal split of the resulting discount
            expense between Dreamschools and the Provider of the enrollment
            pricing plan that it is being applied to.
          </p>
          <br />
          <p dir="ltr">
            Examples of promotional codes that would have a shared expense of
            the discount amount are any that may be deemed a special occasion
            including but not limited to marketing and growth strategies and
            milestones for the Dreamschools platform.
          </p>
          <br />
          <p dir="ltr">
            Other promotional codes may be distributed or given out in smaller
            distribution at an amount agreed upon by Dreamschools and a Provider
            for their own purposes and growth.
          </p>
          <br />
          <p dir="ltr">
            Appointment of Dreamschools as limited payment collection agent for
            Provider(s)
          </p>
          <p dir="ltr">
            Each Provider hereby appoints Dreamschools as the Provider's limited
            payment collection agent solely for the purpose of accepting the
            Tuition Fees from Users. Each Provider agrees that payment made by a
            User through Dreamschools, shall be considered the same as a payment
            made directly to the Provider, and the Provider will make the
            childcare and educational service available to the User in the
            agreed-upon manner as if the Provider has received the Tuition Fees
            (net of Dreamschools’ Administrative Fee) for the applicable
            Enrollment. Each Provider agrees that Dreamschools may, in
            accordance with the cancellation policy selected by the Provider and
            reflected in the relevant Listing, refund to the User that portion
            of the Tuition Fees specified in the applicable cancellation policy.
            Each Provider understands that Dreamschools accepts payments from
            Users as the Provider's limited payment collection agent and that
            Dreamschools’ obligation to pay the Provider is subject to and
            conditional upon successful receipt of the associated payments
            fromUsers. Dreamschools does not guarantee payments to Providers for
            amounts that have not been successfully received by Dreamschools
            fromUsers. In accepting appointment as the limited collection agent
            of the Provider, Dreamschools assumes no liability for any acts or
            omissions of the Provider.
          </p>
          <br />
          <strong dir="ltr">Submissions</strong>
          <p dir="ltr">
            You acknowledge and agree that any questions, comments, suggestions,
            ideas, feedback, or other information regarding the Site
            ("Submissions") provided by you to us are non-confidential and shall
            become our sole property. We shall own exclusive rights, including
            all intellectual property rights, and shall be entitled to the
            unrestricted use and dissemination of these Submissions for any
            lawful purpose, commercial or otherwise, without acknowledgment or
            compensation to you. We are free to use any ideas, concepts,
            techniques, or know-how contained in Submissions for any purpose,
            including, and not limited to developing, manufacturing, and
            marketing products/services using such information and ideas,
            without compensation or obligations to anyone, including you. You
            hereby waive all moral rights to any such Submissions, and you
            hereby warrant that any such Submissions are original with you or
            that you have the right to submit such Submissions, and that
            Submissions do not infringe on any third party’s rights. You agree
            there shall be no recourse against us for any alleged or actual
            infringement or misappropriation of any proprietary right in your
            Submissions.
          </p>
          <br />
          <br />
          <strong dir="ltr">Third Party Sites</strong>
          <p dir="ltr">
            We may link sites that are owned and operated by third parties. Any
            disputes arising between you and the third party whose website you
            accessed via the Site are between you and the respective third
            party. You agree that Dreamschools is under no obligation to get
            involved in any dispute. You agree to hold harmless, defend, and
            indemnify Dreamschools from any liability or costs that may arise
            from a dispute between you and the third party, including attorney
            fees and court costs. In the case of a dispute with Users or a third
            party, you agree to release Dreamschools, its officers, employees,
            stakeholders, and successors from claims, demands, and damages or
            any kind, whether known and unknown, suspected or unsuspected,
            undisclosed or disclosed that arise or are related to such disputes
            or Services.
          </p>
          <p dir="ltr">
            You are responsible for all third party charges and fees associated
            with connecting and using the Site, including fees such as Internet
            service provider fees, telephone and computer equipment charges,
            sales tax, and any other fees related to accessing the Dreamschools
            Site.
          </p>
          <br />
          <strong dir="ltr">Gift Cards</strong>
          <p dir="ltr">
            The following terms apply to your purchase, receipt, or use of a
            Provider e-gift card (“Provider eGift Cards”). The balance on any
            Provider eGift Card is solely the liability of the Issuer, Provider,
            of the giftcard. Provider eGift Cards have no expiration date, no
            fees, are not redeemable for cash, are not reloadable and are not a
            debit, credit or reloadable prepaid card. Provider eGift Cards can
            only be redeemed for us one the Providers site where purchased.
            Promotional offers and discounts may not be applied towards the
            purchase of Provider eGift cards. Balance of Provider eGift Cards
            can be checked by logging into your Dreamschools account and viewing
            card balance. Cancellations and returns are not permitted after the
            purchase of a Provider eGift Card. Provider eGift Cards are
            non-refundable, may not be transferred once redeemed, and the resale
            of Provider eGift Cards is forbidden. The risk of loss and title for
            Provider eGift Cards passes to you upon our or our authorized third
            party’s electronic transmission of the Provider eGift Cards to you
            or a designated recipient, if applicable. Dreamschools is not
            responsible or liable if any Provider eGift Cards are lost, stolen,
            destroyed or used without your permission. When applicable, Provider
            eGift Cards are incorporated and may be subject to the Provider
            Terms of Service and Provider Privacy Policy.
          </p>
          <br />
          <p dir="ltr">
            All Provider eGift Cards are delivered via email. Neither
            Dreamschools or others are responsible for Provider’s eGift Cards
            that are undeliverable or not received due to an error to submit the
            correct email address at the time of purchase for the recipient. It
            is your responsibility to check that you have inputted the correct
            email address for the recipient. Please contact the recipient if you
            suspect they did not receive their Provider eGift Card. If an email
            bounces back due to an invalid email address, we will attempt to
            contact you for a new email address.
          </p>
          <br />
          <p dir="ltr">
            If you have confirmed the recipient’s email address but the Provider
            eGift Card has not been found; have the recipient check the spam
            folder, remove a firewall that may have blocked the email, confirm
            the inbox size is enough to receive emails, and verify the validity
            of the email address.
          </p>
          <br />
          <strong dir="ltr">The Content on Our Site</strong>
          <p dir="ltr">
            All content provided or otherwise made available by Dreamschools
            through the Services and the Site, including but not limited to
            text, graphics, images, music, software, audio, video, concepts,
            methods of operation, works of authorship of any kind, and
            information or other materials appearing on or emanating to and/or
            from the Services, as well as their overall design and appearance
            (the “Content”) is owned, controlled or licensed by or to
            Dreamschools and is protected by intellectual property rights and
            unfair competition laws within and outside of Canada. Content also
            includes any Content that users (including you) provide or make
            available through our Services and Site (“User Content”).
          </p>
          <br />
          <p dir="ltr">
            Subject to your compliance with these Terms, Dreamschools hereby
            grants you a limited, non-exclusive, non-transferable,
            non-sublicensable license to download, view, copy and display the
            Content solely in connection with your permitted use of our Services
            and Site and solely for your personal and non-commercial purposes.
          </p>
          <br />
          <p dir="ltr">
            By making User Content available through our Services and Site, you
            hereby grant Dreamschools a non-exclusive, worldwide, perpetual,
            irrevocable, fully-paid, royalty-free, sub-licensable and
            transferable license to use, copy, reproduce, modify, adapt,
            translate, create derivative works based upon, distribute, publicly
            display, perform, distribute and communicate by means of
            telecommunication your User Content in connection with operating and
            providing our Services, Site, and Content to you and other users.
          </p>
          <br />
          <p dir="ltr">
            You can remove your User Content by specifically deleting it.
            However, in certain instances, some of your User Content may not be
            completely removed and copies of your User Content may continue to
            exist on the Services or the Site. We are not responsible or liable
            for the removal or deletion of (or the failure to remove or delete)
            any of your User Content.
          </p>
          <br />
          <br />
          <strong dir="ltr">Trademarks</strong>
          <p dir="ltr">
            All trademarks, service marks, trade names, logos and graphics
            (“Marks”) used in our Services or on the Site are registered or
            unregistered trademarks of Dreamschools or our licensors in Canada
            and internationally. Nothing in these Terms should be construed and
            granting any license to use any Marks without the express permission
            of Dreamschools or the applicable trademark owner. Unauthorized use
            of any Marks are prohibited, and may be a violation of federal and
            international trademark laws. You acknowledge that you do not
            acquire any ownership rights by using our Site and Services.
          </p>
          <br />
          <strong dir="ltr">Limitation of Liability</strong>
          <p dir="ltr">
            IN NO EVENT SHALL DREAMSCHOOLS, NOR ANY OTHER PARTY INVOLVED IN
            CREATING, PRODUCING, OR DELIVERING THE SITE, THE SERVICES OR CONTENT
            BE LIABLE: (I) TO YOU FOR ANY PUNITIVE, SPECIAL, INCIDENTAL,
            CONSEQUENTIAL OR OTHER INDIRECT DAMAGES (INCLUDING, BUT NOT LIMITED
            TO, DAMAGES FOR LOSS OF DATA OR GOODWILL, LOSS OF PROGRAMS, LOST
            PROFITS, COST OF PROCUREMENT OF SUBSTITUTE SERVICES OR COST OF
            SERVICE INTERRUPTIONS) ARISING OUT OF OR RELATED TO THE USE OF OR
            INABILITY TO USE THE SERVICES, THIS SITE, THE SERVICES, MOBILE
            APPLICATION SOFTWARE (IF ANY) AND ANY AND ALL EQUIPMENT OR DEVICES
            RECEIVED BY YOU AS PART OF YOUR USE OF IN THE SERVICES, EVEN IF
            DREAMSCHOOLS OR ITS AGENTS OR REPRESENTATIVES KNOW OR HAVE BEEN
            ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR (II) TO ANY PERSON
            OTHER THAN YOU FOR ANY DAMAGES WHATSOEVER. YOUR USE OF THE SITE,
            SERVICES, AND CONTENT MEANS THAT YOU AGREE THAT YOU ARE USING SUCH
            AT YOUR OWN RISK AND LIABILITY.
          </p>
          <br />
          <p dir="ltr">
            IN ADDITION, DREAMSCHOOLS DISCLAIMS ALL LIABILITY, REGARDLESS OF THE
            FORM OF ACTION, FOR THE ACTS OR OMISSIONS OF OTHER USERS OR
            UNAUTHORIZED USERS (E.G., “HACKERS”) OF THE SERVICES. EXCEPT WHERE
            DUE TO DREAMSCHOOLS’ GROSS NEGLIGENCE OR WILFUL MISCONDUCT.
          </p>
          <br />
          <p dir="ltr">
            IN NO EVENT SHALL DREAMSCHOOLS BE LIABLE TO YOU OR ANYONE ELSE FOR
            ANY AMOUNT IN EXCESS OF THE AMOUNTS PAID BY YOU (OR BY A THIRD PARTY
            ON YOUR BEHALF, AND ONLY TO THE EXTENT SUCH AMOUNT IS DIRECTLY
            ATTRIBUTABLE TO YOU AND NOT ANOTHER USER) TO DREAMSCHOOLS FOR ACCESS
            TO THE SERVICES AND THIS SITE.
          </p>
          <br />
          <p dir="ltr">
            THE PRECEDING PARAGRAPHS APPLY TO YOU TO THE FULLEST EXTENT
            PERMITTED BY LAW OR EQUITY, IN ALL ACTIONS OF ANY KIND WHATSOEVER,
            WHETHER BASED IN CONTRACT, TORT (INCLUDING, WITHOUT LIMITATION,
            NEGLIGENCE) OR ANY OTHER LEGAL OR EQUITABLE THEORY. ANY PART OF
            THESE TERMS OF USE DECLARED INVALID BY A COURT OF COMPETENT
            JURISDICTION SHALL BE DEEMED SEVERABLE AND SHALL NOT AFFECT THE
            VALIDITY OR ENFORCEABILITY OF THE REMAINDER OF THESE TERMS OF USE.
          </p>
          <br />
          <strong dir="ltr">Governing Law</strong>
          <p dir="ltr">
            These Terms and your use of our Website and Services shall be
            governed by the laws of the Province of Ontario and the federal laws
            of Canada applicable therein. Any dispute arising under these Terms
            shall be resolved exclusively by the courts located in the City of
            Toronto in the Province of Ontario.
          </p>
          <br />
          <p dir="ltr">
            Any cause of action or claim you may have with respect to these
            Terms or our services must be commenced within one (1) year after
            the claim or cause of action arises or such claim or cause of action
            shall be barred.
          </p>
          <br />
          <p dir="ltr">
            We reserve the right to seek all remedies available at law and in
            equity for violations of these Terms, including without limitation
            the right to block access from a particular internet address.
          </p>
          <br />
          <p dir="ltr">
            If any provision of these Terms is deemed unlawful by a court of
            law, then the impugned provision shall be deemed severed and shall
            not affect the validity and enforceability of any remaining
            provisions.
          </p>
          <br />
          <p dir="ltr">
            If you are a Provider providing child care services in Ontario, it
            is your sole responsibility to be in compliance with the governing
            rules dictated in the Child Care and Early Years Act, 2014 (CCEYA)
            and Ontario Regulation 137/15, as amended from time to time.
          </p>
          <br />
          <strong dir="ltr">Arbitration and Waiver of Class Action</strong>
          <p dir="ltr">
            You agree to arbitrate any dispute with us arising from this
            Agreement or your use of Dreamschools on an individual basis in an
            arbitration. That means you cannot sue us in court or have a trial
            by jury. Any disputes will be resolved individually in a private
            proceeding. Whether the dispute is heard in arbitration or in court,
            the parties will not commence against the other A Class Action,
            Class Arbitration, or Representative Action or Proceeding.
          </p>
          <br />
          <strong dir="ltr">Acceptable Use Policy</strong>
          <p dir="ltr">
            Dreamschools values safety and trust in providing users with our
            Site and Services, and we aim to foster a positive and welcoming
            environment for all visitors. In order to maintain our community, we
            have established the following rules of use. You agree you will not:
          </p>
          <p dir="ltr">
            • Permit any other person (other than an agent acting solely on your
            behalf and subject to your direct supervision) to access the
            Services or the Site using your account for any purpose;
          </p>
          <p dir="ltr">
            • Attempt to access another user’s account, including, without
            limitation, attempts to use another person’s credentials to create
            an account for our Services or the Site, unless you are acting
            solely on behalf on another person pursuant to a written agency
            agreement detailing this permission;
          </p>
          <p dir="ltr">
            • Create multiple accounts under different names or emails unless we
            have authorized you to do so, or re-register an account that was
            previously terminated by Dreamschools;
          </p>
          <p dir="ltr">
            • Post, upload, publish, submit or transmit any Content through our
            Services or the Site that: (i) infringes, misappropriates or
            violates a third party’s patent, copyright, trademark, trade secret,
            moral rights or other intellectual property rights, or rights of
            publicity or privacy; (ii) violates, or encourages any conduct that
            would violate, any applicable law or regulation or would give rise
            to civil liability; (iii) is fraudulent, false, misleading or
            deceptive; (iv) is defamatory, obscene, pornographic, vulgar or
            offensive; (v) promotes discrimination, bigotry, racism, hatred,
            harassment or harm against any individual or group; (vi) is violent
            or threatening or promotes violence or actions that are threatening
            to any person or entity; or (vii) promotes illegal or harmful
            activities or substances;
          </p>
          <p dir="ltr">
            • Post, upload, publish, submit, transmit, or communicate, in any
            manner or forum, content directly to and/or about Dreamschools that:
            (i) is defamatory, obscene, pornographic, vulgar or offensive; (ii)
            promotes discrimination, bigotry, racism, hatred, harassment or
            harm; (iii) is violent or threatening or promotes violence or
            actions that are threatening or perceived to be threatening.
          </p>
          <p dir="ltr">
            • Use, display, mirror or frame the Site or Services, or any
            individual element within the Site or Services, Dreamschools’ name,
            any of our Marks, logos or other proprietary information, or the
            layout and design of any page or form contained on a page on our
            Site or in the Services, without our express written consent;
          </p>
          <p dir="ltr">
            • Access, tamper with, or use non-public areas of the Services and
            the Site, Dreamschools’ computer systems, or the technical delivery
            systems of our service providers;
          </p>
          <p dir="ltr">
            • Attempt to probe, scan, or test the vulnerability of any
            Dreamschools system or network or breach any security or
            authentication measures;
          </p>
          <p dir="ltr">
            • Avoid, bypass, remove, deactivate, impair, descramble or otherwise
            circumvent any technological measure implemented by us or our
            service providers or any other third party (including another user)
            to protect the Services, Site, or Content;
          </p>
          <p dir="ltr">
            • Interfere with, or attempt to interfere with, the access of any
            user, host or network, including, without limitation, intentionally
            posting or transmitting to or through the Services or the Site any
            file that contains a virus, bug, worm, Trojan horse, disabling
            device or any other contaminating or destructive feature;
          </p>
          <p dir="ltr">
            • Attempt to access or search the Services, Site or Content or
            download Content from the Services or Site through the use of any
            engine, software, tool, agent, device or mechanism (including
            spiders, robots, crawlers, data mining tools or the like) other than
            the software and/or search agents provided by Dreamschools or other
            generally available third party web browsers;
          </p>
          <p dir="ltr">
            • Send any unsolicited or unauthorized advertising, promotional
            materials, email, junk mail, spam, chain letters or other form of
            solicitation through our Site or Services;
          </p>
          <p dir="ltr">
            • Use the Services, Site or Content for any commercial purpose or
            for the benefit of any third party or in any manner not permitted by
            these Terms;
          </p>
          <p dir="ltr">
            • Forge any TCP/IP packet header or any part of the header
            information in any email or newsgroup posting, or in any way use the
            Services, Site, or Content to send altered, deceptive or false
            source-identifying information;
          </p>
          <p dir="ltr">
            • Use any meta tags or other hidden text or metadata utilizing our
            Marks, logo URL or product name without Dreamschools’ express
            written consent;
          </p>
          <p dir="ltr">
            • Collect or store any personally identifiable information from the
            Services or the Site from other users without their express
            permission;
          </p>
          <p dir="ltr">
            • Impersonate or misrepresent your affiliation with any person or
            entity;
          </p>
          <p dir="ltr">• Violate any applicable law or regulation; or</p>
          <p dir="ltr">
            • Encourage or enable any other individual to do any of the
            foregoing.
          </p>
          <br />
          <p dir="ltr">
            The sender of any communication in or through our Services or the
            Site shall be solely responsible for the content and information
            contained therein, including, without limitation, its truthfulness,
            accuracy and completeness. Although Dreamschools reserves the rights
            to monitor, record, edit or remove any content within our Services
            or the Site, we have no obligation or responsibility to do so. In
            addition, Dreamschools are not liable or responsible to any User or
            Provider or any other user, person or entity for the performance or
            non-performance of the monitoring, recording, editing or removal
            activities mentioned above.
          </p>
          <br />
          <strong dir="ltr">Interpretation</strong>
          <p dir="ltr">
            Where applicable, words in this Terms and Conditions that are
            defined in the singular shall retain the same definition in the
            plural, and vice versa. Words in the masculine include the feminine
            and vice versa. No regard for gender is intended by the language in
            these Terms and Conditions.
          </p>
          <br />
          <strong dir="ltr">Contact Us</strong>
          <p dir="ltr">
            If you have any questions about the Terms of Use, or need to provide
            notice to, or communicate with Dreamschools under the Term of Use,
            please contact Dreamschools by clicking Contact Us, by email at
            support@dreamschools.com, or by delivery in person, by courier or by
            the mail, to Company at:
          </p>
          <p dir="ltr">Centre for Social Innovation, Toronto, Ontario</p>
          <br />
          <p dir="ltr">
            YOUR USE OF THE SITE AND/OR SERVICES CONSTITUTES YOUR
            ACKNOWLEDGEMENT THAT YOU HAVE READ THESE TERMS OF USE AND THE
            PRIVACY POLICY AND THESE TERMS , AND THAT YOU UNDERSTAND THE NATURE
            AND CONSEQUENCES OF SAME, AND AGREE TO BE BOUND BY ALL OF THE TERMS,
            CONDITIONS AND PROVISIONS CONTAINED THEREIN.
          </p>
          <br />
          <br />
          <br />
          <br />

          <div>
            <br />
          </div>
        </div>
      </div>
    </>
  );
};

export default Terms;
