import React from "react";
import Select from "react-dropdown-select";

const MultiInput = props => {
  return (
    // <div className="w-100">
    <Select
      options={
        props.value.findIndex(v => v.name === "N/A") > -1
          ? props.value
          : [...props.value, { name: "N/A" }]
      }
      multi={true}
      values={props.value}
      onChange={values => props.onChange(props.name, values)}
      sortBy="name"
      labelField="name"
      valueField="name"
      create={true}
      createNewLabel="Add {search}"
      placeholder="Add..."
      disabled={props.isDisabled ? true : false}
      closeOnSelect={true}
    />
    // </div>
  );
};

export default MultiInput;
