import React from "react";
import "../fieldComponent.css";
import "./ContentBlock.css";
import Title from "../Title";

const ContentBlock = props => {
  const { name, content, titleStyles, style, icon } = props;

  return (
    <div>
      {/* <p className="content-block-title field-component-title">{name}</p> */}
      <Title icon={icon} name={name} titleStyle={titleStyles}/>
      <p className="field-component-small-text" style={style}>{content}</p>
    </div>
  );
};

export default ContentBlock;
