import React from "react";
import ModalFormLabel from "./ModalFormLabel";

import "./ModalFormGroup.css";

/**
 * Display Group component - a label and corresponding text.
 *
 * @param label Display Label of the form group
 * @param name Input attribute and label htmlFor attribute.
 *
 * @param tooltip Text of tooltip (optional).
 *
 * Pass text either through a text prop or a children prop.
 * @param text Text of span.
 * @param children Text of span.
 */
const ModalDisplayGroup = ({
  label,
  name,
  tooltip,
  text,
  children,
  ...options
}) => (
  <div className="form-group modal-form-group">
    <ModalFormLabel label={label} name={name} tooltip={tooltip} />
    <span id={name} className="modal-span" {...options}>
      {text || children}
    </span>
  </div>
);

export default ModalDisplayGroup;
