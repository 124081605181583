import React, { useState, useEffect, useGlobal } from "reactn";
import PropTypes from "prop-types";

import {
  getSelectedProgramPlan,
  coupon_discount,
  applyGift
} from "./utils";

const ProgramDropinsTotal = props => {
  const [total, setTotal] = useState("Loading...");
  const [_, setPreGiftTotal] = useGlobal("preGiftTotal");
  const giftBalance = useGlobal("giftBalance")[0];

  const calculateTotal = () => {
    const plan = getSelectedProgramPlan(props.program, props.selected);
    const total_price = plan.total_price * (plan.premium ? (1 + parseFloat(plan.premium) / 100) : 1);
    const dayCount = plan.dayCount;
    const dropin_interest = parseInt(plan.dropin_interest);
    const subtotal = total_price * props.selected_days_length;
    const discount_amount = props.coupon
      ? coupon_discount(props.coupon, subtotal)
      : 0;
    const taxRate = plan.isTaxEnabled
      ? parseFloat(plan.taxInfo[0].percentage / 100)
      : 0;
    const tax_amount =
      Math.max(subtotal - discount_amount) * taxRate;

    // credit card processing fee
    const creditPercent = props.program.transaction_cost
      ? parseFloat(1.5) / 100
      : 1;
    const creditCardFee = 0;
    // const creditCardFee = 0;
    console.log("SUBTOTAL", subtotal);
    console.log("discoutn amount:", discount_amount);
    console.log("TAX RATE", taxRate);
    console.log("TAX:", tax_amount);
    let tot =
      Math.max(subtotal - discount_amount) +
      tax_amount +
      creditCardFee;
    setPreGiftTotal(tot);
    ({ amount: tot } = applyGift(
      props.program.manual_invoices,
      giftBalance,
      tot
    ));
    props.totalAmount(tot.toFixed(2));

    setTotal(tot.toFixed(2));
  };

  useEffect(() => {
    calculateTotal();
  }, [props.coupon, giftBalance]);
  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif",
          fontWeight: 600
        }}
      >
        Total
      </p>
      <span style={{ fontWeight: "bold" }}>${total}</span>
    </li>
  );
};

ProgramDropinsTotal.propTypes = {
  program: PropTypes.shape({
    plans: PropTypes.array.isRequired
  }).isRequired,
  selected: PropTypes.number.isRequired,
  selected_days_length: PropTypes.number.isRequired,
  coupon: PropTypes.object
};

export default ProgramDropinsTotal;
