import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import {
  getSelectedProgramPlan,
  promotion_discount,
  coupon_discount,
  MINIMUM_CHARGE
} from "./utils";

const TransactionCostOnline = props => {
  const [fee, setFee] = useState("Loading");

  const calculateFeeAmount = () => {
    const plan = getSelectedProgramPlan( props.program, props.selected );
    const total_price = parseFloat(plan.total_price);

    if (props.promotion) {
      const tax_percent = parseFloat(1.5) / 100;
      const discount_amount = promotion_discount(props.promotion, total_price);
      const calcFee =
        Math.max(0.5, total_price - discount_amount) * tax_percent;

      props.creditCardFeeAmount(calcFee.toFixed(2));
      setFee(calcFee.toFixed(2));
    } else {
      const tax_percent = parseFloat(1.5) / 100;
      const discount_amount = props.coupon
        ? coupon_discount(props.coupon, total_price)
        : 0;
      const calcFee =
        Math.max(0.5, total_price - discount_amount) * tax_percent;

      props.creditCardFeeAmount(calcFee.toFixed(2));
      setFee(calcFee.toFixed(2));
    }
  };

  useEffect(() => {
    calculateFeeAmount();
  }, []);

  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px",
        borderBottom: "1px solid #dedede"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif",
          fontWeight: 500,
          fontSize: "14px"
        }}
      >
        Credit Card Fee (1.5%)
      </p>
      <span style={{ fontWeight: 500, fontSize: "14px" }}>${fee}</span>
    </li>
  );
};

TransactionCostOnline.propTypes = {
  program: PropTypes.shape({
    plans: PropTypes.array.isRequired
  }).isRequired,
  selected: PropTypes.number.isRequired,
  promotion: PropTypes.object,
  coupon: PropTypes.object
};

export default TransactionCostOnline;
