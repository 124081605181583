import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Privacy = () => {
  return (
    <>
      <Helmet>
        <title>Dreamschools - Privacy</title>
        <link
          rel="canonical"
          href="https://www.dreamschools.com/privacy-policy"
        />
      </Helmet>
      <div className="section_page_content">
        <div
          className="custom_content_width_constraint"
          style={{
            whiteSpace: "pre-line",
            fontSize: "1rem",
            paddingBottom: "3.5rem"
          }}
        >
          <h3 dir="ltr"></h3>
          <h3 dir="ltr">Privacy Policy</h3>
          <br />
          <p dir="ltr">Last updated: August 15, 2020</p>
          <br />
          <p dir="ltr">
            Dreamschools values our customers and we respect your privacy and
            personal information which is information about an identifiable
            individual (as defined by applicable privacy or data protection
            laws).
          </p>
          <br />
          <p dir="ltr">
            This privacy policy ("Privacy Policy" or "Policy") describes how
            Dreamschools treats your personal information obtained through the
            use of www.dreamschools.com Site as well as any other media form,
            media channel, mobile website or mobile application related, linked,
            or otherwise connected thereto (collectively, the “Site”) in this
            Privacy Policy. The Policy describes the kinds of personal
            information Dreamschools collects about you, why we collect it, how
            we use it, how we protect it, and under what circumstances we share
            it with third parties. This Policy also describes the decisions you
            can make about your personal information. You may change, amend or
            delete the personal information that you have provided to us at any
            time.
          </p>
          <br />
          <p dir="ltr">
            The Site may contain links to third parties' websites or apps. We
            are not responsible for the privacy practices or the content of
            those websites including with respect to links to terms and
            conditions and privacy policies of third party providers who provide
            tools or services on a website. Therefore, please read carefully any
            privacy policies on those links or websites before either agreeing
            to their terms or using those tools, services or websites.
          </p>
          <br />
          <p dir="ltr">
            Your use of our Site is governed by the{" "}
            <a href="https://dreamschools.com/terms-of-use"> Terms of Use</a>.
          </p>
          <br />
          <strong dir="ltr">Terms</strong>
          <p dir="ltr">
            The terms "you", "User", and "Provider" refer to individuals who use
            this Site for the purposes of the Services offered.
          </p>
          <br />
          <p dir="ltr">
            In this Privacy Policy, "Personal Information" means any information
            about an identifiable individual, such as your name, e-mail address,
            mailing addresses, gender, date of birth, any data about you that
            you elect to provide electronically through the Site and any other
            information that identifies who you are. Registration Data (as
            defined in the Terms of Use and Personal Information will be used by
            Dreamschools solely in accordance with the Terms of Use).
          </p>
          <br />
          <p dir="ltr">
            Unless we explain otherwise in this Policy, the words and phrases
            used in this Policy have the same meaning as in the Terms of Use
          </p>
          <br />
          <strong dir="ltr">Accountability</strong>
          <p dir="ltr">
            Dreamschools is responsible for Personal Information under its
            control, including the transfer of Personal Information to a third
            party service provider for processing on Dreamschools' behalf.
          </p>
          <br />
          <strong dir="ltr">What Information Do We Gather?</strong>
          <p dir="ltr">
            When you sign up for a User Account through the Site, you
            voluntarily give us certain personal information including your
            name, e-mail address, address, postal code/ZIP code, age, and phone
            number. Use of the Site may involve access to your location through
            your mobile device. You will be asked for permission before the Site
            accesses and uses your physical location information. We use your
            computer and/or device’s physical location to provide you with
            location-based services and content. You may disable our use of
            certain location data through your device or browser settings. In
            addition, when you visit our Site or contact us, we collect certain
            information about your activity on our Site, as described below
            under the heading "Our Use of Cookies, Log Files, and Web Beacons".
          </p>
          <br />
          <p dir="ltr">
            By using the Site, you agree that a copy of any messages and any
            voice calls made through the Site may be recorded and stored for the
            purposes of safety and protecting our Users from misuse of
            Dreamschools Services. The recorded messages will only be stored for
            a period of time that is necessary to fulfil its purpose. The
            collection of information from the Messaging Site is done so in
            accordance with the terms in this Policy.
          </p>
          <br />
          <p dir="ltr">
            If you have registered for human resources and billing management
            services offered by Dreamschools, certain sensitive information
            including your Social Insurance Number, address, and financial
            information including your bank account information is collected
            where it is necessary for the functioning of these services, and
            will not be retained for longer than needed or upon termination of
            the services. We call this personal information "Financial
            Information."
          </p>
          <br />
          <p dir="ltr">
            We ask that you do not disclose or send us any sensitive personal
            information; information related to racial or ethnic origin,
            religion or other beliefs, genetic characteristics on or through the
            Dreamschools Services, except where explicitly requested or
            consented to.
          </p>
          <br />
          <p dir="ltr">
            We may collect other information that you provide including
            information relevant to requests you make, survey responses, job
            applications, and your general preferences insofar as it relates to
            your interaction with the Services, Site or Messaging Site.
          </p>
          <br />
          <strong dir="ltr">Sources of Personal Data</strong>
          <p dir="ltr">
            We collect Personal Information about you from 3 of the following
            sources; you, public records, and/or third parties.
          </p>
          <br />
          <p dir="ltr">We collect Personal Data from you when:</p>
          <ul>
            <li dir="ltr">
              <p dir="ltr">You provide us with such information</p>
            </li>
            <ul>
              <li dir="ltr">
                <p dir="ltr">
                  When you create a User Account or use our tools and/or
                  Services
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  When you voluntarily provide us information in free-form text
                  boxes through the Services or through responses on voluntary
                  surveys and/or questionnaires
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">You provide us with such information</p>
              </li>
            </ul>
            <li dir="ltr">
              <p dir="ltr">
                When you use Services and such information is collected
                automatically
              </p>
            </li>
            <ul>
              <li dir="ltr">
                <p dir="ltr">
                  Through Cookies (defined in the “Our Use of Cookies, Log
                  Files, and Web Beacons”)
                </p>
              </li>
            </ul>
            <li dir="ltr">
              <p dir="ltr">
                When or if you download and install certain applications and
                software that we make available, we may receive and collect
                information transmitted from your device for the purpose of
                providing you the relevant Services; such as information
                regarding when you are logged on and available to receive
                updates or software alerts.
              </p>
            </li>
          </ul>
          <br />
          <strong dir="ltr">
            We collect Personal Data from public records from:
          </strong>
          <ul>
            <li dir="ltr">
              <p dir="ltr">
                The government or other sources (select home Providers only)
              </p>
            </li>
          </ul>
          <br />
          <p dir="ltr">We collect Personal Data from third parties from:</p>
          <ul>
            <li dir="ltr">
              <p dir="ltr">Social Networks</p>
            </li>
            <ul>
              <li dir="ltr">
                <p dir="ltr">
                  If you provide us with your social network account credentials
                  or otherwise sign-in to the Services through a third party
                  site or service, some content and/or information in those
                  accounts may be transmitted into your User Account.
                </p>
              </li>
            </ul>
          </ul>
          <br />
          <strong dir="ltr">How We Use Your Personal Information</strong>
          <p dir="ltr">
            We use the Personal Information we collect about you in order to
            provide you with the Services offered by Dreamschools and you agree
            that we may use your Personal Information for the following
            purposes:
          </p>
          <br />
          <p dir="ltr">
            Collecting Your Personal Information To Provide, Customize, and
            Improve The Services Offered By Dreamschools Through The Site
          </p>
          <p dir="ltr">• To support any requests or inquiries made by you.</p>
          <p dir="ltr">
            • To create and manage your Dreamschools User account.
          </p>
          <p dir="ltr">
            • To create and personalize the Services, website content and
            communications based on your preferences.
          </p>
          <p dir="ltr">
            • To assist you and the User(s) you have done (or have agreed to do)
            business with through Dreamschools' Services.
          </p>
          <p dir="ltr">
            • To enforce our Privacy Policy and/or our Terms or any other
            purpose referenced herein or therein.
          </p>
          <p dir="ltr">
            • To properly track and keep records of purchases and/or other
            transactions.
          </p>
          <p dir="ltr">• To evaluate job applications submitted to us.</p>
          <p dir="ltr">
            • To establish, maintain and/or fulfil relationships with our
            business partners, third-party vendors of products and/or services,
            as well as our corporate and business customers.
          </p>
          <p dir="ltr">
            • To improve the Services, including testing, internal analytics and
            business development.
          </p>
          <br />
          <br />
          <p dir="ltr">
            Collecting Your Personal Information to Market Our Services
          </p>
          <p dir="ltr">• To provide marketing and promotional materials.</p>
          <p dir="ltr">
            • To customize, measure, report on and improve our services, content
            and advertising
          </p>
          <p dir="ltr">
            • To learn more about you and your product preferences by looking at
            the IP address of your computer and your activity on the Website
            (among other things).
          </p>
          <p dir="ltr">
            • To promote services related to the Website or those of the
            Dreamschools and/or our affiliates.
          </p>
          <p dir="ltr">• To conduct voluntary market research surveys.</p>
          <p dir="ltr">
            • To allow you to participate in contests and promotions that we
            run.
          </p>
          <br />
          <p dir="ltr">
            Collecting Your Personal Information to Correspond With You
          </p>
          <p dir="ltr">
            • To respond to correspondence that we receive from you, contacting
            you when necessary, and sending you information about Dreamschools.
          </p>
          <p dir="ltr">
            • To end emails, SMS messages or other communications per your
            preferences.
          </p>
          <p dir="ltr">
            • To contact you from time to time with User or Service updates.
          </p>
          <br />
          <p dir="ltr">
            Collecting Your Personal Information to Meet Legal Requirements
          </p>
          <p dir="ltr">
            • To verify that our internal processes function as intended and are
            legally compliant with regulatory or contractual requirements.
          </p>
          <p dir="ltr">
            • To detect and prevent cyber attacks or attempts to commit identity
            theft.
          </p>
          <p dir="ltr">
            • To fulfill legal obligations under applicable law, regulation,
            court orders or other legal processes.
          </p>
          <p dir="ltr">
            • To protect the rights of you, Dreamschools and or another party.
          </p>
          <p dir="ltr">
            • To prevent, investigate or prosecute activity we think may be
            potentially illegal, unlawful or harmful
          </p>
          <br />
          <br />
          <strong dir="ltr">
            How and Where is Personal Information Processed?
          </strong>
          <p dir="ltr">
            Personal Information will be collected, processed, stored and used
            by Dreamschools and may be passed to and processed by other
            companies under the instruction of Dreamschools. Your Personal
            Information may be processed and/or stored outside of Canada. While
            Dreamschools undertakes measures to protect your Personal
            Information, when it is stored and/or processed in other
            jurisdictions, the laws of other countries may not provide the
            degree or likeness of protection for Personal Information that is
            available in Canada. If your Personal Information is transferred
            outside of Canada, it may be available to the foreign government of
            the country in which the information or entity controlling it is
            situated under a lawful order made in that country and used for
            purposes other than those described herein. By providing us with
            your information, you are allowing your Personal Information to be
            transferred outside of Canada.
          </p>
          <br />
          <p dir="ltr">
            Dreamschools may use Google Analytics to gather statistics on web
            traffic, Site usage and information on User behavior. Google
            Analytics is a web analysis service provided by Google Inc.
            ("Google"). Google utilizes the data collected to track and examine
            use, to prepare reports on activities and share them with other
            Google services. Dreamschools may also use AdRoll, an advertising
            retargeting company, that enables Dreamschools to present you with
            retargeting advertising on other websites based on your previous
            interaction with the Website. In addition, Dreamschools and its
            Website allows for User interaction with social media networks and
            other external sites directly from the Website. The interaction and
            information obtained is subject to the terms and conditions and
            privacy policies of Google, Adroll, and the social media and
            third-party Sites, respectively.
          </p>
          <br />
          <strong dir="ltr">Sharing Your Personal Information</strong>
          <p dir="ltr">
            In accordance with this Policy, we may share your Personal
            Information including Financial Information, with our service
            providers, and third parties involved in providing services
            to you. We may also share information with our financial, insurance,
            legal, accounting or other advisors that provide professional
            services to us.
          </p>
          <br />
          <p dir="ltr">
            We will not disclose, share, sell or rent your Personal Information
            in personally identifiable form with any third party, except if, and
            to the extent necessary, in our good faith judgment, doing so is
            required to: complete the Services offered by Dreamschools including
            in connection with contractors / services providers; used to support
            our business and are bound by contractual obligations to keep
            personal information confidential and use it only for the purposes
            that we disclose, third party suppliers, compliance with laws or
            regulations, response to a valid subpoena, order, or government
            request; to establish or exercise the Company’s legal rights or
            defend against legal claims; investigate, detect, suppress, prevent
            or take action regarding illegal or prohibited activities, suspected
            fraud, situations involving potential threats to the reputation or
            physical safety of any person; or as otherwise required by law. The
            personal data collected may be transfered or collected to a third
            party if we undergo a merger, acquisition, bankruptcy or any other
            transaction in which a third party assumes any amount of control of
            our business. Should an event occur, we will make efforts to notify
            you before your information is directed to a third-party with
            different privacy terms and policies.
          </p>
          <br />
          <p dir="ltr">
            We may remove personal identifiers from your information and
            maintain and use it in anonymous form that may later be combined
            with other information to generate aggregated information. Such
            anonymous and aggregated information may be sold, traded, rented, or
            shared.
          </p>
          <br />
          <p dir="ltr">
            We will retain your personal information only for the length of time
            we have an ongoing relationship, provide Services to you, and for as
            long as you have an open account with us. We will also retain it if
            there is a legal obligation to which we are subject, to the extent
            necessary for the purposes for which it was collected as described
            in this Policy, and for reasonable backup, archival, audit, or other
            similar purposes.
          </p>
          <br />
          <p dir="ltr">
            Your Personal Information may be used by Dreamschools and disclosed
            to parties connected with the contemplated or actual financing,
            insuring, sale, assignment or other disposal of all or part of our
            business or assets, including for the purposes of permitting such
            parties to determine whether to proceed or continue with such
            transaction or business relationship, to fulfill reporting, audit,
            legal and/or other requirements or, in the case of sale or
            assignment, the use and disclosure by those parties for
            substantially the same purposes as described in this Privacy Policy.
          </p>
          <strong dir="ltr">
            Our Use of Cookies, Log Files, and Web Beacons
          </strong>
          <p dir="ltr">
            We use browser tracking cookies (or "cookies"), which are small text
            files that are placed on the hard disk of a computer by a website.
            Cookies are uniquely assigned to you, and can only be read by a
            website or web server that issued the cookie to you. We use 3 types
            of cookies on our Site; Strictly Necessary Cookies and
            Analytical/Performance Cookies. Strictly Necessary cookies are
            required for the basic operation of our Site and allow Users to
            browse the Site and utilize the features. They include cookies that
            enable you to log into secure areas of the Site.
            Analytical/Performance Cookies allow us to recognize and count the
            number of visitors and to see how they navigate the Site. This helps
            us to improve the way they work, we are able to ensure that Users
            are easily finding what they are looking for. These cookies are used
            to keep track of User preferences, e.g. the cities for which you
            want to be shown programs or activities. We may also collect
            information about the website you were visiting before you landed on
            our Site, and the website you visit after you leave our Site, if
            this information is supplied to us by your browser.
          </p>
          <br />
          <p dir="ltr">
            We also use browser "log files” which record certain information
            when you visit a website, including your internet protocol (IP)
            address. To improve your experience on our Site, we use cookies and
            logs files to: recognize you when you return to our Site; keep track
            of activity on our Site and remember what items you have clicked on
            or viewed; study how you navigate through our Site and which
            products you request in site searches so that we can improve the
            design, content and function of our Site; and customize the message,
            content and delivery of online banner advertisements and emails that
            reflect how you navigate to and through our Site based on your
            online behavior. We call this "Browsing Data". We may hire third
            party service providers to assist us in the collection and analysis
            of this Browsing Data collected through cookies, but none of your
            Personal Information is disclosed to these third party service
            providers.
          </p>
          <br />
          <p dir="ltr">
            You have the ability to accept or decline our use of cookies. You
            can refuse cookies by turning them off or blocking them in your
            internet browser. If you decide to turn off or block cookies, our
            Site might not function correctly.
          </p>
          <br />
          <p dir="ltr">
            We use “Web Beacons”, also commonly known as pixel tags, clear gifs,
            single-pixel gifs, are transparent small graphic objects/images that
            are embedded into a webpage or email. They are usually invisible to
            users and allow us to check if a user has viewed a page or email,
            count the number of users who have visited the page or opened an
            email, and for other related statistics. Web beacons may be used
            within our services to track email open rates, web page visits, and
            form submissions.We may use Web Beacons alone or in conjunction with
            cookies to compile formation about our Services.
          </p>
          <br />
          <strong dir="ltr">Third Party Cookies</strong>
          <p dir="ltr">
            Certain content on our Site may be served by third parties,
            including advertisers, promotional partners, content providers, and
            application/service providers. The information they collect may be
            associated with your personal information or they may collect your
            information. This can include personal information about your online
            activities over time and across various websites and other online
            channels. They may use this information for interest-based
            advertising or targeted content. We do not control third parties use
            of cookies, web beacons, and other tracking technologies to collect
            information about you when you visit our Site. If you have any
            questions about the use of cookies, tracking technologies,
            advertisements, or other targeted content, please contact the
            respective third party.
          </p>
          <br />
          <strong dir="ltr">Social Media Features</strong>
          <p dir="ltr">
            The Dreamschools Site includes several social media features such as
            the Facebook “Like” button, and widgets, such as the “Share This”
            Button. The Site also may include interactive mini-programs. These
            features collect various pieces of Personal Information including
            your IP Address, the areas of the Dreamschools Site you visit, and
            may set cookies to enable the features to function properly. Social
            media features and widgets are hosted by a third party or hosted
            directly on our Site. The interactions you have with these features
            are shared with third parties and governed by the respective third
            parties Privacy Policy.
          </p>
          <br />
          <strong dir="ltr">
            Accessing and Updating Your Personal Information
          </strong>
          <p dir="ltr">
            At any time you can contact us to: stop receiving emails from us;
            review the personal information held by the Company in connection
            with your account; withdraw your consent for our use and disclosure
            of your information; request a list of third parties to which
            Dreamschools may have provided your personal information; close your
            account; and amend your personal information, where possible, by
            logging into your User Account. If not possible, contact us at
            support@dreamschools.com.
          </p>
          <br />
          <p dir="ltr">
            If you contact us to do any of the things listed above, we may
            require you to provide sufficient information to allow us to
            identify you and tell you about the existence, use and disclosure of
            your Personal Information and this Personal Information will only be
            used for this purpose. If you contact us about your Personal
            Information, we will respond to your request within a reasonable
            time and at no cost to you. We may not accomodate a request to
            change information if we believe that the change would violate any
            law or legal requirement or cause the information to be incorrect.
          </p>
          <br />
          <strong dir="ltr">Links and Third-Party Websites</strong>
          <p dir="ltr">
            We provide links on our Site to other, third party sites we think
            you will enjoy. These sites operate independently of us and have
            established their own privacy and security policies. Any personal
            information you provide on linked pages or other sites is provided
            directly to that third party and subject to that third party's
            privacy policy. We strongly encourage you to review these policies
            at any site you visit. This Policy does not apply to such linked
            pages or other sites, and we are not responsible for the content or
            practices of any linked websites which are provided solely for your
            convenience.
          </p>
          <br />
          <p dir="ltr">
            This Privacy Policy is not intended to and does not create any
            contractual or other legal rights in or on behalf of any party.
          </p>
          <br />
          <strong dir="ltr">Data Integrity and Security</strong>
          <p dir="ltr">
            We aim to provide you with a safe experience. We seek to protect
            your Personal Data from any unauthorized access, use, and disclosure
            using appropriate security measures based on the type of Personal
            Data and how we process it. We have in place certain physical,
            electronic, technological, and organizational safeguards to
            appropriately protect the security and privacy of your personal
            information against loss, theft, and unauthorized access,
            disclosure, copying use or modification. You should help protect
            your data by taking the appropriate steps to safeguard your password
            and/or sign-in mechanisms, limiting access to your computer and/or
            device and browser, and signing off once you are finishing accessing
            your account. Although we work to protect the security of your
            account and other data that you have provided us and we hold in our
            records, no method of transmitting data over the internet is
            completely secure. Please note, however, that while we try to create
            a secure and reliable Site for users, the confidentiality of any
            communication or material transmitted to or from the Site or via
            e-mail cannot be guaranteed.
          </p>
          <br />
          <p dir="ltr">
            We limit access to your personal information within Dreamschools to
            individuals on a need-to-know basis.
          </p>
          <br />
          <strong dir="ltr">Children's Online Privacy Protection Act</strong>
          <p dir="ltr">
            The Children’s Online Privacy Protection Act (“COPPA”) requires that
            online service providers obtain parental consent before they
            knowingly collect personally identifiable information online from
            children who are under 13. We do not knowingly collect or solicit
            personally identifiable information from children under 13; if you
            are a child under 13, please do not attempt to register for the
            Services or send any personal information about yourself to us. If
            you believe that a child under 13 may have provided us personal
            information, please contact us at support@dreamschools.com.
          </p>
          <br />
          <p dir="ltr">
            We do collect information about children under 13 from their
            parents, guardians, and/or caregivers, but please remember COPPA
            does not apply to information collected online about children under
            13, only information collected from them.
          </p>
          <br />
          <strong dir="ltr">CAN SPAM Act</strong>
          <p dir="ltr">
            The CAN-SPAM Act is a law that sets the rules for commercial email,
            establishes requirements for commercial messages, gives recipients
            the right to have emails stopped from being sent to them, and spells
            out tough penalties for violations.
          </p>
          <br />
          <p dir="ltr">We collect your email address in order to:</p>
          <p dir="ltr">
            • Send information, respond to inquiries, and/or other requests or
            questions.
          </p>
          <p dir="ltr">
            • Process orders and to send information and updates pertaining to
            orders
          </p>
          <p dir="ltr">
            • We may also send you additional information related to your
            product and/or service.
          </p>
          <p dir="ltr">
            • Market to our mailing list or continue to send emails to our
            clients after the original transaction has occurred
          </p>
          <br />
          <p dir="ltr">
            To be in accordance with CANSPAM we agree to the following:
          </p>
          <p dir="ltr">
            • NOT use false, or misleading subjects or email addresses
          </p>
          <p dir="ltr">
            • Identify the message as an advertisement in some reasonable way
          </p>
          <p dir="ltr">
            • Include the physical address of our business or site headquarters
          </p>
          <p dir="ltr">
            • Monitor third party email marketing services for compliance, if
            one is used.
          </p>
          <p dir="ltr">• Honor opt-out/unsubscribe requests quickly</p>
          <p dir="ltr">
            • Allow users to unsubscribe by using the link at the bottom of each
            email
          </p>
          <br />
          <strong dir="ltr">Changes to the Privacy Policy</strong>
          <p dir="ltr">
            Dreamschools reserves the right to make changes to this Privacy
            Policy at any time by giving notice to its Users on this page, and
            by ensuring analogous protection of the Personal Information in all
            cases. It is strongly recommended to check this page often,
            referring to the date of the last modification listed at the top of
            the page.
          </p>
          <br />
          <strong dir="ltr">Interpretation</strong>
          <p dir="ltr">
            Where applicable, words in this Privacy Policy that are defined in
            the singular shall retain the same definition in the plural, and
            vice versa. Words in the masculine include the feminine and vice
            versa. No regard for gender is intended by the language in this
            Privacy Policy.
          </p>
          <br />
          <strong dir="ltr">Contact Us</strong>
          <p dir="ltr">
            If you have questions or comments about this Privacy Policy or your
            Personal Information, please contact Dreamschools by clicking
            Contact Us, by email at support@dreamschools.com, or by delivery in
            person, by courier or by the mail, to Company at:
          </p>
          <br />
          <p dir="ltr">Centre for Social Innovation, Toronto, Ontario</p>
        </div>
      </div>
    </>
  );
};

export default Privacy;
