import React from "react";
import DateSel from "react-datepicker";
import {
  format,
  addDays,
  setHours,
  setMinutes,
  getDay,
  addWeeks
} from "date-fns";
import moment from "moment";
import { formTypes } from "../../../components/UI/form";
import { DatePicker } from "baseui/datepicker";

class ProgramSessionClassDates extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selected_days: [],
      startDate: null,
      sessionWeeklySelectedDays: [],
      toRemoveDays: []
    };
  }
  /**
   * Returns the days excluded to the componentDidMount function
   * @param {Array} days_of_week
   */
  getExcludedDays = days_of_week => {
    let excludedDays = [];
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];

    // const mappedDays = days.map((e, i) => {
    //   if (e === days_of_week.filter(f => f === e)[0]) {
    //     return e;
    //   } else {
    //     return excludedDays.push({ name: e, value: e, dayCount: i });
    //   }
    // });

    return excludedDays;
  };

  componentDidMount() {
    const { plan } = this.props;

    if (plan.isDateSelectionEnabled) {
      const days_of_week = Object.keys(this.props.program.days_of_week);
      const excludedDays = this.getExcludedDays(days_of_week);

      this.setState({ excludedDays: excludedDays });
    }

    if (this.props.classSessionDailySelectedDays?.length > 0) {
      this.setState({
        selected_days: this.props.classSessionDailySelectedDays.map(e => new Date(e))
      });
    }
    if (this.props.classSessionWeeklyDate) {
      this.setState({ startDate: this.props.classSessionWeeklyDate });
    }
  }

  checkIfDateNotSelected = () => {
    let hasNotBeenSelected = false;

    if (this.props.singleSessionQuantity !== this.state.selected_days.length) {
      hasNotBeenSelected = true;
    }

    return hasNotBeenSelected;
  };

  arrowBtnOverrides = ({ $theme }) => {
    return {
      color: "black",
      ":focus": {
        color: "black",
        backgroundColor: "#2a41a2"
      },
      ":hover": {
        color: "black",
        backgroundColor: "#2a41a2"
      }
    };
  };

  render() {
    console.log("this.props", this.props);
    console.log("class dates state", this.state);
    const { plan, singleSessionQuantity } = this.props;
    const number_of_weeks =
      this.props.program.number_of_weeks !== 0
        ? this.props.program.number_of_weeks
        : 52;

    return (
      <div
        style={{
          width: "60%",
          margin: "0 auto",
          padding: "20px",
          backgroundColor: "#fff",
          borderRadius: "20px",
          border: "1px solid lightgrey"
        }}
      >
        {/* DateEnabled and day frequency */}
        {plan.isDateSelectionEnabled && plan.frequency === "day" && (
          <div>
            <h4 style={{ fontFamily: "Montserrat" }}>Pick your days : </h4>
            <div
              className="row"
              style={{
                justifyContent: "center",
                width: "80%",

                margin: "0px auto"
              }}
            >
              {new Array(this.props.singleSessionQuantity)
                .fill(1)
                .map((item, index) => {
                  return (
                    <div className="row" style={{ marginBottom: "20px" }} key={`${index}-datepicker`}>
                      <div className="col-12 d-flex">
                        <DatePicker
                          disabled={this.props.disabled}
                          value={this.state.selected_days[index]}
                          onChange={({ date }) => {
                            let days = [...this.state.selected_days];
                            days[index] = date;
                            this.setState({
                              selected_days: days
                            });

                            this.props.selected_days(days);
                            this.props.hasUnselectedDateValues(
                              this.checkIfDateNotSelected()
                            );
                          }}
                          minDate={addDays(new Date(), 1)}
                          maxDate={addWeeks(new Date(), number_of_weeks)}
                          // formatString="MMMM d, yyyy"
                          placeholder="Select a date"
                          filterDate={date => {
                            const day = getDay(date);
                            return this.state.excludedDays.filter(
                              f => f.dayCount === day
                            ).length <= 0;
                          }}
                          overrides={{
                            MonthYearSelectButton: {
                              style: ({ $theme }) => ({
                                color: "black",
                                ":focus": {
                                  color: "black",
                                  backgroundColor: "#2a41a2"
                                },
                                ":hover": {
                                  color: "black",
                                  backgroundColor: "#2a41a2"
                                }
                              })
                            },
                            PrevButton: {
                              style: this.arrowBtnOverrides
                            },
                            NextButton: {
                              style: this.arrowBtnOverrides
                            },
                            Input: {
                              props: {
                                overrides: {
                                  Root: {
                                    style: ({ $theme }) => ({
                                      border: "1px solid #E8E8E8",
                                      zIndex: 1000
                                    })
                                  },
                                  Input: {
                                    style: ({ $theme }) => ({
                                      // outline: `${$theme.colors.warning200} solid`,
                                      backgroundColor: "white",
                                      padding: "8px"
                                    })
                                  }
                                }
                              }
                            },
                            Popover: {
                              props: {
                                overrides: {
                                  Inner: {
                                    style: ({ $theme }) => ({
                                      zIndex: 999999
                                    })
                                  },
                                  Body: {
                                    style: ({ $theme }) => ({
                                      zIndex: 999999
                                    })
                                  }
                                }
                              }
                            }
                          }}
                        />

                        {/* <DateSel
                        className="w-100 dob-border"
                        showYearDropdown
                        showMonthDropdown
                        dateFormat="MMMM d, yyyy"
                        name="startDate"
                        selected={this.state.selected_days[index]}
                        onChange={e => {
                            let days = [...this.state.selected_days];
                            days[index] = e;
                          this.setState({
                            selected_days: days
                          });

                          this.props.selected_days(e);
                          this.props.hasUnselectedDateValues(this.checkIfDateNotSelected())
                        }}
                        minDate={addDays(new Date(), 1)}
                        filterDate={date => {
                            const day = getDay(date);
                            return this.state.excludedDays.filter(f => f.dayCount === day).length > 0 ? false : true;
                        }}
                    /> */}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}

        {/* DateEnabled and week frequency */}
        {plan.isDateSelectionEnabled && plan.frequency === "week" && (
          <div>
            <h4 style={{ fontFamily: "Montserrat" }}>Pick your start day : </h4>
            <div
              className="row"
              style={{
                justifyContent: "center",
                width: "80%",

                margin: "0px auto"
              }}
            >
              <div className="row" style={{ marginBottom: "20px" }}>
                <div className="col-12 d-flex">
                  <DatePicker
                    value={this.state.startDate}
                    onChange={({ date }) => {
                      this.setState({
                        startDate: date
                      });

                      this.props.startDate(date);
                      this.props.hasUnselectedDate(
                        this.state.stateDate === null
                      );
                    }}
                    minDate={addDays(new Date(), 1)}
                    maxDate={addWeeks(new Date(), number_of_weeks)}
                    // formatString="MMMM d, yyyy"
                    placeholder="Select a date"
                    filterDate={date => {
                      const day = getDay(date);
                      return this.state.excludedDays.filter(
                        f => f.dayCount === day
                      ).length <= 0;
                    }}
                    overrides={{
                      MonthYearSelectButton: {
                        style: ({ $theme }) => ({
                          color: "black",
                          ":focus": {
                            color: "black",
                            backgroundColor: "#2a41a2"
                          },
                          ":hover": {
                            color: "black",
                            backgroundColor: "#2a41a2"
                          }
                        })
                      },
                      PrevButton: {
                        style: this.arrowBtnOverrides
                      },
                      NextButton: {
                        style: this.arrowBtnOverrides
                      },
                      Input: {
                        props: {
                          overrides: {
                            Root: {
                              style: ({ $theme }) => ({
                                border: "1px solid #E8E8E8",
                                zIndex: 1000
                              })
                            },
                            Input: {
                              style: ({ $theme }) => ({
                                // outline: `${$theme.colors.warning200} solid`,
                                backgroundColor: "white",
                                padding: "8px"
                              })
                            }
                          }
                        }
                      },
                      Popover: {
                        props: {
                          overrides: {
                            Inner: {
                              style: ({ $theme }) => ({
                                zIndex: 999999
                              })
                            },
                            Body: {
                              style: ({ $theme }) => ({
                                zIndex: 999999
                              })
                            }
                          }
                        }
                      }
                    }}
                  />

                  {/* <DateSel
                        className="w-100 dob-border"
                        showYearDropdown
                        showMonthDropdown
                        dateFormat="MMMM d, yyyy"
                        name="startDate"
                        selected={this.state.startDate}
                        onChange={e => {
                          this.setState({
                            startDate: e
                          });

                          this.props.startDate(e);
                          this.props.hasUnselectedDate(this.state.stateDate === null ? true : false);
                        }}
                        minDate={addDays(new Date(), 1)}
                        filterDate={date => {
                            const day = getDay(date);
                            return this.state.excludedDays.filter(f => f.dayCount === day).length > 0 ? false : true;
                        }}
                    /> */}
                </div>
              </div>
            </div>

            <div className="row">
              <h4 style={{ fontFamily: "Montserrat" }}>Days of the Week</h4>
            </div>

            <div className="row">
              <div className="select" style={{ width: "100%" }}>
                <formTypes.DaysDropdownSelect
                  name="sessionWeeklySelectedDays"
                  value={this.state.sessionWeeklySelectedDays}
                  toremove={this.state.excludedDays}
                  onChange={(e, v) => {
                    this.setState({ sessionWeeklySelectedDays: v });
                    this.props.sessionWeeklySelectedDays(v);
                  }}
                  className="w-100"
                  range={"Monday - Sunday"}
                />
              </div>
              <div className="row">
                <div className="col-12">
                  <p>
                    {this.state.sessionWeeklySelectedDays.length >
                    parseInt(
                      plan.days_per_week
                    ) ? (
                      <span style={{ color: "red" }}>
                        Selected days cannot exceed number of plan days
                      </span>
                    ) : (
                      <>&nbsp; </>
                    )}
                    {this.state.sessionWeeklySelectedDays.length <
                    parseInt(
                      plan.days_per_week
                    ) ? (
                      <span style={{ fontSize: "13px" }}>
                        {parseInt(
                          plan.days_per_week
                        ) - this.state.sessionWeeklySelectedDays.length}{" "}
                        remaining
                      </span>
                    ) : (
                      <>&nbsp;</>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ProgramSessionClassDates;
