import { setGlobal, addReducer } from "reactn";
import axios from "axios";

/**
 * GLOBAL STATE: Family Profile - Child
 *
 * Manages state for children added to a families profile.
 * NOTE: String IDs are used to differentiate from server provided
 * numeric IDs.
 */

const child = {
  id: "0",
  fullName: "",
  birthDate: "",
  gender: "",
  languages: [],
  allergies: [],
  immunized: "",
  medication: [],
  specialFood: [],
  pottyTrained: "",
  healthConcerns: [],
  likes: [],
  dislikes: [],
  comments: "",
  isArchived: false
};

setGlobal({ children: [{ ...child }] });
addReducer("addChildToFamilyProfile", (global, dispatch) => ({
  children: [
    ...global.children,
    ...[{ ...child, id: global.children.length.toString() }]
  ]
}));
addReducer("removeChildFromFamilyProfile", (global, dispatch, obj) => () => {
  return {
    children: [...global.children].filter(c => c.id !== obj.id)
  };
});

/**
 * GLOBAL STATE: Family Profile - Parent / Guardian
 *
 * Manages state for parents and guardians added to a families profile.
 */

const parentGuardian = {
  id: "0",
  fullName: "",
  address: "",
  city: "",
  province: "",
  country: "",
  postalCode: "",
  email: "",
  homePhone: "",
  workPhone: "",
  isArchived: false
};

setGlobal({ parentGuardians: [{ ...parentGuardian }] });
addReducer("addGuardianToFamilyProfile", (global, dispatch) => ({
  parentGuardians: [
    ...global.parentGuardians,
    ...[{ ...parentGuardian, id: global.parentGuardians.length.toString() }]
  ]
}));
addReducer("removeGuardianFromFamilyProfile", (global, dispatch, obj) => () => {
  return {
    parentGuardians: [...global.parentGuardians].filter(c => c.id !== obj.id)
  };
});

/**
 * GLOBAL STATE: Family Profile - Emergency Contact
 *
 * Manages state for emergency contacts added to a families profile.
 */

const emergencyContact = {
  id: "0",
  children: [],
  relationship: "",
  fullName: "",
  address: "",
  phone: ""
};

setGlobal({ emergencyContacts: [{ ...emergencyContact }] });
addReducer("addEmergencyContactToFamilyProfile", (global, dispatch) => ({
  emergencyContacts: [
    ...global.emergencyContacts,
    ...[{ ...emergencyContact, id: global.emergencyContacts.length.toString() }]
  ]
}));
addReducer(
  "removeEmergencyContactFromFamilyProfile",
  (global, dispatch, obj) => () => {
    return {
      emergencyContacts: [...global.emergencyContacts].filter(
        c => c.id !== obj.id
      )
    };
  }
);

/**
 * GLOBAL STATE: Family Profile - Physician
 *
 * Manages state for physicians added to a families profile.
 */

const physician = {
  id: "0",
  children: [],
  fullName: "",
  address: "",
  phone: "",
  company: ""
};

setGlobal({ physicians: [{ ...physician }] });
addReducer("addPhysicianToFamilyProfile", (global, dispatch) => ({
  physicians: [
    ...global.physicians,
    ...[{ ...physician, id: global.physicians.length.toString() }]
  ]
}));
addReducer(
  "removePhysicianFromFamilyProfile",
  (global, dispatch, obj) => () => {
    return {
      physicians: [...global.physicians].filter(c => c.id !== obj.id)
    };
  }
);

/**
 * Program Setup: Save Program
 */

addReducer("saveProfile", async (global, dispatch) => {
  setGlobal({ loading: true });
  try {
    const ep = `${process.env.REACT_APP_API}/par/profile`;
    const ep2 = `${process.env.REACT_APP_API}/par/profile/update-specific`;
    let data = {
      children: global.children,
      parentGuardians: global.parentGuardians,
      emergencyContacts: global.emergencyContacts,
      physicians: global.physicians
    };
    const data2 = {
      displayName: global.parentGuardians[0].fullName,
      phone: global.parentGuardians[0].homePhone
    };

    /**
     * Reduce
     */
    for (let i = 0; i < data.emergencyContacts.length; i++) {
      data.emergencyContacts[i].children = data.emergencyContacts[
        i
      ].children.map(c => c.id);
    }
    for (let i = 0; i < data.physicians.length; i++) {
      data.physicians[i].children = data.physicians[i].children.map(c => c.id);
    }

    /**
     * Send REQ
     */
    const res2 = await axios.post(ep2, data2);
    // Failed request means that the phone number is a duplicate
    if (!res2.data.success) {
      return { ...global, loading: false, lastAPICall: null };
    }

    const res = await axios.post(ep, data);

    setGlobal({
      dsUser: {
        displayName: global.parentGuardians[0].fullName,
        phone: global.parentGuardians[0].homePhone
      }
    });

    /**
     * POPULATE
     */
    let rdata = res.data.data;
    for (let i = 0; i < rdata.emergencyContacts.length; i++) {
      rdata.emergencyContacts[i].children = rdata.emergencyContacts[
        i
      ].children.map(c => {
        return rdata.children.find(ch => ch.id === c);
      });
    }
    for (let i = 0; i < rdata.physicians.length; i++) {
      rdata.physicians[i].children = rdata.physicians[i].children.map(c => {
        return rdata.children.find(ch => ch.id === c);
      });
    }

    return {
      ...global,
      ...rdata,
      loading: false,
      lastAPICall: res
    };
  } catch (err) {
    console.error(err.message);
    return { ...global, loading: false, lastAPICall: null };
  }
});

addReducer("getProfile", async (global, dispatch) => {
  setGlobal({ loading: true });
  try {
    const ep = `${process.env.REACT_APP_API}/par/profile`;
    const res = await axios.get(ep);

    let data = res.data.data;

    /**
     * POPULATE
     */
    for (let i = 0; i < data.emergencyContacts.length; i++) {
      data.emergencyContacts[i].children = data.emergencyContacts[
        i
      ].children.map(c => {
        return data.children.find(ch => ch.id === c);
      });
    }

    for (let i = 0; i < data.physicians.length; i++) {
      data.physicians[i].children = data.physicians[i].children.map(c => {
        return data.children.find(ch => ch.id === c);
      });
    }

    return {
      ...global,
      ...data,
      loading: false,
      lastAPICall: res
    };
  } catch (err) {
    console.error(err.message);
    return { ...global, loading: false, lastAPICall: null };
  }
});
