import React, { useReducer, useGlobal, useEffect, useState } from "reactn";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import { Promotions } from "../../../assets";

// import GoogleMapReact from 'google-map-react'
import Checkout from "../checkout";
import SignIn from "../../../components/modals/SignInModal";
import SignUpNewModal from "../../../components/modals/SignUpNewModal";
import "../../../assets/css/componentSpecificCss/liveSpecificPages.css";

import {
  getPromotionBasedOnId,
  isPromotionAppliedToProgramPlan
} from "./utils";
import { getSelectedProgramPlan } from "../Checkout/utils";
import PromotionDetailWidgetData from "./PromotionDetailWidgetData";

import EventBookingDetail from "./Booking/EventBookingDetail";
import PartyBookingDetail from "./Booking/PartyBookingDetail";
import ProgramBookingDetail from "./Booking/ProgramBookingDetail";
import OnlineBookingDetail from "./Booking/OnlineBookingDetail";
import MembershipBookingDetail from "./Booking/MembershipBookingDetail";
import EventDescription from "./EventDescription";
import PartyDescription from "./PartyDescription";
import ProgramDescription from "./ProgramDescription";
import OnlineDescription from "./OnlineDescription";
import MembershipDescription from "./MembershipDescription";
import {
  PromotionContext,
  promotion_detail_reducer,
  initial_state,
  setCheckout,
  setSignIn,
  setPromotion,
  setProgram,
  setOnline,
  setMembership,
  setEvent,
  setParty,
  setOrganization,
  setIsOpenSignUpModal,
  setIsOpenShareButtonModal
} from "./PromotionsDetail.context";

import ShareButtonModal from "../../../components/modals/ShareButtonModal";

const styles = {
  plan: {
    transition: "all 300ms",
    boxShadow: "0 2px 5px 0 rgba(0,0,0,.2)",
    "&:hover": {
      transform: "scale(1.02) translateY(-3px)",
      boxShadow: "0 7px 12px 0 rgba(0,0,0,.3)"
    }
  },
  planSelected: {
    transform: "scale(1.02) translateY(-3px)",
    boxShadow: "0 7px 12px 0 rgba(0,0,0,.3)"
  },
  priceCrossedOut: {
    textDecoration: "line-through",
    textDecorationColor: "red",
    textDecorationThickness: "2px"
  }
};

function passInPromotionToCheckout(promotion, promotion_type, selected) {
  if (promotion.promotion_type === "program") {
    return isPromotionAppliedToProgramPlan(
      promotion,
      //JSON.parse(promotion_type.plans[selected])
      getSelectedProgramPlan(promotion_type, selected)
    )
      ? promotion
      : null;
  } else if (promotion.promotion_type === "event") {
    return isPromotionAppliedToProgramPlan(
      promotion,
      JSON.parse(promotion_type.tickets[selected])
    )
      ? promotion
      : null;
  } else if (promotion.promotion_type === "party") {
    return isPromotionAppliedToProgramPlan(
      promotion,
      JSON.parse(promotion_type.tickets[selected])
    )
      ? promotion
      : null;
  } else if (promotion.promotion_type === "membership") {
    return isPromotionAppliedToProgramPlan(
      promotion,
      JSON.parse(promotion_type.prices[selected])
    )
      ? promotion
      : null;
  } else if (promotion.promotion_type === "online") {
    return isPromotionAppliedToProgramPlan(
      promotion,
      //JSON.parse(promotion_type.plans[selected])
      getSelectedProgramPlan(promotion_type, selected)
    )
      ? promotion
      : null;
  }
}

const PartnerPromotionsDetail = props => {
  const { match } = props;
  const [global, _] = useGlobal();
  const [state, dispatch] = useReducer(promotion_detail_reducer, initial_state);
  const [slugsMatch, setSlugsMatch] = useState(false);

  const {
    program,
    online,
    event,
    party,
    membership,
    promotion,
    selected,
    checkout,
    signIn,
    isOpenSignUpModal,
    isOpenShareButtonModal,
    dropIns,
    installments,
    singleSessionQuantity
  } = state;

  const getPromotionBasedOnParameters = async () => {
    const resp = await getPromotionBasedOnId(match.params.id);
    console.log({ resp, program, promotion });

    if (resp.success) {
      const { promotion } = resp.data;
      const { promotion_type } = promotion;
      setPromotion(dispatch, promotion);
      setOrganization(dispatch, resp.data.organization);
      if (promotion_type === "program") {
        setProgram(dispatch, resp.data.program);
      } else if (promotion_type === "event") {
        setEvent(dispatch, resp.data.event);
      } else if (promotion_type === "party") {
        setParty(dispatch, resp.data.party);
      } else if (promotion_type === "membership") {
        setMembership(dispatch, resp.data.membership);
      } else if (promotion_type === "online") {
        setOnline(dispatch, resp.data.online);
      }
      setSlugsMatch(resp.data.organization.slug === match.params.slug);
    } else {
      props.history.push("/");
    }
  };
  useEffect(() => {
    getPromotionBasedOnParameters();
  }, []);

  if (checkout && global.dsUser && promotion.promotion_type === "program") {
    return (
      <Checkout
        form={program.formId}
        program={program}
        promotion={passInPromotionToCheckout(promotion, program, selected)}
        selected={selected}
        organization={program.organization_title}
        installments={installments}
        dropins={dropIns}
        close={() => setCheckout(dispatch, false)}
        type={"Program"}
        parentGuardians={global.parentGuardians}
        singleSessionQuantity={singleSessionQuantity}
      />
    );
  } else if (
    checkout &&
    global.dsUser &&
    promotion.promotion_type === "event"
  ) {
    return (
      <Checkout
        form={state.event.formId}
        program={state.event}
        promotion={passInPromotionToCheckout(promotion, event, selected)}
        selected={state.selected}
        organization={state.event.organization_title}
        close={_ => setCheckout(dispatch, false)}
        type={"Event"}
      />
    );
  } else if (
    checkout &&
    global.dsUser &&
    promotion.promotion_type === "party"
  ) {
    return (
      <Checkout
        form={state.party.formId}
        program={state.party}
        promotion={passInPromotionToCheckout(promotion, party, selected)}
        selected={state.selected}
        organization={state.party.organization_title}
        close={_ => setCheckout(dispatch, false)}
        type={"Party"}
      />
    );
  } else if (
    checkout &&
    global.dsUser &&
    promotion.promotion_type === "membership"
  ) {
    return (
      <Checkout
        form={state.membership.formId}
        program={state.membership}
        promotion={passInPromotionToCheckout(promotion, membership, selected)}
        selected={state.selected}
        organization={state.membership.organization_title}
        close={_ => setCheckout(dispatch, false)}
        type={"Membership"}
      />
    );
  } else if (
    checkout &&
    global.dsUser &&
    promotion.promotion_type === "online"
  ) {
    return (
      <Checkout
        form={online.formId}
        program={online}
        promotion={passInPromotionToCheckout(promotion, online, selected)}
        selected={selected}
        dropins={dropIns}
        organization={online.organization_title}
        close={() => setCheckout(dispatch, false)}
        type={"Online"}
        parentGuardians={global.parentGuardians}
        singleSessionQuantity={singleSessionQuantity}
      />
    );
  }

  const getProgramEventOrMembership = () => {
    // check if program, event or membership are defined
    const { promotion_type } = promotion;
    if (promotion_type === "program") {
      return program;
    }
    if (promotion_type === "event") {
      return event;
    }
    if (promotion_type === "party") {
      return party;
    }
    if (promotion_type === "membership") {
      return membership;
    }
    if (promotion_type === "online") {
      return online;
    }
  };

  if (!slugsMatch) {
    return null;
  }

  return (
    <PromotionContext.Provider value={{ state, dispatch }}>
      <div className="containerall">
        {signIn && (
          <SignIn
            onClose={() => setSignIn(dispatch, false)}
            program={getProgramEventOrMembership()}
            checkout={() => {
              setSignIn(dispatch, false);
              // setCheckout(dispatch, true);
            }}
            openSignUpModal={() => setIsOpenSignUpModal(dispatch, true)}
          />
        )}

        {isOpenSignUpModal && (
          <SignUpNewModal
            onClose={_ => setIsOpenSignUpModal(dispatch, false)}
            program={getProgramEventOrMembership()}
            checkout={() => {
              setIsOpenSignUpModal(dispatch, false);
              // setCheckout(dispatch, true);
            }}
            openSignInModal={() => setSignIn(dispatch, true)}
          />
        )}
        {isOpenShareButtonModal && (
          <ShareButtonModal
            onClose={() => {
              setIsOpenShareButtonModal(dispatch, false);
            }}
            url={(process.env.NODE_ENV === 'development' ? "http://localhost:3002" : process.env.REACT_APP_MODE === "staging" ? "https://staging.dreamschools.com" : "https://dreamschools.com")
              + props.history.location.pathname}
            // quote={"HELLO"}
            hashtag={"#DreamSchools"}
          // description={"DESCRIPTION STUFF"}
          />
        )}

        <div className="leftcontainerprogram">
          <div className="titledivision">
            <h1 className="maintitleprogram">{promotion.title}</h1>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <h4
                style={{
                  margin: `0px 0px 10px 0px`,
                  color: `#999`,
                  fontFamily: `Montserrat`,
                  fontWeight: `600`,
                  textDecoration: `underline`,
                  cursor: "pointer",
                  fontSize: "1.2rem"
                }}
                onClick={_ =>
                  props.history.push(`/programs/${props.match.params.slug}`)
                }
              >
                {state.organization && state.organization.organization_title}
              </h4>
              <div>
                <button
                  className="partnerbutton"
                  style={{ borderRadius: "10px", marginRight: "10px" }}
                >
                  Gallery
                </button>

                <button
                  className="partnerbutton"
                  style={{ borderRadius: "10px" }}
                  onClick={() => {
                    setIsOpenShareButtonModal(dispatch, true)
                  }}
                >
                  Share
                </button>
              </div>
            </div>
          </div>

          <div className="maincontainerdiv">
            <img className="imageprogram" alt="program" src={Promotions} />
            {promotion.promotion_type === "program" && (
              <PromotionDetailWidgetData
                program={program}
                promotion={promotion}
              />
            )}
            {promotion.promotion_type === "event" && (
              <PromotionDetailWidgetData event={event} promotion={promotion} />
            )}

            {promotion.promotion_type === "party" && (
              <PromotionDetailWidgetData party={party} promotion={promotion} />
            )}

            {promotion.promotion_type === "membership" && (
              <PromotionDetailWidgetData
                membership={membership}
                promotion={promotion}
              />
            )}
            {promotion.promotion_type === "online" && (
              <PromotionDetailWidgetData
                online={online}
                promotion={promotion}
              />
            )}
          </div>

          <div className="box-size">
            <h3 className="sub-title">Promotion Description</h3>

            <p
              style={{
                fontWeight: "400",
                fontFamily: `"Open sans", sans-serif`,
                whiteSpace: "pre-line"
              }}
            >
              {promotion.description}
            </p>
          </div>
          {promotion.promotion_type === "event" && (
            <EventDescription event={event} />
          )}
          {promotion.promotion_type === "party" && (
            <PartyDescription party={party} />
          )}
          {promotion.promotion_type === "program" && (
            <ProgramDescription program={program} />
          )}
          {promotion.promotion_type === "membership" && (
            <MembershipDescription membership={membership} />
          )}
          {promotion.promotion_type === "online" && (
            <OnlineDescription program={online} />
          )}
        </div>

        <div className="rightcontainerprogram">
          {promotion.promotion_type === "program" && (
            <ProgramBookingDetail classes={props.classes} />
          )}
          {promotion.promotion_type === "event" && (
            <EventBookingDetail classes={props.classes} />
          )}
          {promotion.promotion_type === "party" && (
            <PartyBookingDetail classes={props.classes} />
          )}
          {promotion.promotion_type === "membership" && (
            <MembershipBookingDetail classes={props.classes} />
          )}
          {promotion.promotion_type === "online" && (
            <OnlineBookingDetail classes={props.classes} />
          )}
        </div>
      </div>
    </PromotionContext.Provider>
  );
};

PartnerPromotionsDetail.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object
};

export default withStyles(styles)(PartnerPromotionsDetail);
