import React from "react";
import {
  DROPDOWN,
  STRING_INPUT,
  CONTENT_BLOCK,
  IMAGE_DROPDOWN,
  IMAGE_CONTENT,
  TEXT_IMAGE_LIST,
  BUTTON,
  TEXT_AREA,
  TITLE,
  SWITCH,
  DATE_PICKER,
  SELECT_MULTIPLE_DROPDOWN,
  ITEM_CONTENT,
  GET_HTML
} from "./FieldTypes";
import Dropdown from "./fieldComponents/Dropdown/Dropdown";
import StringInput from "./fieldComponents/StringInput/StringInput";
import ContentBlock from "./fieldComponents/ContentBlock/ContentBlock";
import ImageDropdown from "./fieldComponents/ImageDropdown/ImageDropdown";
import ImageContent from "./fieldComponents/ImageContent/ImageContent";
import TextImageList from "./fieldComponents/TextImageList/TextImageList";
import Button from "./fieldComponents/Button/Button";
import TextArea from "./fieldComponents/TextArea/TextArea";
import Title from "./fieldComponents/Title";
import Switch from "./fieldComponents/Switch/Switch";
import DatePicker from "./fieldComponents/DatePicker/DatePicker";
import SelectMultipleDropdown from "./fieldComponents/SelectMultipleDropdown/SelectMultipleDropdown";
import ItemContent from "./fieldComponents/ItemContent/ItemContent";
import Htmlcomp from "./fieldComponents/htmlcomp/htmlcomp";

/**
 * @param {String} name The name of the field:
 *   eg. "Avatar"
 *
 * @param {Boolean} required True if this is a required field, false otherwise
 *
 * @param {String} type The type of input field:
 *   eg. DROPDOWN, STRING_INPUT, ...
 *
 * @param {Object} data Data for the input field:
 *   eg. for a dropdown {placeholder: "Select Avatar", options: ["1b", "2b"], onSelect: handleSelected}
 */

const Field = props => {
  const { type, data, fieldValid, setFieldValid } = props;

  const validationProps = { fieldValid, setFieldValid };

  const showInputField = () => {
    const combinedProps = { ...data, ...validationProps };

    switch (type) {
      case DROPDOWN:
        return <Dropdown {...combinedProps} />;
      case STRING_INPUT:
        return <StringInput {...combinedProps} />;
      case CONTENT_BLOCK:
        return <ContentBlock {...combinedProps} />;
      case IMAGE_DROPDOWN:
        return <ImageDropdown {...combinedProps} />;
      case IMAGE_CONTENT:
        return <ImageContent {...combinedProps} />;
      case TEXT_IMAGE_LIST:
        return <TextImageList {...combinedProps} />;
      case BUTTON:
        return <Button {...combinedProps} />;
      case TEXT_AREA:
        return <TextArea {...combinedProps} />;
      case TITLE:
        return <Title {...combinedProps} />;
      case SWITCH:
        return <Switch {...combinedProps} />;
      case DATE_PICKER:
        return <DatePicker {...combinedProps} />;
      case SELECT_MULTIPLE_DROPDOWN:
        return <SelectMultipleDropdown {...combinedProps} />;
      case ITEM_CONTENT:
        return <ItemContent {...combinedProps} />;
      case GET_HTML:
        return <Htmlcomp {...combinedProps} />;
      default:
        console.log("BAD MODAL FIELD TYPE!!", type);
        return "";
    }
  };

  return <div>{showInputField()}</div>;
};

export default Field;
