import React from "react";

const PERCENT = "Percentage";
const FIXED_AMOUNT = "Fixed Amount";
const getPromotionTicketPrice = (promotion, ticketPrice) => {
  if (promotion.discount_type === PERCENT) {
    return Math.max(
      0,
      (1 - promotion.discount_percent / 100) * ticketPrice
    ).toFixed(2);
  } else if (promotion.discount_type === FIXED_AMOUNT) {
    return Math.max(0, ticketPrice - promotion.discount_fixed / 100).toFixed(2);
  }
};

const SummarySubTotalEvents = ({ plans, selected, isPromo, promotion }) => {
  console.log("isPromo", isPromo);

  const displayAmount = () => {
    const selectedPlan = JSON.parse(plans[selected]);
    console.log("selectedPlan", selectedPlan);

    if (isPromo) {
      console.log(
        "promoPrice",
        getPromotionTicketPrice(promotion, selectedPlan.ticket_price)
      );
      return getPromotionTicketPrice(promotion, selectedPlan.ticket_price);
    }

    return selectedPlan.ticket_price;
  };

  return (
    <div style={{ marginTop: "25px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flexStart",
          fontWeight: 600,
          color: "#828282",
          fontFamily: "Open Sans"
        }}
      >
        SUMMARY
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "7px",
          color: "darkslategrey",
          fontWeight: 600
        }}
      >
        <div>Subtotal</div>
        <div>${displayAmount()}</div>
      </div>
    </div>
  );
};

export default SummarySubTotalEvents;
