const isEmpty = val => {
  if (!val) return false;
  if (val.length === 0) return false;
  if (typeof val === "string") {
    return val.trim().length > 0;
  }
  if (!val) {
    return false;
  } else {
    return true;
  }
};
const validateFirst = val => {
  if (!val[0].Email || !val[0]["Full Name"] || !val[0]["Phone Number"])
    return false;
  else return true;
};
const isNotEnough = val => {
  if (val)
    if (typeof val === "string") if (val.trim().length < 40) return false;
  return true;
};
const Validators = {
  isEmpty: isEmpty,
  isNotEnough,
  validateFirst
};

export default Validators;
