import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { getSelectedProgramPlan } from "./utils";

const ProgramSingleSessionsSubtotal = props => {
  const [subtotal, setSubtotal] = useState("Loading");
  const calculateSubtotal = () => {
    const plan = getSelectedProgramPlan(props.program, props.selected);
    const total_price = parseInt(plan.total_price);
    setSubtotal(
      parseFloat(
        parseFloat(
          total_price *
            (props.installments
              ? 1 + parseInt(plan.installments_interest) / 100
              : 1) || 0
        ) * props.singleSessionQuantity
      ).toFixed(2)
    );
  };
  useEffect(() => {
    calculateSubtotal();
  }, []);
  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px",
        borderBottom: "1px solid #dedede",
        fontSize: "14px"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif",
          fontWeight: 500,
          fontSize: "14px"
        }}
      >
        Subtotal
      </p>
      <span style={{ fontWeight: 500 }}>${subtotal}</span>
    </li>
  );
};

ProgramSingleSessionsSubtotal.propTypes = {
  program: PropTypes.shape({
    plans: PropTypes.array.isRequired
  }).isRequired,
  selected: PropTypes.number.isRequired,
  installments: PropTypes.number.isRequired
};

export default ProgramSingleSessionsSubtotal;
