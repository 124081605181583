const Names = [
  "Logan Michaels",
  "Michayla Michaels",
  "Jordan Mivat",
  "Christina Anderson",
  "Tommy Johnson",
  "Johnny Thomson"
];

const getNames = amt => {
  return [...Array(amt).keys()].map(
    n => Names[Math.floor(Math.random() * Names.length)]
  );
};

export { Names, getNames };
