import React from "react";
import "../fieldComponent.css";
import "./Button.css";
import Title from "../Title";

const Button = props => {
  const {
    name,
    buttonText,
    buttonText2,
    handleClick,
    handleClick2,
    buttonColour,
    titleStyle,
    textColour
  } = props;

  return (
    <div>
      {/* <p className="field-component-title">{name}</p> */}
      <Title name={name} titleStyle={titleStyle} />
      <button
        className="button-field-button"
        onClick={handleClick}
        style={{
          backgroundColor: buttonColour,
          color: textColour,
          width: "10rem",
          height: "3rem",
          fontSize: "1.3rem",
          marginRight: "2rem"
        }}
      >
        {buttonText}
      </button>
      {handleClick2 && buttonText2 && (
        <button
          className="button-field-button"
          onClick={handleClick2}
          style={{
            backgroundColor: buttonColour,
            color: textColour,
            width: "10rem",
            height: "3rem",
            fontSize: "1.3rem"
          }}
        >
          {buttonText2}
        </button>
      )}
    </div>
  );
};

export default Button;
