import React from "reactn";
import axios from "axios";
import { toast } from "react-toastify";
import { setParents } from "../../store";
import { withRouter, Link } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { GreenCheck, Checkmark } from "../../assets";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import AddEmbedModal from "./AddEmbedModal";
import BaseModal from "./baseModal/BaseModal";
import { GET_HTML } from "./baseModal/FieldTypes";

import { FacebookShareButton, EmailShareButton, WhatsappShareButton } from "react-share";
import { FacebookIcon, EmailIcon, WhatsappIcon } from "react-share"


class ShareButtonModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      url: props.url,
      hashtag: props.hashtag,
      description: props.description,
      quote: props.quote,
      popoverAnchorE1: null,
      embedModal: false
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };

  getBaseModalFields() {
    const newClient = {
      type: GET_HTML,
      data: {
        gethtml: this.addnewmodal
      }
    };

    const fields = [];
    fields.push(newClient);

    return fields;
  }

  getBaseModalButtons() {

  }

  getBaseModalProps() {
    return {
      title: "Share",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      handleClose: this.props.onClose,
      width: "450px",
      height: "30vh",
      midSectionHeight: "52vh"
    };
  };

  addnewmodal = () => {
    return (
      <div className="container" style={{ padding: 0 }}>
        <div className="row" style={{ marginTop: '10px', marginBottom: '30px' }}>
          <div className="col text-center">
            <FacebookShareButton
              url={this.props.url}
              hashtag={"#DreamSchools"}
              className="text-center"
            >
              <FacebookIcon size={70} round style={{ display: "block" }} />
              Facebook
            </FacebookShareButton>
          </div>
          <div className="col text-center">
            <WhatsappShareButton
              url={this.props.url}
              className="text-center"
            >
              <WhatsappIcon size={70} round style={{ display: "block" }} />
              WhatsApp
            </WhatsappShareButton>

          </div>
          <div className="col text-center">
            <EmailShareButton
              url={this.props.url}
              title="Dreamschools Programs"
              className="text-center"
            >
              <EmailIcon size={70} round style={{ display: "block" }} />
              Email
            </EmailShareButton>
          </div>
        </div>

        <div className="row">
          <div className="col text-center">
            <button
              className="btn"
              aria-describedby={Boolean(this.state.popoverAnchorE1) ? "simple-popover" : undefined}
              onClick={(event) => {
                navigator.clipboard.writeText(this.props.url)
                this.setState({
                  ...this.state,
                  popoverAnchorE1: event.currentTarget,
                })
              }}
            >
              Copy Link
            </button>
            <Popover
              style={{ zIndex: 1000000 }}
              id={Boolean(this.state.popoverAnchorE1) ? "simple-popover" : undefined}
              // classes={{ root: this.props.classes.popover }}
              open={Boolean(this.state.popoverAnchorE1)}
              anchorEl={this.state.popoverAnchorE1}
              onClose={() => {
                this.setState({
                  ...this.state,
                  popoverAnchorE1: null
                })
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center"
              }}
            >
              <div style={{ padding: "10px 10px" }}>
              <img src={Checkmark} alt="" style={{
                marginRight: "10px",
                width: "18px",
                verticalAlign: "text-top"
              }}/>
                Link Copied
              </div>
            </Popover>
          </div>
          <div className="col text-center">
            <button
              className="btn"
              onClick={() => {
                this.setState({
                  ...this.state,
                  embedModal: true
                })
              }}
            >
              {"Embed </>"}
            </button>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <>
        {this.state.embedModal && (
          <AddEmbedModal
            url={this.props.url}
            type={this.props.type}
            onClose={() =>
              this.setState({
                iframeSetUp: false,
                selectedIframe: null,
                type: null,
                active: null,
                embedModal: false
              })
            }
          />
        )}
        {!this.state.embedModal && (<BaseModal {...this.getBaseModalProps()} />)}
      </>
    )
  }
}

export default withRouter(ShareButtonModal);
