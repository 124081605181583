import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import {
  getSelectedProgramPlan,
  promotion_discount,
  coupon_discount,
  MINIMUM_CHARGE
} from "./utils";

const TransactionCostProgram = props => {
  const [fee, setFee] = useState("Loading");

  const calculateFeeAmount = () => {
    const plan = getSelectedProgramPlan(props.program, props.selected);
    const tuition_rate = parseFloat(plan.tuition_rate);
    const deposit = parseFloat(plan.deposit ? plan.deposit : 0);
    const discount_amount = props.coupon
      ? coupon_discount(props.coupon, tuition_rate)
      : 0;
    const taxRate = plan.isTaxEnabled
      ? parseFloat(plan.taxInfo[0].percentage) / 100
      : 0;
    const tax_amount =
      Math.max(MINIMUM_CHARGE, tuition_rate - discount_amount) * taxRate;

    if (props.promotion) {
      const tax_percent = parseFloat(1.5) / 100;
      const discount_amount = promotion_discount(props.promotion, tuition_rate);
      const total = Math.max(MINIMUM_CHARGE, tuition_rate - discount_amount + tax_amount);
      console.log("Tax fees..", {MINIMUM_CHARGE, tuition_rate, discount_amount})
      const calcFee = total * tax_percent;

      props.creditCardFeeAmount(calcFee.toFixed(2));

      setFee(calcFee.toFixed(2));
    } else {
      const tax_percent = parseFloat(1.5) / 100;
      const discount_amount = props.coupon
        ? coupon_discount(props.coupon, tuition_rate)
        : 0;
      const total = Math.max(MINIMUM_CHARGE, tuition_rate - discount_amount);
      const calcFee = total * tax_percent;

      props.creditCardFeeAmount(calcFee.toFixed(2));

      setFee(calcFee.toFixed(2));
    }
  };

  useEffect(() => {
    calculateFeeAmount();
  }, []);

  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px",
        borderBottom: "1px solid #dedede"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif",
          fontWeight: 500,
          fontSize: "14px"
        }}
      >
        Credit Card Fee (1.5%)
      </p>
      <span style={{ fontWeight: 500, fontSize: "14px" }}>${fee}</span>
    </li>
  );
};

TransactionCostProgram.propTypes = {
  program: PropTypes.shape({
    plans: PropTypes.array.isRequired
  }).isRequired,
  selected: PropTypes.number.isRequired,
  promotion: PropTypes.object,
  coupon: PropTypes.object
};

export default TransactionCostProgram;
