import React from "reactn";
import {
  OrangePin,
  MemberShip1,
  MemberShip2,
  LiveView1,
  LiveView2,
  LiveView3,
  TimeIcon,
  ParaIcon2
} from "../../assets";
import GoogleMapReact from "google-map-react";
import GeoCode from "react-geocode";
import SignModal from "../../components/modals/SignModal";
import "../../assets/css/educator.css";
import DatePick from "react-datepicker";
import CalendarTemp from "../../components/calendar/CalendarTemp";
import NoDataAvailable from "../../components/front/partner/NoDataAvailable";
import axios from "axios";
import { format, addWeeks, addDays, isSameDay, isSameYear } from "date-fns";
import { toast } from "react-toastify";
import { ReactBnbGallery } from "react-bnb-gallery";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import GiftCardPurchase from "./buy_gift_card";
import { withStyles } from "@material-ui/core/styles";

import "../../assets/css/componentSpecificCss/liveView.css";
import { enGB } from "date-fns/locale";
import { DatePickerCalendar } from "react-nice-dates";
import "react-nice-dates/build/style.css";
import CardElement from "../../components/Payments/stripe_component";
import { StripeProvider, Elements } from "react-stripe-elements";
import PartnerPromotions from "./PartnerPromotions/PartnerPromotions";

import { FacebookShareButton } from "react-share";
import ShareButtonModal from "../../components/modals/ShareButtonModal";

import { DateTime } from "luxon";
import {
  constructDateOnlyWithTimeZone,
  constructTimeRangeFromDecimalHours,
  getFormattedTimeRange,
  getFormattedDateRange,
  getGroupedWeekdayRange,
  buildScheduleForProduct,
  prepareClosureDates,
  makeUTCDateTimeAsLocalTZ
} from "./SpecificPageUtils/utils";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import { duration } from "moment";

import ListingCard from "../../components/front/ListingCard";

const styles = theme => ({
  plan: {
    transition: "all 300ms",
    "&:hover": {
      boxShadow: "0 2px 15px 0 rgba(204,204,204,0.5)"
    }
  },
  planSelected: {
    transform: "scale(1.02) translateY(-3px)",
    boxShadow: "0 7px 12px 0 rgba(0,0,0,.3)"
  },
  tabRoot: {
    color: "gray !important",
    fontFamily: "Montserrat,sans-serif !important",
    fontWeight: "400 !important",
    fontSize: "1.1rem !important",
    // "& .MuiButtonBase-root": {
    marginRight: "10px !important",
    padding: "6px 12px !important",
    minWidth: "0 !important",
    // },
    "&:hover": {
      backgroundColor: "transparent !important",
      color: "gray !important"
    },
    "&:focus": {
      backgroundColor: "transparent !important",
      color: "gray !important"
    }
  },
  tabSelected: {
    color: "black !important",
    "&:hover": {
      backgroundColor: "transparent !important",
      color: "black !important"
    },
    "&:focus": {
      backgroundColor: "transparent !important",
      color: "black !important"
    }
  },
  tabIndicator: {
    backgroundColor: "#ff6663",
    height: "3px !important",
    marginTop: "20px"
  },
  input: {
    height: "3em",
    border: "1px solid #E8E8E8",
    borderRadius: "3px",
    fontFamily: "Montserrat, sans-serif !important",
    paddingLeft: "0.5em"
  },
  textArea: {
    height: "6em",
    border: "1px solid #E8E8E8",
    borderRadius: "3px",
    fontFamily: "Montserrat, sans-serif !important",
    paddingLeft: "0.5em"
  },
  datePicker: {
    height: "3em",
    border: "1px solid #E8E8E8",
    width: "100%",
    borderRadius: "3px",
    fontFamily: "Montserrat, sans-serif !important",
    paddingLeft: "0.5em"
  },
  tabsHeadings: {
    height: "50px",
    minHeight: "40px",
    paddingLeft: "15px",
    borderTop: "1px solid gainsboro",
    borderBottom: "1px solid gainsboro",
    "& .MuiTabs-flexContainer": {
      marginLeft: "0px"
    }
  },
  subHeadings: {
    height: "40px",
    minHeight: "30px",
    paddingLeft: "15px",
    display: "flex",
    alignItems: "center",
    "& .MuiTabs-flexContainer": {
      height: "38px !important",
      marginLeft: "0px !important"
    },
    "& .PreviewPartner-tabIndicator-5": {
      backgroundColor: "#08009C !important",
      height: "3px !important"
    },
    "& .MuiTab-wrapper": {
      paddingBottom: "10px !important"
    }
  },
  cardImg: {
    height: "147px !important",
    width: "100%",
    objectFit: "cover",
    borderRadius: "7px",
    [theme.breakpoints.down(1164)]: {
      height: "100px !important"
    },
    [theme.breakpoints.down(767)]: {
      height: "66px !important"
    }
  }
});

GeoCode.setRegion("ca");
GeoCode.setLanguage("en");
GeoCode.setApiKey("");

const GmapNodeMarker = (lat, lng) => {
  return (
    <div
      lat={lat}
      lng={lng}
      style={{
        backgroundImage: `url(${OrangePin})`,
        backgroundSize: "cover",
        height: 30,
        width: 22,
        cursor: "pointer",
        zIndex: 10,
        position: "relative",
        bottom: 15,
        right: 15
      }}
    ></div>
  );
};

class PreviewPartner extends React.PureComponent {
  constructor(props) {
    super(props);

    this.sublinks = {
      Forms: [
        "Registration",
        "Applications",
        "Appointments",
        "Waitlists",
        "Contact Us"
      ]
    };

    this.themes = [
      `background: #FF6663;
    background: -moz-linear-gradient(right, #FF6663 0%, #FF6663 100%) !important;
    background: -webkit-gradient(
      left right,
      left bottom,
      color-stop(0%, #FF6663),
      color-stop(100%, #FF6663)
    ) !important;
    background: -webkit-linear-gradient(right, #FF6663 0%, #FF6663 100%) !important;
    background: -o-linear-gradient(right, #FF6663 0%, #FF6663 100%) !important;
    background: -ms-linear-gradient(right, #FF6663 0%, #FF6663 100%) !important;
    background: linear-gradient(to right, #FF6663 0%, #FF6663 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FF6663', endColorstr='#FF6663', GradientType=0 );`,
      `background: #7ea0ff;
    background: -moz-linear-gradient(right, #039BE5 0%, #039BE5 100%) !important;
    background: -webkit-gradient(
      left right,
      left bottom,
      color-stop(0%, #039BE5),
      color-stop(100%, #039BE5)
    ) !important;
    background: -webkit-linear-gradient(right, #039BE5 0%, #039BE5 100%) !important;
    background: -o-linear-gradient(right, #039BE5 0%, #039BE5 100%) !important;
    background: -ms-linear-gradient(right, #039BE5 0%, #039BE5 100%) !important;
    background: linear-gradient(to right, #039BE5 0%, #039BE5 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#039BE5', endColorstr='#039BE5', GradientType=0 );`,
      ` background: #E57403;
    background: -moz-linear-gradient(right, #E57403 0%, #E57403 100%) !important;
    background: -webkit-gradient(
      left right,
      left bottom,
      color-stop(0%, #E57403),
      color-stop(100%, #E57403)
    ) !important;
    background: -webkit-linear-gradient(right, #E57403 0%, #E57403 100%) !important;
    background: -o-linear-gradient(right, #E57403 0%, #E57403 100%) !important;
    background: -ms-linear-gradient(right, #E57403 0%, #E57403 100%) !important;
    background: linear-gradient(to right, #E57403 0%, #E57403 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#E57403', endColorstr='#E57403', GradientType=0 );`,
      `background: #1C2680;
    background: -moz-linear-gradient(right, #1C2680 0%, #1C2680 100%) !important;
    background: -webkit-gradient(
      left right,
      left bottom,
      color-stop(0%, #1C2680),
      color-stop(100%, #1C2680)
    ) !important;
    background: -webkit-linear-gradient(right, #1C2680 0%, #1C2680 100%) !important;
    background: -o-linear-gradient(right, #1C2680 0%, #1C2680 100%) !important;
    background: -ms-linear-gradient(right, #1C2680 0%, #1C2680 100%) !important;
    background: linear-gradient(to right, #1C2680 0%, #1C2680 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1C2680', endColorstr=' #1C2680', GradientType=0 );`,
      `background: #079475;
    background: -moz-linear-gradient(right, #079475 0%, #079475 100%) !important;
    background: -webkit-gradient(
      left right,
      left bottom,
      color-stop(0%, #079475),
      color-stop(100%, #079475)
    ) !important;
    background: -webkit-linear-gradient(right, #079475 0%, #079475 100%) !important;
    background: -o-linear-gradient(right, #079475 0%, #079475 100%) !important;
    background: -ms-linear-gradient(right, #079475 0%, #079475 100%) !important;
    background: linear-gradient(to right, #079475 0%, #079475 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#079475', endColorstr='#079475', GradientType=0 );`
    ];

    this.state = {
      galleryShown: false,
      active: "Programs",
      article: null,
      data: { target_ages: [], program_types: [], gallery: [] },
      events: [],
      parties: [],
      memberships: [],
      programs: [],
      Registration: {},
      gifts: [],
      news: [],
      onlineVirtual: [],
      forms: [],
      locations: [],
      displayAppointmentRequestFormStep: 0,
      image_gifts: [
        "partners/Gift Cards/zeus-01.png",
        "partners/Gift Cards/zeus-02.png",
        "partners/Gift Cards/zeus-03.png",
        "partners/Gift Cards/zeus-04.png",
        "partners/Gift Cards/zeus-05.png",
        "partners/Gift Cards/zeus-06.png",
        "partners/Gift Cards/zeus-07.png",
        "partners/Gift Cards/zeus-08.png"
      ],
      formData: {},
      selectedForm: 0,
      selectedProgram: 0,
      sublinks: {
        Forms: [
          "Registration",
          "Applications",
          "Appointments",
          "Waitlists",
          "Contact Us"
        ]
      },
      selectedAppointmentTimeIndex: null,
      days: {},
      isOpenShareButtonModal: false
    };
  }

  elements = (
    element,
    label,
    type = undefined,
    width,
    secId,
    required,
    options = []
  ) => {
    if (element === "input")
      return (
        <input
          type={type}
          style={{ width: width }}
          className={this.props.classes.input}
          name={secId + label}
          onChange={e =>
            this.setState({
              formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
              }
            })
          }
          required={required}
          value={this.state.formData[secId + label]}
        />
      );
    if (element === "donation")
      return (
        <input
          type={type}
          style={{ width: width }}
          className={this.props.classes.input}
          name={secId + label}
          onChange={e =>
            this.setState({
              formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
              }
            })
          }
          required={required}
          value={this.state.formData[secId + label] || ""}
        />
      );
    if (element === "Time")
      return (
        this.state.formData[secId + "Appointment Date"] && (
          <div className="appt-time-scroll">
            {this.state.formData[secId + "Appointment Date"] &&
              this.state.availability.availability
                .filter(
                  e =>
                    new Date(e).getDay() ===
                    this.state.formData[secId + "Appointment Date"].getDay()
                )
                .map((element, i) => (
                  <div className="time-list-btn">
                    <input
                      type="radio"
                      id={this.state.formData[secId + label]}
                      name={secId + label}
                      value={
                        new Date(
                          new Date(
                            this.state.formData[secId + "Appointment Date"]
                          ).setHours(new Date(element).getHours())
                        )
                      }
                      checked={i === this.state.selectedAppointmentTimeIndex}
                      disabled={
                        !this.state.formData[secId + "Appointment Date"]
                      }
                    />
                    <label
                      htmlFor={this.state.formData[secId + label]}
                      onClick={_ =>
                        this.handleTimeClick(element, i, secId, label)
                      }
                    >
                      {new Date(element).getHours()}:00
                    </label>
                    <br />
                  </div>
                ))}
          </div>
        )
      );
    if (element === "Appt")
      return (
        <div className="calendar-box">
          <DatePickerCalendar
            name={secId + label}
            date={this.state.formData[secId + label]}
            format="MMMM d, yyyy"
            minimumDate={new Date()}
            onDateChange={e => {
              this.setState({
                formData: {
                  ...this.state.formData,
                  [secId + label]: e,
                  [secId + "Appointment Time"]: ""
                }
              });
            }}
            modifiers={{
              disabled: date => {
                const availability =
                  this.state.availability.availability.filter(
                    e => new Date(e).getDay() === date.getDay()
                  ).length;

                return availability === 0;
              }
            }}
            locale={enGB}
          />
        </div>
      );
    if (element === "textarea")
      return (
        <textarea
          type={type}
          rows={4}
          name={secId + label}
          style={{ width: width }}
          className={this.props.classes.textArea}
          onChange={e =>
            this.setState({
              formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
              }
            })
          }
          required={required}
          value={this.state.formData[secId + label] || ""}
        ></textarea>
      );
    if (element === "days") {
      return (
        <div
          name={secId + label}
          className="row days"
          validation={"validDays"}
          style={{ width: width, margin: 0 }}
        >
          <div
            data-day="Monday"
            className={`day-monday day ${this.state.formData[secId + label] &&
              this.state[secId + label]["Monday"]
              ? "selecta"
              : ""
              }`}
            onClick={e => {
              this.setDay(e.target.getAttribute("data-day"), secId + label);
            }}
          >
            M
          </div>
          <div
            data-day="Tuesday"
            className={`day-tuesday day ${this.state.formData[secId + label] &&
              this.state.formData[secId + label]["Tuesday"]
              ? "selecta"
              : ""
              }`}
            onClick={e => {
              this.setDay(e.target.getAttribute("data-day"), secId + label);
            }}
          >
            Tu
          </div>
          <div
            data-day="Wednesday"
            className={`day-wednesday day ${this.state.formData[secId + label] &&
              this.state.formData[secId + label]["Wednesday"]
              ? "selecta"
              : ""
              }`}
            onClick={e => {
              this.setDay(e.target.getAttribute("data-day"), secId + label);
            }}
          >
            W
          </div>
          <div
            data-day="Thursday"
            className={`day-thursday day ${this.state.formData[secId + label] &&
              this.state.formData[secId + label]["Thursday"]
              ? "selecta"
              : ""
              }`}
            onClick={e => {
              this.setDay(e.target.getAttribute("data-day"), secId + label);
            }}
          >
            Th
          </div>
          <div
            data-day="Friday"
            className={`day-friday day ${this.state.formData[secId + label] &&
              this.state[secId + label]["Friday"]
              ? "selecta"
              : ""
              }`}
            onClick={e => {
              this.setDay(e.target.getAttribute("data-day"), secId + label);
            }}
          >
            F
          </div>
          <div
            data-day="Saturday"
            className={`day-saturday day ${this.state.formData[secId + label] &&
              this.state.formData[secId + label]["Saturday"]
              ? "selecta"
              : ""
              }`}
            onClick={e => {
              this.setDay(e.target.getAttribute("data-day"), secId + label);
            }}
          >
            Sa
          </div>
          <div
            data-day="Sunday"
            className={`day-sunday day ${this.state.formData[secId + label] &&
              this.state[secId + label]["Sunday"]
              ? "selecta"
              : ""
              }`}
            onClick={e => {
              this.setDay(e.target.getAttribute("data-day"), secId + label);
            }}
          >
            Su
          </div>
        </div>
      );
    }
    if (element === "radios")
      return (
        <div
          style={{
            display: "flex",
            width: width
          }}
        >
          <div
            style={{
              marginRight: "10px",
              fontFamily: "Open Sans, sans-serif"
            }}
          >
            <input
              type="radio"
              name={secId + label}
              value="Immediately"
              onChange={e =>
                this.setState({
                  formData: {
                    ...this.state.formData,
                    [e.target.name]: e.target.value
                  }
                })
              }
            />{" "}
            Immediately
          </div>
          <div
            style={{
              marginRight: "10px",
              fontFamily: "Open Sans, sans-serif"
            }}
          >
            <input
              type="radio"
              name={secId + label}
              value="1-3 Months"
              onChange={e =>
                this.setState({
                  formData: {
                    ...this.state.formData,
                    [e.target.name]: e.target.value
                  }
                })
              }
            />{" "}
            1-3 Months
          </div>
          <div
            style={{
              marginRight: "10px",
              fontFamily: "Open Sans, sans-serif"
            }}
          >
            <input
              type="radio"
              name={secId + label}
              value="3+ Months"
              onChange={e =>
                this.setState({
                  formData: {
                    ...this.state.formData,
                    [e.target.name]: e.target.value
                  }
                })
              }
            />{" "}
            3+ Months
          </div>
        </div>
      );
    if (element === "Dropdown")
      return (
        <select
          style={{ width: width }}
          className={"form-input"}
          name={secId + label}
          onChange={e =>
            this.setState({
              formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
              }
            })
          }
        >
          <option value="" disabled>
            Please Select
          </option>
          {options.map(e => (
            <option value={e.value}>{e.value}</option>
          ))}
        </select>
      );
    if (element === "signature")
      return this.state.formData[secId + label] ? (
        <LazyLoadImage
          src={this.state.formData[secId + label]}
          effect="opacity"
        />
      ) : (
        <a
          href="#/"
          onClick={_ =>
            this.setState({ openSignModal: true, signVar: secId + label })
          }
        >
          Draw Signature
        </a>
      );
    if (element === "Multiple Choices")
      return (
        <div
          style={{
            marginRight: "10px",
            display: "flex",
            alignItems: "center",
            border: "none",
            paddingLeft: "5px",
            width: width,
            fontFamily: "Open Sans, sans-serif"
          }}
        >
          {options.map(e => (
            <div
              style={{
                marginRight: "10px",
                fontFamily: "Open Sans, sans-serif"
              }}
            >
              <input
                type="radio"
                value={e.value}
                name={secId + label}
                style={{ margin: "0px 5px" }}
                onChange={e =>
                  this.setState({
                    formData: {
                      ...this.state.formData,
                      [e.target.name]: e.target.value
                    }
                  })
                }
              />
              {e.value}
            </div>
          ))}
        </div>
      );
    if (element === "Checkboxes")
      return (
        <div
          style={{
            display: "flex",
            width: width
          }}
        >
          {options.map(e => (
            <div
              style={{
                marginRight: "10px",
                display: "flex",
                alignItems: "center",
                border: "none",
                paddingLeft: "5px",
                fontFamily: "Open Sans, sans-serif"
              }}
              className={"form-input"}
            >
              <input
                type="checkbox"
                value={e.value}
                name={secId + label}
                style={{ margin: "0px 5px" }}
                onChange={e =>
                  this.setState({
                    formData: {
                      ...this.state.formData,
                      [e.target.name]: this.state.formData[e.target.name]
                        ? e.target.checked
                          ? this.state.formData[e.target.name]
                            .split(", ")
                            .indexOf(e.target.value) !== -1
                            ? this.state.formData[e.target.name]
                            : this.state.formData[e.target.name] +
                            `, ${e.target.value}`
                          : this.state.formData[e.target.name]
                            .split(", ")
                            .filter(ex => ex !== e.target.value)
                            .join(", ")
                        : e.target.checked
                          ? e.target.value
                          : ""
                    }
                  })
                }
              />
              {e.value}
            </div>
          ))}
        </div>
      );
    if (element === "DOB")
      return (
        <DatePick
          className="w-100"
          showYearDropdown
          showMonthDropdown
          dateFormat="MMMM d, yyyy"
          name={label}
          onChange={e =>
            this.setState({
              formData: { ...this.state.formData, [label]: e }
            })
          }
          selected={this.state.formData[label]}
        />
      );
    if (element === "card")
      return (
        <div style={{ width: "350px", marginTop: "15px" }}>
          <StripeProvider apiKey={process.env.REACT_APP_STRIPE}>
            <Elements>
              <CardElement
                setToken={token =>
                  this.setState({
                    formData: { ...this.state.formData, pay_token: token }
                  })
                }
              />
            </Elements>
          </StripeProvider>
        </div>
      );
  };

  openGallery = () => {
    this.setState({
      galleryShown: !this.state.galleryShown
    });
  };

  createCalendarSchedules() {
    //CLEANUP-TODO: Move the CSS below to somewhere more appropriate.
    const customStyleForEvents = `
      background: rgba(245,198,140,1);
      background: -moz-linear-gradient(left, rgba(245,198,140,1) 0%, rgba(253,132,192,1) 100%);
      background: -webkit-gradient(left top, right top, color-stop(0%, rgba(245,198,140,1)), color-stop(100%, rgba(253,132,192,1)));
      background: -webkit-linear-gradient(left, rgba(245,198,140,1) 0%, rgba(253,132,192,1) 100%);
      background: -o-linear-gradient(left, rgba(245,198,140,1) 0%, rgba(253,132,192,1) 100%);
      background: -ms-linear-gradient(left, rgba(245,198,140,1) 0%, rgba(253,132,192,1) 100%);
      background: linear-gradient(to right, rgba(245,198,140,1) 0%, rgba(253,132,192,1) 100%);`;

    //Not entirely sure why this next block of code parses and stringifys?
    //Only explanation is that it does a deep clone, but why? No data is being modified below!
    //CLEANUP-TODO: Get rid of the following parse+stringify calls.
    const programs = JSON.parse(
      JSON.stringify(this.state.programs.filter(e => e.published === true))
    );
    const events = JSON.parse(JSON.stringify(this.state.events));
    const online = JSON.parse(
      JSON.stringify(this.state.onlineVirtual.filter(e => e.published === true))
    );

    //this function is only called when the user clicks on one of the programs/virtuals in the calendar!
    //so we don't waste time processing the tens and hundreds of offerings in the partner's roster.
    const getAdditionalInfoForPrograms = p => {
      const { formattedTimeRange, /* formattedDate, */ formattedDateRange } =
        this.getDisplayableDateAndTimeRangeForPrograms(p, true);
      const dateInfo1 =
        (p.number_of_weeks > 0 ? "" : "starting ") + formattedDateRange;
      const dateInfo2 =
        p.days_of_week && Object.keys(p.days_of_week).length > 0
          ? `(every ${getGroupedWeekdayRange(
            p.days_of_week,
              /*useLongNames=*/ true
          )})`
          : undefined;
      // const startingDate = p.start;
      // const endingDate = p.start + p.days_of_week*7;
      //const formattedDateRange = getFormattedDateRange( startingDate, endingDate ); // -------needs to show proper timezone!===== )
      return {
        timeInfo1: formattedTimeRange,
        dateInfo1,
        dateInfo2
      };
    };
    //this function is only called when the user clicks on one of the events in the calendar!
    //so we don't waste time processing the tens and hundreds of offerings in the partner's roster.
    const getAdditionalInfoForEvents = e => {
      const { formattedTimeRange, formattedDateRange } =
        this.getDisplayableDateAndTimeRangeForEvents(e);
      return {
        timeInfo1: formattedTimeRange,
        dateInfo1: formattedDateRange
      };
    };

    const schedulesArr = [];
    let idCounter = 0;

    for (const program of programs) {
      if (program.timing[0] === "-1" || program.timing[1] === "-1") continue;

      let { schedules, seriesFinalDate } = buildScheduleForProduct(
        program.program_start,
        program.number_of_weeks * 7,
        program.days_of_week,
        parseFloat(program.timing[0]),
        parseFloat(program.timing[1]),
        prepareClosureDates(
          program.closure_dates,
          program.closure_timeframe,
          program
        )
      );

      //inject the calculated final date into the program object, because nobody thought to create this field in the first place!
      program.program_final_date = seriesFinalDate;

      schedules = schedules.map(
        ({ hasNoDuration, startDateTime, endDateTime }) => ({
          isReadOnly: true,
          start: startDateTime,
          end: endDateTime,
          calendarId: 1, //<---can be used in the future to segment programs/events/online-virtuals, and by age group, etc.
          category: "time",
          customStyle: this.themes[program.program_color || 0].trim(),
          title: (hasNoDuration ? "Start: " : "") + program.program_name,
          programId: program.id,
          id: ++idCounter, //schedulesArr.length + 1,
          description: program.description,
          bgColor: "transparent",
          borderColor: "transparent",
          color: "#000",
          //isOnline: false,
          getAdditionalInfo: () => getAdditionalInfoForPrograms(program) //only called when popup is shown in the calendar.
        })
      );
      schedulesArr.push(...schedules);
    }

    for (const event of events) {
      //below, we use "makeUTCDateTimeAsLocalTZ" to keep the calendar consistent with the timezone of the partner.
      //if the partner created the event in Eastern Time, it will be consistently Eastern Time wherever in the world
      //you view the calendar from.
      schedulesArr.push({
        isReadOnly: true,
        start: makeUTCDateTimeAsLocalTZ(event.event_start, event.timezone),
        end: makeUTCDateTimeAsLocalTZ(event.event_end, event.timezone),
        calendarId: 1, //<---can be used in the future to segment programs/events/online-virtuals, and by age group, etc.
        category: "time",
        customStyle: customStyleForEvents,
        title: event.event_title,
        eventId: event.id,
        id: ++idCounter, //schedulesArr.length + 1,
        description: event.description,
        bgColor: "transparent",
        borderColor: "transparent",
        color: "#000",
        getAdditionalInfo: () => getAdditionalInfoForEvents(event) //only called when popup is shown in the calendar.
      });
    }

    for (const program of online) {
      if (program.timing[0] === "-1" || program.timing[1] === "-1") continue;

      let { schedules, seriesFinalDate } = buildScheduleForProduct(
        program.program_start,
        program.number_of_weeks * 7,
        program.days_of_week,
        parseFloat(program.timing[0]),
        parseFloat(program.timing[1]),
        prepareClosureDates(
          program.closure_dates,
          program.closure_timeframe,
          program
        )
      );

      //inject the calculated final date into the program object, because nobody thought to create this field in the first place!
      program.program_final_date = seriesFinalDate;

      schedules = schedules.map(
        // eslint-disable-next-line no-loop-func
        ({ hasNoDuration, startDateTime, endDateTime }) => ({
          isReadOnly: true,
          start: startDateTime,
          end: endDateTime,
          calendarId: 1, //<---can be used in the future to segment programs/events/online-virtuals, and by age group, etc.
          category: "time",
          customStyle: this.themes[program.program_color || 0].trim(),
          title: (hasNoDuration ? "Start: " : "") + program.program_name,
          programId: program.id,
          id: ++idCounter, //schedulesArr.length + 1,
          description: program.description,
          bgColor: "transparent",
          borderColor: "transparent",
          color: "#000",
          isOnline: true,
          getAdditionalInfo: () => getAdditionalInfoForPrograms(program) //only called when popup is shown in the calendar.
        })
      );
      schedulesArr.push(...schedules);
    }
    return schedulesArr;
  }

  async donate(e, endPnt) {
    e.preventDefault();
    const ep = endPnt || `${process.env.REACT_APP_API}/partners/leads/donate`;
    const results = await axios.post(ep, {
      Form: this.state.forms[this.state.selectedForm],
      data: this.state.formData
    });
    if (results.data.success) {
      toast.success(`Donation Successful! Thank you!`);
      const prevSelectedForm = this.state.selectedForm;
      this.setState({ formData: {}, selectedForm: 0 });
      this.setState({ selectedForm: prevSelectedForm });
    } else {
      toast.error(results.data.message);
    }
  }

  async subForm(e, endPnt) {
    e.preventDefault();

    const ep = endPnt || `${process.env.REACT_APP_API}/partners/leads`;
    const results = await axios.post(ep, {
      Form: this.state.forms[this.state.selectedForm],
      data: this.state.formData
      // sectionId: this.state.forms[this.state.selectedForm].sections[0].id,
    });

    if (!results.data.success) {
      toast.error(
        `If you are an existing user with an account, please reach out to the organization directly`
      );
    }
    const selectedForm = endPnt ? 1 : 0;
    if (results.data.success) {
      toast.success(`Form Submitted`);
      const prevSelectedForm = this.state.selectedForm;
      this.setState({ formData: {}, selectedForm: selectedForm });
      this.setState({ selectedForm: prevSelectedForm });
    }
  }

  handleTimeClick(element, i, secId, label) {
    this.setState({
      formData: {
        ...this.state.formData,
        [secId + label]: new Date(
          new Date(this.state.formData[secId + "Appointment Date"]).setHours(
            new Date(element).getHours()
          )
        )
      },
      selectedAppointmentTimeIndex: i
    });
  }

  setDay(day, label) {
    const days = { ...this.state.days };
    if (days[day]) delete days[day];
    else days[day] = true;

    this.setState({
      formData: {
        ...this.state.formData,
        [label]: days
      },
      [label]: days
    });
  }

  async componentDidMount() {
    if (this.props.match.params.slug) {
      let ep = `${process.env.REACT_APP_API}/partners/search/profile/${this.props.match.params.slug}`;
      this.setGlobal({ loading: true });
      const results = await axios.get(ep);

      if (results.data.success) {
        // try {
        //   await this.dispatch.getCart({ partnerId: results.data.data.data.id });
        // } catch (e) {
        //   console.error("Failed to set cart", e);
        // }
        this.setState({
          data: results.data.data.data,
          events: results.data.data.events
            .filter(f => !f.isArchived && f.published)
            .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
            .sort((a, b) => a.display_order - b.display_order),
          parties: results.data.data.parties
            .filter(f => !f.isArchived && f.published)
            .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
            .sort((a, b) => a.display_order - b.display_order),
          memberships: results.data.data.memberships
            .filter(f => !f.isArchived)
            .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
            .sort((a, b) => a.display_order - b.display_order),
          programs: results.data.data.programs
            .filter(f => !f.isArchived && f.published)
            .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
            .sort((a, b) => a.display_order - b.display_order),
          Registration: results.data.data.form,
          news: results.data.data.news
            .filter(f => !f.isArchived)
            .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)),
          forms: results.data.data.forms.filter(
            e =>
              e.form_name === "Contact-Us" ||
              e.form_name === "Donation" ||
              e.form_name === "Appointments" ||
              e.form_name === "Survey" ||
              e.form_name === "Registration" ||
              e.form_name === "Waiver"
          ),
          gifts: results.data.data.gifts.filter(f => !f.isArchived),
          availability: results.data.data.availability,
          onlineVirtual: results.data.data.virtual
            .filter(f => !f.isArchived && f.published)
            .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
            .sort((a, b) => a.display_order - b.display_order),
          sublinks: {
            // Programs: [], //results.data.data.sublinks,
            Forms: results.data.data.forms
              .filter(
                e =>
                  e.form_name === "Contact-Us" ||
                  e.form_name === "Donation" ||
                  e.form_name === "Appointments" ||
                  e.form_name === "Survey" ||
                  e.form_name === "Registration" ||
                  e.form_name === "Waiver"
              )
              .map(e => e.form_name + ` (${e.form_sub_name})`)
          },
          locations: [
            {
              lat: results.data.data.data.main_lat,
              lng: results.data.data.data.main_long
            },
            ...results.data.data.data.other_locations.map(e => ({
              lat: e.lat,
              lng: e.lng,
              visible: e.visible
            }))
          ]
        });

        // Set the active tab to the leftmost tab.
        const data = this.state.data;
        const tabs = [
          { value: "Programs", display: data.display_program_listings },
          { value: "Events", display: data.display_event_listings },
          { value: "Online", display: data.display_online_listings },
          { value: "Memberships", display: data.display_membership_listings },
          { value: "Parties", display: data.display_party_listings },
          { value: "Gifts", display: data.display_gifts_listings },
          { value: "Promotions", display: data.display_promotions_listings }
        ];
        let activeTab = "";
        for (const { value, display } of tabs) {
          activeTab = value;
          if (display) {
            break;
          }
        }
        this.setState({ active: activeTab || "Programs" });

        this.setGlobal({ loading: false });
      } else {
        this.setGlobal({ loading: false });
        this.props.history.push("/");
      }
    }
  }

  getFields(fieldarr, form_section_name) {
    let fields = fieldarr.filter(e => JSON.parse(e).visible);
    if (form_section_name === "Appointment Request") {
      let parsedFields = fields.map(e => JSON.parse(e));
      let indexOfFieldAppt;
      let indexOfFieldTime;
      const findIndex = parsedFields.map((e, i) => {
        if (e.field === "Appt") {
          indexOfFieldAppt = i;
        }
        if (e.field === "Time") {
          indexOfFieldTime = i;
        }
      });
      const ApptDate = parsedFields.splice(indexOfFieldAppt, 1);
      const ApptTime = parsedFields.splice(indexOfFieldTime - 1, 1);

      parsedFields.splice(0, 0, ApptTime[0]);
      parsedFields.splice(0, 0, ApptDate[0]);

      const stringifyFields = parsedFields.map(e => JSON.stringify(e));
      fields = stringifyFields;
    }

    const arr = [];
    for (let i = 0; i < fields.length; i += 2) {
      if (i + 1 < fields.length) {
        arr.push([fields[i], fields[i + 1]]);
        continue;
      }
      if (i < fields.length) {
        arr.push([fields[i]]);
      }
      if (i >= fields.length) {
        break;
      }
    }
    return arr;
  }

  displaySecondaryTabs = () => {
    let display = false;
    // if (
    //   this.state.active === "Programs" &&
    //   this.state.data.display_program_listings
    // ) {
    //   display = true;
    // }
    if (
      this.state.active === "Forms" &&
      this.state.data.display_forms_listings
    ) {
      display = true;
    }

    return display;
  };

  getDisplayableDateAndTimeRangeForPrograms = (p, alsoDateRange) => {
    let { dateOnlyWithTZ, formattedDate } = constructDateOnlyWithTimeZone(
      p.program_start,
      p.timezone
    );

    let formattedTimeRange = "--";
    if (
      p.timing &&
      p.timing.length === 2 &&
      p.timing[0] !== "-1" &&
      p.timing[1] !== "-1"
    ) {
      // timezone_offset is a deprecated column and marked for removal in the future!
      const timezone_offset = p.timezone_offset || 0;
      //column was only available for online virtual products! So flag it, if non-zero or non-null!
      console.assert(
        timezone_offset === 0,
        `expecting timezone_offset column for OnlineVirtual#${p.id} to be zero or null!`
      );

      //Below, "dateOnlyWithTZ" is used to frame the time ranges within the correct timezone.
      let { startTime, endTime } = constructTimeRangeFromDecimalHours(
        parseFloat(p.timing[0]) -
        timezone_offset /*@@DEPRECATED@@: Will remove timezone_offset column in the future!*/,
        parseFloat(p.timing[1]) -
        timezone_offset /*@@DEPRECATED@@: Will remove timezone_offset column in the future!*/,
        dateOnlyWithTZ
      );
      formattedTimeRange = getFormattedTimeRange(startTime, endTime);
    }

    if (alsoDateRange && !p.isRecurring) {
      const formattedDateRange = getFormattedDateRange(
        dateOnlyWithTZ,
        addWeeks(new Date(p.program_start), p.number_of_weeks),
        " to ",
        true
      );
      return { formattedTimeRange, formattedDate, formattedDateRange };
    } else {
      return { formattedTimeRange, formattedDate };
    }
  };

  getDisplayableDateAndTimeRangeForEvents = e => {
    //Changes were made in the API to return IANA timezone info (eg. America/Toronto) with every product listing.
    console.assert(e.timezone, "timezone not provided for partner/educator!");

    //For events, server+database stores the time UTC+0 timezone, eg. 2021-10-01 T 04:00:00.000 Z
    const eventStart = DateTime.fromISO(e.event_start);
    const eventEnd = DateTime.fromISO(e.event_end);

    //Now we set the timezone to that of the partner!             e.g. 2021-10-01 T 00:00:00.000 -04:00
    //...and it makes appropriate adjustment if it's not daylight time, eg. -05:00
    const rezonedStart = eventStart.setZone(e.timezone);
    const rezonedEnd = eventEnd.setZone(e.timezone);

    //Tried the following 2 lines -- now commented out! They don't work! Possibly due to older Luxon version.
    //const rezonedStart = DateTime.fromISO( e.event_start, {zone:e.timezone, setZone:true} );
    //const rezonedEnd = DateTime.fromISO( e.event_end, {zone:e.timezone, setZone:true} );

    const dateObjStart = new Date(rezonedStart);
    const dateObjEnd = new Date(rezonedEnd);

    let formattedTimeRange = "";
    let formattedDateRange = getFormattedDateRange(rezonedStart, rezonedEnd);
    if (isSameDay(dateObjStart, dateObjEnd)) {
      formattedTimeRange = getFormattedTimeRange(format(dateObjStart, "p"), format(dateObjEnd, "p"));
    } else {
      formattedDateRange = "";
      const formattedStart = format(dateObjStart, "p").toLocaleString(DateTime.TIME_SIMPLE);
      const formattedEnd = format(dateObjEnd, "p").toLocaleString({...DateTime.TIME_SIMPLE, timeZoneName: "short"});
      if (isSameYear(dateObjStart, dateObjEnd))
        formattedTimeRange = `${formattedStart} on ${format(dateObjStart, "LLL d")} to ${formattedEnd} on ${format(dateObjEnd, "LLL d")}`;
      else
        formattedTimeRange = `${formattedStart} on ${format(dateObjStart, "LLL d, yyyy")} to ${formattedEnd} on ${format(dateObjEnd, "LLL d, yyyy")}`;
    }

    return {
      formattedTimeRange,
      formattedDateRange
    };
  };

  getDisplayableDateAndTimeRangeForParties = e => {
    //Changes were made in the API to return IANA timezone info (eg. America/Toronto) with every product listing.
    console.assert(e.timezone, "timezone not provided for partner/educator!");

    //For parties, server+database stores the time UTC+0 timezone, eg. 2021-10-01 T 04:00:00.000 Z
    const partyStart = DateTime.fromISO(e.party_start);
    const partyEnd = DateTime.fromISO(e.party_end);

    //Now we set the timezone to that of the partner!             e.g. 2021-10-01 T 00:00:00.000 -04:00
    //...and it makes appropriate adjustment if it's not daylight time, eg. -05:00
    const rezonedStart = partyStart.setZone(e.timezone);
    const rezonedEnd = partyEnd.setZone(e.timezone);

    const dateObjStart = new Date(rezonedStart);
    const dateObjEnd = new Date(rezonedEnd);

    let formattedTimeRange = "";
    let formattedDateRange = getFormattedDateRange(rezonedStart, rezonedEnd);
    if (isSameDay(dateObjStart, dateObjEnd)) {
      formattedTimeRange = getFormattedTimeRange(format(dateObjStart, "p"), format(dateObjEnd, "p"));
    } else {
      formattedDateRange = ""
      const formattedStart = format(dateObjStart, "p").toLocaleString(DateTime.TIME_SIMPLE);
      const formattedEnd = format(dateObjEnd, "p").toLocaleString({...DateTime.TIME_SIMPLE, timeZoneName: "short"});
      if (isSameYear(dateObjStart, dateObjEnd))
        formattedTimeRange = `${formattedStart} on ${format(dateObjStart, "LLL d")} to ${formattedEnd} on ${format(dateObjEnd, "LLL d")}`;
      else
        formattedTimeRange = `${formattedStart} on ${format(dateObjStart, "LLL d, yyyy")} to ${formattedEnd} on ${format(dateObjEnd, "LLL d, yyyy")}`;
    }
    //Tried the following 2 lines -- now commented out! They don't work! Possibly due to older Luxon version.
    //const rezonedStart = DateTime.fromISO( e.party_start, {zone:e.timezone, setZone:true} );
    //const rezonedEnd = DateTime.fromISO( e.party_end, {zone:e.timezone, setZone:true} );

    return {
      formattedTimeRange,
      formattedDateRange
    };
  };

  getStartingPrice = prices => {
    try {
      const plans = prices.filter(e => !JSON.parse(e).archived);
      const lowestPlan = plans.sort(
        (a, b) => JSON.parse(a).price_per_term - JSON.parse(b).price_per_term
      )[0];
      return JSON.parse(lowestPlan).price_per_term;
    } catch (e) {
      return null;
    }
  };

  render() {
    const { slug } = this.props.match.params;

    if (!this.state.data || this.state.locations.length === 0) {
      return null;
    }

    console.assert(
      this.state.data.timezone,
      "timezone not provided for partner/educator!"
    );
    let { dateOnlyWithTZ } = constructDateOnlyWithTimeZone(
      null,
      this.state.data.timezone
    );

    /* Below, "dateOnlyWithTZ" is used to frame the time ranges within the correct timezone.  */
    let { startTime, endTime } = constructTimeRangeFromDecimalHours(
      parseFloat(this.state.data.timing[0]),
      parseFloat(this.state.data.timing[1]),
      dateOnlyWithTZ
    );
    let partnerOpenHours = getFormattedTimeRange(startTime, endTime);

    let partnerOpenDays = getGroupedWeekdayRange(this.state.data.days_of_week);

    return (
      <>
        <ReactBnbGallery
          show={this.state.galleryShown}
          photos={this.state.data.gallery.map(
            e => `${process.env.REACT_APP_DS_FILES_S3 + "/" + e}`
          )}
          onClose={this.openGallery}
        />
        {this.state.isOpenShareButtonModal && (
          <ShareButtonModal
            onClose={() => {
              this.setState({
                ...this.state,
                isOpenShareButtonModal: false
              })
              console.log("HERE", this.props.history.location.pathname)
              console.log((process.env.NODE_ENV === 'development' ? "http://localhost:3002" : process.env.REACT_APP_MODE === "staging" ? "https://staging.dreamschools.com" : "https://dreamschools.com")
                + this.props.history.location.pathname)
            }}
            url={(process.env.NODE_ENV === 'development' ? "http://localhost:3002" : process.env.REACT_APP_MODE === "staging" ? "https://staging.dreamschools.com" : "https://dreamschools.com")
              + this.props.history.location.pathname}
            // quote={"HELLO"}
            hashtag={"#DreamSchools"}
          // description={"DESCRIPTION STUFF"}
          />
        )}
        {this.state.openSignModal && (
          <SignModal
            onClose={_ => this.setState({ openSignModal: false })}
            complete={img =>
              this.setState({
                formData: { ...this.state.formData, [this.state.signVar]: img }
              })
            }
          />
        )}
        <div
          className="container-fluid header-img"
          style={{
            backgroundImage: `url(${process.env.REACT_APP_DS_FILES_S3}/${this.state.data.bcg_image})`,
            backgroundPosition: "50%",
            backgroundBlendMode: "multiply",
            backgroundSize: "cover",
            backgroundColor: "rgba(0,0,255,.2)"
          }}
        ></div>

        <div className="content-area">
          {/*Header Portion */}
          <div className="top-content">
            <div className="content-display">
              <div className="left-content">
                <div className="previewdivs">
                  <div>
                    <h1 className="title">
                      {this.state.data.organization_title}
                    </h1>

                    <h1 className="title-inner">
                      {this.state.data.organization_title}
                    </h1>

                    <ul className="button-options">
                      <div className="main-btns">
                        <li className="button-list">
                          <button
                            className="partnerbutton"
                            onClick={this.openGallery}
                          >
                            Gallery
                          </button>
                        </li>
                        {this.state.data.website_url && (
                          <li className="button-list">
                            <button
                              className="partnerbutton"
                              onClick={_ =>
                                window.open(
                                  this.state.data.website_url.includes(
                                    "http://"
                                  ) ||
                                    this.state.data.website_url.includes(
                                      "https://"
                                    )
                                    ? this.state.data.website_url
                                    : "http://" + this.state.data.website_url,
                                  "_blank"
                                )
                              }
                            >
                              Website
                            </button>
                          </li>
                        )}
                        <li className="button-list">
                          <button
                            className="partnerbutton"
                            onClick={() => {
                              this.setState({
                                ...this.state,
                                isOpenShareButtonModal: true,
                              })
                            }}
                          >
                            Share
                          </button>
                        </li>
                      </div>
                      <div className="icon-btns">
                        {this.state.data.twitter && (
                          <li className="button-list">
                            <div
                              className="twitterbcg"
                              onClick={_ =>
                                window.open(
                                  this.state.data.twitter.includes("http://") ||
                                    this.state.data.twitter.includes("https://")
                                    ? this.state.data.twitter
                                    : "http://" + this.state.data.twitter,
                                  "_blank"
                                )
                              }
                            ></div>
                          </li>
                        )}
                        {this.state.data.instagram && (
                          <li className="button-list">
                            <div
                              className="igbcg"
                              onClick={_ =>
                                window.open(
                                  this.state.data.instagram.includes(
                                    "http://"
                                  ) ||
                                    this.state.data.instagram.includes(
                                      "https://"
                                    )
                                    ? this.state.data.instagram
                                    : "http://" + this.state.data.instagram,
                                  "_blank"
                                )
                              }
                            ></div>
                          </li>
                        )}
                        {this.state.data.facebook && (
                          <li className="button-list">
                            <div
                              className="fbbcg"
                              onClick={_ =>
                                window.open(
                                  this.state.data.facebook.includes(
                                    "http://"
                                  ) ||
                                    this.state.data.facebook.includes(
                                      "https://"
                                    )
                                    ? this.state.data.facebook
                                    : "http://" + this.state.data.facebook,
                                  "_blank"
                                )
                              }
                            ></div>
                          </li>
                        )}
                      </div>
                    </ul>
                  </div>
                </div>
                <div className="text-area">
                  <div>
                    <p className="text-description">
                      {this.state.data.description}
                    </p>

                    {/* Time Open */}
                    <div className="icon-grid-view">
                      <div
                        className="box icon-containers"
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "flex-start"
                        }}
                      >
                        <img
                          src={LiveView1}
                          alt="Icon"
                          className="middle-icon-img"
                        />
                        <div>
                          <p className="icon-name">OPEN</p>
                          <p className="icon-inner-text">{partnerOpenDays}</p>
                          <p className="icon-inner-text">{partnerOpenHours}</p>
                        </div>
                      </div>

                      {/* Target Ages */}
                      <div className="box icon-containers">
                        <img
                          src={LiveView2}
                          alt="Icon"
                          className="middle-icon-img"
                        />
                        <div>
                          <p className="icon-name">AGE</p>
                          <p className="icon-inner-text">
                            {this.state.data.target_ages
                              .map(f => JSON.parse(f).name)
                              .join(", ")}
                          </p>
                        </div>
                      </div>

                      {/* Category */}
                      <div className="box icon-containers">
                        <img
                          src={LiveView3}
                          alt="Icon"
                          className="middle-icon-img"
                        />
                        <div>
                          <p className="icon-name">CATEGORY</p>
                          {this.state.data.program_types.map((e, i) => {
                            const element = JSON.parse(e);
                            return (
                              <p className="icon-inner-text" key={i}>
                                {element.name}
                              </p>
                            );
                          })}
                          <p className="icon-inner-text">
                            {this.state.data.format === "Both"
                              ? "In-Person, Online"
                              : this.state.data.format}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="data display-icons">
                      {/* Gallery */}
                      <div className="box-img">
                        {this.state.data.gallery.slice(0, 3).map((e, i) => (
                          <LazyLoadImage
                            src={`${process.env.REACT_APP_DS_FILES_S3}/${e}`}
                            alt="Gallery"
                            className="gallery-img"
                            key={i}
                            onClick={this.openGallery}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Google Map */}
              <div className="map-container">
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyAVaN8qvELLoX3nYdqFC91GqbxXLcV-cig"
                  }}
                  defaultZoom={9}
                  defaultCenter={{
                    lat: parseFloat(this.state.locations[0].lat),
                    lng: parseFloat(this.state.locations[0].lng)
                  }}
                  options={{ gestureHandling: "greedy" }}
                >
                  {this.state.locations
                    .filter(
                      loc => loc.visible === true || loc.visible === undefined
                    )
                    .map(e => (
                      <GmapNodeMarker lat={e.lat} lng={e.lng} />
                    ))}
                </GoogleMapReact>
              </div>
            </div>

            {/* 
  =================================================================================================
  Start of Tab buttons
  =================================================================================================
*/}
            {/* Tab pages */}
            <div className="tab-headers">
              <Tabs
                value={this.state.active}
                onChange={(event, newValue) =>
                  this.setState({ active: newValue })
                }
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                TabIndicatorProps={{
                  className: this.props.classes.tabIndicator
                }}
                classes={{ 
                  root: this.props.classes.tabsHeadings,
                }}
              >
                {this.state.data.display_program_listings && (
                  <Tab
                    label="PROGRAMS"
                    value="Programs"
                    disableRipple
                    disableFocusRipple
                    classes={{
                      root: this.props.classes.tabRoot,
                      selected: this.props.classes.tabSelected
                    }}
                  />
                )}
                {this.state.data.display_event_listings && (
                  <Tab
                    label="EVENTS"
                    value="Events"
                    disableRipple
                    disableFocusRipple
                    classes={{
                      root: this.props.classes.tabRoot,
                      selected: this.props.classes.tabSelected
                    }}
                  />
                )}
                {this.state.data.display_online_listings && (
                  <Tab
                    label="ONLINE"
                    value="Online"
                    disableRipple
                    disableFocusRipple
                    classes={{
                      root: this.props.classes.tabRoot,
                      selected: this.props.classes.tabSelected
                    }}
                  />
                )}
                {this.state.data.display_membership_listings && (
                  <Tab
                    label="MEMBERSHIP"
                    value="Memberships"
                    disableRipple
                    disableFocusRipple
                    classes={{
                      root: this.props.classes.tabRoot,
                      selected: this.props.classes.tabSelected
                    }}
                  />
                )}
                {this.state.data.display_party_listings && (
                  <Tab
                    label="PARTIES"
                    value="Parties"
                    disableRipple
                    disableFocusRipple
                    classes={{
                      root: this.props.classes.tabRoot,
                      selected: this.props.classes.tabSelected
                    }}
                  />
                )}
                {this.state.data.display_gifts_listings && (
                  <Tab
                    label="GIFT CARDS"
                    value="Gifts"
                    disableRipple
                    disableFocusRipple
                    classes={{
                      root: this.props.classes.tabRoot,
                      selected: this.props.classes.tabSelected
                    }}
                  />
                )}
                {this.state.data.display_promotions_listings && (
                  <Tab
                    label="PROMOTIONS"
                    value="Promotions"
                    disableRipple
                    disableFocusRipple
                    classes={{
                      root: this.props.classes.tabRoot,
                      selected: this.props.classes.tabSelected
                    }}
                  />
                )}
                {/* {this.state.data.display_forms_listings && (
                  <Tab
                    label="FORMS"
                    value={
                      this.state.active === "Forms"
                        ? this.state.selectedForm
                        : this.state.selectedProgram
                    }
                    onChange={(event, newValue) =>
                      this.state.active === "Forms"
                        ? this.setState({ selectedForm: newValue })
                        : this.setState({ selectedProgram: newValue })
                    }
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    classes={{
                      root: this.props.classes.tabRoot,
                      selected: this.props.classes.tabSelected
                    }}
                  >
                    <Tabs>123</Tabs>
                  </Tab>
                )} */}
              </Tabs>
            </div>
          </div>

          {/* 
  =================================================================================================
  Start of "News" Tab
  =================================================================================================
*/}
          {this.state.active === "News" && this.state.article ? (
            <div>
              <div
                style={{
                  height: "100px",
                  backgroundImage: `url("${process.env.REACT_APP_DS_FILES_S3}/${this.state.article.headerimage}")`,
                  backgroundPosition: "50%",
                  backgroundBlendMode: "multiply",
                  backgroundSize: "cover",
                  backgroundColor: "rgba(0,0,255,.2)"
                }}
              ></div>

              <h3 style={{ textAlign: "center", marginBottom: 0 }}>
                {this.state.article.title}
              </h3>

              <p
                className="small"
                style={{ textAlign: "center", color: "lightgrey" }}
              >
                {format(new Date(this.state.article.date), "LLLL dd, yyyy")}
              </p>

              {this.state.article.mainbody.split("\n").map(e => (
                <p
                  style={{ fontWeight: "bold", width: "80%", margin: "0 auto" }}
                >
                  {e} <br />
                </p>
              ))}

              <div
                style={{
                  width: "80%",
                  margin: "0 auto",
                  display: "flex",
                  justifyContent: "space-between",
                  maxHeight: "200px"
                }}
              >
                {this.state.article.middlegallery.map(e => (
                  <LazyLoadImage
                    src={`${process.env.REACT_APP_DS_FILES_S3}/${e}`}
                    width="25%"
                    alt="Gallery photos"
                  />
                ))}
              </div>

              {this.state.article.bottomsection.split("\n").map(e => (
                <p
                  style={{ fontWeight: "bold", width: "80%", margin: "0 auto" }}
                >
                  {e} <br />
                </p>
              ))}

              <div
                style={{
                  width: "80%",
                  margin: "0 auto",
                  display: "flex",
                  justifyContent: "space-between",
                  maxHeight: "200px"
                }}
              >
                {this.state.article.bottomgallery.map(e => (
                  <LazyLoadImage
                    src={`${process.env.REACT_APP_DS_FILES_S3}/${e}`}
                    width="25%"
                    alt="Gallery photos"
                  />
                ))}
              </div>
              {this.state.article.additional_text.split("\n").map(e => (
                <p
                  style={{ fontWeight: "bold", width: "80%", margin: "0 auto" }}
                >
                  {e} <br />
                </p>
              ))}
              <div
                style={{
                  width: "80%",
                  margin: "0 auto",
                  display: "flex",
                  justifyContent: "space-between",
                  maxHeight: "200px"
                }}
              >
                {this.state.article.additional_gallery.map(e => (
                  <LazyLoadImage
                    src={`${process.env.REACT_APP_DS_FILES_S3}/${e}`}
                    width="25%"
                    alt="Gallery photos"
                  />
                ))}
              </div>
            </div>
          ) : (
            // Card Section - Programs
            <div className="centuryfont card-container">
              {/* 
  =================================================================================================
  Start of "Programs" Tab
  =================================================================================================
*/}
              {this.state.active === "Programs" &&
                this.state.data.display_program_listings &&
                (this.state.programs.length > 0 ? (
                  this.state.programs
                    .filter(e => e.published)
                    .map(e => {
                      const { formattedTimeRange, formattedDate, formattedDateRange } =
                        this.getDisplayableDateAndTimeRangeForPrograms(e, true);

                      const dateText = e.isRecurring 
                        ? `Start Date: ${formattedDate} (Recurring)`
                        : formattedDateRange
                        
                      // Get list of sorted prices per week.
                      const planTypes = [];
                      let prices = e.plans
                        .map(f => JSON.parse(f))
                        .filter(plan => !plan.archived)
                        .filter(plan => {
                          if (plan.hasOwnProperty("active")) {
                            return plan.active;
                          }
                          return true;
                        })
                        .map(plan => {
                          if (!plan.type && !planTypes.includes("Recurring")) {
                            planTypes.push("Recurring");
                          } else if (plan.type && !planTypes.includes(plan.type) && plan.type !== "Free") {
                            planTypes.push(plan.type);
                          }

                          if (plan.type === "Free" || plan.type === "Free Trial") {
                            return 0;
                          } else if (
                            plan.type === "Semester" ||
                            plan.type === "One-Time" ||
                            plan.type === "Single Sessions" ||
                            plan.type === "Drop-In"
                          ) {
                            const price = parseFloat(plan.total_price) * (plan.premium ? 1 + parseFloat(plan.premium) / 100 : 1);
                            return parseInt(price);
                          }
                          // Recurring programs - normalize price -> $/wk
                          else {
                            return parseInt(plan.tuition_rate);
                          }
                        })
                        .sort((a, b) => a - b);

                      let priceText;
                      if (prices.length === 1) {
                        if (prices[0] === 0) {
                          priceText = "Free";
                        } else {
                          priceText = `$${prices[0]}`;
                        }
                      } else if (prices.length >= 2) {
                        priceText = `$${prices[0]} to $${prices[prices.length - 1]
                          }`;
                      }

                      return (
                          <ListingCard
                            type="program"
                            id={e.id}
                            key={e.id}
                            listingName={e.program_name}
                            image={e.program_photo}
                            description={e.description}
                            ageText={e.all_ages ? "All ages welcome!" : `Ages ${e.age_range[0]} to ${e.age_range[1]} yrs`}
                            days_of_week={e.days_of_week}
                            time={formattedTimeRange}
                            date={dateText}
                            priceText={priceText}
                            planTypes={planTypes}
                            slug={slug}
                            history={this.props.history}
                          />
                      );
                    })
                ) : (
                  <NoDataAvailable message="No Programs Available" />
                ))}

              {/* 
  =================================================================================================
  Start of "Online" Tab
  =================================================================================================
*/}
              {this.state.active === "Online" &&
                (this.state.onlineVirtual.length > 0 ? (
                  this.state.onlineVirtual
                    .filter(e => e.published)
                    .map(e => {
                      const { formattedTimeRange, formattedDate, formattedDateRange } =
                        this.getDisplayableDateAndTimeRangeForPrograms(e, true);


                      const dateText = e.isRecurring 
                        ? `Start Date: ${formattedDate} (Recurring)`
                        : formattedDateRange
                   
                      const planTypes = [];
                      let prices = e.plans
                        .map(f => JSON.parse(f))
                        .filter(plan => !plan.archived)
                        .filter(plan => {
                          if (plan.hasOwnProperty("active")) {
                            return plan.active;
                          }
                          return true;
                        })
                        .map(plan => {
                          if (!plan.type && !planTypes.includes("Recurring")) {
                            planTypes.push("Recurring");
                          } else if (plan.type && !planTypes.includes(plan.type) && plan.type !== "Free") {
                            planTypes.push(plan.type);
                          }

                          if (plan.type === "Free") {
                            return 0;
                          } else if (
                            plan.type === "Semester" ||
                            plan.type === "One-Time" ||
                            plan.type === "Single Sessions"
                          ) {
                            return parseInt(plan.total_price);
                          }
                          // Recurring programs - normalize price -> $/wk
                          else {
                            return parseInt(plan.tuition_rate);
                          }
                        })
                        .sort((a, b) => a - b);

                      let priceText;
                      if (prices.length === 1) {
                        if (prices[0] === 0) {
                          priceText = "Free";
                        } else {
                          priceText = `$${prices[0]}`;
                        }
                      } else if (prices.length >= 2) {
                        priceText = `$${prices[0]} to $${prices[prices.length - 1]
                          }`;
                      }

                      return (
                        <ListingCard
                          type="virtual"
                          id={e.id}
                          key={e.id}
                          listingName={e.program_name}
                          image={e.program_photo}
                          description={e.description}
                          ageText={e.all_ages ? "All ages welcome!" : `Ages ${e.age_range[0]} to ${e.age_range[1]} yrs`}
                          days_of_week={e.days_of_week}
                          time={formattedTimeRange}
                          date={dateText}
                          priceText={priceText}
                          planTypes={planTypes}
                          slug={slug}
                          history={this.props.history}
                        />
                      );
                    })
                ) : (
                  <NoDataAvailable message="No Online Programs Available" />
                ))}

              {/* Card Section - Memberships */}

              {/* 
  =================================================================================================
  Start of "Memberships" Tab
  =================================================================================================
*/}
              {this.state.active === "Memberships" &&
                (this.state.memberships.length > 0 ? (
                  this.state.memberships
                    .filter(e => e.published)
                    .map(e => (
                      <div
                        className={`${this.props.classes.plan} card-area`}
                        onClick={_ =>
                          this.props.history.push(
                            `/programs/${slug}/membership/${e.id}`
                          )
                        }
                      >
                        <LazyLoadImage
                          src={
                            e.membership_image === 2
                              ? `${process.env.REACT_APP_DS_FILES_S3}/${e.membership_image_url}`
                              : e.membership_image === 0
                                ? MemberShip1
                                : MemberShip2
                          }
                          alt="Membership"
                          className={this.props.classes.cardImg}
                          wrapperClassName="card-img"
                          style={{ width: "100%" }}
                          effect="opacity"
                        />

                        <div className="card-content">
                          <div className="membership-card-sub1">
                            <h4 className="sub1-name">{e.membership_name}</h4>
                            <span className="sub1-age">
                              {e.description.substr(0, 100)}
                              {e.description.length > 100 && "..."}
                            </span>
                          </div>
                          <div className="card-sub2">
                            <h4 className="sub2-timing">Multiple Locations</h4>
                          </div>
                          <div className="card-sub3">
                            <h4 className="sub3-pricing">
                              Starting at{" "}
                              {e.prices.length > 0 &&
                                `$${this.getStartingPrice(e.prices)}`}
                            </h4>
                          </div>
                        </div>
                      </div>
                    ))
                ) : (
                  <NoDataAvailable message="No Memberships Available" />
                ))}

              {/* Card Section - Events */}

              {/* 
  =================================================================================================
  Start of "Events" Tab
  =================================================================================================
*/}
              {this.state.active === "Events" &&
                (this.state.events.length > 0 ? (
                  this.state.events.map(e => {
                    const { formattedTimeRange, formattedDateRange } =
                      this.getDisplayableDateAndTimeRangeForEvents(e);
                    
                    const ticketTypes = [];
                    e.tickets.map(ticket => {
                      const parsedTicket = JSON.parse(ticket);
                      if (parsedTicket.ticket_quantity === 0) {
                        return;
                      }
                      if (!ticketTypes.includes(parsedTicket.ticket_status))
                        ticketTypes.push(parsedTicket.ticket_status);
                    });

                    console.log(e.tickets);
                    const sortedTickets =  e.tickets.sort((a, b) => JSON.parse(a).ticket_price - JSON.parse(b).ticket_price).map(ticket => JSON.parse(ticket));
                    const priceText = sortedTickets.length > 0
                      ? sortedTickets[0].ticket_price == sortedTickets[sortedTickets.length - 1].ticket_price
                        ? sortedTickets[0].ticket_price === 0
                          ? "Free"
                          : `$${sortedTickets[0].ticket_price}`
                        : sortedTickets[0].ticket_price == 0
                          ? `Free to $${sortedTickets[sortedTickets.length - 1].ticket_price}`
                          : `$${sortedTickets[0].ticket_price} to $${sortedTickets[sortedTickets.length - 1].ticket_price}`
                      : "N/A";
                    return (
                      <ListingCard
                        type="event"
                        id={e.id}
                        key={e.id}
                        listingName={e.event_title}
                        image={e.event_image}
                        description={e.description}
                        ageText={e.all_ages ? "All ages welcome!" : `Ages ${e.target_ages[0]} to ${e.target_ages[1]} yrs`}
                        days_of_week={{}}
                        time={formattedTimeRange}
                        date={formattedDateRange}
                        priceText={priceText}
                        location={e.address1}
                        planTypes={ticketTypes}
                        slug={slug}
                        history={this.props.history}
                      />
                    );
                  })
                ) : (
                  <NoDataAvailable message="No Events Available" />
                ))}

              {/* Card Section - Promotions */}

              {/* 
  {/* 
  =================================================================================================
  Start of "Parties" Tab
  =================================================================================================
*/}
              {this.state.active === "Parties" &&
                (this.state.parties.length > 0 ? (
                  this.state.parties.map(e => {
                    const { formattedTimeRange, formattedDateRange } =
                      this.getDisplayableDateAndTimeRangeForParties(e);

                    const ticketTypes = [];
                    e.tickets.map(ticket => {
                      const parsedTicket = JSON.parse(ticket);
                      if (parsedTicket.ticket_quantity === 0) {
                        return;
                      }
                      if (!ticketTypes.includes(parsedTicket.ticket_status))
                        ticketTypes.push(parsedTicket.ticket_status);
                    });

                    const sortedTickets =  e.tickets.sort((a, b) => JSON.parse(a).ticket_price - JSON.parse(b).ticket_price).map(ticket => JSON.parse(ticket));
                    const priceText = sortedTickets.length > 0
                      ? sortedTickets[0].ticket_price === sortedTickets[sortedTickets.length - 1].ticket_price
                        ? sortedTickets[0].ticket_price == 0
                          ? "Free"
                          : `$${sortedTickets[0].ticket_price}`
                        : sortedTickets[0].ticket_price == 0
                          ? `Free to $${sortedTickets[sortedTickets.length - 1].ticket_price}`
                          : `$${sortedTickets[0].ticket_price} to $${sortedTickets[sortedTickets.length - 1].ticket_price}`
                      : "N/A";
                    return (
                      <ListingCard
                        type="party"
                        id={e.id}
                        key={e.id}
                        listingName={e.party_title}
                        image={e.party_image}
                        description={e.description}
                        ageText={e.all_ages ? "All ages welcome!" : `Ages ${e.target_ages[0]} to ${e.target_ages[1]} yrs`}
                        days_of_week={{}}
                        time={formattedTimeRange}
                        date={formattedDateRange}
                        location={e.address1}
                        priceText={priceText}
                        planTypes={ticketTypes}
                        slug={slug}
                        history={this.props.history}
                        headcount={e.headcount}
                      />
                    );
                  })
                ) : (
                  <NoDataAvailable message="No Parties Available" />
                ))}

              {/* Card Section - Promotions */}

              {/* 
  =================================================================================================
  Start of "Promotions" Tab
  =================================================================================================
*/}
              {this.state.active === "Promotions" && (
                <PartnerPromotions
                  classes={this.props.classes}
                  history={this.props.history}
                  params={this.props.match.params}
                />
              )}

              {/* Calendar */}

              {/* 
  =================================================================================================
  Start of "Calendar"
  =================================================================================================
*/}
              {this.state.active === "Calendar" && (
                <div>
                  <CalendarTemp
                    schedules={this.createCalendarSchedules()}
                    slug={slug}
                  />
                </div>
              )}

              {/* 
  =================================================================================================
  Start of "Forms"
  =================================================================================================
*/}
              {this.state.active === "Forms" && (
                <>
                  {this.state.forms[this.state.selectedForm].form_name ===
                    "Appointments" && !this.state.availability ? (
                    <p
                      style={{
                        margin: "0 auto",
                        width: "60%",
                        fontFamily: "Montserrat"
                      }}
                    >
                      Currently Unavailable! Please set your availability!
                    </p>
                  ) : (
                    <form
                      style={{ marginTop: "15px", width: "100%" }}
                      onSubmit={e => {
                        return this.state.forms[this.state.selectedForm]
                          .form_name === "Appointments"
                          ? this.subForm(
                            e,
                            `${process.env.REACT_APP_API}/partners/appts`
                          )
                          : this.state.forms[this.state.selectedForm]
                            .form_name === "Donation"
                            ? this.donate(e)
                            : this.subForm(e);
                      }}
                    >
                      {this.state.forms[this.state.selectedForm].sections
                        .sort((a, b) =>
                          a.id > b.id ? 1 : a.id < b.id ? -1 : 0
                        )
                        .map(sec => (
                          <div
                            style={{ width: "100%", padding: "0 1em 0 1em" }}
                          >
                            <h3
                              style={{
                                margin: 0,
                                color: "rgb(80, 80, 80)",
                                fontFamily: "Open Sans, sans-serif",
                                paddingLeft: "30px",
                                marginBottom: "20px",
                                marginTop: "20px"
                              }}
                            >
                              {sec.form_section_name}
                            </h3>

                            {sec.form_section_name === "Appointment Request"
                              ? this.getFields(
                                sec.fields,
                                sec.form_section_name
                              ).map((row, i) => {
                                // First Renders the appointment date and time
                                if (
                                  this.state
                                    .displayAppointmentRequestFormStep ===
                                  0 &&
                                  i === 0
                                ) {
                                  return (
                                    <div className="appointment-line-container">
                                      <div className="appointment-calendar-box">
                                        {row.map(f => {
                                          const field = JSON.parse(f);
                                          return (
                                            <div className="appointment-form-container">
                                              {field.type === "file"
                                                ? this.state.formData[
                                                  sec.id + field.first_label
                                                ]
                                                  ? "File Uploaded"
                                                  : this.elements(
                                                    field.field,
                                                    field.first_label,
                                                    field.type,
                                                    "100%",
                                                    sec.id,
                                                    field.required
                                                  )
                                                : this.elements(
                                                  field.field,
                                                  field.first_label,
                                                  field.type,
                                                  "100%",
                                                  sec.id,
                                                  field.required,
                                                  field.options
                                                )}
                                            </div>
                                          );
                                        })}
                                      </div>
                                      <div className="appointment-nxt-btn">
                                        <div
                                          style={{
                                            border: "none",
                                            fontFamily:
                                              "Open Sans, sans-serif",
                                            padding: "0.5em 2.5em",
                                            fontWeight: "700",
                                            backgroundColor: "rgb(32 63 167)",
                                            fontSize: "0.9em",
                                            color: "white",
                                            marginBottom: "10px",
                                            cursor: "pointer",
                                            marginTop: "35px"
                                          }}
                                          onClick={_ => {
                                            if (
                                              Object.keys(this.state.formData)
                                                .length === 0
                                            ) {
                                              toast.error(
                                                "Please select a date and time"
                                              );
                                              return;
                                            }
                                            if (
                                              this.state.formData[
                                              Object.keys(
                                                this.state.formData
                                              )[1]
                                              ] === ""
                                            ) {
                                              toast.error(
                                                "Please select a time"
                                              );
                                              return;
                                            }
                                            this.setState({
                                              displayAppointmentRequestFormStep: 1
                                            });
                                          }}
                                        >
                                          Next
                                        </div>
                                      </div>
                                    </div>
                                  );
                                } else if (
                                  // Then Renders the other fields in the next step
                                  this.state
                                    .displayAppointmentRequestFormStep ===
                                  1 &&
                                  i !== 0
                                ) {
                                  if (row.length === 2 && i === 2) {
                                    // renders the item when the getfields array has a length of 2 and rof of length 2
                                    // renders the item when the getfields array has a length of 1: DEPRECATED
                                    return (
                                      <div>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center"
                                          }}
                                        >
                                          {row.map(f => {
                                            const field = JSON.parse(f);

                                            return (
                                              <div
                                                style={{
                                                  width: "100%",
                                                  alignItems: "center",
                                                  marginBottom: "20px",
                                                  padding: "0 30px 0 30px"
                                                }}
                                              >
                                                <label
                                                  htmlFor={field.first_label}
                                                  style={{
                                                    color: "#696868",
                                                    margin: "0 0px 0px 0px",
                                                    fontWeight: "700",
                                                    width: "175px",
                                                    fontSize: "0.9em",
                                                    fontFamily:
                                                      "Open Sans, sans-serif"
                                                  }}
                                                >
                                                  {field.first_label}
                                                  {field.required && (
                                                    <span
                                                      style={{
                                                        color: "orange"
                                                      }}
                                                    >
                                                      *
                                                    </span>
                                                  )}
                                                </label>
                                                {this.elements(
                                                  field.field,
                                                  field.first_label,
                                                  field.type,
                                                  "100%",
                                                  sec.id,
                                                  field.required,
                                                  field.options
                                                )}
                                              </div>
                                            );
                                          })}
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            width: "100%",
                                            padding: "0 30px 0 30px"
                                          }}
                                        >
                                          <div
                                            style={{
                                              border: "none",
                                              fontFamily:
                                                "Open Sans, sans-serif",
                                              padding: "0.7em 2.5em",
                                              fontWeight: "700",
                                              backgroundColor:
                                                "rgb(32 63 167)",
                                              fontSize: "0.9em",
                                              color: "white",
                                              marginRight: "10px",
                                              cursor: "pointer"
                                            }}
                                            onClick={_ => {
                                              this.setState({
                                                displayAppointmentRequestFormStep: 0
                                              });
                                            }}
                                          >
                                            Back
                                          </div>
                                          <input
                                            type="submit"
                                            style={{
                                              border: "none",
                                              fontFamily:
                                                "Montserrat, sans-serif",
                                              padding: "0.5em 2.5em",
                                              fontWeight: "700",
                                              backgroundColor:
                                                "rgb(32 63 167)",
                                              fontSize: "0.9em",
                                              color: "white"
                                            }}
                                          />
                                        </div>
                                      </div>
                                    );
                                  } else {
                                    // Renders when the array has length greater than 1
                                    return (
                                      <div className="line-container">
                                        {row.map(f => {
                                          const field = JSON.parse(f);
                                          return (
                                            <div className="form-container">
                                              <label
                                                style={{
                                                  color: "#696868",
                                                  margin: "0 0px 0px 0px",
                                                  fontWeight: "700",
                                                  width: "200px",
                                                  fontSize: "0.9em",
                                                  fontFamily:
                                                    "Open Sans, sans-serif"
                                                }}
                                                htmlFor={field.first_label}
                                              >
                                                {field.first_label}{" "}
                                                {field.required && (
                                                  <span
                                                    style={{
                                                      color: "orange"
                                                    }}
                                                  >
                                                    *
                                                  </span>
                                                )}
                                              </label>
                                              {field.type === "file"
                                                ? this.state.formData[
                                                  sec.id + field.first_label
                                                ]
                                                  ? "File Uploaded"
                                                  : this.elements(
                                                    field.field,
                                                    field.first_label,
                                                    field.type,
                                                    "100%",
                                                    sec.id,
                                                    field.required
                                                  )
                                                : this.elements(
                                                  field.field,
                                                  field.first_label,
                                                  field.type,
                                                  "100%",
                                                  sec.id,
                                                  field.required,
                                                  field.options
                                                )}
                                            </div>
                                          );
                                        })}
                                      </div>
                                    );
                                  }
                                }
                              })
                              : this.getFields(
                                sec.fields,
                                sec.form_section_name
                              ).map(row =>
                                row.length > 1 ? (
                                  <div className="line-container">
                                    {row.map(f => {
                                      const field = JSON.parse(f); // This section renders the forms which are not appointments
                                      return (
                                        <div className="form-container">
                                          <label
                                            style={{
                                              color: "#696868",
                                              margin: "0 0px 0px 0px",
                                              fontWeight: "700",
                                              width: "200px",
                                              fontSize: "0.9em",
                                              fontFamily:
                                                "Open Sans, sans-serif"
                                            }}
                                            htmlFor={field.first_label}
                                          >
                                            {field.first_label}{" "}
                                            {field.required && (
                                              <span
                                                style={{ color: "orange" }}
                                              >
                                                *
                                              </span>
                                            )}
                                          </label>
                                          {field.type === "file"
                                            ? this.state.formData[
                                              sec.id + field.first_label
                                            ]
                                              ? "File Uploaded"
                                              : this.elements(
                                                field.field,
                                                field.first_label,
                                                field.type,
                                                "100%",
                                                sec.id,
                                                field.required
                                              )
                                            : this.elements(
                                              field.field,
                                              field.first_label,
                                              field.type,
                                              "100%",
                                              sec.id,
                                              field.required,
                                              field.options
                                            )}
                                        </div>
                                      );
                                    })}
                                  </div>
                                ) : (
                                  // This section renders the forms which are not appointments and have an array length of 1
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center"
                                    }}
                                  >
                                    {row.map(f => {
                                      const field = JSON.parse(f);

                                      return (
                                        <div
                                          style={{
                                            width: "100%",
                                            alignItems: "center",
                                            marginBottom: "20px",
                                            padding: "0 30px 0 30px"
                                          }}
                                        >
                                          <label
                                            htmlFor={field.first_label}
                                            style={{
                                              color: "#696868",
                                              margin: "0 0px 0px 0px",
                                              fontWeight: "700",
                                              width: "175px",
                                              fontSize: "0.9em",
                                              fontFamily:
                                                "Open Sans, sans-serif"
                                            }}
                                          >
                                            {field.first_label}
                                            {field.required && (
                                              <span
                                                style={{ color: "orange" }}
                                              >
                                                *
                                              </span>
                                            )}
                                          </label>
                                          {this.elements(
                                            field.field,
                                            field.first_label,
                                            field.type,
                                            "100%",
                                            sec.id,
                                            field.required,
                                            field.options
                                          )}
                                        </div>
                                      );
                                    })}
                                  </div>
                                )
                              )}
                          </div>
                        ))}
                      {this.state.forms[this.state.selectedForm].form_name !==
                        "Appointments" && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              width: "100%",
                              padding: "0 30px 0 30px"
                            }}
                          >
                            <input
                              type="submit"
                              style={{
                                border: "none",
                                fontFamily: "Open Sans, sans-serif",
                                padding: "0.5em 2.5em",
                                fontWeight: "700",
                                backgroundColor: "rgb(32 63 167)",
                                fontSize: "0.9em",
                                color: "white"
                              }}
                              value={
                                this.state.forms[this.state.selectedForm]
                                  .form_name === "Donation"
                                  ? "Donate"
                                  : "Submit"
                              }
                            />
                          </div>
                        )}
                    </form>
                  )}
                </>
              )}

              {/* Card Section News */}

              {/* 
  =================================================================================================
  Start of "News"
  =================================================================================================
*/}
              {this.state.active === "News" && (
                <div>
                  {this.state.news.map(e => (
                    <div
                      className={`${this.props.classes.plan} news-card`}
                      onClick={_ =>
                        this.props.history.push(
                          `/programs/${slug}/news/${e.id}`
                        )
                      }
                    >
                      <h3 className="news-title">{e.title}</h3>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start"
                        }}
                      >
                        <div className="news-img-container">
                          <LazyLoadImage
                            src={`${process.env.REACT_APP_DS_FILES_S3}/${e.listing_image}`}
                            alt="Listing"
                            className="news-img"
                            wrapperClassName="card-img"
                            effect="opacity"
                          />
                        </div>

                        <p className="news-desc">
                          {e.mainbody.length > 200
                            ? e.mainbody.substr(0, 200) + "..."
                            : e.mainbody}{" "}
                          <br />
                          <span className="news-date">
                            {format(new Date(e.date), "LLLL dd, yyyy")}
                          </span>
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {/* Card Section - Gifts */}

              {/* 
  =================================================================================================
  Start of "Gifts"
  =================================================================================================
*/}
              {this.state.active === "Gifts" && !this.state.activeGift ? (
                <div>
                  {this.state.gifts.map(e => (
                    <div
                      onClick={_ => this.setState({ activeGift: e })}
                      className={`${this.props.classes.plan} gift-card`}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start"
                        }}
                      >
                        <div
                          style={{
                            maxWidth: "300px"
                          }}
                        >
                          <LazyLoadImage
                            src={`${process.env.REACT_APP_DS_FILES_S3}/${this.state.image_gifts[e.image - 1]
                              }`}
                            alt="Listing"
                            className="news-img"
                          />
                        </div>

                        <p className="news-desc" style={{ marginLeft: "30px" }}>
                          <h4 className="gift-title">
                            {e.gift_name || "Gift Card"} ${e.amount}
                          </h4>
                          {e.description.length > 200
                            ? e.description.substr(0, 200) + "..."
                            : e.description}{" "}
                          <br />
                          {/* <span className="news-date">
                            {format(new Date(e.createdAt), "LLLL dd, yyyy")}
                          </span> */}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                this.state.active === "Gifts" &&
                this.state.activeGift && (
                  <GiftCardPurchase
                    gift_card={this.state.activeGift}
                    onBack={() => {
                      this.setState({ activeGift: null });
                    }}
                  />
                )
              )}
            </div>
          )}
        </div>
      </>
    );
  }
}

export default withStyles(styles)(PreviewPartner);
