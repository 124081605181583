import React from "reactn";
import SectionOne from "./forms/SectionOne";
import SectionTwo from "./forms/SectionTwo";
class Setup extends React.Component {
  render() {
    return (
      <div className="cont boxes-row mt-50px">
        <SectionOne vals={this.global.myApplicationProfileInfo} />
        <SectionTwo vals={this.global.myApplicationProfileInfo} />
      </div>
    );
  }
}

export default Setup;
