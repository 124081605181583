import React from "react";
import PropTypes from "prop-types";

function Select(props) {
  const newProps = { ...props };
  const isDisabled = props.isDisabled;
  delete newProps.valueOnly;
  delete newProps.isDisabled;
  delete newProps.type;
  // newProps.value = newProps.value.toString();
  return (
    <select
      {...newProps}
      className={`w-100 ${newProps.className}`}
      disabled={isDisabled}
    >
      {props.options &&
        props.options.map((option, index) => {
          return (
            <option key={index} value={option.value.toString()}>
              {option.label ? option.label : option.name}
            </option>
          );
        })}
    </select>
  );
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array
};

Select.defaultProps = {};
export default Select;
