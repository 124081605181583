import React from "react";
import "../fieldComponent.css";
import "./Switch.css";
import Switch from "@material-ui/core/Switch";
import Title from "../Title";

const BMSwitch = props => {
  const {
    name,
    checked,
    handleClick,
    required,
    options,
    isDisabled,
    info,
    fieldValid,
    setFieldValid,
    wrapperStyles
  } = props;

  return (
    <div style={wrapperStyles}>
      <Title name={name} required={required} info={info} />
      <Switch
        disabled={isDisabled || false}
        checked={checked}
        onClick={handleClick}
        {...options}
      />
    </div>
  );
};

export default BMSwitch;
