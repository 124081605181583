import React from "react";
import { Link } from "react-router-dom";
import {
  FooterBg,
  FooterInstagram,
  FooterFb,
  Twitter_Icon
} from "../../assets";

const PublicFooter = () => {
  return (
    <>
      <div
        className="footer container-fluid"
        style={{ display: "flex", lineHeight: "1.42857143" }}
      >
        <img src={FooterBg} className="bgimg" alt="" />
        <div
          className="container"
          style={{
            paddingRight: 0,
            paddingLeft: 0,
            marginBottom: "20px",
            maxWidth: "initial"
          }}
        >
          <div
            className="row"
            style={{ width: "100%", marginRight: "-15px", marginLeft: "-15px" }}
          >
            <div className="col-lg-5"></div>
            <div className="col-lg-7">
              <div className="row">
                <div className="col-md-4">
                  <div className="mail">
                    <p>contact@dreamschools.com</p>
                  </div>

                  <div className="location">
                    <p>Centre for Social Innovation</p>
                    <p>
                      <span>Toronto, ON</span>
                    </p>
                  </div>
                  <div
                    className="follow"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <span
                      style={{
                        marginRight: "10px",
                        fontWeight: "bold",
                        fontSize: "14px"
                      }}
                    >
                      Follow Us
                    </span>
                    <a
                      href="https://www.facebook.com/pages/category/Community-Organization/Dreamschools-209918316224818/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={FooterFb} alt="" />
                    </a>
                    <a
                      href="https://www.instagram.com/wedreamschools/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={FooterInstagram} alt="" />
                    </a>
                    <a
                      href="https://twitter.com/WeDreamschools"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={Twitter_Icon} alt="" />
                    </a>
                  </div>
                </div>
                <div className="col-md-4 Dreamschools">
                  <h4>Dreamschools</h4>
                  <div className="maxwidthforlist">
                    <div className="row">
                      <div
                        className="col-md-5"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <ul>
                          <li>
                            <Link
                              to="/about"
                              onClick={() => {
                                window.scrollTo(0, 0);
                              }}
                            >
                              About Us
                            </Link>
                          </li>
                          {/* <li>
                            <a href="#/">FAQ</a>
                          </li> */}
                          {/* <li>
                            <a href="http://help.dreamschools.com/">Help</a>
                          </li> */}
                          <li>
                            <a href="/terms-of-use">Terms of Use</a>
                          </li>
                          <li>
                            <a href="/privacy-policy">Privacy Policy</a>
                          </li>
                        </ul>
                      </div>
                      <div
                        className="col-md-7"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <ul>
                          <li>
                            <a href="https://www.wedreamschools.com/">Blog</a>
                          </li>
                          <li>
                            <a style={{'pointer-events': 'none'}}>
                              Dream Tools
                            </a>
                          </li>
                          <li>
                            <a href="/partner">Launch with us</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 locationsdiv">
                  <div>
                    <h4>Locations</h4>
                    <div className="locationmaxw">
                      <div
                        className="row"
                        style={{ marginRight: "-15px", marginLeft: "-15px" }}
                      >
                        <div className="col-xs-6">
                          <ul>
                            <li>
                              <Link
                                to="/search/43.64,-79.433"
                                style={{ color: "white" }}
                              >
                                Greater Toronto, ON
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/search/43.2557206,-79.8711024"
                                style={{ color: "white" }}
                              >
                                Hamilton, ON
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/search/45.4215296,-75.69719309999999"
                                style={{ color: "white" }}
                              >
                                Ottawa, ON
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/search/42.9849233,-81.2452768"
                                style={{ color: "white" }}
                              >
                                London, ON
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/search/43.4516395,-80.4925337"
                                style={{ color: "white" }}
                              >
                                Kitchner, ON
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/search/51.04473309999999,-114.0718831"
                                style={{ color: "white" }}
                              >
                                Calgary, AB
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/search/53.5461245,-113.4938229"
                                style={{ color: "white" }}
                              >
                                Edmonton, AB
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-xs-6">
                          <ul>
                            <li>
                              <Link
                                to="/search/49.2827291,-123.1207375"
                                style={{ color: "white" }}
                              >
                                Vancouver, BC
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/search/48.4284207,-123.3656444"
                                style={{ color: "white" }}
                              >
                                Victoria, BC
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/search/49.0504377,-122.3044697"
                                style={{ color: "white" }}
                              >
                                Abbotsford, BC
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/search/49.895136,-97.13837439999999"
                                style={{ color: "white" }}
                              >
                                Winnipeg, MB
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/search/50.4452112,-104.6188944"
                                style={{ color: "white" }}
                              >
                                Regina, SK
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/search/52.157902,-106.6701577"
                                style={{ color: "white" }}
                              >
                                Saskatoon, SK
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/search/44.6475811,-63.5727683"
                                style={{ color: "white" }}
                              >
                                Halifax, NS
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*window.location.pathname === "/" && (
        <div style={{ height: "80px" }}></div>
      )*/}
    </>
  );
};

export default PublicFooter;
