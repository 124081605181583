import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Promotions } from "../../../assets";

import { format } from "date-fns";
import { convertCentsToDollars, getPromotionsBySlug } from "./utils";

const PartnerPromotions = ({ classes, params, history, location }) => {
  const [promotions, setPromotions] = useState([]);

  const initialPromotionsSetup = async () => {
    const resp = await getPromotionsBySlug(params.slug);

    setPromotions(resp.data.filter(f => f.isPublished));
  };
  useEffect(() => {
    initialPromotionsSetup();
  }, []);
  if (!promotions || promotions.length === 0) {
    return <div data-testid="empty-promotions"></div>;
  }
  return promotions.map(promo => (
    <div
      onClick={() => {
        if (location?.query?.newTab) {
          window.open(`/programs/${params.slug}/promotion/${promo.id}`);
        } else {
          history.push(`/programs/${params.slug}/promotion/${promo.id}`);
        }
      }}
      className={`${classes.plan} card-area`}
      key={promo.id}
    >
      <img
        src={Promotions}
        alt="Promotions"
        style={{
          width: "22%",
          height: "147px",
          objectFit: "cover",
          borderRadius: "7px",
          boxShadow: "0px -1px 10px rgba(0, 0, 0, 0.1)"
        }}
        className="card-img"
      />

      <div className="card-content">
        <div className="card-sub1">
          <h4 className="sub1-name">
            {promo.title}{" "}
            {promo.custom_bill_name ? `: ${promo.custom_bill_name}` : null}
          </h4>
          <span className="sub1-age"></span>
        </div>
        <div className="card-sub2">
          <h4 className="sub2-timing" style={{ textTransform: "capitalize" }}>
            {promo.promotion_type}
          </h4>
          <span className="sub2-date">
            {promo.expiry_date
              ? `Expires On: ${format(
                  new Date(promo.expiry_date),
                  "dd/MM/yyyy"
                )}`
              : ""}
          </span>
        </div>
        <div className="card-sub3">
          <h5 className="sub3-pricing">
            {promo.discount_type === "Percentage"
              ? `${promo.discount_percent}%`
              : promo.discount_type === "Fixed Amount"
              ? `${convertCentsToDollars(promo.discount_fixed)}`
              : "Promo"}
          </h5>
        </div>
      </div>
    </div>
  ));
};

PartnerPromotions.propTypes = {
  classes: PropTypes.object,
  params: PropTypes.shape({
    slug: PropTypes.string
  }),
  history: PropTypes.object,
  location: PropTypes.object
};

export default PartnerPromotions;
