import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { promotion_discount, coupon_discount } from "./utils";

const TaxablePromotionEvent = props => {
  const [taxValue, setTaxValue] = useState("Loading");
  const [taxName, setTaxName] = useState("Loading");
  const getTaxName = () => {
    const displayName = JSON.parse(props.program.tickets[props.selected])
      .taxInfo[0].display_name;
    const percentage = JSON.parse(props.program.tickets[props.selected])
      .taxInfo[0].percentage;
    const name = `${displayName} (${percentage}%)`;
    setTaxName(name);
  };
  const calculateTaxValue = () => {
    const seleted_ticket = JSON.parse(props.program.tickets[props.selected]);
    const parsed_ticket_price = seleted_ticket.ticket_price || 0;
    let val = 0;
    
    if (props.promotion) {
      const discount_amount = promotion_discount(
        props.promotion,
        parsed_ticket_price
      );
      console.log("parsed_ticket_price", parsed_ticket_price)
      console.log("seleted_ticket", seleted_ticket)
      console.log("props.program", props.program)
      val = seleted_ticket.isTaxEnabled ? (Math.max(0, parsed_ticket_price - discount_amount) *
      parseFloat(seleted_ticket.taxInfo[0].percentage / 100)).toFixed(2) :
        Math.max(0, parsed_ticket_price - discount_amount) *
        parseFloat(seleted_ticket.taxInfo[0].percentage / 100);
    } else {
      const coup_discount = props.coupon
        ? coupon_discount(props.coupon, parsed_ticket_price)
        : 0;
      val = parseFloat(
        Math.max(0, parsed_ticket_price - coup_discount) *
          (seleted_ticket.taxInfo[0].percentage / 100)
      ).toFixed(2);
    }
    setTaxValue(val);
    props.taxAmount(val);
  };

  useEffect(() => {
    calculateTaxValue();
    getTaxName();
  }, [props.coupon]);

  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px",
        borderBottom: "1px solid #dedede"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif",
          fontWeight: 600
        }}
      >
        {taxName}
      </p>
      <span style={{ fontWeight: "bold" }}>${taxValue}</span>
    </li>
  );
};

TaxablePromotionEvent.propTypes = {
  program: PropTypes.object.isRequired,
  selected: PropTypes.number.isRequired,
  promotion: PropTypes.object,
  coupon: PropTypes.object
};

export default TaxablePromotionEvent;
