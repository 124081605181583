import React, { useEffect, useState, useGlobal } from "reactn";
import PropTypes from "prop-types";

import { promotion_discount } from "./utils";

const EventPromotionsTotal = props => {
  const [total, setTotal] = useState("Loading");
  const [_, setPreGiftTotal] = useGlobal("preGiftTotal");
  const giftBalance = useGlobal("giftBalance")[0];

  const getTotalAmount = () => {
    const seleted_ticket = JSON.parse(props.program.tickets[props.selected]);
    const parsed_ticket_price = parseFloat(seleted_ticket.ticket_price || 0);

    const discount_amount = promotion_discount(
      props.promotion,
      parsed_ticket_price
    );
    const taxRate = seleted_ticket.isTaxEnabled
      ? parseFloat(seleted_ticket.taxInfo[0].percentage) / 100
      : 0;
    const tax = seleted_ticket.isTaxEnabled? Math.max(0, parsed_ticket_price - discount_amount) * taxRate : Math.max(0, parsed_ticket_price - discount_amount) * taxRate;

    let total = Math.max(0, parsed_ticket_price - discount_amount) + tax;
    console.log("total", total)
    setPreGiftTotal(total);
    total = giftBalance > total ? 0 : total - giftBalance;

    props.totalAmount(total.toFixed(2));

    setTotal(total.toFixed(2));
  };

  useEffect(() => {
    getTotalAmount();
  }, []);

  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif",
          fontWeight: 600
        }}
      >
        Total after promotion
      </p>
      <span style={{ fontWeight: "bold" }}>${total}</span>
    </li>
  );
};

EventPromotionsTotal.propTypes = {
  program: PropTypes.shape({
    tickets: PropTypes.array.isRequired
  }).isRequired,
  selected: PropTypes.number.isRequired,
  promotion: PropTypes.object.isRequired
};

export default EventPromotionsTotal;
