import React, { useState } from "react";
import PropTypes from "prop-types";
import InfoBalloon from "./InfoBalloon";
import { ImageActiveA } from "../../assets";
import Pagination from "../pagination/pagination";
import { withStyles } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import "../../assets/css/componentSpecificCss/newTabbedTable.css";

const styles = {
  thead: {
    background: "transparent !important",
    "& th": {
      color: "#A9A9A9 !important",
      fontSize: "13px !important"
    }
  },
  tabRootTable: {
    color: "gray !important",
    fontSize: "0.9em !important",
    marginRight: "1.5em !important",
    fontFamily: "Montserrat,sans-serif !important",
    fontWeight: "400 !important",
    "&:hover": {
      backgroundColor: "transparent !important",
      color: "gray !important"
    },
    "&:focus": {
      backgroundColor: "transparent !important",
      color: "gray !important"
    },
    display: "none"
  },
  tabSelectedTable: {
    display: "none",
    color: "black !important",
    fontSize: "1.2em !important",
    "&:hover": {
      backgroundColor: "transparent !important",
      color: "black !important"
    },
    "&:focus": {
      backgroundColor: "transparent !important",
      color: "black !important",
      outline: "none !important"
    }
  },
  tabIndicatorTable: {
    backgroundColor: "#1C3EC3",
    height: "3px"
  }
};

const NewTabbedTable = props => {
  const { data, headings, tabs, actions, onTabChange } = props;
  const [useTab, setTab] = useState(0);
  const [usePage, setPage] = useState(0);
  const [useDataLength, setDataLength] = useState(
    data[tabs[0]] ? data[tabs[0]].length : 0
  );

  React.useEffect(() => {
    setDataLength(data[tabs[useTab]] ? data[tabs[useTab]].length : 0);

    //TEMPORARY
  }, [data, tabs, useTab]);

  const changeTab = i => {
    setDataLength(data[tabs[i]] ? data[tabs[i]].length : 0);
    setPage(0);
    setTab(i);
    if (onTabChange) onTabChange(i);
  };

  return (
    <>
      <div style={{ margin: "1em 0 1em 0" }}>
        <Tabs
          value={useTab}
          onChange={(event, newValue) => changeTab(newValue)}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          TabIndicatorProps={{ className: props.classes.tabIndicatorTable }}
        >
          {tabs.map((tab, i) => (
            <Tab
              label={tab + `${data[tab] ? ` (${data[tab].length})` : ""}`}
              value={i}
              disableRipple
              disableFocusRipple
              classes={{
                root: props.classes.tabRootTable,
                selected: props.classes.tabSelectedTable
              }}
            />
          ))}
        </Tabs>
      </div>

      <div style={{ overflow: "auto" }}>
        <table className="table">
          <thead
            className={props.classes.thead}
            style={{ borderTop: "none !important" }}
          >
            <tr>
              {headings[tabs[useTab]] &&
                headings[tabs[useTab]].map((heading, index) => (
                  <th
                    scope="col"
                    key={index}
                    style={heading.customStyle ? heading.customStyle : {}}
                  >
                    {heading.label}
                    {heading.infoBalloon && (
                      <InfoBalloon body={heading.infoBalloon} />
                    )}
                  </th>
                ))}
            </tr>
          </thead>

          <tbody>
            {data[tabs[useTab]] &&
              data[tabs[useTab]]
                // .slice(usePage * 7, usePage * 7 + 7)
                .map((row, k) => {
                  return (
                    <tr key={k} style={{ background: "white" }}>
                      {headings[tabs[useTab]] &&
                        headings[tabs[useTab]].map((heading, index) => {
                          if (heading.id === "action") {
                            return (
                              <td key={index} style={{ whiteSpace: "nowrap" }}>
                                {actions.map((action, i) => (
                                  <button
                                    onClick={() => action.action(row)}
                                    key={i}
                                    style={{ marginLeft: "10px" }}
                                    className="table-action-button"
                                  >
                                    {action.name}
                                  </button>
                                ))}
                              </td>
                            );
                          } else {
                            return heading.customCell ? (
                              heading.customCell(row, index)
                            ) : (
                              <td key={index}>{row[heading.id]}</td>
                            );
                          }
                        })}
                    </tr>
                  );
                })}

            {!data || !data[tabs[useTab]] || !data[tabs[useTab]].length ? (
              <tr style={{ background: "white" }}>
                <td
                  colSpan={
                    headings[tabs[useTab]] ? headings[tabs[useTab]].length : 5
                  }
                  className="text-center"
                >
                  Nothing to Show
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
      {/* {props.prePaginator ? props.prePaginator : null}
      <Pagination
        pageCount={Math.ceil(useDataLength / 7)}
        onPageChange={setPage}
        forcePage={usePage}
      /> */}
    </>
  );
};

NewTabbedTable.propTypes = {
  headings: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  tabs: PropTypes.array.isRequired,
  actions: PropTypes.array
};

NewTabbedTable.defaultProps = {
  headings: {},
  data: {},
  tabs: [],
  actions: []
};

export default withStyles(styles)(NewTabbedTable);
