import React, { useState } from "react";
import "../fieldComponent.css";
import "./Dropdown.css";
import Title from "../Title";

/**
 * @param {String} name
 *
 * @param {Boolean} required
 *
 * @param {String} placeholder
 *
 * @param {Function} handleChange
 *
 * @param {Array<Object>} choices
 */
const Dropdown = props => {
  const {
    name,
    required,
    placeholder,
    handleChange,
    choices,
    value,
    fieldValid,
    setFieldValid,
    isDisabled,
    info,
    styles,
    wrapperStyles,
    titleStyle,
    iconStyles
  } = props;
  const [currentSelection, setCurrentSelection] = useState("");

  // the default value is empty string ""
  // invalid/greyed out value is empty string ""
  return (
    <div style={wrapperStyles}>
{/*       <p className="field-component-title">
        {name}
        {required ? <span className="required-star"> *</span> : ""}
      </p> */}
      <Title name={name} required={required} info={info} titleStyle={titleStyle} iconStyles={iconStyles}/>
      <select
        className={`dropdown-select-field field-component-input-box ${
          currentSelection === ""
            ? "dropdown-select-grey"
            : "dropdown-select-black"
        } ${(() => {
          return fieldValid ? "" : "dropdown-select-invalid";
        })()}`}
        type="text"
        disabled={isDisabled || false}
        style={styles}
        onChange={e => {
          e.persist();
          setCurrentSelection(e.target.value);
          handleChange(e);
          setFieldValid(true);
        }}
        value={value}
      >
        <option value={""}>{placeholder}</option>
        {choices.map(({ value, text }, index) => (
          <option key={index} value={value}>
            {text}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;
