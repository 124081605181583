import "./paymentMethods";
import "./messaging";
import axios from "axios";
import { setGlobal, getGlobal } from "reactn";
axios.interceptors.request.use(
  function (config) {
    try {
      if (window.localStorage) {
        const token = localStorage.getItem("ds_token");

        if (token != null) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        if (config.method === "post") {
          const global = getGlobal();
          if (!global.lastPost) {
            setGlobal({
              lastPost: true
            });
          } else {
            console.log("Operation Aborted due to last Call");
            throw new axios.Cancel(`Operation not completed`);
          }
        }
        return config;
      }
    } catch (e) {
      //if "window.localStorage" above is denied access for security reasons,
      //then the browser will throw a DOMException where e.name='SecurityError'.
      console.error("axios_req_interceptor: ",
        (e instanceof DOMException ? `${e.name}: ${e.message}` : e)
      );
      return config;
    }
  },
  function (err) {
    return Promise.reject(err);
  }
);


axios.interceptors.response.use(
  response => {
    setGlobal({ lastPost: false });
    return response;
  },
  error => {
    if (error.response && error.response.status === 401) {
      /**
       * //Temporarily disabling this. Caused an unnecessary amount of page reloads!
       * //Sometimes reload works - the parent component (eg. AdminLayout) might redirect to a sign-in page!
       * //Other times, (eg. /preview) will cause this to get into an endless reload loop.
      window.location.reload();
      **/
      window.location.assign(`/sign-in?follow=${window.location.pathname}`);
    }
    return error;
  }
);
