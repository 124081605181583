import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import InfoBalloon from "./InfoBalloon";
import { ImageActiveA } from "../../assets";
import Pagination from "../pagination/pagination";
import "../../assets/css/componentSpecificCss/tabbedTable.css";

const TabbedTablePagination = props => {
  const { data, headings, tabs, actions, onTabChange, noTabCount } = props;
  const [activeTab, setActiveTab] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [dataLength, setDataLength] = useState(
    data[tabs[0]] ? data[tabs[0]].length : 0
  );

  useEffect(() => {
    setDataLength(data[tabs[activeTab]] ? data[tabs[activeTab]].length : 0);
    setActivePage(0);
  }, [data, activeTab]);

  const changeTab = i => {
    setDataLength(data[tabs[i]] ? data[tabs[i]].length : 0);
    setActivePage(0);
    setActiveTab(i);
    if (onTabChange) onTabChange(i);
  };

  return (
    <>
      {props.hideHeader ? null : (
        <div className="tablecont">
          <ul>
            {tabs.map((tab, i) => (
              <li key={tab} className={i === activeTab ? "active" : ""}>
                <button onClick={() => changeTab(i)}>
                  <img src={ImageActiveA} className="foractive" alt="" />
                  {tab +
                    `${
                      !noTabCount && data[tab] ? ` (${data[tab].length})` : ""
                    }`}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="tablecont tableScrollX">
        <table className="table">
          <thead>
            <tr>
              {headings[tabs[activeTab]] &&
                headings[tabs[activeTab]].map((heading, index) => (
                  <th
                    scope="col"
                    key={index}
                    style={heading.customStyle ? heading.customStyle : {}}
                  >
                    {heading.label}
                    {heading.infoBalloon && (
                      <InfoBalloon body={heading.infoBalloon} />
                    )}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {data[tabs[activeTab]] &&
              data[tabs[activeTab]]
                .slice(activePage * 7, activePage * 7 + 7)
                .map((row, k) => {
                  return (
                    <tr key={k}>
                      {headings[tabs[activeTab]] &&
                        headings[tabs[activeTab]].map((heading, index) => {
                          if (heading.id === "action") {
                            return (
                              <td key={index} style={{ whiteSpace: "nowrap" }}>
                                {actions.map((action, i) => (
                                  <button
                                    onClick={() => action.action(row, i)}
                                    key={i}
                                    style={{ marginLeft: "10px" }}
                                  >
                                    {action.name}
                                  </button>
                                ))}
                              </td>
                            );
                          } else {
                            return heading.customCell ? (
                              heading.customCell(row, index)
                            ) : (
                              <td key={index}>{row[heading.id]}</td>
                            );
                          }
                        })}
                    </tr>
                  );
                })}
            {!data || !data[tabs[activeTab]] || !data[tabs[activeTab]].length ? (
              <tr>
                <td
                  colSpan={
                    headings[tabs[activeTab]] ? headings[tabs[activeTab]].length : 5
                  }
                  className="text-center"
                >
                  Nothing to Show
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>

      {props.prePaginator ? props.prePaginator : null}
      <Pagination
        pageCount={Math.ceil(dataLength / 7)}
        onPageChange={setActivePage}
        forcePage={activePage}
      />
    </>
  );
};

TabbedTablePagination.propTypes = {
  headings: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  tabs: PropTypes.array.isRequired,
  actions: PropTypes.array
};

TabbedTablePagination.defaultProps = {
  headings: {},
  data: {},
  tabs: [],
  actions: []
};

export default TabbedTablePagination;
