import React, { Component } from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import * as dateFns from "date-fns";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Survey from "../UI/Survey";
import "../../assets/css/componentSpecificCss/requestTourModal.css";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import BaseModal from "./baseModal/BaseModal";
import { GET_HTML } from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE, RED_BUTTON } from "./baseModal/colours";
import { validateForm } from "../../lib/util";

class RequestTourrmationModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      displayName: this.global.dsUser ? this.global.dsUser.displayName : "",
      email: this.global.dsUser ? this.global.dsUser.email : "",
      requestedDate: "",
      requestedTime: "",
      requestPhone: this.global.dsUser ? this.global.dsUser.phone : "",
      requestMessage: "",
      isWithinSetTime: true,
      otp: "",
      otherShown: false,
      phoneValidation: false,

      days: {},
      months: "",
      years: "",
      dropOff: "",
      pickUp: "",
      childcareneed: ""
    };
    this.requestTour = this.requestTour.bind(this);
    this.filterTimes = this.filterTimes.bind(this);
    this.filterDate = this.filterDate.bind(this);
  }
  surveyForm = async information => {
    const params = this.props.match.params;
    const ep = `${process.env.REACT_APP_API}/frnt/program/request-tour/${
      params && params.slug ? params.slug : this.props.program.slug
    }/survey`;
    const res = await axios.post(ep, {
      requestPhone: this.state.requestPhone || this.global.dsUser.phone,
      email: this.state.email || this.global.dsUser.email,
      displayName: this.state.displayName || this.global.dsUser.displayName,
      ...information
    });
    if (res.data.success) {
      this.setState({ code: true, survey: false });
    }
  };
  verifyCode = async e => {
    e.preventDefault();
    const params = this.props.match.params;
    const ep = `${process.env.REACT_APP_API}/frnt/program/request-tour/${
      params && params.slug ? params.slug : this.props.program.slug
    }/confirm`;

    const requestedMinutes =
      parseFloat(this.state.requestedTime) % 1 === 0 ? 0 : 30;
    const requestedHours =
      parseFloat(this.state.requestedTime) % 1 === 0
        ? parseFloat(this.state.requestedTime)
        : parseFloat(this.state.requestedTime) - 0.5;

    const theDate = new Date(this.state.requestedDate).setHours(
      requestedHours,
      requestedMinutes,
      0
    );

    const res = await axios.post(ep, {
      requestedTourDate: theDate,
      isWithin: this.state.isWithinSetTime,
      requestPhone: this.state.requestPhone || this.global.dsUser.phone,
      email: this.state.email || this.global.dsUser.email,
      displayName: this.state.displayName || this.global.dsUser.displayName,
      message: this.state.requestMessage,
      otp: this.state.otp
    });
    if (!res.data.success) {
      this.setState({ codeError: true });
    } else {
      this.setState({ code: false, done: true });
    }
  };
  //new Date(Date.now() + 24 * 60 * 60 * 1000)
  requestTour = async e => {
    e.preventDefault();

    const params = this.props.match.params;
    const ep = `${process.env.REACT_APP_API}/frnt/program/request-tour/${
      params && params.slug ? params.slug : this.props.program.slug
    }/verification`;
    this.setState({
      displayName: this.state.displayName.trim(),
      email: this.state.email.trim()
    });
    try {
      if (!this.state.displayName && !this.global.dsUser) {
        document.getElementsByName("displayName")[0].style.border =
          "1px solid red";
        throw new Error("Name must be supplied.");
      }
      if (!this.state.email && !this.global.dsUser) {
        document.getElementsByName("displayName")[0].style.border =
          "1px solid #ccc";
        document.getElementsByName("email")[0].style.border = "1px solid red";
        throw new Error("Email must be supplied.");
      }
      if (
        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
          this.state.email
        ) &&
        !this.global.dsUser
      ) {
        document.getElementsByName("displayName")[0].style.border =
          "1px solid #ccc";
        document.getElementsByName("email")[0].style.border = "1px solid red";
        throw new Error("Please enter a valid email.");
      }
      if (
        (!this.state.requestPhone ||
          this.state.requestPhone.trim().length !== 10 ||
          isNaN(this.state.requestPhone.trim())) &&
        !this.global.dsUser
      ) {
        document.getElementsByName("displayName")[0].style.border =
          "1px solid #ccc";

        document.getElementsByName("email")[0].style.border = "1px solid #ccc";
        document.getElementsByName("requestPhone")[0].style.border =
          "1px solid red";

        this.setState({ phoneValidation: true });
        throw new Error("The Phone provided is invalid");
      }
      if (!this.state.requestedDate) {
        if (!this.global.dsUser) {
          document.getElementsByName("email")[0].style.border = "1px solid #ccc";
          document.getElementsByName("displayName")[0].style.border =
            "1px solid #ccc";
          document.getElementsByName("requestPhone")[0].style.border =
            "1px solid #ccc";
        }
        document.getElementsByName("requestedDate")[0].style.border =
          "1px solid red";
        throw new Error("Date must be supplied.");
      }
      if (!this.state.requestedTime) {
        if (!this.global.dsUser) {
          document.getElementsByName("email")[0].style.border = "1px solid #ccc";
          document.getElementsByName("displayName")[0].style.border =
            "1px solid #ccc";
          document.getElementsByName("requestPhone")[0].style.border =
            "1px solid #ccc";
        }
        document.getElementsByName("requestedDate")[0].style.border =
          "1px solid #ccc";
        document.getElementsByName("requestedTime")[0].style.border =
          "1px solid red";
        throw new Error("Time must be supplied.");
      }
      this.setState({ phoneValidation: false });
      const theDate = new Date(this.state.requestedDate).setHours(
        this.state.requestedTime,
        0,
        0
      );
      const res = await axios.post(ep, {
        requestPhone: this.state.requestPhone || this.global.dsUser.phone,
        email: this.state.email || this.global.dsUser.email,
        displayName: this.state.displayName || this.global.dsUser.displayName,
        requestedTourDate: theDate,
        isWithin: this.state.isWithinSetTime,
        message: this.state.requestMessage,
      });
      if (res.data.success) {
        if (res.data.message === "Successfully sent tour request" && this.global.dsUser) {
          this.setState({ done: true });
        } else {
          this.setState({...res.data.data });
        }
      } else {
        // Error from backend
        if (!this.global.dsUser) {
          document.getElementsByName("email")[0].style.border = "1px solid #ccc";
          document.getElementsByName("displayName")[0].style.border =
            "1px solid #ccc";
          document.getElementsByName("requestPhone")[0].style.border =
            "1px solid #ccc";
        }
        document.getElementsByName("requestedDate")[0].style.border =
          "1px solid #ccc";
        document.getElementsByName("requestedTime")[0].style.border =
          "1px solid red";
        throw new Error(res.data.message);
      }
    } catch (err) {
      toast.error(err.message);
      console.error(err);
    }
  };

  filterDate(d) {
    const availDays = [
      ...new Set(
        (this.global.school
          ? this.global.school.tourAvail
          : this.props.program
        ).availability.map(x => dateFns.getDay(new Date(x)))
      )
    ];
    return availDays.includes(dateFns.getDay(new Date(d)));
  }

  filterTimes() {
    const avail = this.global.school
      ? this.global.school.tourAvail
      : this.props.program;
    if (this.state.requestedDate) {
      const day = dateFns.getDay(this.state.requestedDate);
      return avail.availability
        .filter(d => {
          const dateNow = new Date();
          const requestDate = new Date(this.state.requestedDate).setHours(
            new Date(d).getHours(),
            new Date(d).getMinutes(),
            0
          );

          return (
            dateFns.getDay(new Date(d)) === day &&
            requestDate - dateNow >= 3600000 * 4
          );
        })
        .map(h => {
          return {
            h: dateFns.getHours(new Date(h)),
            m: dateFns.getMinutes(new Date(h))
          };
        });
    }
    return [];
  }

  /// SURVEY

  setDay(day) {
    const days = { ...this.state.days };
    if (days[day]) delete days[day];
    else days[day] = true;
    this.setState({
      days
    });
  }
  submitSurvey = e => {
    e.preventDefault();
    if (validateForm(this.state, e.target)) {
      this.surveyForm(this.state);
    } else {
      this.setState({
        error: true
      });
    }
  };
  clickRadio = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  /// ================== NEW MODAL ========================== ///
  addnewmodal = () => {
    const requestedMinutes =
      parseFloat(this.state.requestedTime) % 1 === 0 ? 0 : 30;

    const requestedHours =
      parseFloat(this.state.requestedTime) % 1 === 0
        ? parseFloat(this.state.requestedTime)
        : parseFloat(this.state.requestedTime) - 0.5;
    return (
      <div>
        {!this.state.done && !this.state.survey && !this.state.code && (
          <form>
            <div className="modal-main">
              {this.global.dsUser ? null : (
                <>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "10px"
                    }}
                  >
                    <label
                      style={{
                        width: "32%",
                        textAlign: "left",
                        fontSize: "1.8rem",
                        fontFamily: "futura-pt, sans-serif",
                        fontWeight: "500",
                        color: "#626262"
                      }}
                    >
                      Name<span style={{ color: "rgb(255, 0, 0)" }}> *</span>
                    </label>

                    <div
                      style={{
                        height: "40px",
                        width: "70%",
                        marginLeft: "30px"
                      }}
                    >
                      <ModalFields.input
                        name="displayName"
                        //label="Name"
                        //required
                        type="text"
                        style={{
                          height: "40px",
                          fontSize: "1.5rem",
                          fontFamily: "futura-pt, sans-serif",
                          fontWeight: "400",
                          color: "#797979",
                          border: "1px solid #c5c5c5",
                          borderRadius: "4px",
                          paddingLeft: "14px"
                        }}
                        placeholder="Your Name..."
                        value={this.state.displayName}
                        onChange={e => {
                          this.setState({
                            [e.target.name]: e.target.value
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "10px"
                    }}
                  >
                    <label
                      style={{
                        width: "32%",
                        textAlign: "left",
                        fontSize: "1.8rem",
                        fontFamily: "futura-pt, sans-serif",
                        fontWeight: "500",
                        color: "#626262"
                      }}
                    >
                      Email<span style={{ color: "rgb(255, 0, 0)" }}> *</span>
                    </label>

                    <div
                      style={{
                        height: "40px",
                        width: "70%",
                        marginLeft: "30px"
                      }}
                    >
                      <ModalFields.input
                        name="email"
                        //label="Email"
                        //required
                        type="text"
                        style={{
                          height: "40px",
                          fontSize: "1.5rem",
                          fontFamily: "futura-pt, sans-serif",
                          fontWeight: "400",
                          color: "#797979",
                          border: "1px solid #c5c5c5",
                          borderRadius: "4px",
                          paddingLeft: "14px"
                        }}
                        placeholder="Your Email..."
                        value={this.state.email}
                        onChange={e => {
                          this.setState({
                            [e.target.name]: e.target.value.toLowerCase()
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "10px"
                    }}
                  >
                    <label
                      style={{
                        width: "32%",
                        textAlign: "left",
                        fontSize: "1.8rem",
                        fontFamily: "futura-pt, sans-serif",
                        fontWeight: "500",
                        color: "#626262"
                      }}
                    >
                      Cell Phone
                      <span style={{ color: "rgb(255, 0, 0)" }}> *</span>
                    </label>

                    <div
                      style={{
                        height: "40px",
                        width: "70%",
                        marginLeft: "30px"
                      }}
                    >
                      {/*                       <ModalFields.input
                        name="requestPhone"
                        //label="Cell Phone"
                        //required
                        type="test"
                        style={{
                          height: "40px",
                          fontSize: "1.5rem",
                          fontFamily: "futura-pt, sans-serif",
                          fontWeight: "400",
                          color: "#797979",
                          border: "1px solid #c5c5c5",
                          borderRadius: "4px",
                          paddingLeft: "14px"
                        }}
                        placeholder="Your Phone..."
                        value={this.state.requestPhone}
                        onChange={e => {
                          this.setState({
                            [e.target.name]: e.target.value
                          });
                        }}
                      /> */}
                      <ModalFields.input
                        name="requestPhone"
                        style={{
                          height: "40px",
                          fontSize: "1.5rem",
                          fontFamily: "futura-pt, sans-serif",
                          fontWeight: "400",
                          color: "#797979",
                          border: "1px solid #c5c5c5",
                          borderRadius: "4px",
                          paddingLeft: "14px"
                        }}
                        type="tel"
                        maxLength={"10"}
                        placeholder="Your Phone..."
                        value={this.state.requestPhone}
                        onChange={e => {
                          if (/^[0-9]{0,10}$/.test(e.target.value)) {
                            this.setState({
                              [e.target.name]: e.target.value
                            });
                          }
                        }}
                        /*                     onChange={e => {
                      this.setState({
                        [e.target.name]: e.target.value
                      });
                    }} */
                      />
                    </div>
                  </div>
                  {this.state.phoneValidation && (
                    <div
                      style={{
                        color: "red",
                        fontFamily: "futura-pt, sans-serif"
                      }}
                    >
                      The phone number entered must contain numerical values
                      between 0 to 9 only
                    </div>
                  )}

                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "10px"
                    }}
                  >
                    <label
                      style={{
                        width: "32%",
                        textAlign: "left",
                        fontSize: "1.8rem",
                        fontFamily: "futura-pt, sans-serif",
                        fontWeight: "500",
                        color: "#626262"
                      }}
                    >
                      Message to the Educator
                    </label>

                    <div
                      style={{
                        height: "100px",
                        width: "63%",
                        marginLeft: "0px"
                      }}
                    >
                      <ModalFields.textarea
                        name="requestMessage"
                        //label="Message to the Educator"
                        placeholder="Enter your message..."
                        style={{
                          height: "100px",
                          fontSize: "1.5rem",
                          fontFamily: "futura-pt, sans-serif",
                          fontWeight: "400",
                          color: "#797979",
                          border: "1px solid #c5c5c5",
                          borderRadius: "4px",
                          paddingLeft: "14px"
                        }}
                        value={this.state.requestMessage}
                        onChange={e => {
                          this.setState({
                            [e.target.name]: e.target.value
                          });
                        }}
                      />
                    </div>
                  </div>
                </>
              )}

              {!this.state.otherShown ? (
                <>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "10px"
                    }}
                  >
                    <label
                      style={{
                        width: "32%",
                        textAlign: "left",
                        fontSize: "1.8rem",
                        fontFamily: "futura-pt, sans-serif",
                        fontWeight: "500",
                        color: "#626262"
                      }}
                    >
                      Date Requested
                      <span style={{ color: "rgb(255, 0, 0)" }}> *</span>
                    </label>

                    <div
                      style={{
                        height: "40px",
                        width: "70%",
                        marginLeft: "30px"
                      }}
                    >
                      <DatePicker
                        selected={this.state.requestedDate}
                        minDate={new Date("2020-04-01T04:00:00.000Z")}
                        maxDate={
                          new Date(Date.now() + 21 * 24 * 60 * 60 * 1000)
                        }
                        onChange={date =>
                          this.setState({ requestedDate: date })
                        }
                        dateFormat="MMMM d, yyyy"
                        className="form-control-date"
                        placeholderText="Date Requested"
                        filterDate={this.filterDate}
                        name="requestedDate"
                        autoComplete="off"
                      />
                    </div>
                  </div>

                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "10px"
                    }}
                  >
                    <label
                      style={{
                        width: "32%",
                        textAlign: "left",
                        fontSize: "1.8rem",
                        fontFamily: "futura-pt, sans-serif",
                        fontWeight: "500",
                        color: "#626262"
                      }}
                    >
                      Requested Time
                      <span style={{ color: "rgb(255, 0, 0)" }}> *</span>
                    </label>

                    <div
                      style={{
                        height: "40px",
                        width: "70%",
                        marginLeft: "30px"
                      }}
                    >
                      <select
                        name="requestedTime"
                        id="requestedTime"
                        //className="form-control"
                        style={{
                          width: "100%",
                          height: "40px",
                          fontSize: "1.5rem",
                          fontFamily: "futura-pt, sans-serif",
                          fontWeight: "400",
                          color: "#797979",
                          border: "1px solid #c5c5c5",
                          borderRadius: "4px",
                          paddingLeft: "14px"
                        }}
                        disabled={!this.state.requestedDate}
                        onChange={e => {
                          this.setState({ [e.target.name]: e.target.value });
                        }}
                      >
                        <option value="">Please select a time...</option>
                        {this.filterTimes().map((t, i) => {
                          return (
                            <option
                              value={parseFloat(`${t.h}.${t.m === 30 ? 5 : 0}`)}
                              key={i}
                            >
                              {dateFns.format(
                                new Date().setHours(t.h, t.m, 0),
                                "hh:mm a"
                              )}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  <div
                    className="w-100 text-center mt-5"
                    style={{
                      fontSize: "9pt",
                      fontStyle: "italic",
                      color: "#2750CC",
                      cursor: "pointer",
                      fontFamily: "futura-pt, sans-serif"
                    }}
                    onClick={() =>
                      this.setState({
                        otherShown: true,
                        requestedDate: "",
                        requestedTime: "",
                        isWithinSetTime: false
                      })
                    }
                  >
                    Or select a time outside of the Educator's availability!
                    <i className="ml-3 fas fa-arrow-right"></i>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "10px"
                    }}
                  >
                    <label
                      style={{
                        width: "32%",
                        textAlign: "left",
                        fontSize: "1.8rem",
                        fontFamily: "futura-pt, sans-serif",
                        fontWeight: "500",
                        color: "#626262"
                      }}
                    >
                      Date Requested
                      <span style={{ color: "rgb(255, 0, 0)" }}> *</span>
                    </label>

                    <div
                      style={{
                        height: "40px",
                        width: "70%",
                        marginLeft: "30px"
                      }}
                    >
                      <DatePicker
                        selected={this.state.requestedDate}
                        minDate={new Date("2020-04-01T04:00:00.000Z")}
                        maxDate={
                          new Date(Date.now() + 21 * 24 * 60 * 60 * 1000)
                        }
                        onChange={date =>
                          this.setState({ requestedDate: date })
                        }
                        dateFormat="MMMM d, yyyy"
                        className="form-control-date"
                        autoComplete="off"
                        placeholderText="Date Requested"
                        name="requestedDate"
                      />
                    </div>
                  </div>

                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "10px"
                    }}
                  >
                    <label
                      style={{
                        width: "32%",
                        textAlign: "left",
                        fontSize: "1.8rem",
                        fontFamily: "futura-pt, sans-serif",
                        fontWeight: "500",
                        color: "#626262"
                      }}
                    >
                      Requested Time
                      <span style={{ color: "rgb(255, 0, 0)" }}> *</span>
                    </label>

                    <div
                      style={{
                        height: "40px",
                        width: "70%",
                        marginLeft: "30px"
                      }}
                    >
                      <select
                        name="requestedTime"
                        id="requestedTime"
                        //className="form-control"
                        style={{
                          width: "100%",
                          height: "40px",
                          fontSize: "1.5rem",
                          fontFamily: "futura-pt, sans-serif",
                          fontWeight: "400",
                          color: "#797979",
                          border: "1px solid #c5c5c5",
                          borderRadius: "4px",
                          paddingLeft: "14px"
                        }}
                        disabled={!this.state.requestedDate}
                        onChange={e => {
                          this.setState({ [e.target.name]: e.target.value });
                        }}
                      >
                        <option value="">Please select a time...</option>
                        {Array.from(Array(16).keys()).map((t, i) => {
                          return (
                            <option
                              value={
                                i % 2 === 0
                                  ? parseInt(t) + 7
                                  : parseInt(t) + 7.5
                              }
                              key={i}
                            >
                              {i % 2 === 0
                                ? dateFns.format(
                                    new Date().setHours(parseInt(t) + 7, 0, 0),
                                    "hh:mm a"
                                  )
                                : dateFns.format(
                                    new Date().setHours(parseInt(t) + 7, 30, 0),
                                    "hh:mm a"
                                  )}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  <div
                    className="w-100 text-center mt-5"
                    style={{
                      fontSize: "9pt",
                      fontStyle: "italic",
                      color: "#2750CC",
                      cursor: "pointer",
                      fontFamily: "futura-pt, sans-serif"
                    }}
                    onClick={() =>
                      this.setState({
                        otherShown: false,
                        requestedDate: "",
                        requestedTime: "",
                        isWithinSetTime: true
                      })
                    }
                  >
                    <i className="mr-3 fas fa-arrow-left"></i>Or select a time
                    inside of the Educator's availability
                  </div>
                </>
              )}
            </div>
          </form>
        )}
      </div>
    );
  };

  surveyModal = () => {
    return (
      <div className="modal-main">
        <h4
          style={{
            textAlign: "center",
            color: "red",
            display: this.state.error ? "block" : "none",
            fontFamily: "futura-pt, sans-serif"
          }}
        >
          Please fill all the fields to continue!
        </h4>
        <div
          className="form-group"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px"
          }}
        >
          <label
            name="age"
            style={{
              width: "25%",
              textAlign: "left",
              fontSize: "1.8rem",
              fontFamily: "futura-pt, sans-serif",
              fontWeight: "500",
              color: "#626262"
            }}
          >
            {" "}
            What is your child's age?
          </label>
          <div
            style={{
              width: "70%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row-reverse"
            }}
          >
            <select
              name="years"
              id="agedesired"
              type="number"
              min="0"
              max="20"
              //className="form-control"
              style={{
                width: "100%",
                height: "40px",
                fontSize: "1.5rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "400",
                color: "#797979",
                border: "1px solid #c5c5c5",
                borderRadius: "4px",
                paddingLeft: "14px"
              }}
              validation={"isNumber"}
              value={this.state.years}
              onChange={e => {
                this.setState({
                  [e.target.name]: e.target.value
                });
              }}
            >
              <option value="" disabled>
                Select Years
              </option>
              {new Array(10).fill(1).map((_, i) => (
                <option value={i + 2} key={i}>
                  {i + 2}
                  {` Years`}
                </option>
              ))}
            </select>
            <br />

            <select
              name="months"
              id="agedesired"
              type="number"
              min="0"
              max="11"
              //className="form-control"
              style={{
                width: "100%",
                height: "40px",
                fontSize: "1.5rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "400",
                color: "#797979",
                border: "1px solid #c5c5c5",
                borderRadius: "4px",
                paddingLeft: "14px"
              }}
              validation={"isNumber"}
              value={this.state.months}
              onChange={e => {
                this.setState({
                  [e.target.name]: e.target.value
                });
              }}
            >
              <option value="" disabled>
                Select Months
              </option>
              {new Array(23).fill(1).map((_, i) => (
                <option value={i + 1} key={i}>
                  {i + 1}
                  {i === 0 ? ` Month` : ` Months`}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div
          className="form-group"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px"
          }}
        >
          <label
            name="days"
            style={{
              width: "25%",
              textAlign: "left",
              fontSize: "1.8rem",
              fontFamily: "futura-pt, sans-serif",
              fontWeight: "500",
              color: "#626262"
            }}
          >
            {" "}
            Days of care needed
          </label>
          <div
            name="days"
            className="row days"
            validation={"validDays"}
            style={{
              width: "70%"
            }}
          >
            <div
              data-day="Monday"
              className={`day-monday day ${
                this.state.days["Monday"] ? "selecta" : ""
              }`}
              onClick={e => this.setDay(e.target.getAttribute("data-day"))}
            >
              M
            </div>
            <div
              data-day="Tuesday"
              className={`day-tuesday day ${
                this.state.days["Tuesday"] ? "selecta" : ""
              }`}
              onClick={e => this.setDay(e.target.getAttribute("data-day"))}
            >
              Tu
            </div>
            <div
              data-day="Wednesday"
              className={`day-wednesday day ${
                this.state.days["Wednesday"] ? "selecta" : ""
              }`}
              onClick={e => this.setDay(e.target.getAttribute("data-day"))}
            >
              W
            </div>
            <div
              data-day="Thursday"
              className={`day-thursday day ${
                this.state.days["Thursday"] ? "selecta" : ""
              }`}
              onClick={e => this.setDay(e.target.getAttribute("data-day"))}
            >
              Th
            </div>
            <div
              data-day="Friday"
              className={`day-friday day ${
                this.state.days["Friday"] ? "selecta" : ""
              }`}
              onClick={e => this.setDay(e.target.getAttribute("data-day"))}
            >
              F
            </div>
            <div
              data-day="Saturday"
              className={`day-saturday day ${
                this.state.days["Saturday"] ? "selecta" : ""
              }`}
              onClick={e => this.setDay(e.target.getAttribute("data-day"))}
            >
              Sa
            </div>
            <div
              data-day="Sunday"
              className={`day-sunday day ${
                this.state.days["Sunday"] ? "selecta" : ""
              }`}
              onClick={e => this.setDay(e.target.getAttribute("data-day"))}
            >
              Su
            </div>
          </div>
        </div>

        <div
          className="form-group"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px"
          }}
        >
          <label
            name="dropOffTime"
            style={{
              width: "25%",
              textAlign: "left",
              fontSize: "1.8rem",
              fontFamily: "futura-pt, sans-serif",
              fontWeight: "500",
              color: "#626262"
            }}
          >
            Preferred Drop Off Time
            <span style={{ color: "rgb(255, 0, 0)" }}> *</span>
          </label>

          <div
            style={{
              height: "40px",
              width: "80%",
              marginLeft: "30px"
            }}
          >
            <select
              //className="form-control"
              style={{
                width: "100%",
                height: "40px",
                fontSize: "1.5rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "400",
                color: "#797979",
                border: "1px solid #c5c5c5",
                borderRadius: "4px",
                paddingLeft: "14px"
              }}
              name="dropOff"
              onChange={e => this.setState({ [e.target.name]: e.target.value })}
              title="Drop Off Time"
              validation={"isNotEmpty"}
            >
              <option value="" defaultValue>
                Select time
              </option>
              <option value="6:00 am">6:00 am</option>
              <option value="7:00 am">7:00 am</option>
              <option value="8:00 am">8:00 am</option>
              <option value="9:00 am">9:00 am</option>
              <option value="10:00 am">10:00 am</option>
              <option value="11:00 am">11:00 am</option>
              <option value="12:00 pm">12:00 pm</option>
              <option value="1:00 pm">1:00 pm</option>
              <option value="2:00 pm">2:00 pm</option>
              <option value="3:00 pm">3:00 pm</option>
              <option value="4:00 pm">4:00 pm</option>
              <option value="5:00 pm">5:00 pm</option>
              <option value="6:00 pm">6:00 pm</option>
              <option value="7:00 pm">7:00 pm</option>
            </select>
          </div>
        </div>

        <div
          className="form-group"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px"
          }}
        >
          <label
            name="pickUpTime"
            style={{
              width: "25%",
              textAlign: "left",
              fontSize: "1.8rem",
              fontFamily: "futura-pt, sans-serif",
              fontWeight: "500",
              color: "#626262"
            }}
          >
            Preferred Pick Up Time
            <span style={{ color: "rgb(255, 0, 0)" }}> *</span>
          </label>

          <div
            style={{
              height: "40px",
              width: "80%",
              marginLeft: "30px"
            }}
          >
            <select
              //className="form-control"
              style={{
                width: "100%",
                height: "40px",
                fontSize: "1.5rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "400",
                color: "#797979",
                border: "1px solid #c5c5c5",
                borderRadius: "4px",
                paddingLeft: "14px"
              }}
              name="pickUp"
              onChange={e => this.setState({ [e.target.name]: e.target.value })}
              validation={"isNotEmpty"}
              title="Pick Up Time"
            >
              <option value="" defaultValue>
                Select time
              </option>
              <option value="6:00 am">6:00 am</option>
              <option value="7:00 am">7:00 am</option>
              <option value="8:00 am">8:00 am</option>
              <option value="9:00 am">9:00 am</option>
              <option value="10:00 am">10:00 am</option>
              <option value="11:00 am">11:00 am</option>
              <option value="12:00 pm">12:00 pm</option>
              <option value="1:00 pm">1:00 pm</option>
              <option value="2:00 pm">2:00 pm</option>
              <option value="3:00 pm">3:00 pm</option>
              <option value="4:00 pm">4:00 pm</option>
              <option value="5:00 pm">5:00 pm</option>
              <option value="6:00 pm">6:00 pm</option>
              <option value="7:00 pm">7:00 pm</option>
            </select>
          </div>
        </div>

        <div
          className="form-group"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px"
          }}
        >
          <label
            name="childcareneed"
            style={{
              width: "25%",
              textAlign: "left",
              fontSize: "1.8rem",
              fontFamily: "futura-pt, sans-serif",
              fontWeight: "500",
              color: "#626262"
            }}
          >
            When do you need childcare?
            <span style={{ color: "rgb(255, 0, 0)" }}> *</span>
          </label>

          <div
            className="row"
            style={{
              height: "40px",
              width: "80%",
              marginLeft: "30px",
              fontFamily: "futura-pt, sans-serif"
            }}
          >
            <input
              type="radio"
              name="childcareneed"
              value="Immediately"
              validation={"isNotEmpty"}
              onClick={e => this.clickRadio(e)}
            />{" "}
            Immediately <br />
            <input
              type="radio"
              name="childcareneed"
              style={{
                marginLeft: "5px"
              }}
              value="1-3 Months"
              validation={"isNotEmpty"}
              onClick={e => this.clickRadio(e)}
            />{" "}
            1-3 Months <br />
            <input
              type="radio"
              name="childcareneed"
              style={{
                marginLeft: "5px"
              }}
              value="3+ Months"
              validation={"isNotEmpty"}
              onClick={e => this.clickRadio(e)}
            />{" "}
            3+ Months
            <br />
          </div>
        </div>
      </div>
    );
  };

  confirmationModal = () => {
    return (
      <>
        <h4
          style={{
            fontSize: "16px",
            fontFamily: "futura-pt, sans-serif",
            fontWeight: "500",
            textAlign: "center",
            color: "red",
            display: this.state.codeError ? "block" : "none"
          }}
        >
          The code entered is invalid!
        </h4>
        <p
          style={{
            fontSize: "16px",
            fontFamily: "futura-pt, sans-serif",
            fontWeight: "500",
            color: "#626262"
          }}
        >
          Hey {this.state.displayName}, we just sent a verification code to :{" "}
          {this.state.requestPhone}.
        </p>
        <p
          style={{
            fontSize: "16px",
            fontFamily: "futura-pt, sans-serif",
            fontWeight: "500",
            color: "#626262"
          }}
        >
          Please enter the code below to send your tour request :
        </p>
        <input
          name="phoneCode"
          type="text"
          style={{
            height: "40px",
            width: "100%",
            fontSize: "1.5rem",
            fontFamily: "futura-pt, sans-serif",
            fontWeight: "400",
            color: "#797979",
            border: "1px solid #c5c5c5",
            borderRadius: "4px",
            paddingLeft: "14px"
          }}
          placeholder="Enter your verification code here..."
          //className="form-control"
          value={this.state.otp}
          onChange={e => this.setState({ otp: e.target.value })}
        />
      </>
    );
  };

  completedModal = () => {
    return (
      <p
        style={{
          fontSize: "16px",
          fontFamily: "futura-pt, sans-serif",
          fontWeight: "500",
          color: "#626262"
        }}
      >
        The educator will review your tour request and get back to you shortly.
        Please keep an eye on your emails or SMS, so you know when your tour
        gets accepted.
      </p>
    );
  };

  getBaseModalFields() {
    const mainmodalInput = {
      type: GET_HTML,
      data: {
        gethtml: this.addnewmodal
      }
    };

    const surveymodalInput = {
      type: GET_HTML,
      data: {
        gethtml: this.surveyModal
      }
    };

    const confirmationmodalInput = {
      type: GET_HTML,
      data: {
        gethtml: this.confirmationModal
      }
    };

    const completedmodalInput = {
      type: GET_HTML,
      data: {
        gethtml: this.completedModal
      }
    };

    const fields = [];
    if (!this.state.done && !this.state.survey && !this.state.code) {
      fields.push(mainmodalInput);
    }
    if (this.state.survey) {
      fields.push(surveymodalInput);
    }
    if (!this.state.survey && this.state.code) {
      fields.push(confirmationmodalInput);
    }
    if (this.state.done) {
      fields.push(completedmodalInput);
    }

    return fields;
  }

  getBaseModalButtons = () => {
    const cancelButton = {
      name: "Cancel",
      buttonColour: RED_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: this.props.onClose
    };

    const requesttourButton = {
      name: "Request Tour",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: this.requestTour
    };

    const backButton = {
      name: "Back",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: () =>
        this.setState({
          code: false,
          survey: this.state.survey === undefined ? undefined : true
        })
    };

    const nextButton = {
      name: "Next",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => this.verifyCode(e)
    };

    const surveybackButton = {
      name: "Back",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: () =>
        this.setState({
          survey: false
        })
    };

    const surveynextButton = {
      name: "Next",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: this.submitSurvey
    };

    const finishButton = {
      name: "Finish",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      handleClick: this.props.onClose
    };

    const fieldsButton = [];
    if (!this.state.done && !this.state.survey && !this.state.code) {
      fieldsButton.push(cancelButton);
      fieldsButton.push(requesttourButton);
    }
    if (!this.state.survey && this.state.code) {
      fieldsButton.push(backButton);
      fieldsButton.push(nextButton);
    }
    if (this.state.survey) {
      fieldsButton.push(surveybackButton);
      fieldsButton.push(surveynextButton);
    }
    if (this.state.done) {
      fieldsButton.push(finishButton);
    }

    return fieldsButton;
  };

  getBaseModalProps = () => {
    return {
      title:
        !this.state.survey && this.state.code
          ? "Verify your Phone"
          : this.state.survey
          ? "Please fill this quick survey to continue!"
          : this.state.done
          ? "Thank you for requesting a tour!"
          : "Request Tour",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      handleClose: this.props.onClose,
      height: 650
      /*  width: "550px" */
    };
  };

  /// ================== END NEW MODAL ========================== ///

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;

    let heading = "Request Tour";
    if (!this.state.survey && this.state.code) {
      heading = "Verify your Phone";
    } else if (this.state.survey) {
      heading = "Please fill this quick survey to continue!";
    } else if (this.state.done) {
      heading = "Thank you for requesting a tour!";
    }

    const requestedMinutes =
      parseFloat(this.state.requestedTime) % 1 === 0 ? 0 : 30;

    const requestedHours =
      parseFloat(this.state.requestedTime) % 1 === 0
        ? parseFloat(this.state.requestedTime)
        : parseFloat(this.state.requestedTime) - 0.5;

    return (
      <ModalTemplate heading={heading} onClose={this.props.onClose}>
        {!this.state.done && !this.state.survey && !this.state.code && (
          <form>
            <div className="modal-main">
              {this.global.dsUser ? null : (
                <>
                  <ModalFields.input
                    name="displayName"
                    label="Name"
                    required
                    type="text"
                    placeholder="Your Name..."
                    value={this.state.displayName}
                    onChange={e => {
                      this.setState({
                        [e.target.name]: e.target.value
                      });
                    }}
                  />
                  <ModalFields.input
                    name="email"
                    label="Email"
                    required
                    type="text"
                    placeholder="Your Email..."
                    value={this.state.email}
                    onChange={e => {
                      this.setState({
                        [e.target.name]: e.target.value.toLowerCase()
                      });
                    }}
                  />
                  <ModalFields.input
                    name="requestPhone"
                    label="Cell Phone"
                    required
                    type="tel"
                    maxLength={"10"}
                    placeholder="Your Phone..."
                    value={this.state.requestPhone}
                    onChange={e => {
                      if (/^[0-9]{0,10}$/.test(e.target.value)) {
                        this.setState({
                          [e.target.name]: e.target.value
                        });
                      }
                    }}
                    /*                     onChange={e => {
                      this.setState({
                        [e.target.name]: e.target.value
                      });
                    }} */
                  />
                  {this.state.phoneValidation && (
                    <div style={{ color: "red" }}>
                      The phone number entered must contain numerical values
                      between 0 to 9 only
                    </div>
                  )}
                  <ModalFields.textarea
                    name="requestMessage"
                    label="Message to the Educator"
                    placeholder="Enter your message..."
                    value={this.state.requestMessage}
                    onChange={e => {
                      this.setState({
                        [e.target.name]: e.target.value
                      });
                    }}
                  />
                </>
              )}

              {!this.state.otherShown ? (
                <>
                  <ModalFields.custom
                    label="Date Requested"
                    required
                    name="requestDate"
                  >
                    <DatePicker
                      selected={this.state.requestedDate}
                      minDate={new Date("2020-04-01T04:00:00.000Z")}
                      maxDate={new Date(Date.now() + 21 * 24 * 60 * 60 * 1000)}
                      onChange={date => this.setState({ requestedDate: date })}
                      dateFormat="MMMM d, yyyy"
                      className="form-control"
                      placeholderText="Date Requested"
                      filterDate={this.filterDate}
                      name="requestedDate"
                      autoComplete="off"
                    />
                  </ModalFields.custom>
                  <ModalFields.custom
                    label="Requested Time"
                    name="requestedTime"
                    required
                  >
                    <select
                      name="requestedTime"
                      id="requestedTime"
                      className="form-control"
                      disabled={!this.state.requestedDate}
                      onChange={e => {
                        this.setState({ [e.target.name]: e.target.value });
                      }}
                    >
                      <option value="">Please select a time...</option>
                      {this.filterTimes().map((t, i) => {
                        return (
                          <option
                            value={parseFloat(`${t.h}.${t.m === 30 ? 5 : 0}`)}
                            key={i}
                          >
                            {dateFns.format(
                              new Date().setHours(t.h, t.m, 0),
                              "hh:mm a"
                            )}
                          </option>
                        );
                      })}
                    </select>
                  </ModalFields.custom>

                  <div
                    className="w-100 text-center mt-5"
                    style={{
                      fontSize: "9pt",
                      fontStyle: "italic",
                      color: "#2750CC",
                      cursor: "pointer"
                    }}
                    onClick={() =>
                      this.setState({
                        otherShown: true,
                        requestedDate: "",
                        requestedTime: "",
                        isWithinSetTime: false
                      })
                    }
                  >
                    Or select a time outside of the Educator's availability!
                    <i className="ml-3 fas fa-arrow-right"></i>
                  </div>
                </>
              ) : (
                <>
                  <ModalFields.custom
                    label="Date Requested"
                    name="requestedDate"
                    required
                  >
                    <DatePicker
                      selected={this.state.requestedDate}
                      minDate={new Date("2020-04-01T04:00:00.000Z")}
                      maxDate={new Date(Date.now() + 21 * 24 * 60 * 60 * 1000)}
                      onChange={date => this.setState({ requestedDate: date })}
                      dateFormat="MMMM d, yyyy"
                      className="form-control"
                      autoComplete="off"
                      placeholderText="Date Requested"
                      name="requestedDate"
                    />
                  </ModalFields.custom>

                  <ModalFields.custom
                    label="Requested Time"
                    name="requestedTime"
                    required
                  >
                    <select
                      name="requestedTime"
                      id="requestedTime"
                      className="form-control"
                      disabled={!this.state.requestedDate}
                      onChange={e => {
                        this.setState({ [e.target.name]: e.target.value });
                      }}
                    >
                      <option value="">Please select a time...</option>
                      {Array.from(Array(16).keys()).map((t, i) => {
                        return (
                          <option
                            value={
                              i % 2 === 0 ? parseInt(t) + 7 : parseInt(t) + 7.5
                            }
                            key={i}
                          >
                            {i % 2 === 0
                              ? dateFns.format(
                                  new Date().setHours(parseInt(t) + 7, 0, 0),
                                  "hh:mm a"
                                )
                              : dateFns.format(
                                  new Date().setHours(parseInt(t) + 7, 30, 0),
                                  "hh:mm a"
                                )}
                          </option>
                        );
                      })}
                    </select>
                  </ModalFields.custom>

                  <div
                    className="w-100 text-center mt-5"
                    style={{
                      fontSize: "9pt",
                      fontStyle: "italic",
                      color: "#2750CC",
                      cursor: "pointer"
                    }}
                    onClick={() =>
                      this.setState({
                        otherShown: false,
                        requestedDate: "",
                        requestedTime: "",
                        isWithinSetTime: true
                      })
                    }
                  >
                    <i className="mr-3 fas fa-arrow-left"></i>Or select a time
                    inside of the Educator's availability
                  </div>
                </>
              )}
            </div>

            <div className="modal-footer">
              <button
                onClick={this.props.onClose}
                className="mx-2 backbtn btn profile-btn"
              >
                Cancel
              </button>
              <button
                type="submit"
                onClick={this.requestTour}
                className="btn profile-btn"
              >
                Request Tour
              </button>
            </div>
          </form>
        )}
        {this.state.survey && (
          <>
            <Survey
              submit={info => this.surveyForm(info)}
              back={() =>
                this.setState({
                  survey: false
                })
              }
            />
          </>
        )}
        {!this.state.survey && this.state.code && (
          <>
            <h4
              style={{
                textAlign: "center",
                color: "red",
                display: this.state.codeError ? "block" : "none"
              }}
            >
              The code entered is invalid!
            </h4>
            <p>
              Hey {this.state.displayName}, we just sent a verification code to
              : {this.state.requestPhone}.
            </p>
            <p>Please enter the code below to send your tour request :</p>
            <form onSubmit={e => this.verifyCode(e)}>
              <input
                name="phoneCode"
                type="text"
                placeholder="Enter your verification code here..."
                className="form-control"
                value={this.state.otp}
                onChange={e => this.setState({ otp: e.target.value })}
              />
              <div className="modal-footer">
                <button
                  type="button"
                  className="mx-2 backbtn btn profile-btn"
                  onClick={() =>
                    this.setState({
                      code: false,
                      survey: this.state.survey === undefined ? undefined : true
                    })
                  }
                >
                  Back
                </button>
                <button type="submit" className="btn profile-btn">
                  Next
                </button>
              </div>
            </form>
          </>
        )}

        {this.state.done && (
          <p>
            The educator will review your tour request and get back to you
            shortly. Please keep an eye on your emails or SMS, so you know when
            your tour gets accepted.
          </p>
        )}
      </ModalTemplate>
    );
  }
}

export default withRouter(RequestTourrmationModal);
