import React from "react";
import { Calendar, Books, Piggy_Bank } from "../../../assets";

const Benefits = () => {
  return (
    <div className="container-fluid providebenefits">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1>Benefits of starting your own Dreamschool</h1>

            <div className="boxes">
              <div className="box">
                <div className="placeforimg">
                  <div className="img">
                    <img
                      style={{ maxWidth: "160px" }}
                      src={Calendar}
                      alt="Calendar"
                    />
                  </div>
                </div>
                <div className="fortitle">
                  <h2>SCHEDULING</h2>
                </div>
                <p>
                  Get more control over your time by setting your own schedule
                </p>
              </div>
              <div className="box">
                <div className="placeforimg">
                  <div className="img">
                    <img src={Books} alt="Books" />
                  </div>
                </div>
                <div className="fortitle">
                  <h2>TEACH WHAT YOU LOVE</h2>
                </div>
                <p>
                  Design your own curriculum
                  <br />
                  and teaching philosophy
                </p>
              </div>
              <div className="box">
                <div className="placeforimg">
                  <div className="img">
                    <img src={Piggy_Bank} alt="Piggy bank" />
                  </div>
                </div>
                <div className="fortitle">
                  <h2>OPTMIZE EARNINGS</h2>
                </div>
                <p>
                  Intelligently manage earnings
                  <br />
                  and expenses to optimize your earnings
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="line"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
