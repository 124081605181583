import { setGlobal, addReducer } from "reactn";
import axios from "axios";
import "./shared";
import "./auth/auth";
import "./parents/cart";
import "./parents/familyProfile"

setGlobal({
  ccUser: {},
  ccContacts: [],
  openVerificationModal: false
});

addReducer("saveKids", async (global, dispatch, submit) => {
  setGlobal({ loading: true });
  try {
    const ep = `${process.env.REACT_APP_API}/frnt/program/kidprofile/save`;
    const res = await axios.put(ep, {
      ...global.organizationInfo,
      other_locations: global.organizationInfo.other_locations.filter(e => e),
      age_range: global.organizationInfo.age_range.map(e => e.name).join(", "),
      program_structures: global.organizationInfo.program_structures
        .map(e => e.name)
        .join(", "),
      short_save: typeof submit === "boolean"
    });

    if (typeof submit === "function") submit(res.data.data.slug);

    return {
      ...global,
      loading: false,
      lastAPICall: res
    };
  } catch (err) {
    console.error(err.message);
    return { ...global, loading: false, lastAPICall: null };
  }
});
addReducer("uploadProgramPhoto", async (global, dispatch, photo) => {
  setGlobal({ loading: true });
  try {
    const ep = `${process.env.REACT_APP_API}/frnt/program/kidprofile/gallery`;
    const res = await axios.post(ep, photo);
    if (res.data.data["gallery"]) {
      return {
        ...global,
        organizationInfo: {
          ...global.organizationInfo,
          gallery: [...global.organizationInfo.gallery, res.data.data.gallery]
        },
        loading: false,
        lastAPICall: res
      };
    }
    return {
      ...global,
      organizationInfo: { ...global.organizationInfo, ...res.data.data },
      loading: false,
      lastAPICall: res
    };
  } catch (err) {
    console.error(err.message);
    return { ...global, loading: false, lastAPICall: null };
  }
});

addReducer("saveSearch", async (global, dispatch, submit) => {
  setGlobal({ loading: true });
  try {
    return {
      ...global,
      loading: false
    };
  } catch (err) {
    console.error(err.message);
    return { ...global, loading: false, lastAPICall: null };
  }
});

const setParents = () => {
  require("./parents");
};

const setEducators = () => {
  require("./educators");
};

const setAdmin = () => {
  require("./admin");
};
const setPartner = () => {
  require("./partners");
};
setGlobal({
  loading: false,
  sidenavShown: false,
  lastAPICall: {},
  dsUser: null,
  pathway: []
});

export { setParents, setEducators, setAdmin, setPartner };
