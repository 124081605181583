import {
  setGlobal
  // addReducer
} from "reactn";
// import axios from "axios";
// import { toast } from "react-toastify";

const profile = {
  organization_title: "",
  address1: "",
  address2: "",
  city: "",
  province: "",
  postalcode: "",
  country: "",
  target_ages: ""
};

setGlobal({ organizationInfo: profile });
