import React, { useState, useEffect, useGlobal } from "reactn";
import PropTypes from "prop-types";
import { coupon_discount, applyGift, getSelectedProgramPlan } from "./utils";

const ProgramSemesterInstallmentsFee1 = props => {
  const [total, setTotal] = useState("Loading");
  const [depositTotal, setDepositTotal] = useState("0");
  const [_, setPreGiftTotal] = useGlobal("preGiftTotal");
  const giftBalance = useGlobal("giftBalance")[0];

  const calculateTotal = () => {
    const selected_plan = getSelectedProgramPlan(props.program, props.selected);
    let total_price = parseFloat(selected_plan.total_price);
    if (selected_plan.addOnItems) {
      selected_plan.addOnItems.map(item => {
        total_price += parseFloat(item.value);
      });
    }
    const installments_breakdown = parseInt(
      selected_plan.installments_breakdown
    );
    const installments_interest = parseInt(selected_plan.installments_interest ? selected_plan.installments_interest : 0);
    const taxRate = selected_plan.isTaxEnabled
      ? parseFloat(selected_plan.taxInfo[0].percentage) / 100
      : 0;
    const installments =
      (props.installments ? 1 + installments_interest / 100 : 1) || 0;

    const total_amount = total_price * installments;
    const discount_amount = props.coupon
      ? coupon_discount(props.coupon, total_amount)
      : 0;
    const tax_amount = Math.max(0, total_amount - discount_amount) * taxRate;

    // Credit Card Processing Fee
    const creditCardPercent = props.program.transaction_cost
      ? parseFloat(1.5) / 100
      : 1;
    const creditCardFee = props.program.transaction_cost
      ? Math.max(0.5, total_amount - discount_amount) * creditCardPercent
      : 0;
    // const creditCardFee = 0;
    let tot =
      Math.max(0, total_amount - discount_amount + tax_amount + creditCardFee) /
      installments_breakdown;
    console.log(
      "LOOK HERE 123",
      total_amount,
      discount_amount,
      tax_amount,
      tot
    );

    props.totalAmount(tot.toFixed(2));
    setTotal(tot.toFixed(2));

    if (
      selected_plan.includeDeposit &&
      selected_plan.deposit &&
      selected_plan.deposit !== "0"
    ) {
      const depositAmount = parseFloat(selected_plan.deposit);
      const depositDiscount = props.coupon
        ? coupon_discount(props.coupon, depositAmount)
        : 0;
      const depositTax = Math.max(0, depositAmount - depositDiscount) * taxRate;
      let tot = Math.max(0, depositAmount - depositDiscount) + depositTax;
      setDepositTotal(tot.toFixed(2));
    }

    setPreGiftTotal(tot);
    ({ amount: tot } = applyGift(
      props.program.manual_invoices,
      giftBalance,
      tot
    ));
  };

  useEffect(() => {
    calculateTotal();
  }, [props.coupon, giftBalance]);

  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px",
        borderBottom: "1px solid #dedede"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif",
          fontWeight: 700,
          fontSize: "14px"
        }}
      >
        Installment #1 - {"Payable Now"}
      </p>
      <span style={{ fontWeight: 700 }}>${parseFloat(total).toFixed(2)}</span>
    </li>
  );
};

ProgramSemesterInstallmentsFee1.propTypes = {
  program: PropTypes.shape({
    plans: PropTypes.array.isRequired
  }).isRequired,
  selected: PropTypes.number.isRequired,
  installments: PropTypes.number.isRequired,
  coupon: PropTypes.object
};

export default ProgramSemesterInstallmentsFee1;
