import React from "reactn";
import PropTypes from "prop-types";

import TicketPrice from "./TicketPrice";
import EventSubtotal from "./EventSubtotal";
import CheckoutPromotionDisplay from "./CheckoutPromotionDisplay";
import TaxableEvent from "./TaxableEvent";
import EventPromotionsTotal from "./EventPromotionsTotal";
import CheckoutCouponDisplay from "./CheckoutCouponDisplay";
import EventTotal from "./EventTotal";
import TaxablePromotionEvent from "./TaxablePromotionEvent";
import { getSelectedEventTicket } from "./utils";

import CheckoutGiftCard from "./CheckoutGiftCard";

const CheckoutEvent = ({
  program,
  selected,
  promotion,
  coupon,
  type,
  giftBalance,
  itemAmount,
  promotionAmount,
  couponAmount,
  taxAmount,
  totalAmount
}) => {
  let balance = giftBalance;
  if (!giftBalance) {
    balance = 0;
  }
  return (
    <>
      <TicketPrice
        program={program}
        selected={selected}
        itemAmount={e => itemAmount(e)}
      />
      <EventSubtotal program={program} selected={selected} />
      {getSelectedEventTicket(program, selected).isTaxEnabled && (
        <>
          {promotion ? (
            <>
              <TaxablePromotionEvent
                program={program}
                selected={selected}
                promotion={promotion}
                taxAmount={e => taxAmount(e)}
              />
            </>
          ) : (
            <>
              <TaxableEvent
                coupon={coupon}
                program={program}
                selected={selected}
                taxAmount={e => taxAmount(e)}
              />
            </>
          )}
        </>
      )}
      {promotion && (
        <CheckoutPromotionDisplay
          promotion={promotion}
          program={program}
          selected={selected}
          promotionAmount={e => promotionAmount(e)}
        />
      )}
      {coupon && (
        <>
          <CheckoutCouponDisplay
            type={type}
            program={program}
            coupon={coupon}
            selected={selected}
            couponAmount={e => couponAmount(e)}
          />
        </>
      )}

      {!!giftBalance && (
        <CheckoutGiftCard
          giftBalance={giftBalance}
          manual={program.manual_invoices}
        />
      )}

      {promotion ? (
        <>
          <EventPromotionsTotal
            program={program}
            selected={selected}
            promotion={promotion}
            totalAmount={e => totalAmount(e)}
          />{" "}
        </>
      ) : (
        <>
          <EventTotal
            coupon={coupon}
            program={program}
            selected={selected}
            totalAmount={e => totalAmount(e)}
          />{" "}
        </>
      )}
    </>
  );
};

CheckoutEvent.propTypes = {
  program: PropTypes.object.isRequired,
  selected: PropTypes.number.isRequired,
  promotion: PropTypes.object,
  coupon: PropTypes.object,
  type: PropTypes.string.isRequired
};

export default CheckoutEvent;
