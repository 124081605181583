import React from "reactn";
import "./RequestInfo.css";
import BaseModal from "./baseModal/BaseModal";
import { withRouter } from "react-router";
import { BLUE_BUTTON, RED_BUTTON, WHITE } from "./baseModal/colours";
import { TITLE } from "./baseModal/FieldTypes";
import { toast } from "react-toastify";

const ConfirmArchiveModal = ({
    onSubmit,
    onClose,
    confirmHeader,
    toArchive,
    toRemove,
    modalBody,
    isPermanent,
    notAllowed
  }) => {
    const confirmationHandler = async e => {
      const successMsg = toArchive
        ? "Successfully deleted!"
        : "Successfully restored!";
      const errorMsg = toArchive ? "Could not delete." : "Could not restore.";
      try {
        e.preventDefault();
        onSubmit();
        onClose();
        toast.success(successMsg);
      } catch (error) {
        toast.error(errorMsg);
      }
    };
  
    const getConfirmArchiveModalFields = () => {
      const titleField = {
        type: TITLE,
        data: {
            name:
                modalBody ||
                (toArchive
                ? `Are you sure you would like to remove this ${toRemove}? ${
                    isPermanent
                        ? "This will be a permanent deletion, and cannot be re-archived later."
                        : ""
                    }\nEnsure to click the SAVE button when successfully removed.`
                : `Are you sure you would like to restore this ${toRemove}? \nEnsure to click the SAVE button when successfully restored.`),
            titleStyle: {
                fontSize: "1.8rem"
            }
        }
      };
  
      const fields = [];
  
      fields.push(titleField);
  
      return fields;
    };
  
    const getConfirmArchiveModalButtons = () => {
      const createButton = {
        name: "Confirm",
        buttonColour: toArchive ? RED_BUTTON : BLUE_BUTTON,
        textColour: WHITE,
        handleClick: confirmationHandler
      };
      return [createButton];
    };
  
    //Modal for when deletion is allowed;
    const getConfirmArchiveModalProps = () => {
      return {
        title: confirmHeader || "Confirmation",
        fields: getConfirmArchiveModalFields(),
        buttons: getConfirmArchiveModalButtons(),
        height: "65vh",
        midSectionHeight: "52vh",
        handleClose: onClose
      };
    };
  
    //Modal props for when deletion is not allowed (user is enrolled to targeted program, event, etc...)
    const getArchiveNotAllowedProps = () => {
      return {
        title: confirmHeader || "Confirmation",
        fields: getConfirmArchiveModalFields(),
        height: "65vh",
        midSectionHeight: "52vh",
        handleClose: onClose
      };
    };
  
    return (
      <>
        {notAllowed ? (
          <BaseModal {...getArchiveNotAllowedProps()} />
        ) : (
          <BaseModal {...getConfirmArchiveModalProps()} />
        )}
      </>
    );
  };
  
  export default withRouter(ConfirmArchiveModal);
