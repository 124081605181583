import React from "reactn";
import { Link } from "react-router-dom";

class Login extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      rememberMe: false
    };
    this.onChange = this.onChange.bind(this);
    this.attemptLogin = this.attemptLogin.bind(this);
  }

  onChange = (e, v) => {
    this.setState({ [e]: v });
  };

  attemptLogin = async e => {
    e.preventDefault();
    const test = await this.dispatch.performLoginPartners(this.state);
    const location = this.props.location.search;
    if (this.global.dsUser) {
      this.props.history.push(
        !location ? "/partners" : location.slice(location.indexOf("=") + 1)
      );
    }

    return;
  };

  render() {
    const { email, password, rememberMe } = this.state;
    return (
      <div className="login-signup-form mb-5">
        <div className="form-content custom-form-content">
          <h2>Log-in</h2>

          <form
            id="new_session"
            className="new_user"
            noValidate="novalidate"
            onSubmit={this.attemptLogin}
          >
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <br />
              <input
                className="form-control"
                type="email"
                value={email}
                name="email"
                onChange={e => this.onChange("email", e.target.value)}
                id="email"
              />
            </div>

            <div className="form-group">
              <label htmlFor="password">Password</label>
              <br />
              <input
                className="form-control"
                type="password"
                name="password"
                value={password}
                onChange={e => this.onChange("password", e.target.value)}
                id="password"
              />
            </div>

            <label htmlFor="rememberMe">
              <input
                type="checkbox"
                checked={rememberMe}
                name="rememberMe"
                onChange={e => this.onChange("rememberMe", e.target.checked)}
                id="rememberMe"
              />{" "}
              Remember me
            </label>
            <div className="form-actions">
              <input
                type="submit"
                name="commit"
                value="Login"
                className="btn btn-custom"
                data-disable-with="Login"
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default Login;
