import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { ModalFields, ModalTemplate } from "../UI/modalComponents";
import BaseModal from "./baseModal/BaseModal";
import { GET_HTML } from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
class EditParentModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      "Full Name": "",
      Email: "",
      "Cell Phone": "",
      Address: "",
      City: "",
      Province: "",
      "Postal Code": "",
      Country: "",
      isArchived: false
      // "Work Phone": ""
    };
    this.required = [
      { name: "Full Name", validation: e => !e },
      { name: "Email", validation: e => !e },
      { name: "Cell Phone", validation: e => !e },
      { name: "Address", validation: e => !e },
      { name: "City", validation: e => !e },
      { name: "Province", validation: e => !e },
      { name: "Postal Code", validation: e => !e },
      { name: "Country", validation: e => !e }
    ];
    this.mappings = {
      fullName: "Full Name",
      email: "Email",
      homePhone: "Cell Phone",
      address: "Address",
      city: "City",
      province: "Province",
      country: "Country",
      postalCode: "Postal Code",
      isArchived: "isArchived"
      // workPhone: "Work Phone"
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };
  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/par/profile/parent/${this.props.params.id}`;
    const results = await axios.get(ep);

    if (results.data.success) {
      const obj = {};
      Object.keys(results.data.data).forEach(e => {
        if (this.state[this.mappings[e]] !== undefined) {
          obj[this.mappings[e]] = results.data.data[e];
        }
      });
      this.setState({ ...obj });
    }
  }
  reverseMap() {
    const obj = { ...this.mappings };
    Object.keys(this.mappings).forEach(
      e => (obj[e] = this.state[this.mappings[e]])
    );
    return obj;
  }
  async submitForm(e) {
    e.preventDefault();
    let cancel = false;
    for (let requiredField of this.required) {
      if (requiredField.validation(this.state[requiredField.name])) {
        document.getElementsByName(requiredField.name)[0].style.boxShadow =
          "none";
        document.getElementsByName(requiredField.name)[0].style.border =
          "1px solid red";
        cancel = true;
      }
    }
    if (cancel) return;
    if (this.state["Cell Phone"].length !== 10) {
      toast.error("Phone number must be 10 digits");
      return;
    }
    const endp = `${process.env.REACT_APP_API}/par/profile/parent`;
    const parentObj = {
      ...this.reverseMap(),
      id: this.props.params.id,
      isFirst: this.props.params.location === 0
    };
    const result = await axios.put(endp, parentObj);
    if (result.data.success) {
      const isFirst = this.props.params.location === 0;
      toast.success("User Profile Updated!");
      this.props.update(parentObj, isFirst);
      this.props.onClose();
    }
  }

  /// ================== NEW MODAL ========================== ///
  addnewmodal = () => {
    return (
      <div>
        {Object.keys(this.state)
          .filter(e => e !== "isArchived")
          .map((e, i) => (
            <div
              className="modal-main"
              style={{
                marginBottom: "10px"
              }}
            >
              <div
                //className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  style={{
                    width: "32%",
                    textAlign: "left",
                    fontSize: "1.8rem",
                    fontFamily: "futura-pt, sans-serif",
                    fontWeight: "500",
                    color: "#626262"
                  }}
                >
                  {e}
                  <span style={{ color: "rgb(255, 0, 0)" }}> *</span>
                </label>
                <div
                  style={{
                    height: "40px",
                    width: "80%",
                    marginLeft: "50px"
                  }}
                >
                  <ModalFields.input
                    //label={e}
                    name={e}
                    key={i}
                    style={{
                      height: "40px",
                      fontSize: "1.5rem",
                      fontFamily: "futura-pt, sans-serif",
                      fontWeight: "400",
                      color: "#797979",
                      border: "1px solid #c5c5c5",
                      borderRadius: "4px",
                      paddingLeft: "14px"
                    }}
                    //required={this.required.find(v => v.name === e)}
                    placeholder={`${e}...`}
                    disabled={e === "Email"}
                    onChange={e => {
                      this.setState({
                        [e.target.name]: e.target.value
                      });
                    }}
                    type={e === "Cell Phone" ? "number" : undefined}
                    onKeyPress={(event, label) => {
                      if (label === "Cell Phone") {
                        const keyCode = event.which
                          ? event.which
                          : event.keyCode;
                        const isValidKey = keyCode >= 48 && keyCode <= 57; // only allow numbers
                        if (!isValidKey) {
                          event.preventDefault();
                        }
                      }
                    }}
                    value={this.state[e]}
                  />
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  };

  getBaseModalFields() {
    const newClient = {
      type: GET_HTML,
      data: {
        gethtml: this.addnewmodal
      }
    };

    const fields = [];
    fields.push(newClient);

    return fields;
  }

  getBaseModalButtons = () => {
    const addButton = {
      name: "Save",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => this.submitForm(e)
    };

    return [addButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Edit Parent",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      handleClose: this.props.onClose,
      width: "550px",
      height: "68vh",
      midSectionHeight: "52vh"
    };
  };

  /// ================== END NEW MODAL ========================== ///

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
  }
}

export default withRouter(EditParentModal);
