const yesNoOptions = [
  { name: "Please Select", value: "" },
  { name: "Yes", value: "Yes" },
  { name: "No", value: "No" }
];

const genderOptions = [
  { name: "Please Select", value: "" },
  { name: "Male", value: "Male" },
  { name: "Female", value: "Female" },
  { name: "Non-binary", value: "Non-binary" },
  { name: "Not Listed", value: "Not-Listed" },
  { name: "To-Be-Determined", value: "Other" }
];

const main_tags_option = JSON.parse(
  `[{"name":"Academics","value":"Academics"},
  {"name":"Acting","value":"Acting"},
  {"name":"Adventure","value":"Adventure"},
  {"name":"After School","value":"After School"},
  {"name":"All Girls","value":"All Girls"},
  {"name":"Animals","value":"Animals"},
  {"name":"Animation","value":"Animation"},
  {"name":"Aquarium","value":"Aquarium"},
  {"name":"Aquatics","value":"Aquatics"},
  {"name":"Arts","value":"Arts"},
  {"name":"Athletics","value":"Athletics"},
  {"name":"Badminton","value":"Badminton"},
  {"name":"Baking","value":"Baking"},
  {"name":"Ballet","value":"Ballet"},
  {"name":"Baseball","value":"Baseball"},
  {"name":"Basketball","value":"Basketball"},
  {"name":"Baton","value":"Baton"},
  {"name":"Before & After","value":"Before & After"},
  {"name":"Bike","value":"Bike"},
  {"name":"Biking","value":"Biking"},
  {"name":"Bilingual","value":"Bilingual"},
  {"name":"Birthday Party","value":"Birthday Party"},
  {"name":"Birthdays","value":"Birthdays"},
  {"name":"Camp","value":"Camp"},
  {"name":"Centre","value":"Centre"},
  {"name":"Ceramics","value":"Ceramics"},
  {"name":"Chess","value":"Chess"},
  {"name":"Childcare","value":"Childcare"},
  {"name":"Children","value":"Children"},
  {"name":"Chinese","value":"Chinese"},
  {"name":"Circus","value":"Circus"},
  {"name":"Classes","value":"Classes"},
  {"name":"Coding","value":"Coding"},
  {"name":"Comedy","value":"Comedy"},
  {"name":"Computers","value":"Computers"},
  {"name":"Cooking","value":"Cooking"},
  {"name":"Crafts","value":"Crafts"},
  {"name":"Creative","value":"Creative"},
  {"name":"Culture","value":"Culture"},
  {"name":"Dance","value":"Dance"},
  {"name":"Day","value":"Day"},
  {"name":"Daycare","value":"Daycare"},
  {"name":"Debate","value":"Debate"},
  {"name":"Drama","value":"Drama"},
  {"name":"Drawing","value":"Drawing"},
  {"name":"Drop-In","value":"Drop-In"},
  {"name":"Education","value":"Education"},
  {"name":"English","value":"English"},
  {"name":"Entrepreneurship","value":"Entrepreneurship"},
  {"name":"Equestrian","value":"Equestrian"},
  {"name":"Exercise","value":"Exercise"},
  {"name":"Faith","value":"Faith"},
  {"name":"Fencing","value":"Fencing"},
  {"name":"Film","value":"Film"},
  {"name":"Fitness","value":"Fitness"},
  {"name":"French","value":"French"},
  {"name":"Fun","value":"Fun"},
  {"name":"Games","value":"Games"},
  {"name":"Golf","value":"Golf"},
  {"name":"Gymnastics","value":"Gymnastics"},
  {"name":"Hockey","value":"Hockey"},
  {"name":"Home","value":"Home"},
  {"name":"Homework","value":"Homework"},
  {"name":"Horseback Riding","value":"Horseback Riding"},
  {"name":"Hydrotherapy","value":"Hydrotherapy"},
  {"name":"Ice Rink","value":"Ice Rink"},
  {"name":"Ice Skating","value":"Ice Skating"},
  {"name":"Indoor","value":"Indoor"},
  {"name":"Indoor Play","value":"Indoor Play"},
  {"name":"Karate","value":"Karate"},
  {"name":"Language","value":"Language"},
  {"name":"Leadership","value":"Leadership"},
  {"name":"Lessons","value":"Lessons"},
  {"name":"Licensed","value":"Licensed"},
  {"name":"Literacy","value":"Literacy"},
  {"name":"Life Skills","value":"Life Skills"},
  {"name":"Magic","value":"Magic"},
  {"name":"March Break","value":"March Break"},
  {"name":"Martial Arts","value":"Martial Arts"},
  {"name":"Math","value":"Math"},
  {"name":"Media","value":"Media"},
  {"name":"Mixed","value":"Mixed"},
  {"name":"Music","value":"Music"},
  {"name":"Musical Theatre","value":"Musical Theatre"},
  {"name":"Nature","value":"Nature"},
  {"name":"Open Play","value":"Open Play"},
  {"name":"Outdoors","value":"Outdoors"},
  {"name":"Overnight","value":"Overnight"},
  {"name":"Painting","value":"Painting"},
  {"name":"Performing Arts","value":"Performing Arts"},
  {"name":"Photography","value":"Photography"},
  {"name":"Piano","value":"Piano"},
  {"name":"Playgrounds","value":"Playgrounds"},
  {"name":"Pool","value":"Pool"},
  {"name":"Pottery","value":"Pottery"},
  {"name":"Preschool","value":"Preschool"},
  {"name":"Private","value":"Private"},
  {"name":"Private School","value":"Private School"},
  {"name":"Private Tutoring","value":"Private Tutoring"},
  {"name":"Public Speaking","value":"Public Speaking"},
  {"name":"Reading","value":"Reading"},
  {"name":"Rec Center","value":"Rec Center"},
  {"name":"Recreational","value":"Recreational"},
  {"name":"Religion","value":"Religion"},
  {"name":"Rink","value":"Rink"},
  {"name":"Robotics","value":"Robotics"},
  {"name":"Rowing","value":"Rowing"},
  {"name":"Safety","value":"Safety"},
  {"name":"Sailing","value":"Sailing"},
  {"name":"Science","value":"Science"},
  {"name":"Sewing","value":"Sewing"},
  {"name":"Singing","value":"Singing"},
  {"name":"Skateboard","value":"Skateboard"},
  {"name":"Skating","value":"Skating"},
  {"name":"Skiing","value":"Skiing"},
  {"name":"Soccer","value":"Soccer"},
  {"name":"Social Development","value":"Social Development"},
  {"name":"Spa","value":"Spa"},
  {"name":"Spanish","value":"Spanish"},
  {"name":"Special Needs","value":"Special Needs"},
  {"name":"Splash Pad","value":"Splash Pad"},
  {"name":"Sport","value":"Sport"},
  {"name":"Sport Camp","value":"Sport Camp"},
  {"name":"Sports","value":"Sports"},
  {"name":"Spring Break","value":"Spring Break"},
  {"name":"STEAM","value":"STEAM"},
  {"name":"STEM","value":"STEM"},
  {"name":"Summer","value":"Summer"},
  {"name":"Swimming","value":"Swimming"},
  {"name":"Taekwondo","value":"Taekwondo"},
  {"name":"Teen","value":"Teen"},
  {"name":"Tennis","value":"Tennis"},
  {"name":"Theatre","value":"Theatre"},
  {"name":"Therapy","value":"Therapy"},
  {"name":"Traditional","value":"Traditional"},
  {"name":"Trampoline","value":"Trampoline"},
  {"name":"Tutoring","value":"Tutoring"},
  {"name":"Violin","value":"Violin"},
  {"name":"Virtual","value":"Virtual"},
  {"name":"Vocal","value":"Vocal"},
  {"name":"Volleyball","value":"Volleyball"},
  {"name":"Winter","value":"Winter"},
  {"name":"Winter Break","value":"Winter Break"},
  {"name":"Yoga","value":"Yoga"},
  {"name":"Youth","value":"Youth"}]`
);

const MultiProgramCats = [
  { name: "Academics", value: "Academics" },
  { name: "After School", value: "After School" },
  { name: "Art", value: "Art" },
  { name: "Athletics", value: "Athletics" },
  { name: "Before & After School", value: "Before & After School" },
  { name: "Camps", value: "Camps" },
  { name: "Cooking", value: "Cooking" },
  { name: "Creative", value: "Creative" },
  { name: "Dance", value: "Dance" },
  { name: "Daycare / Preschool (Home)", value: "Daycare / Preschool (Home)" },
  {
    name: "Daycare / Preschool (Centre)",
    value: "Daycare / Preschool (Centre)"
  },
  { name: "Drama", value: "Drama" },
  { name: "Exercise", value: "Exercise" },
  { name: "Facilities (Rec Centre)", value: "Facilities (Rec Centre)" },
  { name: "Faith", value: "Faith" },
  { name: "Grants Program", value: "Grants Program" },
  { name: "Language / Culture", value: "Language / Culture" },
  { name: "Leadership", value: "Leadership" },
  { name: "Mixed", value: "Mixed" },
  { name: "Music", value: "Music" },
  { name: "Outdoors", value: "Outdoors" },
  { name: "Playgrounds", value: "Playgrounds" },
  { name: "Service Volunteer", value: "Service Volunteer" },
  { name: "STEM", value: "STEM" },
  { name: "Special Needs", value: "Special Needs" },
  { name: "Theatre", value: "Theatre" },
  { name: "Tutoring", value: "Tutoring" }
];
const Main_tags = [
  "Academics",
  "After School",
  "Arts",
  "Athletics",
  "Before & After School",
  "Camps",
  "Cooking",
  "Creative",
  "Dance",
  "Daycare / Preschool",
  "Drama",
  "Exercise",
  "Faith",
  "Language / Culture",
  "Leadership",
  "Life Skills",
  "Mixed",
  "Music",
  "Outdoors",
  "Playgrounds",
  "STEM",
  "Special Needs",
  "Theatre",
  "Tutoring"
].map(e => ({ name: e, value: e }));

const membershipTypes = [
  { name: "Please Select", value: "" },
  { name: "Children", value: "Children" },
  { name: "Family", value: "Family" },
  { name: "Youth", value: "Youth" },
  { name: "Adult", value: "Adult" },
  { name: "All", value: "All" }
];

const programTypeOptions = [
  { name: "Please Select", value: "" },
  {
    name: "Independent Program (Unlicensed)",
    value: "Independent Program Unlicensed"
  },
  {
    name: "Independent Program (Licensed)",
    value: "Independent Program Licensed"
  },
  { name: "Day Care Center", value: "Day Care" }
];

const paymentAccountTypes = [
  { name: "Please Select", value: "" },
  { name: "Chequing", value: "Chequing" },
  { name: "Savings", value: "Savings" }
];

const ageGroups = [
  { name: "All ages", value: "All", order: 0 },
  { name: "Infant (0-2 yrs)", value: "Infant", order: 1 },
  { name: "Toddler (2-3 yrs)", value: "Toddler", order: 2 },
  { name: "Preschool (3-4 yrs)", value: "Preschool", order: 3 },
  { name: "Kindergarten (4-6 yrs)", value: "Kindergarten", order: 4 },
  { name: "Mixed Age (0-6)", value: "Mixed Age", order: 5 },
  { name: "Gradeschool (5-12 yrs)", value: "Gradeschool", order: 6 },
  { name: "Youth (13+)", value: "Youth (13+)", order: 7 }
  // { name: "School Age (6-9 yrs)", value: "School Age", order: 5 }
];

const formGroups = [
  { name: "Registration (General)", value: "Registration (General)", id: 0 },
  { name: "Application (General)", value: "Application (General)", id: 1 },
  { name: "Appointments (General)", value: "Appointments (General)", id: 2 },
  { name: "Contact Us (General)", value: "Contact Us (General)", id: 3 },
  { name: "Survey", value: "Survey", id: 4 },
  { name: "Donation", value: "Donation", id: 5 },
  { name: "Waiver (Children)", value: "Waiver (Children)", id: 6 },
  { name: "Application (Children)", value: "Application (Children)", id: 7 }
];

const centerAgeGroups = [
  { name: "Infant (0-18 mths)", value: "Infant", order: 1 },
  { name: "Toddler (18-30 mths)", value: "Toddler", order: 2 },
  { name: "Preschool (30 mths-6 yrs)", value: "Preschool", order: 3 },
  { name: "Kindergarten (44 mths-7 yrs)", value: "Kindergarten", order: 4 },
  { name: "Mixed Age (0-6)", value: "Mixed Age", order: 5 }
  // { name: "School Age (6-9 yrs)", value: "School Age", order: 5 }
];

const languages = [
  { name: "English", value: "English" },
  { name: "French", value: "French" },
  { name: "Mandarin", value: "Mandarin" },
  { name: "Cantonese", value: "Cantonese" },
  { name: "Punjabi", value: "Punjabi" },
  { name: "Spanish", value: "Spanish" },
  { name: "Tagalog / Filipino", value: "Tagalog / Filipino" },
  { name: "Arabic", value: "Arabic" },
  { name: "German", value: "German" },
  { name: "Italian", value: "Italian" },
  { name: "Portugese", value: "Portugese" },
  { name: "Persian (Farsi)", value: "Persian (Farsi)" },
  { name: "Urdu", value: "Urdu" },
  { name: "Russian", value: "Russian" },
  { name: "Polish", value: "Polish" },
  { name: "Vietnamese", value: "Vietnamese" },
  { name: "Korean", value: "Korean" },
  { name: "Tamil", value: "Tamil" },
  { name: "Hindi", value: "Hindi" },
  { name: "Gujarati", value: "Gujarati" },
  { name: "Greek", value: "Greek" },
  { name: "Ukrainian", value: "Ukrainian" },
  { name: "Dutch", value: "Dutch" },
  { name: "Romanian", value: "Romanian" },
  { name: "Bengali", value: "Bengali" },
  { name: "Creole", value: "Creole" },
  { name: "Hungarian", value: "Hungarian" },
  { name: "Serbian", value: "Serbian" },
  { name: "Croatian", value: "Croatian" },
  { name: "Japanese", value: "Japanese" },
  { name: "Inuktitut", value: "Inuktitut" },
  { name: "Somali", value: "Somali" },
  { name: "Armenian", value: "Armenian" },
  { name: "Turkish", value: "Turkish" },
  { name: "Albanian", value: "Albanian" },
  { name: "Czech", value: "Czech" },
  { name: "Cambodian / Khmer", value: "Cambodian / Khmer" },
  { name: "Bulgarian", value: "Bulgarian" },
  { name: "Hebrew", value: "Hebrew" },
  { name: "Slovak", value: "Slovak" },
  { name: "Finnish", value: "Finnish" },
  { name: "Macedonian", value: "Macedonian" },
  { name: "Danish", value: "Danish" },
  { name: "Lao", value: "Lao" },
  { name: "Pashto", value: "Pashto" },
  { name: "Bosnian", value: "Bosnian" },
  { name: "Sindhi", value: "Sindhi" },
  { name: "Dene", value: "Dene" },
  { name: "Oromo", value: "Oromo" },
  { name: "Malay", value: "Malay" },
  { name: "Slovenian", value: "Slovenian" },
  { name: "Swahili", value: "Swahili" },
  { name: "Kurdish", value: "Kurdish" },
  { name: "Taiwanese", value: "Taiwanese" },
  { name: "Telugu", value: "Telugu" },
  { name: "Afrikaans", value: "Afrikaans" },
  { name: "Nepali", value: "Nepali" },
  { name: "Thai", value: "Thai" },
  { name: "Swedish", value: "Swedish" },
  { name: "Lithuanian", value: "Lithuanian" },
  { name: "Estonian", value: "Estonian" },
  { name: "Maltese", value: "Maltese" },
  { name: "Latvian", value: "Latvian" },
  { name: "Norwegian", value: "Norwegian" }
];

const percentageAmounts = [
  { name: "0%", value: 0 },
  { name: "10%", value: 10 },
  { name: "25%", value: 25 },
  { name: "40%", value: 40 },
  { name: "50%", value: 50 }
];

const percentageHalves = [
  { name: "0%", value: 0 },
  { name: "50%", value: 50 },
  { name: "100%", value: 100 }
];
const program_structures = JSON.parse(
  `[{"name":"After School","value":"After School"},{"name":"Camp","value":"Camp"},{"name":"Childcare","value":"Childcare"},{"name":"Classes","value":"Classes"},{"name":"Drop-In","value":"Drop-In"},{"name":"Lessons","value":"Lessons"},{"name":"Mixed Programs","value":"Mixed Programs"},{"name":"Recurring","value":"Recurring"},{"name":"Summer","value":"Summer"},{"name":"Virtual","value":"Virtual"},{"name":"Workshops","value":"Workshops"}]`
);

export {
  yesNoOptions,
  genderOptions,
  main_tags_option,
  MultiProgramCats,
  Main_tags,
  membershipTypes,
  programTypeOptions,
  paymentAccountTypes,
  ageGroups,
  formGroups,
  centerAgeGroups,
  languages,
  percentageAmounts,
  percentageHalves,
  program_structures
};
