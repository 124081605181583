import React from "reactn";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

class ConsoleRoute extends React.PureComponent {
  constructor(props) {
    super(props);
    document.getElementsByTagName("html")[0].className = "console-pages";
    document.getElementsByTagName("html")[0].style.fontSize = "initial";
    document.getElementsByTagName("html")[0].style.lineHeight = "initial";
  }

  async componentDidMount() {
    try {
      if (localStorage.getItem("ds_token") && !this.global.dsUser) {
        await this.dispatch.validateToken();
      }
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const {
      component: Component,
      isAuthenticated,
      iFrame,
      to,
      ...rest
    } = this.props;
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            <Redirect to={to} />
          ) : (
            <>
              <div
                id="page-content"
                className="new-heaven"
                style={{ width: "90%", margin: "0 auto" }}
              >
                <Component {...props} />
              </div>
            </>
          )
        }
      />
    );
  }
}

ConsoleRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  to: PropTypes.string
};

ConsoleRoute.defaultProps = {
  to: "/private"
};

export default ConsoleRoute;
