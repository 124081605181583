import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { getSelectedProgramPlan } from "./utils";

const sessionType = (category, quantity) => {
  const categoryValues = {
    day: {
      singleCategory: "Class",
      multipleCategory: "Classes",
      singleFrequency: "date",
      multipleFrequency: "dates"
    },
    week: {
      singleCategory: "Class",
      multipleCategory: "Classes",
      singleFrequency: "week",
      multipleFrequency: "weeks"
    }
  };

  return (
    <span>
      {categoryValues[category]
        ? `${
            quantity > 1
              ? `${categoryValues[category].multipleCategory} (${categoryValues[category].multipleFrequency})`
              : `${categoryValues[category].singleCategory} (${categoryValues[category].singleFrequency})`
          }`
        : ""}
    </span>
  );
};

const ProgramSingleSessions = props => {
  console.log("props", props);

  const [fee, setFee] = useState("Loading");
  const calculateFee = () => {
    const plan = getSelectedProgramPlan(props.program, props.selected);
    setFee(
      parseFloat(plan.total_price * props.singleSessionQuantity).toFixed(2)
    );
    props.itemAmount(parseFloat(plan.total_price).toFixed(2));
  };
  useEffect(() => {
    calculateFee();
  }, []);
  const plan = getSelectedProgramPlan(props.program, props.selected);
  console.log("plan", plan);

  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px",
        borderBottom: "1px solid #dedede"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif",
          fontWeight: 500,
          fontSize: "14px"
        }}
      >
        Session Fee x {props.singleSessionQuantity}{" "}
        {sessionType(plan.frequency, props.singleSessionQuantity)}
      </p>
      <span style={{ fontWeight: 500, fontSize: "14px" }}>${fee}</span>
    </li>
  );
};

ProgramSingleSessions.propTypes = {
  program: PropTypes.shape({
    plans: PropTypes.array.isRequired
  }).isRequired,
  selected: PropTypes.number.isRequired
};

export default ProgramSingleSessions;
