import React from "react";
import PropTypes from "prop-types";

import { OrangePin } from "../../../assets";

import GoogleMapReact from "google-map-react";

const OnlineDescription = ({ program }) => {
  const getLocation = locations => {
    if (!locations) {
      return;
    }
    const data = locations.filter(
      e => e.address === this.state.program.address1
    );
    return data.length ? (
      <div
        lat={data[0].lat}
        lng={data[0].lng}
        style={{
          backgroundImage: `url(${OrangePin})`,
          backgroundSize: "cover",
          height: 30,
          width: 22,
          cursor: "pointer",
          zIndex: 10,
          position: "relative",
          bottom: 15,
          right: 15
        }}
      ></div>
    ) : (
      <div
        lat={this.state.program.main_lat}
        lng={this.state.program.main_long}
        style={{
          backgroundImage: `url(${OrangePin})`,
          backgroundSize: "cover",
          height: 30,
          width: 22,
          cursor: "pointer",
          zIndex: 10,
          position: "relative",
          bottom: 15,
          right: 15
        }}
      ></div>
    );
  };
  return (
    <>
      <div className="box-size">
        <h3 className="sub-title">Online Program Description</h3>

        <p
          style={{
            fontWeight: "400",
            fontFamily: `"Open sans", sans-serif`,
            whiteSpace: "pre-line"
          }}
        >
          {program.description}
        </p>
      </div>

      {/* <div className="box-size">
            <h3 className="sub-title">Additional Details</h3>
            <p
                style={{
                    fontWeight: "400",
                    fontFamily: `"Open sans", sans-serif`,
                    whiteSpace: "pre-line"
                }}
            >
                {program.additional_notes}
            </p>
        </div> */}

      {/* <div className="box-size">
            <h3 className="sub-title">Location</h3>
            <div className="map-size">
                <p>Address : {program.address1}</p>
                <GoogleMapReact
                    bootstrapURLKeys={{
                        key: "AIzaSyDVNuWNguQz4vB25OdDpztwxE3QR_CzG0g"
                    }}
                    defaultZoom={9}
                    defaultCenter={{ lat: 43.695572, lng: -79.415605 }}
                    options={{ gestureHandling: "greedy" }}
                >
                    {getLocation(program.other_locations)}
                </GoogleMapReact>
            </div>
        </div> */}
    </>
  );
};

OnlineDescription.propTypes = {
  program: PropTypes.object.isRequired
};

export default OnlineDescription;
