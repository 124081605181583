import React, { useState } from "react";
import "../fieldComponent.css";
import "./SelectMultipleDropdown.css";
import Select from "react-dropdown-select";
import { ModalFields } from "../../../../UI/modalComponents";
/**
 * @param {String} name
 *
 * @param {Boolean} required
 *
 * @param {String} placeholder
 *
 * @param {Function} handleChange
 *
 * @param {Array<Object>} choices
 */
const SelectMultipleDropdown = props => {
  const {
    name,
    required,
    placeholder,
    handleChange,
    choices,
    value,
    fieldValid,
    setFieldValid,
    isDisabled,
    styles,
    wrapperStyles,
    titleStyle
  } = props;

// value must be an array
// choices must be an array of objects and each object must have text and value
// handleChange is a function taking one parameter, this parameter is a list of objects 


  const [currentSelection, setCurrentSelection] = useState("");

  
  // the default value is empty string ""
  // invalid/greyed out value is empty string ""
  console.log('props', props)
  return (
    
    <div style={wrapperStyles}>
      <p className="field-component-title" style={titleStyle}>
        {name}
        {required ? <span className="required-star"> *</span> : ""}
      </p>
      <Select
        className="dropdown-select-field field-component-input-box" 
        multi={true}
        options={choices}
        values={value}
        style={styles}
        placeholder={placeholder}
        onChange = {handleChange}
        onDropdownOpen={props.open}
        //style={{ top: "0px" }}
        labelField={"text"}
        valueField={"text"}
        disabled={isDisabled || false}
        closeOnSelect={true}
      />
    </div>
  );
};

export default SelectMultipleDropdown;
