import React from "reactn";
import { Link, withRouter } from "react-router-dom";
import { Redesign_Logo, DreamHouseLogo2 } from "../../assets";
import ProgramSearchInput from "../common/ProgramSearchInput";
import "../../assets/css/componentSpecificCss/header.css";
import CartModal from "../parents/CartModal";

class Header extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      phoneSized: false,
      showCart: false
    };
    this.updateScreenSize = this.updateScreenSize.bind(this);
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleClickOutside(event) {
    if (
      this.wrapperRef?.current &&
      !this.wrapperRef.current?.contains(event.target)
    ) {
      this.setState({ showCart: false });
    }
  }

  getPath = () => {
    if (this.global.dsUser.accountType) {
      switch (this.global.dsUser.accountType) {
        case "Admin":
          return "/admin";
        case "Community":
          return "/admin";
        case "Parent":
          return "/user";
        case "Volunteer":
          return "/user";
        case "Student":
          return "/user";
        case "Educator":
          return "/educators";
        case "Partner":
          return "/partners";
        default:
          return "";
      }
    }
  };
  shrinkNavbar = () => {
    const div = document.getElementById("navbar-collapse");
    div.classList.remove("show");
  };

  updateScreenSize() {
    this.setState({
      phoneSized: window.innerWidth < 600
    });
  }

  async componentDidMount() {
    console.log("props", this.props);
    this.setGlobal({ loading: true });
    await this.updateScreenSize();
    window.addEventListener("resize", this.updateScreenSize);
    document.addEventListener("mousedown", this.handleClickOutside);
    this.setGlobal({ loading: false });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateScreenSize);
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  render() {
    return (
      <header className="fixed-header">
        {!this.state.phoneSized && this.state.showCart && (
          <div ref={this.wrapperRef}>
            <CartModal partnerSlug={this.props.match.params.slug} />
          </div>
        )}
        <nav className="navbar navbar-default custom-navbar" role="navigation">
          <div className="col-lg-3 col-md-3">
            <div className="navbar-header">
              {!this.props.match.path.includes("programs") ? (
                <>
                  {this.global.dsUser?.accountType !== "Parent" &&
                    this.global.dsUser?.accountType !== "Student" &&
                    this.global.dsUser?.accountType !== "Volunteer" ? (
                    <Link
                      to="/"
                      onClick={() => {
                        window.scrollTo(0, 0);
                        // this.props.history.push("/");
                      }}
                    >
                      <img
                        className="school-logo"
                        src={Redesign_Logo}
                        alt="Dreamschools"
                      />
                    </Link>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "9px"
                      }}
                      onClick={() => {
                        window.scrollTo(0, 0);
                        // this.props.history.push("/");
                      }}
                    >
                      <img
                        className="school-logo"
                        style={{
                          objectFit: "contain",
                          height: "28px",
                          width: "28px",
                          marginRight: "5px"
                        }}
                        src={DreamHouseLogo2}
                        alt="Dreamschools"
                      />
                      <span className="nav-bar-title">Home</span>
                    </div>
                  )}
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    marginLeft: `${this.props.match.path === "/programs/:slug"
                        ? "33px"
                        : "11px"
                      }`
                  }}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    // this.props.history.push("/");
                  }}
                >
                  <img
                    // className="school-logo"
                    style={{
                      objectFit: "contain",
                      height: "28px",
                      width: "28px",
                      marginRight: "5px"
                    }}
                    src={DreamHouseLogo2}
                    alt="Dreamschools"
                  />
                  {/* <span style={{ width: "300px", color: "#505050", fontSize: "22px", fontFamily: 'Montserrat', fontWeight: 700, letterSpacing: ".1px" }}>{this.global.organizationInfo.organization_title}</span> */}
                </div>
              )}
              <button
                type="button"
                className="navbar-toggle"
                data-toggle="collapse"
                data-target="#navbar-collapse"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
            </div>
          </div>

          {/* <!-- Collect the nav links, forms, and other content for toggling --> */}
          <div className="col-lg-9 col-md-9">
            <div
              className="collapse navbar-collapse flex-important"
              id="navbar-collapse"
            >
              <div className="row">
                {!this.props.match.path.includes("programs") &&
                  !this.props.match.path.includes("user") ? (
                  <div className="col-xl-4 col-lg-4 col-md-4 d-xl-block">
                    <ProgramSearchInput
                      placeholder="Search by name, address, or keyword"
                      shrinknav={_ => this.shrinkNavbar()}
                    />
                  </div>
                ) : (
                  <div className="col-xl-4 col-lg-4 col-md-4 d-xl-block"> </div>
                )}
                <div
                  className="col-lg-8 col-xl-8 col-md-8 right"
                  style={{ padding: 0 }}
                >
                  <ul className="nav navbar-nav navbar-right custom-right-navbar">
                    {this.global.dsUser?.accountType !== "Parent" &&
                      this.global.dsUser?.accountType !== "Student" &&
                      this.global.dsUser?.accountType !== "Volunteer" && (
                        <li>
                          <Link
                            to="/about"
                            onClick={() => {
                              window.scrollTo(0, 0);
                              this.shrinkNavbar();
                              this.props.history.push("/about");
                            }}
                          >
                            About
                          </Link>
                        </li>
                      )}
                    {/* {!this.state.phoneSized && ( */}
                    <li>
                      <Link
                        to="/search"
                        onClick={() => {
                          window.scrollTo(0, 0);
                          this.shrinkNavbar();
                          this.props.history.push("/search");
                        }}
                      >
                        {!this.state.phoneSized ? "Search" : "Find Activity"}
                      </Link>
                    </li>
                    {/* )} */}
                    {!this.global.dsUser ? (
                      <>
                        <li>
                          <Link
                            className="nav-link"
                            to="/sign-in"
                            onClick={() => {
                              window.scrollTo(0, 0);
                              this.shrinkNavbar();
                              this.props.history.push("/sign-in");
                            }}
                          >
                            Sign In
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="nav-link"
                            to="/register"
                            onClick={() => {
                              window.scrollTo(0, 0);
                              this.shrinkNavbar();
                              this.props.history.push("/register");
                            }}
                          >
                            Register
                          </Link>
                        </li>
                      </>
                    ) : (
                      <>
                        <li>
                          <Link
                            className="nav-link"
                            to={`${this.getPath()}`}
                            onClick={() => {
                              window.scrollTo(0, 0);
                              this.shrinkNavbar();
                              this.props.history.push(`${this.getPath()}`);
                            }}
                          >
                            Dashboard
                          </Link>
                        </li>
                        {this.global.dsUser.accountType !== "Educator" && (
                          <>
                            <li>
                              <Link
                                className="nav-link display-options"
                                to={`${this.getPath()}/family-profile`}
                                onClick={() => {
                                  window.scrollTo(0, 0);
                                  this.shrinkNavbar();
                                  this.props.history.push(
                                    `${this.getPath()}/family-profile`
                                  );
                                }}
                              >
                                Profile
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="nav-link display-options"
                                to={`${this.getPath()}/account-settings`}
                                onClick={() => {
                                  window.scrollTo(0, 0);
                                  this.shrinkNavbar();
                                  this.props.history.push(
                                    `${this.getPath()}/account-settings`
                                  );
                                }}
                              >
                                Accounts
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="nav-link display-options"
                                to={`${this.getPath()}/interested-programs`}
                                onClick={() => {
                                  window.scrollTo(0, 0);
                                  this.shrinkNavbar();
                                  this.props.history.push(
                                    `${this.getPath()}/interested-programs`
                                  );
                                }}
                              >
                                Interests
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="nav-link display-options"
                                to={`${this.getPath()}/orders`}
                                onClick={() => {
                                  window.scrollTo(0, 0);
                                  this.shrinkNavbar();
                                  this.props.history.push(
                                    `${this.getPath()}/orders`
                                  );
                                }}
                              >
                                Bookings
                              </Link>
                            </li>
                            {this.props.match.params.slug &&
                              (this.state.phoneSized ? (
                                <li>
                                  <Link
                                    className="nav-link display-options"
                                    to={`/user/cart`}
                                    onClick={() => {
                                      window.scrollTo(0, 0);
                                      this.shrinkNavbar();
                                      this.props.history.push(`/user/cart`);
                                    }}
                                  >
                                    Cart
                                  </Link>
                                </li>
                              ) : (
                                <li>
                                  <a
                                    className="nav-link"
                                    onClick={() => {
                                      this.setState({ showCart: true });
                                    }}
                                  >
                                    Cart
                                  </a>
                                </li>
                              ))}
                            {!this.props.match.params.slug && (
                              <li>
                                <Link
                                  className="nav-link"
                                  to={`/user/cart`}
                                  onClick={() => {
                                    window.scrollTo(0, 0);
                                    this.shrinkNavbar();
                                    this.props.history.push(`/user/cart`);
                                  }}
                                >
                                  Cart
                                </Link>
                              </li>
                            )}
                          </>
                        )}
                        <li>
                          <Link
                            className="nav-link"
                            to={`/`}
                            onClick={() => {
                              window.scrollTo(0, 0);
                              this.shrinkNavbar();
                              this.dispatch.logout();
                            }}
                          >
                            Log-out
                          </Link>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default withRouter(Header);
