import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "./DateInput.css";

const DateInputAddChildModal = props => {
  const date = props.value ? new Date(props.value) : null;
  const { isDisabled } = props;
  return (
    <DatePicker
      onChange={e => props.onChange(props.name, e)}
      className="custom-addChild-date"
      dateFormat="MMMM d, yyyy"
      showMonthDropdown
      showYearDropdown
      selected={date}
      disabled={isDisabled ? true : false}
      minDate={props.minDate ? props.minDate : null}
      maxDate={props.maxDate ? props.maxDate : new Date(((new Date()).getFullYear() + 2).toString())}
      // maxDate to be selected will be one year behind the year above.
      filterDate={props.filterDate ? props.filterDate : null}
    />
  );
};

const DateInput = props => {
  const date = props.value ? new Date(props.value) : null;
  const { isDisabled } = props;
  return (
    <DatePicker
      onChange={e => props.onChange(props.name, e)}
      className="select"
      dateFormat="MMMM d, yyyy"
      showMonthDropdown
      showYearDropdown
      selected={date}
      disabled={isDisabled ? true : false}
      minDate={props.minDate ? props.minDate : null}
      maxDate={props.maxDate ? props.maxDate : null}
      filterDate={props.filterDate ? props.filterDate : null}
    />
  );
};

const DateWithCheckMark = props => {
  const date = props.value ? new Date(props.value) : null;
  const [disabled, setDisabled] = useState(false);
  const { isDisabled } = props;
  return (
    <div style={{ position: "relative" }}>
      {date ? (
        date.toString() !== "Invalid Date" ? (
          <DatePicker
            onChange={e => props.onChange(props.name, e)}
            className="select"
            dateFormat="MMMM d, yyyy"
            showMonthDropdown
            showYearDropdown
            selected={date}
            disabled={disabled || isDisabled ? true : false}
            minDate={props.minDate ? props.minDate : null}
            maxDate={props.maxDate ? props.maxDate : null}
            filterDate={props.filterDate ? props.filterDate : null}
          />
        ) : (
          <input
            type="text"
            value={"TBD"}
            onChange={e => props.onChange(props.name, e)}
          />
        )
      ) : (
        <DatePicker
          onChange={e => props.onChange(props.name, e)}
          className="select"
          dateFormat="MMMM d, yyyy"
          showMonthDropdown
          showYearDropdown
          selected={date}
          disabled={disabled || isDisabled ? true : false}
          minDate={props.minDate ? props.minDate : null}
          maxDate={props.maxDate ? props.maxDate : null}
          filterDate={props.filterDate ? props.filterDate : null}
        />
      )}

      <div
        style={{
          position: "absolute",
          top: "-5px",
          right: "10px",
          display: "flex",
          alignItems: "center"
        }}
      >
        <input
          type="checkbox"
          name="macheck"
          style={{ marginRight: "5px" }}
          checked={date && date.toString() === "Invalid Date"}
          onClick={e => {
            setDisabled(e.target.checked);
            props.onChange(props.name, e.target.checked ? "TBD" : null);
          }}
        />
        <label htmlFor="macheck" style={{ marginBottom: 0 }}>
          TBD
        </label>
      </div>
    </div>
  );
};
const MonthYearInput = props => {
  return (
    <DatePicker
      onChange={e => props.onChange(props.name, e)}
      className="select"
      dateFormat="MM/yy"
      showMonthYearPicker
      selected={props.value}
    />
  );
};

const DateInputs = {
  DateInput,
  MonthYearInput,
  DateWithCheckMark,
  DateInputAddChildModal
};

export default DateInputs;
