import { setGlobal, addReducer } from "reactn";
import { startOfWeek, endOfWeek, format, eachDayOfInterval } from "date-fns";

const today = new Date();
const attendanceCalendar = {
  start: startOfWeek(today),
  end: endOfWeek(today),
  children: []
};

setGlobal({ attendanceCalendar: attendanceCalendar });
addReducer("getAttendanceCalendar", (global, dispatch, start, end) => {
  if (process.env.REACT_APP_MODE === "dev") {
    return {
      attendanceCalendar: {
        ...global.attendanceCalendar,
        children: devAttendanceCalendar(start, end)
      }
    };
  }
});

addReducer("setAttendanceCalendarPeriod", (global, dispatch, start) => {
  if (process.env.REACT_APP_MODE === "dev") {
    return {
      attendanceCalendar: {
        children: devAttendanceCalendar(start, endOfWeek(start)),
        start: startOfWeek(start),
        end: endOfWeek(start)
      }
    };
  }
});

addReducer("setAttendanceChildStatus", (global, dispatch, data) => {
  const cId = data.child.child.id;
  let day = Object.keys(
    global.attendanceCalendar.children[data.child.child.id].days
  ).find(d => {
    return (
      global.attendanceCalendar.children[data.child.child.id].days[d].day ===
      data.day
    );
  });
  delete data.child;
  const c = {
    ...global.attendanceCalendar.children[cId],
    days: {
      ...global.attendanceCalendar.children[cId].days,
      [day]: data
    }
  };
  return {
    attendanceCalendar: {
      ...global.attendanceCalendar,
      children: {
        ...global.attendanceCalendar.children,
        [cId]: c
      }
    }
  };
});

const devAttendanceCalendar = (start, end) => {
  const statuses = [
    {
      status: "Extended",
      percent: 25,
      notified: true,
      extendedHours: 0,
      extendedMinutes: 0,
      fee: 0
    },
    {
      status: "Reduced",
      percent: 10,
      notified: true,
      extendedHours: 0,
      extendedMinutes: 0,
      fee: 0
    },
    {
      status: "Present",
      percent: 0,
      notified: true,
      extendedHours: 0,
      extendedMinutes: 0,
      fee: 0
    },
    {
      status: "Absent",
      percent: 50,
      notified: true,
      extendedHours: 0,
      extendedMinutes: 30,
      fee: 0
    },
    {
      status: "Select",
      percent: 0,
      notified: true,
      extendedHours: 0,
      extendedMinutes: 0,
      fee: 0
    }
  ];
  const children = [
    "Owen Thompson",
    "Clarissa Thompson",
    "Beesan Sun",
    "Sally Kyune",
    "Peter June"
  ];
  const enrollmentPeriods = [
    { start: new Date("July 14, 2019"), end: new Date("August 6, 2019") },
    { start: new Date("June 1, 2019"), end: new Date("August 31, 2019") },
    { start: new Date("April 24, 2019"), end: new Date("September 24, 2019") },
    { start: new Date("August 4, 2019"), end: new Date("August 28, 2019") },
    { start: new Date("August 18, 2019"), end: new Date("August 24, 2019") }
  ];
  const daysOfWeek = [
    [1, 2, 4],
    [3, 5],
    [1, 3, 5],
    [0, 5, 6],
    [2, 3, 4, 5]
  ];
  const planRates = [
    { weekday: 75, weekend: 90, duration: 4 },
    { weekday: 60, weekend: 80, duration: 5 },
    { weekday: 75, weekend: 90, duration: 8 },
    { weekday: 60, weekend: 80, duration: 7 },
    { weekday: 60, weekend: 80, duration: 6 }
  ];
  let newAttendance = {};
  children.forEach((child, childId) => {
    if (
      !(
        enrollmentPeriods[childId].end < start ||
        enrollmentPeriods[childId].start > end
      )
    ) {
      let attendance = {
        child: {
          name: child,
          id: childId,
          enrollmentPeriod: enrollmentPeriods[childId],
          daysOfWeek: daysOfWeek[childId]
        },
        planRate: planRates[childId],
        days: {}
      };
      eachDayOfInterval({ start: start, end: end }).forEach((day, i) => {
        if (
          daysOfWeek[childId].includes(i) &&
          new Date(format(day, "LLL dd, yyyy")) >=
            attendance.child.enrollmentPeriod.start &&
          new Date(format(day, "LLL dd, yyyy")) <=
            attendance.child.enrollmentPeriod.end
        ) {
          attendance.days[i] = {
            ...statuses[Math.floor(Math.random() * statuses.length)],
            day: format(day, "LLL dd, yyyy")
          };
        }
      });
      newAttendance[childId] = attendance;
    }
  });
  return newAttendance;
};
