import React from "react";
import { BgHeroProvide, BgHeroProvideRight } from "../../../assets";

const ProvideHero = () => {
  return (
    <div className="container-fluid providehero">
      <img className="leftsidebg" src={BgHeroProvide} alt="" />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <p>Dreamschools designs technology</p>
            <p>to seamlessly connect parents with educators,</p>
            <p> and encompasses a suite of tools</p>
            <p> to easily manage quality programs end-to-end.</p>
            <p>
              <span>Discover your Dream Tools today!</span>{" "}
            </p>
          </div>
        </div>
      </div>
      <img className="rightsidebg" src={BgHeroProvideRight} alt="" />
    </div>
  );
};
export default ProvideHero;
