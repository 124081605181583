import React, { useState, useEffect } from "react";
import Field from "./Field";
import "./BaseModal.css";
import { closeButton } from "../../../assets";

const DEFAULT_HEIGHT = 500;
const TOP_HEIGHT = 63;
const BUTTON_SECTION_HEIGHT = 86;

/**
 * The base modal component
 *
 * More documentation can be found at:
 * https://dreamschools.atlassian.net/wiki/spaces/DREAMSCHOO/pages/1179090945/Base+Modal
 *
 * @param {String} title A string for the header of the modal
 *
 * @param {Array<Object>} fields All the input fields of the modal
 *
 * @param {Array<Object>} buttons All submit buttons
 *
 * @param {Function} handleClose Function to call when closing
 *
 */
const BaseModal = props => {
  const { title, fields, buttons, handleClose, width, height, midSectionHeight, titleClass } = props;
  const pages = props?.pages;

  // an array of booleans whether to show
  const [fieldValids, setFieldValids] = useState([]);
  const [pageNum, setPageNum] = useState(pages ? pages.start : 0);

  useEffect(() => {
    const allTrueArray = fields.map(field => true);
    setFieldValids(allTrueArray);
  }, []);

  const setFieldValidsIndex = index => value => {
    setFieldValids(prev => {
      const copy = [...prev];
      copy[index] = value;
      return copy;
    });
  };

  const getFieldSectionHeight = () => {
    const modalHeight = height || DEFAULT_HEIGHT;
    const bottomHeight =
      !buttons || buttons?.length === 0 ? 0 : BUTTON_SECTION_HEIGHT;

    const fieldSectionHeight = modalHeight - TOP_HEIGHT - bottomHeight;
    return fieldSectionHeight + "px";
  };

  const handleClickAndValidate = (handleClick, validatorKey, e) => {
    // no validation
    if (!validatorKey) {
      handleClick(e);
      return;
    }

    const allFieldsValid = fields.reduce((prev, field, index) => {
      const fieldValidator = field?.validators?.[validatorKey];
      // if there is no validator for this field, then the field is valid
      const fieldIsValid = fieldValidator ? fieldValidator() : true;
      if (!fieldIsValid) {
        setFieldValidsIndex(index)(false);
      }
      console.log(
        `field ${field.data.name} is ${fieldIsValid ? "valid" : "invalid"}`
      );
      return prev && fieldIsValid;
    }, true);

    if (allFieldsValid) {
      handleClick(e);
    }
  };

  const showTitle = () => {
    if (!title) {
      console.log("No title");
      return null;
    }
    return (
      <div className="base-modal-title-wrapper">
        <h3 className={titleClass || "base-modal-title"}>{title}</h3>
        <div onClick={handleClose}>
          <img className="base-modal-exit-image" src={closeButton} alt="" />
        </div>
      </div>
    );
  };

  const showFields = (page) => {
    if (!fields) {
      console.log("No fields");
      return null;
    }
    let displayedFields = fields;

    if (pages) {
      displayedFields = fields.filter(field => field.page === page);
    }

    console.log(displayedFields);
    
    return (
      <div className="base-modal-fields" id="base-modal-content">
        {displayedFields.map((field, i) => (
          <div key={i} className="base-modal-field">
            <Field
              {...field}
              fieldValid={fieldValids[i]}
              setFieldValid={setFieldValidsIndex(i)}
            />
          </div>
        ))}
      </div>
    );
  };

  const showButtons = () => {
    if (!buttons?.length) {
      return "";
    }

    return (
      <div className="base-modal-buttons">
        {buttons.map(
          (
            {
              name,
              handleClick,
              buttonColour,
              textColour,
              validatorKey,
              image,
              isDisabled,
              buttonClass,
              type
            },
            i
          ) => (
            <button
              key={i}
              style={{
                backgroundColor: buttonColour,
                color: textColour,
                cursor: isDisabled ? "not-allowed" : "pointer"
              }}
              onClick={e =>
                handleClickAndValidate(handleClick, validatorKey, e)
              }
              className={buttonClass || "base-modal-button"}
              disabled={isDisabled || false}
              type={type}
            >
              {image ? (
                <img className="base-modal-button-image" src={image} alt="" />
              ) : (
                ""
              )}
              {name}
            </button>
          )
        )}
      </div>
    );
  };

  const showPages = () => {
    if (!pages) {
      return ;
    }

    return (
      <div
        style={{
          // position: "absolute",
          color: "grey",
          fontSize: "20px",
          marginLeft: "5px"
        }}
      >
      {pages.pageNums.map(num => {
        return (
          <span
            style={{ marginLeft: "10px",  cursor: "pointer" }}
            onClick={_ => setPageNum(num) }
            className={pageNum === num ? "spanpageselected" : ""}
          >
            {num + 1}
          </span>
        );
      })}
      </div>
    );
  }

  return (
    <div className="request-modal-cont base-modal-outer-wrapper">
      <div
        id="base-modal-wrapper"
        className="base-modal-wrapper"
        style={{
          height: height || "500px",
          width: width || "450px"
        }}
      >
        <div id="base-modal-title">{showTitle()}</div>
        <div
          id="base-modal-mid-section"
          className="mid-section"
          style={{
            maxHeight: getFieldSectionHeight(),
            height: midSectionHeight || "250vh"
        }}
        >
          {showFields(pageNum)}
        </div>
        <div id="base-modal-buttons">{showButtons()}</div>
        {showPages()}
      </div>
    </div>
  );
};

export default BaseModal;
