import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";

class RequestInformationModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      requestName: "",
      requestEmail: "",
      requestPhone: "",
      requestMessage: ""
    };
    this.sendMessage = this.sendMessage.bind(this);
  }

  sendMessage = async e => {
    e.preventDefault();
    const params = this.props.match.params;
    const ep = `${process.env.REACT_APP_API}/frnt/program/request-info/${
      params.slug ? params.slug : this.props.program.slug
    }`;
    try {
      if (
        !this.global.dsUser ||
        !this.global.dsUser.displayName ||
        !this.global.dsUser.email
      ) {
        if (!this.state.requestName) throw new Error("Name must be provided.");
        if (!this.state.requestEmail)
          throw new Error("Email must be provided.");
        if (
          !this.state.requestPhone ||
          this.state.requestPhone.trim().length !== 10 ||
          isNaN(this.state.requestPhone.trim())
        )
          throw new Error("The Phone provided is invalid");
        if (this.state.requestMessage.trim().length < 40)
          throw new Error("Enter at least 40 characters in the message field!");
      }
      const res = await axios.post(ep, {
        requestName: this.global.dsUser
          ? this.global.dsUser.displayName
          : this.state.requestName,
        requestEmail: this.global.dsUser
          ? this.global.dsUser.email
          : this.state.requestEmail,
        requestPhone: this.global.dsUser
          ? this.global.dsUser.phone
          : this.state.requestPhone,
        requestMessage: this.state.requestMessage
      });
      if (res.data.success) {
        toast("Information requested!");
        this.props.onClose(true);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  render() {
    return (
      <div className="request-modal-cont">
        <div className="request-modal">
          <h3>Request Info</h3>
          <form>
            {this.global.dsUser ? null : (
              <>
                <label htmlFor="requestName">Name</label>
                <input
                  name="requestName"
                  id="requestName"
                  type="text"
                  className="form-control"
                  placeholder="Your Name..."
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                />
                <label htmlFor="requestEmail">Email</label> <br />
                <input
                  name="requestEmail"
                  id="requestEmail"
                  type="email"
                  placeholder="Your Email..."
                  className="form-control"
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                />
                <label htmlFor="requestPhone">Phone</label> <br />
                <input
                  name="requestPhone"
                  id="requestPhone"
                  type="test"
                  placeholder="Your Phone..."
                  className="form-control"
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                />
              </>
            )}
            <label htmlFor="requestMessage">Message</label>
            <textarea
              rows="4"
              cols="20"
              name="requestMessage"
              id="requestMessage"
              type="text"
              placeholder="Enter your message..."
              className="form-control"
              onChange={e => {
                this.setState({
                  [e.target.name]: e.target.value
                });
              }}
            />
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => this.props.onClose(false)}
                className="mx-2 backbtn btn profile-btn"
              >
                Cancel
              </button>
              <button
                type="submit"
                onClick={this.sendMessage}
                className="btn profile-btn"
              >
                Request Info
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(RequestInformationModal);
