import React, { useEffect, useState, useGlobal } from "reactn";
import PropTypes from "prop-types";

import {
  getSelectedProgramPlan,
  isProgramPlanTaxEnabled,
  coupon_discount,
  applyGift,
  promotion_discount
} from "./utils";

const ProgramSingleSessionsPromotionsTotal = props => {
  const [total, setTotal] = useState("Loading");
  const [_, setPreGiftTotal] = useGlobal("preGiftTotal");
  const giftBalance = useGlobal("giftBalance")[0];

  const calculateTotal = (plan) => {
    const total_price = plan.total_price * props.singleSessionQuantity;

    // not an installment
    let tot = 0;
    const discount_amount = promotion_discount(props.promotion, total_price);

    const taxRate = plan.isTaxEnabled
      ? parseFloat(plan.taxInfo[0].percentage / 100)
      : 0;
    const tax_amount = plan.isTaxEnabled
    ? Math.max(0, total_price - discount_amount) * taxRate : Math.max(0, total_price - discount_amount) * taxRate;
 
    const creditCardPercent = props.program.transaction_cost
      ? parseFloat(1.5) / 100
      : 1;
    const creditCardFee = props.program.transaction_cost
      ? Math.max(0.5, total_price - discount_amount) * creditCardPercent
      : 0;
    // const creditCardFee = 0;
    console.log("breakdown", {total_price, discount_amount, tax_amount, creditCardFee})
    tot =
      (total_price !== 0 ? Math.max(0, total_price - discount_amount) : 0) +
      tax_amount +
      creditCardFee;

    setPreGiftTotal(tot);
    ({ amount: tot } = applyGift(
      props.program.manual_invoices,
      giftBalance,
      tot
    ));
    props.totalAmount(tot.toFixed(2));
    if (total_price - discount_amount === 0 ) {
      tot = 0
    }
    console.log("tot", tot)
    setTotal(tot.toFixed(2));
  };

  useEffect(() => {
    const plan = getSelectedProgramPlan(props.program, props.selected);
    if (
      plan.method === "Online" ||
      plan.type === "Free"
    ) {
      setTotal("0.00");
    } else {
      calculateTotal(plan);
    }
  }, [props.coupon, giftBalance]);

  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px",
        fontSize: "14px"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif",
          fontWeight: 600
        }}
      >
        Total
      </p>
      <span style={{ fontWeight: "bold" }}>${total}</span>
    </li>
  );
};

ProgramSingleSessionsPromotionsTotal.propTypes = {
  program: PropTypes.shape({
    plans: PropTypes.array.isRequired
  }).isRequired,
  selected: PropTypes.number.isRequired,
  installments: PropTypes.number.isRequired,
  coupon: PropTypes.object
};

export default ProgramSingleSessionsPromotionsTotal;
