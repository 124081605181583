import Validators from "../validators";

const ValidateProfile = vals => {
  if (!vals) return false;
  const fields = {
    fullName: [Validators.isEmpty],
    homePhone: [Validators.isEmpty],
    birthDate: [Validators.isEmpty],
    languages: [Validators.isEmpty],
    ageGroups: [Validators.isEmpty]
  };
  for (let key of Object.keys(fields)) {
    for (let validator of fields[key]) {
      if (!validator(vals.info[key])) return false;
    }
  }

  const address = {
    addressLineOne: [Validators.isEmpty],
    city: [Validators.isEmpty],
    province: [Validators.isEmpty],
    country: [Validators.isEmpty],
    postalCode: [Validators.isEmpty]
  };
  for (let key of Object.keys(address)) {
    for (let validator of address[key]) {
      if (!validator(vals.address[key])) return false;
    }
  }
  if (vals.address.numYearsAt === "") return false;
  return true;
};

const ValidateHome = vals => {
  if (!vals) return false;
  const fields = {
    homeType: [Validators.isEmpty],
    ownership: [Validators.isEmpty],
    outdoorPlayArea: [Validators.isEmpty],
    childProofed: [Validators.isEmpty],
    smoker: [Validators.isEmpty],
    pets: [Validators.isEmpty]
  };
  for (let key of Object.keys(fields)) {
    for (let validator of fields[key]) {
      if (!validator(vals.home[key])) return false;
    }
  }

  const regular = {
    fullName: [Validators.isEmpty],
    relationship: [Validators.isEmpty],
    // vsc: [Validators.isEmpty],
    // criminalCheck: [Validators.isEmpty],
    smoker: [Validators.isEmpty],
    birthDate: [Validators.isEmpty],
    immunized: [Validators.isEmpty]
  };
  for (let val of vals.regulars) {
    for (let key of Object.keys(regular)) {
      for (let validator of regular[key]) {
        if (!validator(val[key])) return false;
      }
    }
  }
  return true;
};

const ValidateCredentials = vals => {
  if (!vals) return false;
  if (!vals.references) return false;
  if (vals.references.length === 0) return false;

  const reference = {
    fullName: [Validators.isEmpty],
    relationship: [Validators.isEmpty]
  };
  for (let val of vals.references) {
    if (!val["email"] && !val["phone"]) return false;
    for (let key of Object.keys(reference)) {
      for (let validator of reference[key]) {
        if (!validator(val[key])) return false;
      }
    }
  }
  return true;
};

const ValidateChildren = (vals, info) => {
  if (!vals) return false;
  if (info.hasChildren && !vals.length) return false;
  const fields = {
    fullName: [Validators.isEmpty],
    gender: [Validators.isEmpty],
    birthDate: [Validators.isEmpty],
    immunized: [Validators.isEmpty]
  };
  for (let val of vals) {
    for (let key of Object.keys(fields)) {
      for (let validator of fields[key]) {
        if (!validator(val[key])) return false;
      }
    }
  }
  return true;
};

const MyApplication = {
  ValidateProfile,
  ValidateHome,
  ValidateCredentials,
  ValidateChildren
};

export default MyApplication;
