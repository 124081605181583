import { setGlobal, addReducer } from "reactn";
import { CometChat } from "@cometchat-pro/chat";
import Axios from "axios";

setGlobal({
  ccSelectedContact: {},
  ccSelectedMessages: [],
  ccAdminSelectedMessages: [],
  ccAdminSelectedContact: {}
});

addReducer("ccChangeConversation", async (global, dispatch, contact) => {
  // const UID = contact.conversationWith.uid;
  // const messagesRequest = new CometChat.MessagesRequestBuilder()
  //   .setLimit(50)
  //   .setUID(UID)
  //   .build();

  // try {
  //   const ccMessages = await messagesRequest.fetchPrevious();
  //   return {
  //     ccSelectedMessages: [...ccMessages],
  //     ccSelectedContact: contact
  //   };
  // } catch (err) {
  //   console.error(err);
  // }
});
addReducer("ccChangeConversationAdmin", async (global, dispatch, contact) => {
  // const UID = contact.conversationWith.uid;
  // const cometchatApi = `https://api-us.cometchat.io/v2.0/users/${global.program.user.cometchat}/users/${UID}/messages`;
  // const results = await fetch(cometchatApi, {
  //   headers: new Headers({
  //     "content-type": "application/json",
  //     accept: "application/json",
  //     appId: process.env.REACT_APP_COMETCHAT_APPID,
  //     apiKey: process.env.REACT_APP_COMETCHAT_API
  //   })
  // });
  // const final = await results.json();

  // return {
  //   ccAdminSelectedMessages: [...final.data],
  //   ccAdminSelectedContact: contact
  // };
});

addReducer("ccSendMessage", async (global, dispatch, message) => {
  // const messageType = CometChat.MESSAGE_TYPE.TEXT;
  // const receiverType = CometChat.RECEIVER_TYPE.USER;
  // const uid = global.ccSelectedContact.conversationWith.uid;
  // const notifyUser = await Axios.post(
  //   `${process.env.REACT_APP_API}/shared/connection/message/${uid}`,
  //   { message, uid, from: global.dsUser.displayName }
  // );
  // const messageToSend = message.replace(
  //   /(?:(?:(\s*\(?([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\)?\s*(?:[.-]\s*)?)([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})/,
  //   " xxx xxx-xxxx "
  // );
  // const textMessage = new CometChat.TextMessage(
  //   uid,
  //   messageToSend,
  //   receiverType
  // );

  // try {
  //   CometChat.sendMessage(textMessage);
  //   return {
  //     ccSelectedMessages: [
  //       ...global.ccSelectedMessages,
  //       {
  //         text: message,
  //         sentAt: Date.now() / 1000,
  //         receiver: { uid },
  //         sender: { avatar: global.dsUser.photo }
  //       }
  //     ]
  //   };
  // } catch (err) {
  //   console.error(err);
  // }
});

addReducer("ccReceiveMessage", (global, dispatch, message) => {
  const convouid = global.ccSelectedContact.conversationWith.uid;
  const senderuid = message.sender.uid;

  if (convouid === senderuid) {
    return {
      ccSelectedMessages: [...global.ccSelectedMessages, message]
    };
  }
});
