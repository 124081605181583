import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const MembershipPrice = props => {
  const [price, setPrice] = useState("Loading");

  const calculatePrice = () => {
    const plan = JSON.parse(props.program.prices[props.selected]);
    const membershipPrice = parseFloat(plan.price_per_term).toFixed(2);
    setPrice(membershipPrice);
    props.itemAmount(membershipPrice);
  };

  useEffect(() => {
    calculatePrice();
  }, []);

  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px",
        borderBottom: "1px solid #dedede"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif",
          fontWeight: 600
        }}
      >
        Membership Cost -{" "}
        {JSON.parse(props.program.prices[props.selected]).auto_renew
          ? `Auto-Renews every ${
              JSON.parse(props.program.prices[props.selected]).membership_term
            } Month(s) until cancellation`
          : "Payable in Full"}
      </p>
      <span style={{ fontWeight: "bold" }}>${price}</span>
    </li>
  );
};

MembershipPrice.propTypes = {
  program: PropTypes.shape({
    prices: PropTypes.array.isRequired
  }).isRequired,
  selected: PropTypes.number.isRequired
};

export default MembershipPrice;
