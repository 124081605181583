import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { getSelectedProgramPlan } from "./utils";

const ProgramSemesterAddOnItem = props => {
  const [addOnFee, setAddOnFee] = useState("Loading");
  const [addOnItem, setAddOnItem] = useState("Loading");
  const [isAddOn, setIsAddOn] = useState(false);
  const calculateFee = () => {
    const plan = getSelectedProgramPlan(props.program, props.selected);

    if (plan.addOn) {
      props.addOnSemesterItemAmount(parseFloat(props.totalAddOnFee));
      setAddOnFee(parseFloat(plan.addOnItems[0].addOnFee).toFixed(2));
      setAddOnItem(plan.addOnItems[0].addOnDetails);
      setIsAddOn(true);
    }
  };
  useEffect(() => {
    calculateFee();
  }, []);

  if (!isAddOn) return null;

  const formattedMoney = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD", // Replace with the appropriate currency code
    minimumFractionDigits: 2 // Number of decimal places
  }).format(props.item.value);

  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px",
        borderBottom: "1px solid #dedede"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif",
          fontWeight: 500,
          fontSize: "14px"
        }}
      >
        Add-On Fee ({props.item.text})
      </p>
      <span style={{ fontWeight: 500, fontSize: "14px" }}>
        {formattedMoney}
      </span>
    </li>
  );
};

ProgramSemesterAddOnItem.propTypes = {
  program: PropTypes.shape({
    plans: PropTypes.array.isRequired
  }).isRequired,
  selected: PropTypes.number.isRequired
};

export default ProgramSemesterAddOnItem;
