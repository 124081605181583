import React from "react";
import PropTypes from "prop-types";

import { format } from "date-fns";

import { LiveView1, LiveView2, LiveView3, LiveView5 } from "../../../assets";

const PromotionDetailWidgetData = ({
  promotion,
  program,
  event,
  party,
  membership,
  online
}) => {
  const getMembershipType = () => {
    if (membership && membership.membership_type) {
      const x = membership.membership_type.map((e, i) => {
        const element = JSON.parse(e);
        return i + 1 === membership.membership_type.length ? (
          element.name
        ) : (
          <p>{element.name + ","}</p>
        );
      });
      return x;
    }
    return "Loading...";
  };
  const getMembershipPrices = () => {
    if (membership && membership.prices && membership.prices.length) {
      const x = membership.prices.reduce((p, val) => {
        const c = JSON.parse(val);
        return p < c.price_per_term ? p : c.price_per_term;
      }, Infinity);

      if (!x) {
        return "Free";
      }
      return `$${x}`;
    }
    return "Loading...";
  };
  const formatDaysOfWeek = () => {
    if (program && program.days_of_week) {
      // return JSON.stringify(Object.keys(program.days_of_week))
      return Object.keys(program.days_of_week)
        .sort((a, b) => (a < b ? a : b))
        .join(", ");
    }
    return "Loading...";
  };

  const formatTimming = () => {
    if (program && program.timing) {
      if (parseInt(program.timing[0]) > 12) {
        return parseInt(program.timing[0]) - 12;
      } else {
        return parseInt(program.timing[0]);
      }
    }
    return "Loading...";
  };

  const formatStartTiming = () => {
    if (program && program.timing) {
      return program.timing[0] / parseInt(program.timing[0]) > 1 ? "30" : "00";
    }

    return "Loading...";
  };

  const formatEndTimming = () => {
    if (program && program.timing) {
      const start = program.timing[0] < 11.5 ? "A.M." : "P.M." + " - ";
      const end =
        parseInt(program.timing[1]) > 12
          ? parseInt(program.timing[1]) - 12
          : parseInt(program.timing[1]);
      return start + end;
    }
    return "Loading...";
  };

  const formatTimingPart2 = () => {
    if (program && program.timing) {
      return (program.timing[1] / parseInt(program.timing[1]) > 1
        ? "30"
        : "00") +
        " " +
        program.timing[1] >
        11.5
        ? "P.M."
        : "A.M.";
    }
    return "Loading...";
  };

  const formatAgeGrouping = () => {
    if (program && program.age_groupings) {
      return program.age_groupings.map(e => JSON.parse(e).name).join(", ");
    }
    return "Loading...";
  };

  const formatEventStartEndDate = () => {
    if (event && event.event_start && event.event_end) {
      return `${format(
        new Date(event.event_start),
        "LLLL dd, yyyy hh:mm aa"
      )} - ${format(new Date(event.event_end), "LLLL dd, yyyy hh:mm aa")}`;
    }
    return "Loading...";
  };

  const formatPartyStartEndDate = () => {
    if (party && party.party_start && party.party_end) {
      return `${format(
        new Date(party.party_start),
        "LLLL dd, yyyy hh:mm aa"
      )} - ${format(new Date(party.party_end), "LLLL dd, yyyy hh:mm aa")}`;
    }
    return "Loading...";
  };

  const formatOnlineDaysOfWeek = () => {
    if (online && online.days_of_week) {
      // return JSON.stringify(Object.keys(program.days_of_week))
      return Object.keys(online.days_of_week)
        .sort((a, b) => (a < b ? a : b))
        .join(", ");
    }
    return "Loading...";
  };

  const formatOnlineTiming = () => {
    if (online && online.timing) {
      if (parseInt(online.timing[0]) > 24) {
        return parseInt(online.timing[0]) - 24;
      } else if (parseInt(online.timing[0]) > 12) {
        return parseInt(online.timing[0]) - 12;
      } else {
        return parseInt(online.timing[0]);
      }
    }
    return "Loading...";
  };

  const formatOnlineStartTiming = () => {
    if (online && online.timing) {
      return online.timing[0] / parseInt(online.timing[0]) > 1 ? "30" : "00";
    }

    return "Loading...";
  };

  const formatOnlineEndTiming = () => {
    if (online && online.timing) {
      const start = online.timing[0] < 11.5 ? "A.M." : "P.M." + " - ";
      const end =
        parseInt(online.timing[1]) > 12
          ? parseInt(online.timing[1]) - 12
          : parseInt(online.timing[1]);
      return start + end;
    }
    return "Loading...";
  };

  const formatOnlineTimingPart2 = () => {
    if (online && online.timing) {
      return (online.timing[1] / parseInt(online.timing[1]) > 1 ? "30" : "00") +
        " " +
        online.timing[1] >
        11.5
        ? "P.M."
        : "A.M.";
    }
    return "Loading...";
  };

  const formatOnlineAgeGrouping = () => {
    if (online && online.age_groupings) {
      return online.age_groupings.map(e => JSON.parse(e).name).join(", ");
    }
    return "Loading...";
  };

  return (
    <div className="widgetdata">
      <div
        className="box"
        style={{
          display: "flex",
          alignItems: "center",
          marginRight: "10px",
          marginBottom: "10px"
        }}
      >
        {promotion.promotion_type === "membership" ? (
          <></>
        ) : (
          <img src={LiveView1} alt="icon" className="icon-img" />
        )}

        {promotion.promotion_type === "program" ? (
          <p className="icon-text">
            {formatDaysOfWeek()}
            &nbsp;-{" "}
            <>
              {formatTimming()}:{formatStartTiming()} {formatEndTimming()}:
              {formatTimingPart2()}
            </>
          </p>
        ) : promotion.promotion_type === "event" ? (
          <p className="icon-text">{formatEventStartEndDate()}</p>
        ) : promotion.promotion_type === "party" ? (
          <p className="icon-text">{formatPartyStartEndDate()}</p>
        ) : null}

        {promotion.promotion_type === "online" ? (
          <p className="icon-text">
            {formatOnlineDaysOfWeek()}
            &nbsp;-{" "}
            <>
              {formatOnlineTiming()}:{formatOnlineStartTiming()}{" "}
              {formatOnlineEndTiming()}:{formatOnlineTimingPart2()}
            </>
          </p>
        ) : null}
      </div>

      <div
        className="box"
        style={{
          display: "flex",
          alignItems: "center",
          marginRight: "10px",
          marginBottom: "10px"
        }}
      >
        <img src={LiveView2} alt="icon" className="icon-img" />

        <p className="icon-text">
          {promotion.promotion_type === "program" ? (
            formatAgeGrouping()
          ) : promotion.promotion_type === "event" ? (
            <>
              {event && event.target_ages && event.target_ages[0]} to{" "}
              {event && event.target_ages && event.target_ages[1]}
            </>
          ) : promotion.promotion_type === "party" ? (
            <>
              {party && party.target_ages && party.target_ages[0]} to{" "}
              {party && party.target_ages && party.target_ages[1]}
            </>
          ) : null}
          {promotion.promotion_type === "membership" && getMembershipType()}
          {promotion.promotion_type === "online" && formatOnlineAgeGrouping()}
        </p>
      </div>

      <div
        className="box"
        style={{
          display: "flex",
          alignItems: "center",
          marginRight: "10px",
          marginBottom: "10px"
        }}
      >
        <img src={LiveView3} alt="icon" className="icon-img" />

        <p className="icon-text">
          {promotion.promotion_type === "program" &&
            program &&
            program.program_category}
          {promotion.promotion_type === "online" &&
            online &&
            online.program_category}
          {promotion.promotion_type === "event" && event && (
            <>
              {event.tickets &&
                event.tickets.length &&
                event.tickets.reduce((p, val) => {
                  const c = JSON.parse(val);
                  return c.ticket_status === "Free"
                    ? "Free"
                    : `$${c.ticket_price}`;
                }, Infinity)}
            </>
          )}
          {promotion.promotion_type === "party" && party && (
            <>
              {party.tickets &&
                party.tickets.length &&
                party.tickets.reduce((p, val) => {
                  const c = JSON.parse(val);
                  return c.ticket_status === "Free"
                    ? "Free"
                    : `$${c.ticket_price}`;
                }, Infinity)}
            </>
          )}

          {promotion.promotion_type === "membership" && getMembershipPrices()}
        </p>
      </div>
      {promotion.program_category == "program" && (
        <div
          className="box"
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: "10px",
            marginBottom: "10px"
          }}
        >
          <img src={LiveView5} alt="icon" className="icon-img" />

          <p className="icon-text">
            Total Spots :{" "}
            {program && program.total_spots
              ? `${program.total_spots}`
              : "# Spots Pending"}
          </p>
        </div>
      )}
    </div>
  );
};

PromotionDetailWidgetData.propTypes = {
  promotion: PropTypes.shape({
    promotion_type: PropTypes.string
  }).isRequired,
  program: PropTypes.object,
  event: PropTypes.object,
  party: PropTypes.object,
  membership: PropTypes.object
};

export default PromotionDetailWidgetData;
