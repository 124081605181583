import React, { useState } from "reactn";
import PropTypes from "prop-types";
import InfoBalloon from "./InfoBalloon";
import Pagination from "../pagination/pagination";

const Table = props => {
  const { data, headings, actions } = props;
  const [usePage, setPage] = useState(0);
  const [useDataLength, setDataLength] = useState(
    data.length ? data.length : 0
  );

  React.useEffect(() => {
    setDataLength(data.length);
  }, [data]);

  return (
    <>
      <div className="tablecont overflow-x-scroll">
        <table className="table">
          <thead>
            <tr>
              {headings &&
                headings.map((heading, index) => {
                  return (
                    <th
                      scope="col"
                      key={`${index}-heading`}
                      style={heading.customStyle ? heading.customStyle : {}}
                    >
                      {heading.label}
                      {heading.infoBalloon && (
                        <InfoBalloon body={heading.infoBalloon} />
                      )}
                    </th>
                  );
                })}
            </tr>
          </thead>
          <tbody>
            {data &&
              data.slice(usePage * 7, usePage * 7 + 7).map((row, index) => {
                return (
                  <tr key={`${index}-row`}>
                    {headings &&
                      headings.map((heading, index) => {
                        if (heading.id === "action") {
                          return (
                            <td
                              key={`${index}-acell`}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              {actions.map((action, i) => (
                                <button
                                  onClick={() => action.action(row)}
                                  style={{ marginLeft: "10px" }}
                                  key={`${i}-action`}
                                >
                                  {action.name}
                                </button>
                              ))}
                            </td>
                          );
                        } else {
                          return heading.customCell ? (
                            heading.customCell(row, index)
                          ) : (
                            <td key={`${index}-custom`}>
                              {heading.objField
                                ? row[heading.id][heading.objField]
                                : row[heading.id]
                                ? row[heading.id]
                                : "Unavailable"}
                            </td>
                          );
                        }
                      })}
                  </tr>
                );
              })}
            {!data.length ? (
              <tr>
                <td colSpan={headings.length} className="text-center">
                  Nothing to Show
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
      <Pagination
        pageCount={Math.ceil(useDataLength / 7)}
        onPageChange={setPage}
        forcePage={usePage}
      />
    </>
  );
};

Table.propTypes = {
  headings: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  actions: PropTypes.array,
  customCell: PropTypes.any
};

Table.defaultProps = {
  actions: []
};

export default Table;
