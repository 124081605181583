import Input from "./Input";
import Select from "./Select";
import DateInputs from "./DateInput";
import MultiInput from "./MultiInput";
import SwitchToggle from "./SwitchToggle";

import {
  DropdownSelect,
  YesNoDropdownSelect,
  GenderDropdownSelect,
  ChildMultiSelect,
  ProgramTypeSelect,
  PaymentAccountTypeSelect,
  FormGroupSelect,
  ChildAgeGroupSelect,
  ChildCenterAgeGroupSelect,
  LanguageInput,
  PercentageOffDropdownSelect,
  PercentageExtraDropdownSelect,
  PercentageHalvesDropdownSelect,
  TimeDropdownSelect,
  DaysOfWeekRangeDropdownSelect,
  PetsDropdownSelect,
  ProgramTypeSelectMulti,
  OutdoorPlayAreaDropdownSelect,
  DaysDropdownSelect,
  MemberShipSelect,
  MealDropdownSelect,
  DateMultiSelect,
  SecondaryTags,
  ProgramStructures,
  MainTags,
  MultiProgramCategories
} from "./DropdownSelect";
import BoxFormTabbed from "./BoxFormTabbed";
import BoxForm from "./BoxForm";
import Form from "./Form";
import TextArea from "./TextArea";
import SelectOptions from "./SelectOptions";

const formTypes = {
  DateAddChildModal: DateInputs.DateInputAddChildModal,
  Date: DateInputs.DateInput,
  Datewith: DateInputs.DateWithCheckMark,
  MonthYearInput: DateInputs.MonthYearInput,
  Select,
  Input,
  LanguageSelect: LanguageInput,
  MultiInput,
  SwitchToggle,
  DropdownSelect,
  YesNoDropdownSelect,
  GenderDropdownSelect,
  ChildMultiSelect,
  ProgramTypeSelectMulti,
  ProgramTypeSelect,
  PaymentAccountTypeSelect,
  FormGroupSelect,
  ChildAgeGroupSelect,
  ChildCenterAgeGroupSelect,
  MainTags,
  PercentageOffDropdownSelect,
  PercentageExtraDropdownSelect,
  MemberShipSelect,
  PercentageHalvesDropdownSelect,
  TimeDropdownSelect,
  ProgramStructures,
  DaysOfWeekRangeDropdownSelect,
  DaysDropdownSelect,
  SecondaryTags,
  TextArea,
  MealDropdownSelect,
  PetsDropdownSelect,
  OutdoorPlayAreaDropdownSelect,
  SelectOptions,
  MultiProgramCategories,
  DateMultiSelect
};

export { Input, Select, formTypes, BoxFormTabbed, BoxForm, Form };
