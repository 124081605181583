import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import SignatureCanvas from "react-signature-canvas";
import BaseModal from "./baseModal/BaseModal";
import { GET_HTML } from "./baseModal/FieldTypes";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
class SignModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      val: -1
    };
    this.sigPad = {};
  }

  requestTour = async e => {
    e.preventDefault();
  };
  trim = () => {
    this.props.complete(this.sigPad.getTrimmedCanvas().toDataURL("image/png"));
    this.props.onClose();
  };
  async submitForm(e) {
    e.preventDefault();
    if (this.state.val > -1) {
      if (this.state.val > 10 && !this.state.question) {
        toast.error("Please Complete the form!");
      } else {
        this.props.addField(
          this.state.val,
          this.state.question,
          this.state.options
        );
        this.props.onClose();
      }
    } else {
      toast.error("Please Complete the form!");
    }
  }

  signatureCanvas = () => {
    return (
      <div className="sigCanvas-container" style={{ textAlign: "center" }}>
        <SignatureCanvas
          penColor="green"
          canvasProps={{
            width: 400,
            height: 190,
            className: "sigCanvas",
            style: {
              border: "1px solid black",
              borderRadius: "10px"
            }
          }}
          ref={r => {
            this.sigPad = r;
          }}
        />
      </div>
    );
  };

  getBaseModalFields() {
    const signField = {
      type: GET_HTML,
      data: {
        gethtml: this.signatureCanvas
      }
    };

    const fields = [];
    fields.push(signField);

    return fields;
  }

  getBaseModalButtons = () => {
    const signButton = {
      name: "Sign",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => {
        this.trim();
      }
    };

    const clearButton = {
      name: "Clear",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatoryKey: "validateSubmit",
      handleClick: e => {
        e.preventDefault();
        this.sigPad.clear();
      }
    };

    const buttons = [signButton, clearButton];

    return buttons;
  };

  getBaseModalProps = () => {
    return {
      title: `Sign`,
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      handleClose: this.props.onClose,
      height: "65vh",
      midSectionHeight: "49vh"
    };
  };

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
  }
  // render() {
  //   return (
  //     <div className="request-modal-cont">
  //       <div className="request-modal lesspadding">
  //         <div>
  //           <h3>Sign</h3>
  //           <form
  //             onSubmit={e => this.submitForm(e)}
  //             style={{ textAlign: "center" }}
  //           >
  //             <SignatureCanvas
  //               penColor="green"
  //               canvasProps={{
  //                 width: 400,
  //                 height: 200,
  //                 className: "sigCanvas",
  //                 style: { border: "1px solid black", borderRadius: "10px" }
  //               }}
  //               ref={r => {
  //                 this.sigPad = r;
  //               }}
  //             />
  //           </form>
  //           <div className="modal-footer">
  //             <button
  //               type="button"
  //               onClick={_ => this.trim()}
  //               className="btn profile-btn"
  //             >
  //               Sign
  //             </button>
  //             <button
  //               type="button"
  //               className="btn profile-btn"
  //               onClick={_ => this.sigPad.clear()}
  //             >
  //               Clear
  //             </button>
  //           </div>

  //           <IconButton
  //             style={{ position: "absolute", right: "0", top: "0" }}
  //             onClick={_ => this.props.onClose()}
  //           >
  //             <Close />
  //           </IconButton>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }
}

export default withRouter(SignModal);
