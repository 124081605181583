import React, { useState } from "react";
import "../fieldComponent.css";
import "./ImageDropdown.css";
import { Dropdown } from "semantic-ui-react";
import Title from "../Title";

const ImageDropdown = props => {
  const {
    name,
    required,
    placeholder,
    handleChange,
    choices,
    value,
    fieldValid,
    setFieldValid
  } = props;

  const [currentSelection, setCurrentSelection] = useState("");

  return (
    <div style={{ zIndex: "10000" }}>
      {/* <p className="field-component-title">
        {name}
        {required ? <span className="required-star"> *</span> : ""}
      </p> */}
      <Title name={name} required={required} />

      <Dropdown
        // style={{
        //   border: "1.5px solid #EDF4FF",
        //   borderRadius: "4px",
        //   color: "#8c8ea0",
        //   border: `1px solid ${!fieldValid ? "rgb(255, 0, 0)" : "#c5c5c5"}`
        // }}
        style={{
          border: `1px solid ${!fieldValid ? "rgb(255, 0, 0)" : "#c5c5c5"}`,
          color: `${currentSelection === "" ? "#797979" : "#000"}`
        }}
        className="form-control field-component-input-box"
        placeholder={placeholder}
        fluid
        selection
        onChange={(_, { value }) => {
          setFieldValid(true);
          handleChange(value);
          setCurrentSelection(value);
        }}
        // onChange={(_, { value }) => {
        //   this.setState({
        //     avatar: value
        //   });
        // }}
        value={value}
        options={choices}
        // options={this.avatar_images.map((e, i) => ({
        //   text: `Person ${i + 1}`,
        //   value: e.name,
        //   key: e.name,
        //   image: {
        //     avatar: true,
        //     src: `${e.image}`
        //   }
        // }))}
      />
    </div>
  );
};

export default ImageDropdown;
