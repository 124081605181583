import ModalTemplate from "./ModalTemplate";
import ModalFormGroup from "./ModalCustomGroup.jsx";

import ModalInputGroup from "./ModalInputGroup";
import ModalCustomGroup from "./ModalCustomGroup";
import ModalAreaGroup from "./ModalAreaGroup";
import ModalSwitchGroup from "./ModalSwitchGroup";
import ModalDisplayGroup from "./ModalDisplayGroup";

const ModalFields = {
  input: ModalInputGroup,
  textarea: ModalAreaGroup,
  switch: ModalSwitchGroup,
  custom: ModalCustomGroup,
  display: ModalDisplayGroup
};

export { ModalTemplate, ModalFields };
