import React from "reactn";
import { BoxForm, formTypes } from "../../../../components/UI/form";
import "../../../../assets/css/componentSpecificCss/kidProgram.css";

class SectionTwo extends React.PureComponent {
  constructor(props) {
    super(props);
    /*this.setAddress = this.setAddress.bind(this);
    this.customForm = this.customForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this._geoSuggest = null;
    this._geoSuggestPrev = null;*/

    this.state = {
      selectedDay: "",
      fromHours: -1,
      toHours: -1
    };
  }
  handleChange = (e, v) => {
    this.setGlobal({
      organizationInfo: {
        ...this.global.organizationInfo,
        [e]: v
      }
    });
  };

  setDay(day) {
    const days = { ...this.global.organizationInfo.days_of_week };
    if (days[day]) delete days[day];
    else days[day] = true;
    this.handleChange("days_of_week", days);
  }

  displayHours = (fromHours, toHours) => {
    if (fromHours === -1) {
      return "-";
    } else {
      return (
        <div>
          {parseInt(fromHours) > 12
            ? parseInt(fromHours) - 12
            : parseInt(fromHours)}
          :{fromHours / parseInt(fromHours) > 1 ? "30" : "00"}{" "}
          {fromHours < 11.5 ? "AM" : "PM"} -{" "}
          {parseInt(toHours) > 12 ? parseInt(toHours) - 12 : parseInt(toHours)}:
          {toHours / parseInt(toHours) > 1 ? "30" : "00"}{" "}
          {toHours > 11.5 ? "PM" : "AM"}
        </div>
      );
    }
  };

  customForm = () => {
    return (
      <div className="formbody">
        {/* {!this.state.timeChangeShown ? null : (
          <ConfirmTimeChangeForm onClose={this.setTime} />
        )} */}
        <form>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">Website URL</h3>
            </div>
            <div className="col-7 d-flex">
              <input
                className="input-fields"
                style={{ width: "100%" }}
                name="website"
                onChange={e => this.handleChange(e.target.name, e.target.value)}
                value={this.global.organizationInfo.website}
              />
            </div>
          </div>
          {/* <div className="row">
            <div
              className="col-5"
              style={{ display: "flex", alignItems: "center" }}
            >
              <h3 className="label-names">Days of Week</h3>
            </div>
            <div className="col-7 d-flex">
              <div
                name="days"
                className="row days"
                validation={"validDays"}
                style={{ width: "100%", margin: 0, padding: 0 }}
              >
                <div
                  data-day="Monday"
                  className={`day-monday day ${
                    this.global.organizationInfo.days_of_week["Monday"]
                      ? "selecta"
                      : ""
                  }`}
                  onClick={e => this.setDay(e.target.getAttribute("data-day"))}
                >
                  M
                </div>
                <div
                  data-day="Tuesday"
                  className={`day-tuesday day ${
                    this.global.organizationInfo.days_of_week["Tuesday"]
                      ? "selecta"
                      : ""
                  }`}
                  onClick={e => this.setDay(e.target.getAttribute("data-day"))}
                >
                  Tu
                </div>
                <div
                  data-day="Wednesday"
                  className={`day-wednesday day ${
                    this.global.organizationInfo.days_of_week["Wednesday"]
                      ? "selecta"
                      : ""
                  }`}
                  onClick={e => this.setDay(e.target.getAttribute("data-day"))}
                >
                  W
                </div>
                <div
                  data-day="Thursday"
                  className={`day-thursday day ${
                    this.global.organizationInfo.days_of_week["Thursday"]
                      ? "selecta"
                      : ""
                  }`}
                  onClick={e => this.setDay(e.target.getAttribute("data-day"))}
                >
                  Th
                </div>
                <div
                  data-day="Friday"
                  className={`day-friday day ${
                    this.global.organizationInfo.days_of_week["Friday"]
                      ? "selecta"
                      : ""
                  }`}
                  onClick={e => this.setDay(e.target.getAttribute("data-day"))}
                >
                  F
                </div>
                <div
                  data-day="Saturday"
                  className={`day-saturday day ${
                    this.global.organizationInfo.days_of_week["Saturday"]
                      ? "selecta"
                      : ""
                  }`}
                  onClick={e => this.setDay(e.target.getAttribute("data-day"))}
                >
                  Sa
                </div>
                <div
                  data-day="Sunday"
                  className={`day-sunday day ${
                    this.global.organizationInfo.days_of_week["Sunday"]
                      ? "selecta"
                      : ""
                  }`}
                  onClick={e => this.setDay(e.target.getAttribute("data-day"))}
                >
                  Su
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">Hours of Operations</h3>
            </div>
            <div className="col-7 d-flex" style={{ alignItems: "center" }}>
              <div style={{ width: "40%" }}>
                <formTypes.TimeDropdownSelect
                  value={parseFloat(
                    this.state.fromHours[0] || -1
                  )}
                  name="hours"
                  onChange={(e, v) =>
                    this.handleChange(e, [
                      v,
                      this.global.organizationInfo.hours[1]
                    ])
                  }
                ></formTypes.TimeDropdownSelect>
              </div>
              To
              <div style={{ width: "40%" }}>
                <formTypes.TimeDropdownSelect
                  name="hours"
                  value={parseFloat(
                    this.global.organizationInfo.hours[1] || -1
                  )}
                  onChange={(e, v) =>
                    this.handleChange(e, [
                      this.global.organizationInfo.hours[0],
                      v
                    ])
                  }
                ></formTypes.TimeDropdownSelect>
              </div>
            </div>
          </div> */}
          {/* New Hours UI */}
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">Operational Hours</h3>
            </div>

            {/* Display Section */}
            <div className="operational-hours-container">
              <div className="open-hours">
                {this.global.organizationInfo.operational_days.map((e, i) => {
                  const ele = typeof e === "string" ? JSON.parse(e) : e;

                  return (
                    <div key={`${i}-od`}>
                      <div className="daily-timings">
                        <strong className="days-of-week">{ele.day}</strong>
                        <span className="days-timings">
                          {this.displayHours(ele.fromHours, ele.toHours)}
                        </span>
                        <span
                          className="remove-btn"
                          onClick={_ =>
                            this.setGlobal({
                              organizationInfo: {
                                ...this.global.organizationInfo,
                                operational_days: [
                                  ...this.global.organizationInfo.operational_days.filter(
                                    (f, index) => index !== i
                                  )
                                ]
                              }
                            })
                          }
                        >
                          Remove
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            {/* Form section */}
            <div className="col-12 d-flex" style={{ alignItems: "center" }}>
              <select
                name="selectedDay"
                id="selectedDay"
                type="text"
                // className="form-control"
                style={{
                  width: "25%",
                  marginRight: "10px",
                  padding: "5px",
                  border: "1px solid gainsboro"
                }}
                onChange={e => {
                  this.setState({
                    [e.target.name]: e.target.value
                  });
                }}
                value={this.state.selectedDay}
              >
                <option value="" disabled selected>
                  Please Select
                </option>
                <option value="Monday">Monday</option>
                <option value="Tuesday">Tuesday</option>
                <option value="Wednesday">Wednesday</option>
                <option value="Thursday">Thursday</option>
                <option value="Friday">Friday</option>
                <option value="Saturday">Saturday</option>
                <option value="Sunday">Sunday</option>
              </select>
              <div style={{ width: "25%", marginRight: "10px" }}>
                <formTypes.TimeDropdownSelect
                  value={parseFloat(this.state.fromHours)}
                  name="fromHours"
                  onChange={(e, v) => this.setState({ fromHours: v })}
                ></formTypes.TimeDropdownSelect>
              </div>
              {/* To */}
              <div style={{ width: "25%", marginRight: "10px" }}>
                <formTypes.TimeDropdownSelect
                  name="toHours"
                  value={parseFloat(this.state.toHours)}
                  onChange={(e, v) => this.setState({ toHours: v })}
                ></formTypes.TimeDropdownSelect>
              </div>
              <div
                onClick={_ => {
                  this.setState({
                    selectedDay: "",
                    fromHours: -1,
                    toHours: -1
                  });
                  this.setGlobal({
                    organizationInfo: {
                      ...this.global.organizationInfo,
                      operational_days: [
                        ...this.global.organizationInfo.operational_days,
                        {
                          day: this.state.selectedDay,
                          fromHours: this.state.fromHours,
                          toHours: this.state.toHours
                        }
                      ]
                    }
                  });
                }}
                style={{
                  height: "20px",
                  width: "20px",
                  border: "1px solid #FF6663",
                  borderRadius: "7px",
                  background: "#FF6663",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <i className="fas fa-plus" style={{ color: "white" }}></i>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">Facebook</h3>
            </div>
            <div className="col-7 d-flex">
              <input
                className="input-fields"
                style={{ width: "100%" }}
                name="facebook"
                onChange={e => this.handleChange(e.target.name, e.target.value)}
                value={this.global.organizationInfo.facebook}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">Twitter</h3>
            </div>
            <div className="col-7 d-flex">
              <input
                className="input-fields"
                style={{ width: "100%" }}
                name="twitter"
                onChange={e => this.handleChange(e.target.name, e.target.value)}
                value={this.global.organizationInfo.twitter}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">Instagram</h3>
            </div>
            <div className="col-7 d-flex">
              <input
                className="input-fields"
                style={{ width: "100%" }}
                name="instagram"
                onChange={e => this.handleChange(e.target.name, e.target.value)}
                value={this.global.organizationInfo.instagram}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Format <span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <select
                style={{ width: "100%" }}
                name="format"
                onChange={e => this.handleChange(e.target.name, e.target.value)}
                value={this.global.organizationInfo.format}
                className="input-fields"
              >
                <option value="">Please Select</option>
                <option value="In-Person">In-Person</option>
                <option value={"Online"}>Online</option>
                <option value="In-Person, Online">Both</option>
              </select>
            </div>
          </div>
        </form>
      </div>
    );
  };

  render() {
    return (
      <BoxForm
        handleChange={this.handleChange}
        name={"Additional Details"}
        customForm={this.customForm()}
      />
    );
  }
}

export default SectionTwo;
