import React, { useState, useEffect, useGlobal } from "reactn";
import { applyGift } from "./utils";

import "../../../assets/css/componentSpecificCss/checkout.css";

// This component uses the users gift card balance and the pre-gift total.
const CheckoutGiftCard = props => {
  const preGiftTotal = useGlobal("preGiftTotal")[0];
  const global = useGlobal();
  console.log("global is checkoutgiftcard", global);
  const [giftDiscount, setGiftDiscount] = useState("Loading");
  const calculateGiftDiscount = () => {
    console.log("checkout gift card", [
      props.manual,
      props.giftBalance,
      preGiftTotal
    ]);
    const { giftDiscount } = applyGift(
      props.manual,
      props.giftBalance,
      preGiftTotal
    );
    setGiftDiscount(giftDiscount.toFixed(2));
  };

  useEffect(() => {
    calculateGiftDiscount();
  }, [props.giftBalance]);
  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px",
        borderBottom: "1px solid #dedede"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif"
        }}
      >
        Gift Card
      </p>
      <span> - ${giftDiscount}</span>
    </li>
  );
};

export default CheckoutGiftCard;
