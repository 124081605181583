import { setGlobal, addReducer } from "reactn";
import { startOfWeek, endOfWeek } from "date-fns";

const today = new Date();
const weeklySlots = {
  slots: 0,
  start: startOfWeek(today),
  end: endOfWeek(today),
  myChildren: 0,
  days: {},
  available: {
    0: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0
  }
};

setGlobal({ weeklySlots: weeklySlots });
addReducer("getWeeklySlots", (global, dispatch, start, end) => {
  if (process.env.REACT_APP_MODE === "dev") {
    return {
      weeklySlots: devWeeklySlots(start, end)
    };
  }
});

addReducer("setWeeklySlotsPeriod", (global, dispatch, start) => {
  if (process.env.REACT_APP_MODE === "dev") {
    return {
      weeklySlots: {
        ...devWeeklySlots(start),
        start: startOfWeek(start),
        end: endOfWeek(start)
      }
    };
  }
});

const devWeeklySlots = (start, end) => {
  const childPend = {
    status: "Pending",
    child: {
      fullName: "Captain America"
    }
  };
  const childActive = {
    status: "Active",
    child: {
      fullName: "The Hulk"
    }
  };
  const childExpire = {
    status: "Expiring",
    child: {
      fullName: "Wolverine"
    }
  };
  const days = {
    0: [
      {
        fullDay: childPend
      },
      {
        partialDay: childExpire
      },
      {
        halfSecond: childPend
      }
    ],
    1: [
      {
        fullDay: childActive
      },
      {
        partialDay: childActive
      },
      {
        halfFirst: childExpire
      }
    ],
    2: [
      {
        fullDay: childActive
      },
      {
        partialDay: childExpire
      },
      {
        halfFirst: childExpire,
        halfSecond: childPend
      }
    ],
    3: [
      {
        fullDay: childPend
      },
      {
        partialDay: childExpire
      },
      {
        halfFirst: childActive,
        halfSecond: childActive
      }
    ],
    4: [
      {
        partialDay: childActive
      },
      {
        halfFirst: childExpire,
        halfSecond: childPend
      }
    ],
    5: [
      {
        fullDay: childPend
      },
      {
        partialDay: childActive
      },
      {
        halfFirst: childExpire,
        halfSecond: childPend
      }
    ],
    6: [
      {
        fullDay: childPend
      },
      {
        partialDay: childExpire
      }
    ]
  };
  return {
    start: startOfWeek(start ? start : today),
    end: endOfWeek(start ? start : today),
    myChildren: 1,
    days: days,
    slots: 5,
    available: {
      0: 0.5,
      1: 0.5,
      2: 0,
      3: 0,
      4: 1,
      5: 0,
      6: 1
    }
  };
};
