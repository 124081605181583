import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const TicketPrice = props => {
  const [ticketPrice, setTicketPrice] = useState("Loading");

  const calculateTicketPrice = () => {
    const plan = JSON.parse(props.program.tickets[props.selected]);
    const price = plan.ticket_price
      ? `$${parseFloat(plan.ticket_price).toFixed(2)}`
      : "Free";
    setTicketPrice(price);
    props.itemAmount(parseFloat(plan.ticket_price).toFixed(2));
  };

  useEffect(() => {
    calculateTicketPrice();
  }, []);
  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px",
        borderBottom: "1px solid #dedede"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif",
          fontWeight: 600
        }}
      >
        Ticket Price - Payable Now.
      </p>
      <span style={{ fontWeight: "bold" }}>{ticketPrice}</span>
    </li>
  );
};

TicketPrice.propTypes = {
  program: PropTypes.object.isRequired,
  selected: PropTypes.number.isRequired
};

export default TicketPrice;
