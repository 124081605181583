export const PERCENT = "Percentage";
export const FIXED_AMOUNT = "Fixed Amount";
export const COUPON_FIXED_AMOUNT = "Amount";

export const PROGRAM = "program";
export const ONLINE = "online";
export const EVENT = "event";
export const MEMBERSHIP = "membership";
export const PARTY = "party";

export const MINIMUM_CHARGE = 0.5; // 50 Cents

export const PROP_PROGRAM = "Program";
export const PROP_ONLINE = "Online";
export const PROP_EVENT = "Event";
export const PROP_MEMBERSHIP = "Membership";
export const PROP_PARTY = "Party";

export const SEMESTER_DROPINS = "Drop-In";
export const INSTALLMENTS = "Installments";
export const REGULAR_SEMESTER = "Regular-Semester";
export const SINGLE_SESSIONS = "Single-Sessions";
export const ONE_TIME = "One-Time";
export const SEMESTER = "Semester";



const KNOWN_PLURAL_FORMS = {
  "Class": "Classes",
  "Lesson": "Lessons",
  "Session": "Sessions",
}
export const pluralize = (word, quantity) => {
  if (quantity===1)
    return word;

  if (KNOWN_PLURAL_FORMS[word])
    return KNOWN_PLURAL_FORMS[word];

  if (word.endsWith('s') || word.endsWith('S'))
    return word+"es";
  else
    return word+"s";
}


export function coupon_discount(coupon, number) {
  if (coupon.type === PERCENT) {
    const percent = parseFloat(coupon.amount_off);
    return (number * percent) / 100;
  } else if (coupon.type === COUPON_FIXED_AMOUNT) {
    return parseFloat(coupon.amount_off); // stored as dollars (not cents)
  }
}

export function getPromotionPercentAmount(number, discount) {
  return (number * discount) / 100;
}

export function getPromotionFixedAmount(cents) {
  return cents / 100;
}

export function promotion_discount(promotion, number) {
  if (promotion.discount_type === PERCENT) {
    return getPromotionPercentAmount(number, promotion.discount_percent);
  } else if (promotion.discount_type == FIXED_AMOUNT) {
    return getPromotionFixedAmount(promotion.discount_fixed);
  }
}
export function getPromotionDiscountAmount(promotion, selected_plan) {
  if (promotion.discount_type === PERCENT) {
    const number = parseFloat(selected_plan.tuition_rate);
    const discount = promotion.discount_percent;
    
    const amount = getPromotionPercentAmount(number, discount);

    return amount;
  } else if (promotion.discount_type === FIXED_AMOUNT) {
    return getPromotionFixedAmount(promotion.discount_fixed);
  }
}

export function getMembershipTotalPrice(program, selected) {
  const price = JSON.parse(program.prices[selected]);
  const total_number = parseFloat(price.price_per_term);
  const tax_addition = price.isTaxEnabled
    ? parseFloat(
        parseFloat(price.price_per_term) * (price.taxInfo[0].percentage / 100)
      )
    : 0;
  return parseFloat(total_number + tax_addition).toFixed(2);
}

export function getEventTotalPrice(program, selected) {
  const ticket = JSON.parse(program.tickets[selected]);
  const ticket_price = parseFloat(ticket.ticket_price || 0);
  const tax_amount = ticket.isTaxEnabled
    ? parseFloat(ticket_price * (ticket.taxInfo[0].percentage / 100))
    : 0;

  return parseFloat(ticket_price + tax_amount).toFixed(2);
}

export function getMembershipPromotionDiscountAmount(
  promotion,
  membership,
  selected
) {
  if (promotion.discount_type === PERCENT) {
    const parsed_plan = JSON.parse(membership.prices[selected]);
    return getPromotionPercentAmount(
      parsed_plan.price_per_term,
      promotion.discount_percent
    );
  } else if (promotion.discount_type === FIXED_AMOUNT) {
    return getPromotionFixedAmount(promotion.discount_fixed);
  }
}

export function displayTotalEventHST(program, selected) {
  return parseFloat(
    parseFloat(JSON.parse(program.tickets[selected]).ticket_price || 0) * 1.13
  ).toFixed(2);
}

export function getEventPromotionDiscountAmount(promotion, event, selected) {
  if (promotion.discount_type === PERCENT) {
    const parsed_ticket_price =
      JSON.parse(event.tickets[selected]).ticket_price || 0;
    return getPromotionPercentAmount(
      parsed_ticket_price,
      promotion.discount_percent
    );
  } else if (promotion.discount_type === FIXED_AMOUNT) {
    return getPromotionFixedAmount(promotion.discount_fixed);
  }
}

export function getPartyPromotionDiscountAmount(promotion, party, selected) {
  if (promotion.discount_type === PERCENT) {
    const parsed_ticket_price =
      JSON.parse(party.tickets[selected]).ticket_price || 0;
    return getPromotionPercentAmount(
      parsed_ticket_price,
      promotion.discount_percent
    );
  } else if (promotion.discount_type === FIXED_AMOUNT) {
    return getPromotionFixedAmount(promotion.discount_fixed);
  }
}

export function displayTotalEventHSTAndPromotion(promotion, event, selected) {
  const totalwithhst = displayTotalEventHST(event, selected);

  let promotionpriceReduction = getEventPromotionDiscountAmount(
    promotion,
    event,
    selected
  );

  return Math.max(0, totalwithhst - promotionpriceReduction).toFixed(2);
}

export function isProgramPlanTaxEnabled(program, selected) {
  const plan = getSelectedProgramPlan(program, selected);
  return plan.isTaxEnabled;
}

export function isPartyPlanTaxEnabled(program, selected) {
  const plan = getSelectedPartyTicket(program, selected);
  return plan.isTaxEnabled;
}

export function getSelectedProgramPlan(program, selected) {
  return JSON.parse(program.plans.filter(e => JSON.parse(e).id == selected)[0]);
}

export function getSelectedPartyTicket(party, selected) {
  return JSON.parse(party.tickets[selected]);
}

export function getSelectedEventTicket(event, selected) {
  return JSON.parse(event.tickets[selected]);
}

export function getSelectedMembershipPrices(membership, selected) {
  return JSON.parse(membership.prices[selected]);
}

/**
 *
 * All amounts in dollars.
 *
 * @param {Boolean} isManual
 * @param {Number} giftBalance
 * @param {Number} preGiftTotal
 */
export function applyGift(isManual, giftBalance, preGiftTotal) {
  let amount, giftDiscount;
  if (isManual)
    amount = giftBalance >= preGiftTotal ? 0 : preGiftTotal - giftBalance;
  else
    amount =
      giftBalance >= preGiftTotal
        ? 0
        : preGiftTotal - giftBalance < MINIMUM_CHARGE
        ? MINIMUM_CHARGE
        : preGiftTotal - giftBalance;
  giftDiscount = preGiftTotal - amount;
  console.log({ isManual, giftBalance, preGiftTotal, amount, giftDiscount });
  return { amount, giftDiscount };
}
