import React from "react";
import ModalFormLabel from "./ModalFormLabel";

import "./ModalFormGroup.css";

import Switch from "@material-ui/core/Switch";

/**
 * Switch Form Group component.
 *
 * @param label Display Label of the form group.
 * @param name Input attribute and label htmlFor attribute.
 *
 * @param tooltip Text of tooltip (optional).
 *
 * @param checked Value of the Switch.
 * @param onClick Click handler of Switch.
 */
const ModalSwitchGroup = ({
  label,
  name,
  tooltip,
  checked,
  onClick,
  className,
  ...options
}) => (
  <div className="form-group modal-form-group">
    <ModalFormLabel label={label} name={name} tooltip={tooltip} />
    <div className={className || "col-9"}>
      <Switch
        name={name}
        id={name}
        checked={checked}
        onClick={onClick}
        color="primary"
        {...options}
      />
    </div>
  </div>
);

export default ModalSwitchGroup;
