import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { getSelectedProgramPlan } from "./utils";

const ProgramTuitionRate = props => {
  const [tuition_rate, setTuitionRate] = useState("Loading");

  const calculateTuitionRate = () => {
    const plan = getSelectedProgramPlan(props.program, props.selected);
    const rate = parseFloat(plan.tuition_rate).toFixed(2);
    setTuitionRate(rate);
    props.itemAmount(rate);
  };

  useEffect(() => {
    calculateTuitionRate();
  }, []);
  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px",
        borderBottom: "1px solid #dedede"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif",
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "20px"
        }}
      >
        Enrollment Fee - Payable on the 1st day of enrollment{" "}
        {props.displayDate &&
          `(${moment(props.displayDate).format("YYYY-MM-DD")})`}
      </p>
      <span style={{ fontWeight: 500, fontSize: "14px" }}>${tuition_rate}</span>
    </li>
  );
};

ProgramTuitionRate.propTypes = {
  program: PropTypes.shape({
    plans: PropTypes.array.isRequired
  }).isRequired,
  selected: PropTypes.number.isRequired,
  displayDate: PropTypes.string
};

export default ProgramTuitionRate;
