import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { getSelectedProgramPlan } from "./utils";

const OnlineOneTimeSemester = props => {
  const [fee, setFee] = useState("Loading");
  const calculateFee = () => {
    const plan = getSelectedProgramPlan(props.program, props.selected);
    props.itemAmount(parseFloat(plan.total_price).toFixed(2));
    setFee(parseFloat(plan.total_price).toFixed(2));
  };
  useEffect(() => {
    calculateFee();
  }, []);
  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px",
        borderBottom: "1px solid #dedede"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif"
        }}
      >
        Semester Fee - Payable Now.
      </p>
      <span>${fee}</span>
    </li>
  );
};

OnlineOneTimeSemester.propTypes = {
  program: PropTypes.shape({
    plans: PropTypes.array.isRequired
  }).isRequired,
  selected: PropTypes.number.isRequired
};

export default OnlineOneTimeSemester;
