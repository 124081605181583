import React from "reactn";
import { format } from "date-fns";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import "../../assets/css/componentSpecificCss/calendarDetailsModal.css";
import axios from "axios";
import { DateTime } from "luxon";

const gradients = [
  "gra-yellow-pink",
  "gra-blue-purple",

  "gra-purple-blue",
  "gra-teal-green",
  "gra-yellow-orange"
];


class CalendarDetailsModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      colour: 0,   //remnant of some old code. No idea if it has any relation to the "program_color" column in partner_programs table.
   };
  }


  render() {
    const { open, onClose, student, selectedEvent } = this.props;
    const separatorLineColor = this.state.colour;   //remnant of some old code!
    const additionalInfo = selectedEvent?.getAdditionalInfo();
    const viewMoreLink = !selectedEvent
      ? ``
      : (selectedEvent.eventId
          ? `/iframe/programs/${this.props.slug}/event/${selectedEvent.eventId}`
          : (selectedEvent.isOnline
              ? `/iframe/programs/${this.props.slug}/online/${selectedEvent.programId}`
              : `/iframe/programs/${this.props.slug}/program/${selectedEvent.programId}`
            )
        );

    return (
      open && (
        <div className="request-modal-cont">
          <div
            className="request-modal"
            style={{ padding: 0, overflow: "hidden" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "48px",
                padding: "10px 30px"
              }}
            >
              <h3
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "1.8rem",
                  color: "rgb(153, 153, 153)"
                }}
              >
                {selectedEvent.title}
              </h3>
              <div style={{ marginTop: "25px" }}>
                <IconButton
                  style={{
                    borderRadius: "50%",
                    color: "#ff6663"
                  }}
                  onClick={_ => this.props.onClose()}
                >
                  <Close />
                </IconButton>
              </div>
            </div>
            <div>
              {selectedEvent.id ? (
                <>
                  <div
                    style={{
                      width: "100%",
                      height: "9px",
                      marginTop: "20px"
                    }}
                    className={gradients[separatorLineColor]}
                  ></div>

                  <div className="row" style={{ padding: "10px 30px", maxHeight: "6vh" }}>
                    <div className="col-2">
                      <span className="font-weight-bold" style={{ fontFamily: "Montserrat" }}>Dates :</span>
                    </div>
                    <div className="col-10">
                      <span style={{ fontFamily: "Montserrat" }}>
                        {additionalInfo.dateInfo1}<br />{additionalInfo.dateInfo2}
                      </span>
                    </div>
                  </div>

                  <div className="row" style={{ padding: "10px 30px" }}>
                    <div className="col-2">
                      <span className="font-weight-bold" style={{ fontFamily: "Montserrat" }}>Time :</span>
                    </div>
                    <div className="col-10">
                      <span style={{ fontFamily: "Montserrat" }}>
                        {additionalInfo.timeInfo1}
                      </span>
                    </div>
                  </div>

                  <div className="row" style={{ padding: "10px 30px", maxHeight: "30vh" }}>
                    <div className="col-12">
                      <div style={{fontFamily: "Montserrat", maxHeight:"28vh", overflowY:"clip"}}>
                        <span
                          className="font-weight-bold"
                          style={{fontFamily: "Montserrat"}}
                        >
                          Description :
                        </span>
                        <p style={{whiteSpace:"pre-line"}}>{selectedEvent.description}</p>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
              <div className="row">
                <div className="col-12">
                  <div
                    className="buttons mt-3"
                    style={{
                      marginBottom: "1rem",
                      textAlign: "right",
                      fontFamily: "Montserrat"
                    }}
                  >
                    <a
                      className="btn btn-temp-details"
                      style={{ width: "30%", fontFamily: "Montserrat" }}
                      href={viewMoreLink}
                      target="_blank" rel="noreferrer"
                    >
                      View More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    );
  }
}

export default CalendarDetailsModal;
