import { createMuiTheme } from "@material-ui/core";

export const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      //Button overrides
      root: {
        fontFamily: "Nunito, sans-serif",
        fontSize: "5rem",
        borderRadius: "20px !important"
      },
      sizeLarge: {
        padding: "1rem"
      }
    }
  }
});
