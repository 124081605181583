import React from "reactn";
import { BoxForm, formTypes } from "../../../../components/UI/form";
import { MiniPlus } from "../../../../assets";
import Geosuggest from "react-geosuggest";
import ProgramSearchInput from "../../../../components/common/ProgramSearchInput";
class SectionOne extends React.PureComponent {
  constructor(props) {
    super(props);
    /*this.setAddress = this.setAddress.bind(this);
    this.customForm = this.customForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this._geoSuggest = null;
    this._geoSuggestPrev = null;*/
  }
  handleChange = (e, v) => {
    this.setGlobal({
      organizationInfo: {
        ...this.global.organizationInfo,
        [e]: v
      }
    });
  };

  customForm = () => {
    return (
      <div className="formbody">
        {/* {!this.state.timeChangeShown ? null : (
          <ConfirmTimeChangeForm onClose={this.setTime} />
        )} */}
        <form>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Organization Title<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <input
                  className="input-fields"
                  name="organization"
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  value={this.global.organizationInfo.organization}
                />
              </div>
            </div>
          </div>
          {this.global.organizationInfo.program_name.toLocaleLowerCase() !==
            this.global.organizationInfo.organization.toLocaleLowerCase() && (
            <div className="row">
              <div className="col-5">
                <h3 className="label-names">
                  Location Title<span className="requiredField">*</span>
                </h3>
              </div>
              <div className="col-7 d-flex">
                <div className={`w-100`}>
                  <input
                    name="program_name"
                    className="input-fields"
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.value)
                    }
                    value={this.global.organizationInfo.program_name}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Address<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div
                className={`w-100`}
                style={{ border: "1px solid gainsboro", padding: "0px 8px" }}
              >
                <ProgramSearchInput
                  placeholder="Find a Program"
                  noicon={true}
                  blueborder={true}
                  name="search"
                  style={{ marginTop: "0px" }}
                  className="input-fields"
                  value={this.global.organizationInfo.address}
                  gLoc={(e, loc) => {
                    const address = e.split(",");
                    this.handleChange("address", e);
                    this.handleChange("city", address[1].trim());
                    this.handleChange(
                      "province",
                      address[2].trim().split(" ")[0]
                    );
                    this.handleChange(
                      "postalcode",
                      address[2].trim().split(" ")[1] +
                        address[2].trim().split(" ")[2]
                    );
                    this.handleChange("country", address[3].trim());
                    this.handleChange("lat", loc.lat);
                    this.handleChange("long", loc.lng);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">Unit / Suite</h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <input
                  className="input-fields"
                  name="unit"
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  value={this.global.organizationInfo.unit}
                />
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-5">
              <h3 className="label-names" >City</h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <input
                  name="city"
                  className="input-fields"
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  value={this.global.organizationInfo.city}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">Province</h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <input
                  name="province"
                  className="input-fields"
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  value={this.global.organizationInfo.province}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">Postal Code</h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <input
                  name="postalcode"
                  className="input-fields"
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  value={this.global.organizationInfo.postalcode}
                />
              </div>
            </div>
          </div> */}
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">Target Ages</h3>
            </div>
            <div className="col-7 d-flex">
              <formTypes.ChildAgeGroupSelect
                value={
                  this.global.organizationInfo.age_range.map(e =>
                    typeof e === "string" ? JSON.parse(e) : e
                  ) || []
                }
                onChange={(_, value) =>
                  this.handleChange(
                    "age_range",
                    value.map(e => ({
                      name: e.name
                    }))
                  )
                }
              ></formTypes.ChildAgeGroupSelect>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">Organization Type</h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <select
                  className="input-fields"
                  name="organization_type"
                  style={{ width: "100%" }}
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  value={this.global.organizationInfo.organization_type}
                >
                  <option>Private</option>
                  <option>Community Organization</option>
                  <option>Church</option>
                  <option>Centre-Based</option>
                  <option>Non-Profit</option>
                  <option>Large Brand</option>
                  <option>School</option>
                  <option>Home Childcare</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">Phone Number</h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <input
                  className="input-fields"
                  name="phone1"
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  value={this.global.organizationInfo.phone1}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">Email</h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <input
                  className="input-fields"
                  name="email"
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                  value={this.global.organizationInfo.email}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  };

  render() {
    return (
      <BoxForm
        handleChange={this.handleChange}
        name={"Organization Info"}
        customForm={this.customForm()}
      />
    );
  }
}

export default SectionOne;
