import React from "react";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ModalBanner } from "../../../assets";

import "./ModalTemplate.css";

/**
 * Template for a general form modal.
 *
 * @param props.heading String - Heading text of modal.
 * @param props.onClose Function - execute when close button is clicked.
 * @param props.footerContent Footer content.
 *
 * If footerContent not provided (e.g submit button cannot be split from its form),
 * a .modal-footer and .modal-main div should be present in props.children, adjacent.
 *
 * @param props.children JSX - Main body of modal, likely a form.
 */
const ModalTemplate = ({
  children,
  heading,
  onClose,
  footerContent,
  ...options
}) => (
  <div className="request-modal-cont">
    <div className="request-modal new-modal" {...options}>
      <img src={ModalBanner} alt="Banner" className="modal-banner" />
      <div className="modal-head">
        <h3>{heading || ""}</h3>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </div>
      {footerContent ? (
        <>
          <div className="modal-main">{children}</div>
          <div className="modal-footer">{footerContent}</div>
        </>
      ) : (
        children
      )}
    </div>
  </div>
);

export default ModalTemplate;
