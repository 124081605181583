import { setGlobal } from "reactn";

const overview = {
  interstedPrograms: {
    total: 9,
    newThisWeek: 3
  },
  newAppliations: {
    total: 3,
    newThisWeek: 0
  },
  pendingPayments: {
    total: 10,
    newThisWeek: 2
  },
  overduePayments: {
    total: 11,
    newThisWeek: 0
  },
  enrollments: {
    activeEnrollments: {
      total: 11,
      newThisWeek: 0
    },
    pendingEnrollments: {
      total: 15,
      newThisWeek: 0
    }
  },
  programs: {
    programSetup: {
      total: 11,
      newThisWeek: 0
    },
    publishedPrograms: {
      total: 11,
      newThisWeek: 0
    }
  },
  earnings: {
    cumulative: "$14,200",
    mapData: {
      data: [
        0, 10000, 5000, 15000, 10000, 20000, 15000, 25000, 20000, 30000, 25000,
        40000
      ],
      label: "Earnings"
    }
  }
};

setGlobal({ educatorDashboard: overview });
