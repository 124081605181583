import { createContext } from "react";

export function promotion_detail_reducer(state, action) {
  const { type, payload } = action;
  switch (type) {
    case actions.SET_PROMOTIONS:
      return { ...state, promotion: payload };
    case actions.SET_PROGRAM:
      return {
        ...state,
        program: payload,
        online: null,
        event: null,
        party: null,
        membership: null,
        planQuantities: initialPlanQuantities(payload.plans, "program"),
      };
    case actions.SET_ONLINE:
      return {
        ...state,
        program: null,
        online: payload,
        event: null,
        party: null,
        membership: null,
        planQuantities: initialPlanQuantities(payload.plans, "online"),
      };
    case actions.SET_ORGANIZATION:
      return { ...state, organization: payload };
    case actions.SET_EVENT:
      return {
        ...state,
        program: null,
        online: null,
        event: payload,
        party: null,
        membership: null,
      };
    case actions.SET_PARTY:
      return {
        ...state,
        program: null,
        online: null,
        event: null,
        party: payload,
        membership: null,
      };
    case actions.SET_SELECTED:
      return {
        ...state,
        selected: payload.id,
        dropIns: !!payload.dropIns,
        installments: !!payload.installments,
      };
    case actions.SET_PLAN_QUANTITY:
      const newPlanQuantities = {
        ...state.planQuantities,
        [payload.id]: payload.val,     //this will override any previous key with a similar id.
      };
      return { ...state, planQuantities: newPlanQuantities };
    case actions.SET_CHECKOUT:
      return {
        ...state,
        checkout: payload.checkout,
        singleSessionQuantity: !!payload.singleSessionQuantity,
      };
    case actions.SET_SIGNUP_MODAL:
      return { ...state, isOpenSignUpModal: payload };
    case actions.SET_SHARE_BUTTON_MODAL:
      return { ...state, isOpenShareButtonModal: payload };
    // case actions.SET_DROP_INS:
    //   return { ...state, dropIns: payload };
    // case actions.SET_INSTALLMENTS:
    //   return { ...state, installments: payload };
    case actions.SET_SIGNIN_MODAL:
      return { ...state, signIn: payload };
    case actions.SET_MEMBERSHIP:
      return {
        ...state,
        program: null,
        online: null,
        event: null,
        party: null,
        membership: payload,
      };
    case actions.SET_SINGLE_SESSION_QUANTITY:
      return { ...state, singleSessionQuantity: payload };
    default:
      return state;
  }
}

// actions
export const actions = {
  SET_PROMOTIONS: "SET_PROMOTIONS",
  SET_PROGRAM: "SET_PROGRAM",
  SET_ONLINE: "SET_ONLINE",
  SET_EVENT: "SET_EVENT",
  SET_PARTY: "SET_PARTY",
  SET_MEMBERSHIP: "SET_MEMBERSHIP",
  SET_ORGANIZATION: "SET_ORGANIZATION",
  SET_CHECKOUT: "SET_CHECKOUT",
  SET_SIGNUP_MODAL: "SET_SIGNUP_MODAL",
  SET_SHARE_BUTTON_MODAL: "SET_SHARE_BUTTON_MODAL",
  // SET_DROP_INS: "SET_DROP_INS",
  // SET_INSTALLMENTS: "SET_INSTALLMENTS",
  SET_SELECTED: "SET_SELECTED",
  SET_SIGNIN_MODAL: "SET_SIGNIN_MODAL",
  SET_PLAN_QUANTITY: "SET_PLAN_QUANTITY",
};

// dispatch actions
export const setPromotion = (dispatch, payload) => {
  dispatch({ type: actions.SET_PROMOTIONS, payload });
};

export const setProgram = (dispatch, payload) => {
  dispatch({ type: actions.SET_PROGRAM, payload });
};

export const setOnline = (dispatch, payload) => {
  dispatch({ type: actions.SET_ONLINE, payload });
};

export const setMembership = (dispatch, payload) => {
  dispatch({ type: actions.SET_MEMBERSHIP, payload });
};

export const setSelected = (dispatch, payload) => {
  dispatch({ type: actions.SET_SELECTED, payload });
};

export const setPlanQuantity = (dispatch, payload) => {
  dispatch({ type: actions.SET_PLAN_QUANTITY, payload })
}

export const setEvent = (dispatch, payload) => {
  dispatch({ type: actions.SET_EVENT, payload });
};

export const setParty = (dispatch, payload) => {
  dispatch({ type: actions.SET_PARTY, payload });
};

export const setOrganization = (dispatch, payload) => {
  dispatch({ type: actions.SET_ORGANIZATION, payload });
};

export const setCheckout = (dispatch, payload) => {
  if (typeof payload === "boolean")
    payload = { checkout: payload };
  dispatch({ type: actions.SET_CHECKOUT, payload });
};

export const setSignIn = (dispatch, payload) => {
  dispatch({ type: actions.SET_SIGNIN_MODAL, payload });
};

export const setIsOpenSignUpModal = (dispatch, payload) => {
  dispatch({ type: actions.SET_SIGNUP_MODAL, payload });
};

export const setIsOpenShareButtonModal = (dispatch, payload) => {
  dispatch({ type: actions.SET_SHARE_BUTTON_MODAL, payload });
};

// export const setDropIns = (dispatch, payload) => {
//   dispatch({ type: actions.SET_DROP_INS, payload });
// };

// export const setInstallments = (dispatch, payload) => {
//   dispatch({ type: actions.SET_INSTALLMENTS, payload });
// };


//Takes an array of plans, and returns a dict/object with each key=planId and value=1.
const initialPlanQuantities = (plans, productType) => {
  const kvpairs = plans.map(plan => ([JSON.parse(plan).id, 1]))  //returns many pairs of [planId,1]
  return Object.fromEntries(kvpairs);    //returns object like this: { 0:1, 1:1, 2:1, 3:1, 4:1 }
};


export const initial_state = {
  program: {},
  online: {},
  event: {},
  party: {},
  membership: {},
  promotion: {},
  organization: {},
  checkout: false,
  signIn: false,
  isOpenSignUpModal: false,
  isOpenShareButtonModal: false,
  selected: null,
  dropIns: false,
  installments: false,
  singleSessionQuantity: null,
  planQuantities: {}
};

export const PromotionContext = createContext(initial_state);
