import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { getSelectedProgramPlan } from "./utils";
const ProgramSemesterInstallmentDeposit = props => {
  const [deposit, setDeposit] = useState("Loading");
  const calculateDepositAmount = () => {
    const amnt = getSelectedProgramPlan(props.program, props.selected).deposit;
    setDeposit(parseFloat(amnt).toFixed(2));
    props.depositAmount(parseFloat(amnt).toFixed(2));
  };

  useEffect(() => {
    calculateDepositAmount();
  }, []);
  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px",
        borderBottom: "1px solid #dedede"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif",
          fontWeight: 700,
          fontSize: "14px"
        }}
      >
        Deposit - Payable Now
      </p>
      <span style={{ fontWeight: 700 }}>${deposit}</span>
    </li>
  );
};

ProgramSemesterInstallmentDeposit.propTypes = {
  program: PropTypes.shape({
    plans: PropTypes.array.isRequired
  }).isRequired,
  selected: PropTypes.number.isRequired
};

export default ProgramSemesterInstallmentDeposit;
