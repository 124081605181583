import React from "react";
import "../fieldComponent.css";
import "./StringInput.css";
import Title from "../Title";

/**
 * @param {String} placeholder the placeholder to display when input is empty
 *
 * @param {Function} handleOnChange when input changes
 */
const StringInput = props => {
  const {
    name,
    inputName,
    required,
    placeholder,
    handleChange,
    value,
    fieldValid,
    setFieldValid,
    type,
    info,
    isDisabled,
    styles,
    wrapperStyles,
    titleStyle,
    iconStyles
  } = props;

  return (
    <div style={wrapperStyles}>
      {/* <p className="field-component-title">
        {name}
        {required ? <span className="required-star"> *</span> : ""}
      </p> */}
      <Title name={name} required={required} info={info} titleStyle={titleStyle} iconStyles={iconStyles}/>

      <input
        type={type || "text"}
        name={inputName}
        disabled={isDisabled || false}
        value={value}
        style={styles}
        onChange={e => {
          e.persist();
          handleChange(e);
          setFieldValid(true);
        }}
        onWheel={e => e.target.blur()}
        placeholder={placeholder}
        className={`string-input-field field-component-input-box ${
          fieldValid ? "" : "string-input-invalid"
        }`}
      />
    </div>
  );
};

export default StringInput;
