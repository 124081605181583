import React from "reactn";
import { BoxForm, formTypes } from "../../../../components/UI/form";
import { Button } from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";
import "../../../../assets/css/componentSpecificCss/kidProgram.css";

class SectionFive extends React.PureComponent {
  constructor(props) {
    super(props);
    /*this.setAddress = this.setAddress.bind(this);
    this.customForm = this.customForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this._geoSuggest = null;
    this._geoSuggestPrev = null;*/
    this.state = { days: {}, values: [{ name: "Stupid", value: "Stupid" }] };
  }
  handleChange = (e, v) => {
    this.setGlobal({
      organizationInfo: {
        ...this.global.organizationInfo,
        [e]: v
      }
    });
  };

  customForm = () => {
    const vals = this.global.myProgramLocationHours;
    return (
      <div className="formbody">
        <form>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Program Structures<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <div className={`w-100`}>
                <formTypes.ProgramStructures
                  value={
                    (this.global.organizationInfo.program_structures &&
                      this.global.organizationInfo.program_structures.map(e =>
                        typeof e === "string" ? JSON.parse(e) : e
                      )) ||
                    []
                  }
                  onChange={(_, value) =>
                    this.setGlobal({
                      organizationInfo: {
                        ...this.global.organizationInfo,
                        program_structures: value
                      }
                    })
                  }
                ></formTypes.ProgramStructures>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Main Descriptor (Max 1 e.g. {this.global.organizationInfo.image}
                )<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <formTypes.MainTags
                value={
                  this.global.organizationInfo.main_tags.map(e =>
                    typeof e === "string" ? JSON.parse(e) : e
                  ) || []
                }
                max={this.global.organizationInfo.main_tags.length > 0}
                onChange={(n, v) => {
                  const image = v.length > 0 ? v[0].value : null;

                  let obj = {
                    ...this.global.organizationInfo,
                    main_tags: v
                  };
                  if (image !== null) {
                    obj["image"] = image;
                  }

                  if (v.length < 2) {
                    this.setGlobal({
                      organizationInfo: obj
                    });
                  }
                }}
              ></formTypes.MainTags>
            </div>
          </div>

          <div className="row">
            <div className="col-5">
              <h3 className="label-names">Tags Main Descriptor (Max : 6)</h3>
            </div>
            <div className="col-7 d-flex">
              <formTypes.SecondaryTags
                value={
                  this.global.organizationInfo.tags.map(e =>
                    typeof e === "string" ? JSON.parse(e) : e
                  ) || []
                }
                max={this.global.organizationInfo.tags.length > 5}
                onChange={(n, v) => {
                  this.setGlobal({
                    organizationInfo: {
                      ...this.global.organizationInfo,
                      tags: v
                    }
                  });
                }}
              ></formTypes.SecondaryTags>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h3 className="label-names">Re-opening Details for 2022</h3>
            </div>
            <div className="col-12 d-flex">
              <div className={`w-100`}>
                <textarea
                  rows="5"
                  style={{ width: "100%" }}
                  placeholder="Re-opening notes for families, covid-19 measures, etc."
                  className="input-fields"
                  name="covid19"
                  value={this.global.organizationInfo.covid19}
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                ></textarea>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  };

  render() {
    return (
      <BoxForm
        handleChange={this.handleChange}
        name={"Descriptor Tag Details"}
        customForm={this.customForm()}
      />
    );
  }
}

export default SectionFive;
