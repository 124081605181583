import React from "react";
import { Link } from "react-router-dom";
import {
  FooterBg,
  FooterInstagram,
  FooterFb,
  Twitter_Icon
} from "../../assets";

const Footer = () => {
  return (
    <>
      <div
        className="footer container-fluid"
        style={{
          height: "165px",
          display: "flex",
          lineHeight: "normal",
          minHeight: "auto"
        }}
      >
        <img
          src={FooterBg}
          className="bgimg"
          alt=""
          style={{ height: "155px" }}
        />
      </div>
    </>
  );
};

export default Footer;
