import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { formTypes } from "../UI/form";
import { SWITCH, TEXT_AREA, GET_HTML } from "./baseModal/FieldTypes";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
import BaseModal from "./baseModal/BaseModal";
class AddProgramModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      program_name: "",
      description: "",
      timing: [-1, -1],
      age_groupings: [],
      all_ages: false
    };
    this.required = [
      { name: "name", validation: e => !e },
      { name: "company", validation: e => !e }
    ];
  }

  requestTour = async e => {
    e.preventDefault();
  };
  async submitForm(e) {
    e.preventDefault();
    if (this.state.program_name === "" || this.state.age_groupings.length === 0) {
      toast.error("Fill in all required fields.");
      return;
    }
    this.props.addProgram(this.state);
    this.props.onClose();
  }

  getBaseModalFields = () => {
    const programName = () => {
      return (
        <div
          className="form-group"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 0
          }}
        >
          <label
            style={{
              width: "35%",
              textAlign: "left",
              fontSize: "1.15rem",
              fontFamily: "futura-pt, sans-serif",
              fontWeight: "500",
              color: "#626262"
            }}
          >
            Program Name{" "}<span style={{ color: "red" }}>*</span>
          </label>
          <div
            style={{
              marginLeft: "10px",
              display: "flex",
              alignItems: "left",
              fontSize: "1.15rem",
              fontFamily: "futura-pt, sans-serif",
              fontWeight: "500",
              color: "#626262",
              marginBottom: "0.5rem"
            }}
          >
            <input
              style={{
                height: "40px",
                width: "230px",
                fontSize: "0.93rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "300",
                color: "#494949",
                border: "1px solid #c5c5c5",
                borderRadius: "4px",
                paddingLeft: "10px"
              }}
              type="text"
              onChange={e => this.setState({ program_name: e.target.value })}
              value={this.state.program_name}
              placeholder={"Program Name..."}
            />
          </div>
        </div>
      );
    }

    const programNameField = {
      type: GET_HTML,
      data: {
        gethtml: programName
      }
    }

    const ageGroupings = () => {
      return (
        <div
          className="form-group"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 0
          }}
        >
          <label
            style={{
              width: "35%",
              textAlign: "left",
              fontSize: "1.15rem",
              fontFamily: "futura-pt, sans-serif",
              fontWeight: "500",
              color: "#626262"
            }}
          >
            Age Groupings{" "}<span style={{ color: "red" }}>*</span>
          </label>
          <div
            style={{
              marginLeft: "0px",
              display: "flex",
              alignItems: "left",
              fontSize: "1.15rem",
              fontFamily: "futura-pt, sans-serif",
              fontWeight: "500",
              color: "#626262",
              marginBottom: "0.5rem",
              width: "60%"
            }}
          >
            <formTypes.ChildAgeGroupSelect
              name="age_groupings"
              value={this.state.age_groupings}
              onChange={(_, v) => this.setState({ age_groupings: v })}
            ></formTypes.ChildAgeGroupSelect>
          </div>
        </div>
      );
    };

    const ageGroupingField = {
      type: GET_HTML,
      data: {
        gethtml: ageGroupings
      }
    }

    const ageRange = () => {
      return (
        <div
          className="form-group"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 0
          }}
        >
          <label
            style={{
              width: "35%",
              textAlign: "left",
              fontSize: "1.15rem",
              fontFamily: "futura-pt, sans-serif",
              fontWeight: "500",
              color: "#626262"
            }}
          >
            Age Range{" "}
          </label>
          <div
            style={{
              marginLeft: "0px",
              display: "flex",
              alignItems: "left",
              fontSize: "1.15rem",
              fontFamily: "futura-pt, sans-serif",
              fontWeight: "500",
              color: "#626262",
              marginBottom: "0.5rem"
            }}
          >
            <div className={`w-100`}>
                    <formTypes.Select
                      className="form-control"
                      value={this.state.timing[0]}
                      onChange={(_, v) =>
                        this.setState({ timing: [v, this.state.timing[1]] })
                      }
                      options={[
                        { label: "1 year", value: 1 },
                        { label: "2 years", value: 2 },
                        { label: "3 years", value: 3 },
                        { label: "4 years", value: 4 },
                        { label: "5 years", value: 5 },
                        { label: "6 years", value: 6 },
                        { label: "7 years", value: 7 },
                        { label: "8 years", value: 8 },
                        { label: "9 years", value: 9 },
                        { label: "10 years", value: 10 },
                        { label: "11 years", value: 11 },
                        { label: "12 years", value: 12 },
                        { label: "13 years", value: 13 },
                        { label: "14 years", value: 14 },
                        { label: "15 years", value: 15 },
                        { label: "16 years", value: 16 },
                        { label: "17 years", value: 17 },
                        { label: "18 years", value: 18 },
                        { label: "19 years", value: 19 }
                      ]}
                    ></formTypes.Select>
                  </div>
                  <span style={{ fontSize: "14px", color: "grey", marginTop: "7px", marginLeft: "5px", marginRight: "5px" }}>To</span>
                  <div className={`w-100`}>
                    <formTypes.Select
                      className="form-control"
                      value={this.state.timing[1]}
                      onChange={(_, v) =>
                        this.setState({ timing: [this.state.timing[0], v] })
                      }
                      options={[
                        { label: "1 year", value: 1 },
                        { label: "2 years", value: 2 },
                        { label: "3 years", value: 3 },
                        { label: "4 years", value: 4 },
                        { label: "5 years", value: 5 },
                        { label: "6 years", value: 6 },
                        { label: "7 years", value: 7 },
                        { label: "8 years", value: 8 },
                        { label: "9 years", value: 9 },
                        { label: "10 years", value: 10 },
                        { label: "11 years", value: 11 },
                        { label: "12 years", value: 12 },
                        { label: "13 years", value: 13 },
                        { label: "14 years", value: 14 },
                        { label: "15 years", value: 15 },
                        { label: "16 years", value: 16 },
                        { label: "17 years", value: 17 },
                        { label: "18 years", value: 18 },
                        { label: "19 years", value: 19 }
                      ]}
                    ></formTypes.Select>
                  </div>
          </div>
        </div>
      );
    };

    const ageRangeField = {
      type: GET_HTML,
      data: {
        gethtml: ageRange
      }
    }

    const allAgesField = {
      type: SWITCH,
      data: {
        name: "All Ages",
        required: false,
        checked: this.state.all_ages,
        handleClick: e => this.setState({ all_ages: e.target.checked }),
        styles: {
          width: "63%"
        },
        wrapperStyles: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }
      }
    }

    const descriptionField = {
      type: TEXT_AREA,
      data: {
        name: "Description Summary",
        required: false,
        placeholder: "",
        handleChange: e => this.setState({ description: e.target.value }),
        value: this.state.description
      }
    }

    const fields = [];

    fields.push(programNameField);
    fields.push(ageGroupingField);
    fields.push(ageRangeField);
    fields.push(allAgesField);
    fields.push(descriptionField);

    return fields;
  }

  getBaseModalButtons = () => {
    const addButton = {
      name: "Add Program",
      buttonClass: "client-settings-button",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      handleClick: e => this.submitForm(e)
    };
    return [addButton];
  }

  getBaseModalProps = () => {
    return {
      title: "Add Program",
      titleClass: "client-settings-title",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "52vh",
      handleClose: () => this.props.onClose()
    };
  }

  render() {

    return <BaseModal {...this.getBaseModalProps()}/>;
    return (
      <div className="request-modal-cont">
        <div className="request-modal lesspadding">
          <div>
            <h3>Add Program</h3>
            <form onSubmit={e => this.submitForm(e)}>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="displayName"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Program Name *
                </label>
                <input
                  name="program_name"
                  id="displayName"
                  type="text"
                  className="form-control"
                  placeholder="Program Name..."
                  style={{ width: "70%" }}
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                />
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="displayName"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Age Groupings *
                </label>
                <div style={{ width: "70%" }}>
                  <formTypes.ChildAgeGroupSelect
                    name="age_groupings"
                    value={this.state.age_groupings}
                    onChange={(_, v) => this.setState({ age_groupings: v })}
                  ></formTypes.ChildAgeGroupSelect>
                </div>
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="displayName"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Age Range
                </label>
                <div
                  style={{
                    width: "70%",
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <div className={`w-100`}>
                    <formTypes.Select
                      className="form-control"
                      value={this.state.timing[0]}
                      onChange={(_, v) =>
                        this.setState({ timing: [v, this.state.timing[1]] })
                      }
                      options={[
                        { label: "1 year", value: 1 },
                        { label: "2 years", value: 2 },
                        { label: "3 years", value: 3 },
                        { label: "4 years", value: 4 },
                        { label: "5 years", value: 5 },
                        { label: "6 years", value: 6 },
                        { label: "7 years", value: 7 },
                        { label: "8 years", value: 8 },
                        { label: "9 years", value: 9 },
                        { label: "10 years", value: 10 },
                        { label: "11 years", value: 11 },
                        { label: "12 years", value: 12 },
                        { label: "13 years", value: 13 },
                        { label: "14 years", value: 14 },
                        { label: "15 years", value: 15 },
                        { label: "16 years", value: 16 },
                        { label: "17 years", value: 17 },
                        { label: "18 years", value: 18 },
                        { label: "19 years", value: 19 }
                      ]}
                    ></formTypes.Select>
                  </div>
                  <span style={{ fontSize: "14px", color: "grey" }}>To</span>
                  <div className={`w-100`}>
                    <formTypes.Select
                      className="form-control"
                      value={this.state.timing[1]}
                      onChange={(_, v) =>
                        this.setState({ timing: [this.state.timing[0], v] })
                      }
                      options={[
                        { label: "1 year", value: 1 },
                        { label: "2 years", value: 2 },
                        { label: "3 years", value: 3 },
                        { label: "4 years", value: 4 },
                        { label: "5 years", value: 5 },
                        { label: "6 years", value: 6 },
                        { label: "7 years", value: 7 },
                        { label: "8 years", value: 8 },
                        { label: "9 years", value: 9 },
                        { label: "10 years", value: 10 },
                        { label: "11 years", value: 11 },
                        { label: "12 years", value: 12 },
                        { label: "13 years", value: 13 },
                        { label: "14 years", value: 14 },
                        { label: "15 years", value: 15 },
                        { label: "16 years", value: 16 },
                        { label: "17 years", value: 17 },
                        { label: "18 years", value: 18 },
                        { label: "19 years", value: 19 }
                      ]}
                    ></formTypes.Select>
                  </div>
                </div>
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="email"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  All Ages
                </label>
                <input
                  type="checkbox"
                  name="all_ages"
                  checked={this.state.checked}
                  onChange={e => this.setState({ all_ages: e.target.checked })}
                />
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                <label
                  htmlFor="displayName"
                  style={{ width: "25%", textAlign: "left" }}
                >
                  Description Summary
                </label>
                <textarea
                  rows="8"
                  style={{ width: "70%" }}
                  name="description"
                  className="form-control"
                  onChange={e => this.setState({ description: e.target.value })}
                  value={this.state.description}
                />
              </div>

              <div className="modal-footer">
                <button
                  type="submit"
                  onClick={this.cancelThis}
                  className="btn profile-btn"
                >
                  Add Program
                </button>
              </div>
            </form>

            <IconButton
              style={{ position: "absolute", right: "0", top: "0" }}
              onClick={_ => this.props.onClose()}
            >
              <Close />
            </IconButton>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AddProgramModal);
