import React from "react";
import ProvideHero from "../../components/front/learn-more/ProvideHero";
import ApplicationForm from "../../components/front/learn-more/ApplicationForm";
import WhyPartner from "../../components/front/learn-more/WhyPartner";
import Benefits from "../../components/front/learn-more/Benefits";
import ContactForm from "../../components/front/learn-more/ContactForm";
import { Helmet } from "react-helmet";

const Partner = () => {
  return (
    <>
      <Helmet>
        <title>Dreamschools - Request a Demo</title>
        <link rel="canonical" href="https://www.dreamschools.com/learn-more" />
      </Helmet>
      <ProvideHero />
      <ApplicationForm />
      <Benefits />
      <WhyPartner />
    </>
  );
};

export default Partner;
