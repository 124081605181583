import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";

import BaseModal from "./baseModal/BaseModal";
import {
  CONTENT_BLOCK,
  TITLE,
  STRING_INPUT,
  SWITCH
} from "./baseModal/FieldTypes";
import { create } from "lodash";
class AddEmbedModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      height: "650px",
      width: "100%",
      scrolling: false,
      newTab: false
    };
    this.required = [
      { name: "name", validation: e => !e },
      { name: "company", validation: e => !e }
    ];
    const iFrameUrl = this.props.url.includes("iframe") ? this.props.url :
      `${this.props.url.slice(0, this.props.url.indexOf("/programs")) + (this.props.match?.params?.id ? "/iframe" : "/iframes") + this.props.url.slice(this.props.url.indexOf("/programs"))}`;
    this.iFrameUrl = iFrameUrl.replace("virtual", "online")
  }

  requestTour = async e => {
    e.preventDefault();
  };
  async submitForm(e) {
    e.preventDefault();
    let cancel = false;
    for (let requiredField of this.required) {
      if (requiredField.validation(this.state[requiredField.name])) {
        document.getElementsByName(requiredField.name)[0].style.boxShadow =
          "none";
        document.getElementsByName(requiredField.name)[0].style.border =
          "1px solid red";
        cancel = true;
      }
    }
    if (cancel) return;
    const endp = `${process.env.REACT_APP_API}/adm/partner/add`;
    const result = await axios.post(endp, this.state);
    if (result.data.success) {
      toast.success("Lead Added Successfully");
      this.props.addLead(result.data.data);
      this.props.onClose();
    }
  }

  getBaseModalFields = () => {
    const widthField = {
      type: STRING_INPUT,
      data: {
        name: "Width",
        value: this.state.width,
        required: false,
        handleChange: e =>
          this.setState({
            width: e.target.value
          })
      }
    };

    const heightField = {
      type: STRING_INPUT,
      data: {
        name: "Height",
        value: this.state.height,
        required: false,
        handleChange: e =>
          this.setState({
            height: e.target.value
          })
      }
    };

    const scrollingField = {
      type: SWITCH,
      data: {
        name: "Scrolling",
        checked: this.state.scrolling,
        required: false,
        handleClick: () =>
          this.setState({
            scrolling: !this.state.scrolling
          }),
        info: "Whether Embed should be scrollable"
      }
    };

    const newTabField = {
      type: SWITCH,
      data: {
        name: "Open in New Tab",
        checked: this.state.newTab,
        required: false,
        handleClick: () =>
          this.setState({
            newTab: !this.state.newTab
          }),
        info: "Whether Embed links should open in a new tab"
      }
    };

    const fields = [];

    fields.push(widthField);
    fields.push(heightField);
    fields.push(scrollingField);

    // if (this.props.active && this.props.active !== "Gifts" && this.props.active !== "Calendar") {
    fields.push(newTabField);
    // }

    return fields;
  };

  getBaseModalButtons = () => {
    const createButton = {
      name: "Generate Embedded Link",
      buttonColour: "#3B4EFF",
      textColour: "#fff",
      handleClick: () => this.setState({ confirmed: true })
    };
    return [createButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Embed this page",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      height: "65vh",
      midSectionHeight: "52vh",
      handleClose: () => this.props.onClose()
    };
  };

  getCompleteIframeFields = () => {
    const iframeField = {
      type: CONTENT_BLOCK,
      data: {
        name: "Creating Embedded Code",
        textStyles: {
          "overflowWrap": "break-word",
          "wordBreak": "break-all"
        },
        style: {
          "borderStyle": "solid",
          "borderWidth": "2px",
          "borderColor": "#CBCBCB",
          "borderRadius": "10px",
          padding: "10px",
          "overflowWrap": "break-word",
          "wordBreak": "break-all",
          fontSize: "16px"
        },
        content: `
          <iframe src="${this.iFrameUrl}" width="${this.state.width
          }" height="${this.state.height}" style="border: none;" scrolling="${this.state.scrolling ? "yes" : "no"
          }"></iframe>`
      }
    };

    const fields = [];

    fields.push(iframeField);

    return fields;
  };

  getCompleteIframeModal = () => {
    const props = {
      title: "Copy and paste the code below.",
      fields: this.getCompleteIframeFields(),
      height: "65vh",
      midSectionHeight: "52vh",
      handleClose: () => this.props.onClose()
    };
    return props;
  };

  render() {
    return (
      <>
        {this.state.confirmed ? (
          <>
            <BaseModal {...this.getCompleteIframeModal()} />
          </>
        ) : (
          <>
            <BaseModal {...this.getBaseModalProps()} />
          </>
        )}
      </>
    );
  }
}

export default withRouter(AddEmbedModal);
