import { setGlobal } from "reactn";

const ach = {
  fullName: "",
  birthDate: "",
  addressOne: "",
  addressTwo: "",
  city: "",
  province: "",
  country: "",
  postalCode: "",
  accountType: "",
  transitNo: "",
  institutionNo: "",
  accountNo: ""
};

setGlobal({ achPayment: ach });

const creditCard = {
  fullName: "",
  birthDate: "",
  addressOne: "",
  addressTwo: "",
  city: "",
  province: "",
  country: "",
  postalCode: "",
  cardNo: "",
  cvvNo: "",
  expiry: ""
};

setGlobal({ ccPayment: creditCard });
setGlobal({ stripe: null });
