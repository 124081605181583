import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { toast } from "react-toastify";
import { formTypes } from "../../components/UI/form/index";
//import { formTypes } from "../../components/UI/form";
import { withRouter } from "react-router-dom";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import BaseModal from "./baseModal/BaseModal";
import { GET_HTML } from "./baseModal/FieldTypes";
import { notEmptyString, validateEmail } from "./baseModal/validators";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";

class AddChildModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      "Full Name": { component: "input", value: "" },
      "Birth Date": { component: formTypes.DateAddChildModal, value: "" },
      Gender: { component: formTypes.GenderDropdownSelect, value: "" },
      Allergies: { component: formTypes.MultiInput, value: [] },
      Medication: { component: formTypes.MultiInput, value: [] },
      Immunized: { component: formTypes.YesNoDropdownSelect, value: "", hidden: true },
      "Special Food": { component: formTypes.MultiInput, value: [], hidden: true },
      "Potty Trained": { component: formTypes.YesNoDropdownSelect, value: "", hidden: true },
      "Health Concerns": { component: formTypes.MultiInput, value: [] },
      Languages: { component: formTypes.LanguageSelect, value: [], hidden: true },
      Likes: { component: formTypes.MultiInput, value: [], hidden: true },
      Dislikes: { component: formTypes.MultiInput, value: [], hidden: true },
      Comments: { component: "input", value: "" }
    };
    this.required = [
      { name: "Full Name", validation: e => !e.value },
      { name: "Birth Date", validation: e => !e.value },
      { name: "Gender", validation: e => !e.value },
      // { name: "Languages", validation: e => e.value.length === 0 },
      { name: "Allergies", validation: e => e.value.length === 0 },
      // { name: "Immunized", validation: e => !e.value },
      { name: "Medication", validation: e => e.value.length === 0 }
    ];

    this.mappings = {
      fullName: "Full Name",
      birthDate: "Birth Date",
      gender: "Gender",
      allergies: "Allergies",
      immunized: "Immunized",
      medication: "Medication",
      specialFood: "Special Food",
      pottyTrained: "Potty Trained",
      healthConcerns: "Health Concerns",
      languages: "Languages",
      likes: "Likes",
      dislikes: "Dislikes",
      comments: "Comments"
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };
  reverseMap() {
    const obj = { ...this.mappings };
    Object.keys(this.mappings).forEach(
      e => (obj[e] = this.state[this.mappings[e]].value)
    );
    return obj;
  }
  async submitForm(e) {
    e.preventDefault();
    let cancel = false;
    for (let requiredField of this.required) {
      if (requiredField.validation(this.state[requiredField.name])) {
        document.getElementsByName(requiredField.name)[0].style.boxShadow =
          "none";
        document.getElementsByName(requiredField.name)[0].style.border =
          "1px solid red";
        cancel = true;
      }
    }
    if (cancel) return;
    const endp = `${process.env.REACT_APP_API}/par/profile/child`;
    const childObj = {
      ...this.reverseMap()
    };
    const result = await axios.post(endp, childObj);
    if (result.data.success) {
      toast.success("Child Updated");
      this.props.update(result.data.data);
      this.props.onClose();
    }
  }

  /// ================== NEW MODAL ========================== ///
  addnewmodal = () => {
    console.log("this.state", this.state);
    return (
      <div className="modal-main">
        {Object.keys(this.state).map((e, i) => {
          if (!this.state[e].hidden) {
          const MyComp = this.state[e].component;
          const style = {
            width: "70%",
            fontSize: "1.5rem",
            color: "#626262"
          };
          if (e === "Birth Date") {
            style.border = "1px solid #c5c5c5";
            style.borderRadius = "2px";
          }
          return (
            <ModalFields.custom
              label={e}
              name={e}
              required={this.required.find(v => v.name === e)}
              key={i}
              titleStyle={{
                width: "25%",
                textAlign: "left",
                fontSize: "1.8rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "500",
                color: "#626262"
              }}
            >
              {MyComp === "input" ? (
                <input
                  name={e}
                  type="text"
                  className="form-control"
                  style={{
                    width: "70%",
                    fontFamily: "futura-pt, sans-serif",
                    fontSize: "1.5rem",
                    color: "#626262",
                    border: "1px solid #c5c5c5",
                    borderRadius: "4px",
                    paddingLeft: "14px"
                  }}
                  placeholder={`${e}...`}
                  onChange={v => {
                    this.setState({
                      [e]: {
                        ...this.state[e],
                        value: v.target.value
                      }
                    });
                  }}
                  value={this.state[e].value}
                />
              ) : (
                <div style={style} name={e}>
                  {/* Make the rest 1.5rem */}
                  <MyComp
                    value={this.state[e].value}
                    name={e}
                    onChange={(_, v) =>
                      this.setState({
                        [e]: { ...this.state[e], value: v }
                      })
                    }
                  />
                </div>
              )}
            </ModalFields.custom>
          );
          }
        })}
      </div>
    );
  };

  getBaseModalFields() {
    const newClient = {
      type: GET_HTML,
      data: {
        gethtml: this.addnewmodal
      }
    };

    const fields = [];
    fields.push(newClient);

    return fields;
  }

  /*   getBaseModalFields() {
    const fullnameInput = {
      type: STRING_INPUT,
      data: {
        name: "Full Name",
        required: true,
        placeholder: "Please Select",
        value: this.state.fullName,
        handleChange: v => {
          this.setState({
            fullName: {
              ...this.state.fullName,
              value: v.target.value
            }
          });
        },
      }
    };

    const genderInput = {
      type: DROPDOWN,
      data: {
        name: "Gender",
        required: true,
        placeholder: "Please Select",
        value: this.state.gender,
        handleChange: (_, v) =>
        this.setState({
          gender: { ...this.state.gender, value: v }
        }),
      }
    };

    

    const fields = [];
    fields.push(fullnameInput);
    fields.push(genderInput);


    return fields;
  } */

  getBaseModalButtons = () => {
    const addButton = {
      name: "Save",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => this.submitForm(e)
    };

    return [addButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Add Child",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      handleClose: this.props.onClose,
      width: "550px",
      height: "60vh",
      midSectionHeight: "52vh"
    };
  };

  /// ================== END NEW MODAL ========================== ///

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
    return (
      <ModalTemplate
        heading="Add Child!"
        onClose={_ => {
          this.props.onClose();
        }}
      >
        <form onSubmit={e => this.submitForm(e)}>
          <div className="modal-main">
            {Object.keys(this.state).map((e, i) => {
              const MyComp = this.state[e].component;
              const style = {
                width: "70%",
                color: "#8c8ea0"
              };
              if (e === "Birth Date") {
                style.border = "1.5px solid #EDF4FF";
              }
              return (
                <ModalFields.custom
                  label={e}
                  name={e}
                  required={this.required.find(v => v.name === e)}
                  key={i}
                >
                  {MyComp === "input" ? (
                    <input
                      name={e}
                      type="text"
                      className="form-control"
                      placeholder={`${e}...`}
                      style={{ width: "70%" }}
                      onChange={v => {
                        this.setState({
                          [e]: {
                            ...this.state[e],
                            value: v.target.value
                          }
                        });
                      }}
                      value={this.state[e].value}
                    />
                  ) : (
                    <div style={style} name={e}>
                      <MyComp
                        value={this.state[e].value}
                        name={e}
                        onChange={(_, v) =>
                          this.setState({
                            [e]: { ...this.state[e], value: v }
                          })
                        }
                      />
                    </div>
                  )}
                </ModalFields.custom>
              );
            })}
          </div>
          <div className="modal-footer">
            <button
              type="submit"
              onClick={this.cancelThis}
              className="btn profile-btn"
            >
              Save
            </button>
          </div>
        </form>
      </ModalTemplate>
    );
  }
}

export default withRouter(AddChildModal);
