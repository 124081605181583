import React from "reactn";
import axios from "axios";
import { SubmitImg } from "../../../assets";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

class ApplicationForm extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      user: {
        name: "",
        email: "",
        phone: "",
        postalcode: "",
        company: ""
      },
      additional: {
        homeStructure: "",
        ownership: "",
        outdoor: "",
        experience: "",
        smartPhone: ""
      },
      stage: 0
      // submitted: false //!!localStorage.getItem("applied_educator")
    };
    this.submitApplication = this.submitApplication.bind(this);
    this.setGlobal({ preApp: false });
    this.validateUser = this.validateUser.bind(this);
  }

  validateUser = () => {
    try {
      this.setState({
        user: {
          fullName: this.state.user.fullName.trim(),
          email: this.state.user.email.trim(),
          phoneNumber: this.state.user.phoneNumber.trim(),
          address: this.state.user.address.trim(),
          postalCode: this.state.user.postalCode.trim()
        }
      });
      const postalRegex = new RegExp(
        /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]( )?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i
      );
      if (!this.state.user.fullName) throw new Error("Full Name is required.");
      if (this.state.user.fullName.split(" ").length < 2)
        throw new Error("First and Last name required.");
      if (!this.state.user.email) throw new Error("Email is required.");
      if (!this.state.user.phoneNumber)
        throw new Error("Phone Number is required.");
      if (!this.state.user.address) throw new Error("Address is required.");
      if (!this.state.user.postalCode)
        throw new Error("Postal Code is required.");
      if (this.state.user.postalCode.replace(" ", "").length !== 6)
        throw new Error("Invalid Postal Code.");
      if (!postalRegex.test(this.state.user.postalCode))
        throw new Error("Invalid Postal Code.");
      this.setState({ stage: 1 });
    } catch (err) {
      toast.error(err.message);
    }
  };

  submitApplication = async e => {
    e.preventDefault();
    try {
      this.setState({
        user: {
          name: this.state.user.name.trim(),
          email: this.state.user.email.trim().toLowerCase(),
          phone: this.state.user.phone.trim(),
          company: this.state.user.company.trim(),
          postalcode: this.state.user.postalcode.trim()
        }
      });
      const postalRegex = new RegExp(
        /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]( )?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i
      );
      if (!this.state.user.name) throw new Error("Full Name is required.");
      if (this.state.user.name.split(" ").length < 2)
        throw new Error("First and Last name required.");
      if (!this.state.user.email) throw new Error("Email is required.");
      if (!this.state.user.company)
        throw new Error("Website / Company is required.");
      if (!this.state.user.postalcode)
        throw new Error("Postal Code is required.");
      if (this.state.user.postalcode.replace(" ", "").length !== 6)
        throw new Error("Invalid Postal Code.");
      if (!postalRegex.test(this.state.user.postalcode))
        throw new Error("Invalid Postal Code.");
      this.setGlobal({ loading: true });
      const res = await axios.post(
        `${process.env.REACT_APP_API}/apply/business`,
        {
          ...this.state.user
        }
      );
      this.setGlobal({ loading: false });
      if (res.data.success) {
        //localStorage.setItem("applied_educator", JSON.stringify(res.data.data));
        this.setGlobal({ preApp: true });
      } else
        toast.error(
          "You already applied to dreamschools! Keep a look on your emails for further instructions!"
        );
    } catch (err) {
      toast.error(err.message);
    }
  };

  render() {
    return (
      <div className="container-fluid providesubmitform">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <form
                className="submit-form"
                id="new_teacher"
                acceptCharset="UTF-8"
                method="post"
              >
                {this.global.preApp ? (
                  <>
                    <h2 className="w-100 text-center" style={{ marginTop: 80 }}>
                      Your application has been received!
                    </h2>
                    <p
                      className="w-100 text-center"
                      style={{ marginBottom: 120, marginTop: 40 }}
                    >
                      Our team will be in touch with you shortly!
                    </p>
                  </>
                ) : (
                  <>
                    {this.state.stage === 0 ? (
                      <>
                        <h2>Request Demo and Learn More!</h2>
                        <p style={{ marginBottom: "30px" }}>
                          Our team will be in touch with you shortly
                        </p>
                        <input
                          placeholder="Name"
                          className="input_fields"
                          type="text"
                          name="name"
                          value={this.state.user.name}
                          onChange={e =>
                            this.setState({
                              user: {
                                ...this.state.user,
                                [e.target.name]: e.target.value
                              }
                            })
                          }
                        />
                        <input
                          placeholder="Website / Company"
                          className="input_fields"
                          type="text"
                          name="company"
                          id="teacher_email"
                          value={this.state.user.company}
                          onChange={e =>
                            this.setState({
                              user: {
                                ...this.state.user,
                                [e.target.name]: e.target.value
                              }
                            })
                          }
                        />
                        <input
                          placeholder="Postal Code"
                          className="input_fields postal_code"
                          type="text"
                          name="postalcode"
                          id="teacher_postal_code"
                          value={this.state.user.postalcode}
                          onChange={e =>
                            this.setState({
                              user: {
                                ...this.state.user,
                                [e.target.name]: e.target.value
                              }
                            })
                          }
                        />
                        <input
                          placeholder="Email"
                          className="input_fields telephone_no"
                          name="email"
                          value={this.state.user.email}
                          onChange={e =>
                            this.setState({
                              user: {
                                ...this.state.user,
                                [e.target.name]: e.target.value
                              }
                            })
                          }
                        />

                        <input
                          placeholder="Phone (optional)"
                          className="input_fields telephone_no"
                          type="tel"
                          name="phone"
                          id="teacher_telephone_no"
                          value={this.state.user.phone}
                          onChange={e =>
                            this.setState({
                              user: {
                                ...this.state.user,
                                [e.target.name]: e.target.value
                              }
                            })
                          }
                        />
                        <button
                          type="button"
                          name="commit"
                          value="SUBMIT"
                          className="btn-default submit-btn"
                          onClick={this.submitApplication}
                        >
                          SUBMIT
                        </button>
                        <p style={{ marginBottom: "7px" }}>
                          <span>
                            By performing this action, you agree to our{" "}
                            <Link
                              className="term-link"
                              target="_blank"
                              rel="noopener noreferrer"
                              to="/terms-of-use"
                              onClick={() => {
                                window.scrollTo(0, 0);
                              }}
                            >
                              Terms of Use
                            </Link>{" "}
                            and{" "}
                            <Link
                              className="term-link"
                              target="_blank"
                              rel="noopener noreferrer"
                              to="/privacy-policy"
                              onClick={() => {
                                window.scrollTo(0, 0);
                              }}
                            >
                              Privacy Policy
                            </Link>
                          </span>
                        </p>
                      </>
                    ) : (
                      <>
                        <h2>Just a little more info!</h2>
                        <p style={{ marginBottom: "30px" }}>
                          We're as excited as you
                        </p>
                        <select
                          name="homeStructure"
                          value={this.state.additional.homeStructure}
                          className="input_fields"
                          onChange={e =>
                            this.setState({
                              additional: {
                                ...this.state.additional,
                                [e.target.name]: e.target.value
                              }
                            })
                          }
                          required
                        >
                          <option value="" disabled selected hidden>
                            Home or Center Structure...
                          </option>
                          <option value="Detached">Detached</option>
                          <option value="Semi-Detached">Semi-Detached</option>
                          <option value="Townhouse">Townhouse</option>
                          <option value="Condo">Condo</option>
                          <option value="Apartment">Apartment</option>
                          <option value="Other">Other space</option>
                        </select>

                        <select
                          name="ownership"
                          value={this.state.additional.ownership}
                          onChange={e =>
                            this.setState({
                              additional: {
                                ...this.state.additional,
                                [e.target.name]: e.target.value
                              }
                            })
                          }
                          required
                        >
                          <option value="" disabled selected hidden>
                            Ownership...
                          </option>
                          <option value="Own">Own</option>
                          <option value="Rent">Rent</option>
                        </select>

                        <select
                          name="outdoor"
                          value={this.state.additional.outdoor}
                          onChange={e =>
                            this.setState({
                              additional: {
                                ...this.state.additional,
                                [e.target.name]: e.target.value
                              }
                            })
                          }
                          required
                        >
                          <option value="" disabled selected hidden>
                            Outdoor Options...
                          </option>
                          <option value="N/A">N/A</option>
                          <option value="Private Backyard">
                            Private Backyard
                          </option>
                          <option value="Shared Backyard">
                            Shared Backyard
                          </option>
                          <option value="Park">Nearby Park</option>
                        </select>

                        <select
                          name="experience"
                          value={this.state.additional.experience}
                          onChange={e =>
                            this.setState({
                              additional: {
                                ...this.state.additional,
                                [e.target.name]: e.target.value
                              }
                            })
                          }
                          required
                        >
                          <option value="" disabled selected hidden>
                            Childcare Experience...
                          </option>
                          <option value="1-2 Years">1-2 Years</option>
                          <option value="3-4 Years">3-4 Years</option>
                          <option value="5+ Years">5+ Years</option>
                          <option value="None">
                            None, but can learn quickly
                          </option>
                        </select>

                        <select
                          name="smartPhone"
                          value={this.state.additional.smartPhone}
                          onChange={e =>
                            this.setState({
                              additional: {
                                ...this.state.additional,
                                [e.target.name]: e.target.value
                              }
                            })
                          }
                          required
                        >
                          <option value="" disabled selected hidden>
                            Smartphone Skills...
                          </option>
                          <option value="Beginner">Beginner</option>
                          <option value="Intermediate">Intermediate</option>
                          <option value="Advanced">Advanced</option>
                          <option value="None">
                            None, but can learn quickly
                          </option>
                        </select>

                        <button
                          type="button"
                          name="commit"
                          value="SUBMIT"
                          className="btn-default submit-btn"
                          onClick={this.submitApplication}
                        >
                          SUBMIT
                        </button>
                        <p style={{ marginBottom: "7px" }}>
                          <span>
                            By performing this action, you agree to our{" "}
                            <Link
                              className="term-link"
                              target="_blank"
                              rel="noopener noreferrer"
                              to="/terms-of-use"
                              onClick={() => {
                                window.scrollTo(0, 0);
                              }}
                            >
                              Terms of Use
                            </Link>{" "}
                            and{" "}
                            <Link
                              className="term-link"
                              target="_blank"
                              rel="noopener noreferrer"
                              to="/privacy-policy"
                              onClick={() => {
                                window.scrollTo(0, 0);
                              }}
                            >
                              Privacy Policy
                            </Link>
                          </span>
                        </p>
                      </>
                    )}
                  </>
                )}
              </form>
            </div>
            <div className="col-lg-6 flex">
              <img src={SubmitImg} className="img-responsive" alt="" />
            </div>
            <div className="col-lg-12">
              <div className="line"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ApplicationForm;
