import React, { Fragment } from "reactn";
import { Redirect, withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import "../../assets/css/componentSpecificCss/requestTourModal.css";
import { ParaIcon5 } from "../../assets";
import { numToHours } from "../../lib/util";
import RequestTourModal from "../modals/RequestTourModal";

class SeePricingModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      planModalOpen: false,
      selectedPlan: null,
      selectedCategory: null,
      requestTourModalOpen: false
    };
  }

  render() {
    return (
      <>
        {/* This section is to open the inner pricing modals */}
        {!this.state.planModalOpen ? (
          <div className="request-modal-cont">
            <div className="request-modal request-tour-modal-height">
              {/* <div className="flex full-width"> */}
              <h3>See Pricing</h3>
              {/* <img src={ParaIcon5} alt="" />
            </div> */}
              {this.global.school.pricingPlans
                ? this.global.school.pricingPlans
                    .sort((prev, next) =>
                      prev.orderBy < next.orderBy ? -1 : 1
                    )
                    .map((cat, i) => {
                      return cat.plans.length ? (
                        <Fragment key={`${i}-cat`}>
                          <h5
                            style={{
                              margin: 0,
                              marginTop: i > 0 ? "20px" : "40px",
                              marginBottom: "10px"
                            }}
                          >
                            {cat.name}
                          </h5>
                          <div>
                            {cat.plans
                              .sort((prev, next) =>
                                prev.dataOrder < next.dataOrder ? -1 : 1
                              )
                              .map((plan, j) => (
                                <Fragment key={`${j}-plan`}>
                                  <p>
                                    {plan.numDays} days / week (
                                    {numToHours(plan.hoursFrom)} -{" "}
                                    {numToHours(plan.hoursTo)})
                                  </p>
                                  <button
                                    className="btn-link"
                                    style={{ margin: 0 }}
                                    onClick={e =>
                                      this.setState({
                                        planModalOpen: true,
                                        selectedPlan: plan,
                                        selectedCategory: cat
                                      })
                                    }
                                  >
                                    See Pricing
                                  </button>
                                </Fragment>
                              ))}
                          </div>
                        </Fragment>
                      ) : null;
                    })
                : null}

              <IconButton
                style={{ position: "absolute", right: "0", top: "0" }}
                onClick={_ => this.props.onClose()}
              >
                <Close />
              </IconButton>
            </div>
          </div>
        ) : (
          <>
            {
              <div className="request-modal-cont">
                <div className="request-modal">
                  <h3>Pricing Plan</h3>
                  <p>
                    <strong className="mr-2">Enrollment (Recurring):</strong>$
                    {this.state.selectedPlan.amount} /{" "}
                    {this.state.selectedPlan.interval}
                  </p>
                  <p>
                    <span
                      className="mr-2"
                      style={{
                        fontStyle: "italic",
                        color: "rgb(171, 171, 171)",
                        fontSize: "12px"
                      }}
                    >
                      One time Deposit (applied to program end) : $
                      {this.state.selectedPlan.depositAmount}
                    </span>
                  </p>
                  <div className="modal-footer">
                    {this.global.school.publishStage == 2 && (
                      <button
                        onClick={() => {
                          this.setState({
                            planModalOpen: false,
                            selectedPlan: null,
                            selectedCategory: null
                          });
                          this.props.onClose();
                          this.props.openTourModal();
                        }}
                        className="btn profile-btn"
                      >
                        Request a Tour
                      </button>
                    )}
                    <button
                      onClick={() =>
                        this.setState({
                          planModalOpen: false,
                          selectedPlan: null,
                          selectedCategory: null
                        })
                      }
                      className="backbtn btn profile-btn"
                    >
                      Close
                    </button>
                    {this.global.school.publishStage == 2 && (
                      <button
                        onClick={() =>
                          this.global.dsUser &&
                          this.global.dsUser.accountType === "Parent"
                            ? this.setState({
                                redirect: {
                                  to: `/user/interested-programs/${this.global.school.slug}/choose-child`
                                }
                              })
                            : this.setState({
                                redirect: {
                                  to: `/sign-in?follow=/user/interested-programs/${this.global.school.slug}/choose-child`
                                }
                              })
                        }
                        className="btn profile-btn"
                      >
                        Enroll
                      </button>
                    )}
                  </div>
                </div>
              </div>
            }
            {/* {this.state.requestTourModalOpen && (
              <RequestTourModal
                onClose={() => this.setState({ requestTourModalOpen: false })}
              />
            )} */}

            {this.state.redirect && <Redirect to={this.state.redirect.to} />}
          </>
        )}
      </>
    );
  }
}

export default withRouter(SeePricingModal);
