import React from "react";
import DateSel from "react-datepicker";
import {
  format,
  addDays,
  setHours,
  setMinutes,
  getDay,
  addWeeks,
  isSameDay
} from "date-fns";
import moment from "moment";
import { DatePicker } from "baseui/datepicker";
import axios from "axios";

class ProgramSessionsTiming extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      timePickerIncludedTimes: [],
      displayTimesList: [],
      customTimes: {},
      programTiming: [],
      singleSessionEnrollments: null,
      quantity: this.props.singleSessionQuantity
    };
  }

  minuteDisplay = value => {
    const time = value.toString();
    if (time.split(".")[1] == 5) {
      return 30;
    }
    if (time.split(".")[1] == 25) {
      return 15;
    }
    if (time.split(".")[1] == 75) {
      return 45;
    }
    return 0;
  };

  getDayOfWeek = date => {
    switch (new Date(date).getDay()) {
      case 0:
        return "Sunday";
      case 1:
        return "Monday";
      case 2:
        return "Tuesday";
      case 3:
        return "Wednesday";
      case 4:
        return "Thursday";
      case 5:
        return "Friday";
      case 6:
        return "Saturday";
    }
  };

  hourDisplay = value => {
    const time = value.toString().split(".");
    return parseInt(time[0]);
  };

  /**
   * Returns the days excluded to the componentDidMount function
   * @param {Array} days_of_week
   */
  getExcludedDays = days_of_week => {
    let excludedDays = [];
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];

    days.forEach((e, i) => {
      if (e === days_of_week.filter(f => f === e)[0]) {
        return e;
      } else {
        return excludedDays.push({ name: e, value: e, dayCount: i });
      }
    });

    return excludedDays;
  };

  /**
   * Returns the times that should be excluded in the time picker for the given date
   * @param {Date} selectedDate
   * @returns {Array} the times to exclude
   */
  getOpenTimeslots(selectedDate) {
    const {
      plan,
      program: { total_spots: maxSpotsPerSlot, enableCustomTimes, customTimes }
    } = this.props;

    let singleSessionEnrollments = this.state.singleSessionEnrollments;

    if (!singleSessionEnrollments) return [];

    console.log("singleSessionEnrollments", singleSessionEnrollments);

    let timeToSubtract = 1;
    let range = this.state.programTiming;
    let timeValues = [];
    let organizerTimes = [];
    let timePickerIncludedTimes = [];
    if (plan.frequency === "minutes") {
      timeToSubtract = parseFloat(parseInt(plan.duration) / 60);
    }

    if (enableCustomTimes) {
      const day = this.getDayOfWeek(selectedDate);
      console.log(day);
      if (!customTimes[day]) {
        return [];
      }

      range = customTimes[day]
        .map((e, i) => {
          if (i % 2 === 0) {
            return [
              parseFloat(e),
              parseFloat(this.props.program.customTimes[day][i + 1])
            ];
          }
        })
        .filter(e => e);
    }
    let slotsFull = range.map(_ => 0);
    singleSessionEnrollments.forEach(({ time, date }) => {
      if (!isSameDay(new Date(date), new Date(selectedDate))) return;
      range.forEach(([start, end], index) => {
        if (start <= time && end >= time) {
          slotsFull[index]++;
        }
      });
    });
    range = range.filter((_, index) => {
      return slotsFull[index] < maxSpotsPerSlot;
    });

    range.map(e => {
      let timeStart = e[0];
      let timeEnd = e[1];

      for (let i = timeStart; i <= timeEnd - timeToSubtract; i = i + 0.25) {
        timeValues.push(i);
      }
      return e;
    });

    organizerTimes = timeValues.map(f => ({
      h: this.hourDisplay(f),
      m: this.minuteDisplay(f)
    }));

    timePickerIncludedTimes = organizerTimes.map(f =>
      setHours(setMinutes(new Date(), f.m), f.h)
    );
    return timePickerIncludedTimes;
  }

  componentDidMount() {
    const fetchSingleSessionEnrollments = async programId => {
      let ep;
      if (this.props.productTable === "partner_programs") {
        ep = `${process.env.REACT_APP_API}/partners/programs/enrollments/session_timing/${programId}`;
      } else {
        ep = `${process.env.REACT_APP_API}/partners/virtual/enrollments/session_timing/${programId}`;
      }
      const result = await axios.get(ep);
      result.data.data.flat().map(e => JSON.parse(e));

      this.setState({
        singleSessionEnrollments: result.data.data
          .flat()
          .map(e => JSON.parse(e))
      });
    };

    if (!this.props.singleSessionEnrollments) {
      fetchSingleSessionEnrollments(this.props.program.id);
    } else {
      this.setState({
        singleSessionEnrollments: this.props.singleSessionEnrollments
      });
    }

    let {
      plan,
      singleSessionQuantity,
      program: { timing }
    } = this.props;

    const quantity = new URLSearchParams(window.location.search).get(
      "singleSessionQuantity"
    );

    if (quantity) {
      this.setState({ quantity: parseInt(quantity) });
    }

    if (plan.isTimeEnabled) {
      let range = [];
      timing.forEach((e, index) => {
        if (index % 2 === 0) {
          range.push([parseFloat(e), parseFloat(timing[index + 1])]);
        }
      });

      this.setState({ programTiming: range });

      const days_of_week = Object.keys(this.props.program.days_of_week);
      const excludedDays = this.getExcludedDays(days_of_week);

      let initTimeList = [];
      // new Array(this.props.singleSessionQuantity).fill(1).map(e =>
      //   initTimeList.push({
      //     date: null,
      //     displayTime: null,
      //     time: null,
      //     hours: null,
      //     minutes: null,
      //     weekday: null
      //   })
      // );

      if (this.state.quantity) {
        for (let i = 0; i < this.state.quantity; i++) {
          initTimeList.push({
            date: null,
            displayTime: null,
            time: null,
            hours: null,
            minutes: null,
            weekday: null
          });
        }
      } else {
        for (let i = 0; i < parseInt(quantity); i++) {
          initTimeList.push({
            date: null,
            displayTime: null,
            time: null,
            hours: null,
            minutes: null,
            weekday: null
          });
        }
      }

      this.setState({
        excludedDays: excludedDays,
        displayTimesList: initTimeList
      });
    }

    //NOTE: In keeping with stupid legacy state/property names used by a former developer,
    //      "sessionDateTimesState" is the value, and "sessionDateTimes" is the setter!
    if (this.props.sessionDateTimesState?.length > 0) {
      this.setState({ displayTimesList: this.props.sessionDateTimesState });
    }
  }

  checkIfMultipleQuantitiesNotSelected = () => {
    let hasNotBeenSelected = false;

    this.state.displayTimesList.map(e =>
      e.date === null ||
      e.displayTime === null ||
      e.time === null ||
      e.timeInfo === null
        ? (hasNotBeenSelected = true)
        : e
    );
    return hasNotBeenSelected;
  };

  arrowBtnOverrides = ({ $theme }) => {
    return {
      color: "black",
      ":focus": {
        color: "black",
        backgroundColor: "#2a41a2"
      },
      ":hover": {
        color: "black",
        backgroundColor: "#2a41a2"
      }
    };
  };

  render() {
    console.log("this.props", this.props);
    console.log("timing state", this.state);
    const { plan } = this.props;
    const number_of_weeks = this.props.program.number_of_weeks;
    //check what happens when null
    const closure_dates = this.props.program.closure_dates.map(closure_date => {
      return new Date(closure_date).getTime();
    });
    const closure_timeframe = this.props.program.closure_timeframe.map(
      closure_range => {
        return new Date(closure_range).getTime();
      }
    );

    return (
      <div
        style={{
          width: "60%",
          margin: "0 auto",
          padding: "20px",
          backgroundColor: "#fff",
          borderRadius: "20px",
          border: "1px solid lightgrey"
        }}
      >
        {plan.isTimeEnabled && (
          <div>
            <h4 style={{ fontFamily: "Montserrat", textAlign: "left" }}>
              Pick your date and time slots :{" "}
            </h4>
            {this.state.displayTimesList.map((listItem, index) => {
              console.log("listItem" + index, listItem);
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    marginBottom: "20px"
                  }}
                >
                  <div>
                    <DatePicker
                      value={listItem?.date}
                      onChange={({ date }) => {
                        console.log("date", date);
                        let datesList = [...this.state.displayTimesList];
                        datesList[index].date = date;
                        datesList[index].displayTime = null;
                        datesList[index].time = null;
                        datesList[index].hours = null;
                        datesList[index].minutes = null;
                        if (this.props.program.enableCustomTimes) {
                          datesList[index].weekday = this.getDayOfWeek(date);
                        }
                        this.setState({
                          displayTimesList: datesList
                        });
                        this.props.hasUnselectedValuesMultipleQuantities(
                          this.checkIfMultipleQuantitiesNotSelected()
                        );

                        if (!this.checkIfMultipleQuantitiesNotSelected()) {
                          return this.props.sessionDateTimes(
                            this.state.displayTimesList
                          );
                        }
                      }}
                      // minDate={addDays(new Date(this.props.startDate), 1)}
                      minDate={new Date(this.props.startDate)}
                      maxDate={addWeeks(
                        new Date(this.props.startDate),
                        number_of_weeks
                      )}
                      // formatString="MMMM d, yyyy"
                      placeholder="Select a date"
                      filterDate={date => {
                        const day = getDay(date);
                        const timeValue = date.getTime();
                        //check if date is in closure_dates
                        if (
                          closure_dates &&
                          closure_dates.includes(timeValue)
                        ) {
                          return false;
                        }
                        //check if date is in closure_timeframe
                        if (
                          closure_timeframe &&
                          timeValue >= closure_timeframe[0] &&
                          timeValue <= closure_timeframe[1]
                        ) {
                          return false;
                        }
                        if (
                          this.state.excludedDays.filter(
                            f => f.dayCount === day
                          ).length > 0
                        ) {
                          return false;
                        }
                        return true;
                      }}
                      disabled={this.props.disabled}
                      overrides={{
                        MonthYearSelectButton: {
                          style: ({ $theme }) => ({
                            color: "black",
                            ":focus": {
                              color: "black",
                              backgroundColor: "#2a41a2"
                            },
                            ":hover": {
                              color: "black",
                              backgroundColor: "#2a41a2"
                            }
                          })
                        },
                        PrevButton: {
                          style: this.arrowBtnOverrides
                        },
                        NextButton: {
                          style: this.arrowBtnOverrides
                        },
                        Input: {
                          props: {
                            overrides: {
                              Root: {
                                style: ({ $theme }) => ({
                                  border: "1px solid #E8E8E8",
                                  zIndex: 1000
                                })
                              },
                              Input: {
                                style: ({ $theme }) => ({
                                  outline: `${$theme.colors.warning200} solid`,
                                  backgroundColor: "white",
                                  padding: "8px"
                                })
                              }
                            }
                          }
                        },
                        Popover: {
                          props: {
                            overrides: {
                              Inner: {
                                style: ({ $theme }) => ({
                                  zIndex: 999999
                                })
                              },
                              Body: {
                                style: ({ $theme }) => ({
                                  zIndex: 999999
                                })
                              }
                            }
                          }
                        }
                      }}
                    />
                  </div>
                  <div className="program-sessions-time-picker">
                    <DateSel
                      className="w-100 dob-border"
                      selected={listItem?.displayTime}
                      onChange={date => {
                        const hours = new Date(date).getHours();
                        let minutes = new Date(date).getMinutes();
                        if (minutes === 45) {
                          minutes = 75;
                        } else if (minutes === 30) {
                          minutes = 5;
                        } else if (minutes === 15) {
                          minutes = 25;
                        }
                        const timeValues = parseFloat(`${hours}.${minutes}`);

                        let datesList = [...this.state.displayTimesList];
                        datesList[index].displayTime = date;
                        datesList[index].time = timeValues;
                        datesList[index].hours = hours;
                        datesList[index].minutes = minutes;
                        datesList[index].weekday = listItem?.weekday;

                        this.setState({ displayTimesList: datesList });

                        this.props.hasUnselectedValuesMultipleQuantities(
                          this.checkIfMultipleQuantitiesNotSelected()
                        );

                        if (!this.checkIfMultipleQuantitiesNotSelected()) {
                          return this.props.sessionDateTimes(
                            this.state.displayTimesList
                          );
                        }
                      }}
                      includeTimes={this.getOpenTimeslots(listItem?.date)}
                      showTimeSelect
                      showTimeSelectOnly
                      disabled={!listItem?.date || this.props.disabled}
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      placeholderText="Select a time"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

export default ProgramSessionsTiming;
