import React, { useEffect, useState, useGlobal } from "reactn";
import PropTypes from "prop-types";

import {
  getSelectedProgramPlan,
  isProgramPlanTaxEnabled,
  coupon_discount,
  applyGift
} from "./utils";

const OnlineOneTimeSemesterTotal = props => {
  const [total, setTotal] = useState("Loading");
  const [_, setPreGiftTotal] = useGlobal("preGiftTotal");
  const giftBalance = useGlobal("giftBalance")[0];

  const calculateTotal = (plan) => {
    let addOnTotal = 0;

    if (plan.addOn) {
      plan.addOnItems.map(item => {
        addOnTotal += parseFloat(item.value);
      });
    }

    const total_price = parseFloat(plan.total_price) + parseFloat(addOnTotal);

    console.log("setting pre gift total to", parseInt(total_price));

    const taxRate = plan.isTaxEnabled
      ? parseFloat(plan.taxInfo[0].percentage / 100)
      : 0;

    const discount_amount = props.coupon
      ? coupon_discount(props.coupon, parseFloat(total_price))
      : 0;
    // const tax_amount = Math.max(0, total_price) * taxRate;
    const tax_amount = Math.max(0, total_price - discount_amount) * taxRate;

    // One-Time / Semester
    console.log("fees are", { discount_amount, tax_amount, total_price });

    // const creditCardPercent = props.program.transaction_cost
    //   ? parseFloat(1.5) / 100
    //   : 1;
    // const creditCardFee = props.program.transaction_cost
    //   ? Math.max(0.5, total_price - discount_amount) * creditCardPercent
    //   : 0;

    // const tot =
    //   Math.max(0.5, total_price - discount_amount) + tax_amount + creditCardFee;
    let tot = Math.max(0, total_price - discount_amount) + tax_amount;
    console.log("All fees");
    tot = Math.max(0, total_price - discount_amount) + tax_amount;

    let savedtot = Math.max(0, total_price - discount_amount) + tax_amount;

    console.log("tot is ", tot);
    setPreGiftTotal(tot);
    props.totalAmount(tot.toFixed(2));

    ({ amount: tot } = applyGift(
      props.program.manual_invoices,
      giftBalance,
      tot
    ));
    console.log(
      "program total, appluGift",
      props.program.manual_invoices,
      giftBalance,
      tot
    );
    savedtot = giftBalance > tot ? 0 : savedtot - giftBalance;

    props.totalAmount(savedtot.toFixed(2));

    setTotal(tot.toFixed(2));
  };

  useEffect(() => {
    const plan = getSelectedProgramPlan( props.program, props.selected );
    if (plan.type === "Free") {
      setTotal("0.00");
    } else {
      calculateTotal(plan);
    }
  }, [props.coupon, giftBalance]);

  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif",
          fontWeight: 600
        }}
      >
        Total
      </p>
      <span style={{ fontWeight: "bold" }}>${total}</span>
    </li>
  );
};

OnlineOneTimeSemesterTotal.propTypes = {
  program: PropTypes.shape({
    plans: PropTypes.array.isRequired
  }).isRequired,
  selected: PropTypes.number.isRequired,
  coupon: PropTypes.object
};

export default OnlineOneTimeSemesterTotal;
