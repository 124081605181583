import React, { useGlobal, useEffect } from "reactn";
import axios from "axios";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import "./RequestInfo.css";

import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

const AddPromoCodeModal = props => {
  const [promoCode, setPromoCode] = useGlobal("promoCode");
  const [couponGlobal, setCouponGlobal] = useGlobal("coupon");
  let setCoupon;
  if (props.setCouponCode) {
    setCoupon = props.setCouponCode;
  } else {
    setCoupon = setCouponGlobal;
  }
  const submitForm = async e => {
    e.preventDefault();

    if (!promoCode) {
      toast.error("Please enter a promo code");
      return;
    }
    // check if promocode exists for partnerId
    const partnerId = props.program.partnerId;
    const ep = `${process.env.REACT_APP_API}/partners/stripe/coupon/partner/${partnerId}/${promoCode}`;
    const resp = await axios.get(ep);

    if (resp.data && resp.data.success) {
      console.log("resp.data", resp.data);
      console.log("props.program", props.program);

      if (resp.data.coupon.isArchived) {
        toast.error("Coupon is no longer valid", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        setPromoCode("");
        return;
      }

      // validate redeem by
      const redeemBy = resp.data.coupon.redeem_by;
      if (redeemBy && new Date(redeemBy) < new Date()) {
        toast.error("Coupon has expired", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        setPromoCode("");
        return;
      }

      // validate program
      if (resp.data.coupon.coupon_for) {
        const programId = props.program.id;
        console.log("props:", props);
        const programCouponIds = [
          ...resp.data.coupon.programs_picked,
          ...resp.data.coupon.online_picked,
          ...resp.data.coupon.event_picked
        ].map(e => JSON.parse(e).value.id);
        if (!programCouponIds.includes(programId) && !programCouponIds.includes(props.program.programId)) {
          toast.error("Coupon is not valid for this program", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
          setPromoCode("");
          return;
        }
      }

      setCoupon(resp.data.coupon);
      props.onClose();
    } else {
      // reset promoCode
      toast.error(`Promo code with name "${promoCode}" does not exist.`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      setPromoCode("");
    }
  };

  return (
    <div className="request-modal-cont">
      <div className="request-modal lesspadding">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "48px",
            marginBottom: "1.5rem"
          }}
        >
          <h3>Apply a Promo Code</h3>
          <IconButton
            style={{
              borderRadius: "50%",
              color: "#2880FF",
              height: "50px",
              width: "50px",
              marginTop: "20px"
            }}
            onClick={() => props.onClose()}
          >
            <Close />
          </IconButton>
        </div>
        <form onSubmit={e => submitForm(e)}>
          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 0
            }}
          >
            <label
              htmlFor="promoCode"
              style={{ width: "25%", textAlign: "left" }}
            >
              Promo Code
            </label>
            <input
              name="promoCode"
              type="text"
              className="form-control"
              placeholder="Enter Promo Code"
              style={{ width: "70%" }}
              onChange={e => {
                setPromoCode(e.target.value);
              }}
              value={promoCode}
            />
          </div>
          <div className="modal-footer">
            <button type="submit" className="btn profile-btn">
              Apply
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

AddPromoCodeModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  program: PropTypes.object.isRequired
};

export default AddPromoCodeModal;
