import { setGlobal, addReducer } from "reactn";

setGlobal({ enrolledStudents: [] });

addReducer("getEnrolledStudents", (global, dispatch) => {
  if (process.env.REACT_APP_MODE === "dev") {
    const x = [
      {
        student: "Jesse Wheeler",
        startDate: "Apr 23, 2019",
        endDate: "Apr 29, 2019",
        daysOfWeek: "Mon, Tue, Thur",
        programPlan: { period: "Full", planName: "Plan A" },
        actions: [{ action: "View", obj: "/educators/enrolled/jesse-wheeler" }]
      }
    ];
    return {
      enrolledStudents: x
    };
  }
});
