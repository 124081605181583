import React from "react";
import PropTypes from "prop-types";

function TextArea(props) {
  const newOnChange = !props.valueOnly
    ? props.onChange
    : e => props.onChange(props.name, e.target.value);
  const nProps = { ...props };
  delete nProps.valueOnly;
  delete nProps.isDisabled;
  delete nProps.fullWidth;
  delete nProps.type;
  return props.isDisabled ? (
    <textarea
      className="w-100"
      {...{ ...nProps, onChange: newOnChange }}
      readOnly
    />
  ) : (
    <textarea className="w-100" {...{ ...nProps, onChange: newOnChange }} />
  );
}

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  valueOnly: PropTypes.bool,
  isDisabled: PropTypes.bool
};

TextArea.defaultProps = {
  valueOnly: false,
  name: "",
  isDisabled: false
};
export default TextArea;
