import React, { Component } from "reactn";
import { CardElement, injectStripe } from "react-stripe-elements";
import { toast } from "react-toastify";

class _CardForm extends React.Component {
  state = {
    errorMessage: ""
  };
  handleCardSubmission = async _ => {
    if (this.props.stripe) {
      try {
        const token = await this.props.stripe.createToken();
        if (token.error) {
          return;
        }
        this.props.setToken(token);
      } catch (ex) {
        toast.error(ex.message);
        console.error(ex);
        this.setGlobal({ loading: false, lastAPICall: null });
      }
    } else {
    }
  };

  handleChange = event => {
    if (event.complete) {
      this.handleCardSubmission();
    }
  };

  render() {
    return (
      <div style={{ width: "70%", display: "flex" }} className="childwidth100">
        <CardElement
          onChange={this.handleChange}
          style={{ width: "100%" }}
          className="childofdiv"
          hidePostalCode={true}
        />
      </div>
    );
  }
}
const CardForm = injectStripe(_CardForm);
export default CardForm;
