import React from "reactn";
import "./RequestInfo.css";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

class ApplyEnrollModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      displayName: "",
      email: "",
      password: "",
      requestPhone: ""
    };
    this.loginRegister = this.loginRegister.bind(this);
  }

  loginRegister = async e => {
    this.setState({
      displayName: this.state.displayName.trim(),
      email: this.state.email.trim(),
      password: this.state.password.trim()
    });
    e.preventDefault();
    try {
      if (!this.state.displayName) throw new Error("Name is required.");
      if (!this.state.email) throw new Error("Email is required.");
      if (
        !this.state.requestPhone ||
        this.state.requestPhone.trim().length !== 10 ||
        isNaN(this.state.requestPhone.trim())
      )
        throw new Error("The Phone provided is invalid");
      if (!this.state.password) throw new Error("Password is required.");
      if (this.state.password.length < 6)
        throw new Error("Password must be at least 6 characters.");
      this.setGlobal({ loading: true });
      const ep = `${process.env.REACT_APP_API}/auth/signin-register`;
      const res = await axios.post(ep, {
        ...this.state,
        slug: this.props.match.params.slug
      });
      if (res.data.success) {
        localStorage.setItem("ds_token", res.data.data.token);
        window.location.href = `/parents/interested-programs/${this.props.match.params.slug}/choose-child`;
      } else {
        this.setGlobal({ loading: false, lastAPICall: res });
        toast.error("Failed to sign in / register.");
      }
    } catch (err) {
      toast.error(err.message);
      this.setGlobal({ loading: false, lastAPICall: null });
    }
    return false;
  };

  render() {
    return (
      <div className="request-modal-cont">
        <div className="request-modal">
          <form>
            <h3>Sign In or Register to Apply</h3>
            <label htmlFor="displayName">Name</label>
            <input
              name="displayName"
              id="displayName"
              type="text"
              className="form-control"
              placeholder="Your Name..."
              onChange={e => {
                this.setState({
                  [e.target.name]: e.target.value
                });
              }}
            />
            <label htmlFor="email">Email</label>
            <input
              name="email"
              id="email"
              type="email"
              placeholder="Your Email..."
              className="form-control"
              onChange={e => {
                this.setState({
                  [e.target.name]: e.target.value
                });
              }}
            />
            <label htmlFor="requestPhone">Phone</label> <br />
            <input
              name="requestPhone"
              id="requestPhone"
              type="test"
              placeholder="Your Phone..."
              className="form-control"
              onChange={e => {
                this.setState({
                  [e.target.name]: e.target.value
                });
              }}
            />
            <label htmlFor="password">Password</label>
            <input
              name="password"
              id="password"
              type="password"
              placeholder="Your Password..."
              className="form-control"
              onChange={e => {
                this.setState({
                  [e.target.name]: e.target.value
                });
              }}
            />
            <div className="modal-footer">
              <button
                onClick={this.props.onClose}
                className="mx-2 backbtn btn profile-btn"
              >
                Cancel
              </button>
              <button
                className="btn profile-btn"
                type="submit"
                onClick={this.loginRegister}
              >
                Apply
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(ApplyEnrollModal);
