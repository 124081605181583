import { setGlobal, addReducer } from "reactn";
import axios from "axios";
import { toast } from "react-toastify";

setGlobal({ profile: {} });

/**
 * Global State: My Application Personal Information
 */
const myInformation = {
  fullName: "",
  homePhone: "",
  cellPhone: "",
  birthDate: null,
  languages: [],
  ageGroups: [],
  hasChildren: true
};

setGlobal({ myApplicationProfileInfo: myInformation });

/**
 * Global State: My Application Address(es)
 */
const myAddress = {
  addressLineOne: "",
  addressLineTwo: "",
  city: "",
  province: "",
  country: "",
  postalCode: "",
  numYearsAt: "",
  previousAddress: ""
};

setGlobal({ myApplicationProfileAddress: myAddress });

/**
 * Global State: My Application Home Info
 */
const homeInfo = {
  homeType: "",
  ownership: "",
  outdoorPlayArea: [],
  childProofed: "",
  smoker: "",
  pets: []
};

setGlobal({ myApplicationHomeInfo: homeInfo });

/**
 * Global State: My Application Home Regulars
 */
const homeRegulars = {
  id: "0",
  fullName: "",
  relationship: "",
  vsc: "",
  criminalCheck: "",
  immunized: "",
  birthDate: "",
  smoker: ""
};

setGlobal({ myApplicationHomeRegulars: [] });
addReducer("addApplicationHomeRegular", (global, dispatch) => ({
  myApplicationHomeRegulars: [
    ...global.myApplicationHomeRegulars,
    ...[
      {
        ...homeRegulars,
        id: global.myApplicationHomeRegulars.length.toString()
      }
    ]
  ]
}));
addReducer("removeApplicationHomeRegular", (global, dispatch, obj) => () => {
  return {
    myApplicationHomeRegulars: [...global.myApplicationHomeRegulars].filter(
      c => c.id !== obj.id
    )
  };
});

/**
 * Global State: My Application Documentation
 */

const documentation = {
  vsc: "",
  criminalCheck: "",
  firstAid: "",
  childCPR: "",
  healthAssessment: "",
  immunization: "",
  registeredECE: "",
  registeredEducator: ""
};

const documentationStatus = {
  vsc: 0,
  criminalCheck: 0,
  firstAid: 0,
  childCPR: 0,
  healthAssessment: 0,
  immunization: 0,
  registeredECE: 0,
  registeredEducator: 0
};

setGlobal({ myApplicationDocumentation: documentation });
setGlobal({ myApplicationDocumentationStatus: documentationStatus });

/**
 * Global State: My Application References
 */
const reference = {
  id: "0",
  fullName: "",
  relationship: "",
  phone: "",
  email: ""
};

setGlobal({ myApplicationReferences: [reference] });
addReducer("addApplicationReference", (global, dispatch) => ({
  myApplicationReferences: [
    ...global.myApplicationReferences,
    ...[
      {
        ...reference,
        id: global.myApplicationReferences.length.toString()
      }
    ]
  ]
}));
addReducer("removeApplicationReference", (global, dispatch, obj) => () => {
  return {
    myApplicationReferences: [...global.myApplicationReferences].filter(
      c => c.id !== obj.id
    )
  };
});

/**
 * Global State: Children
 */
const child = {
  id: "0",
  fullName: "",
  gender: "",
  birthDate: null,
  immunized: ""
};

setGlobal({ myApplicationChildren: [] });
addReducer("addApplicationChild", (global, dispatch) => ({
  myApplicationChildren: [
    ...global.myApplicationChildren,
    ...[
      {
        ...child,
        id: global.myApplicationChildren.length.toString()
      }
    ]
  ]
}));
addReducer("removeApplicationChild", (global, dispatch, obj) => {
  return {
    myApplicationChildren: [...global.myApplicationChildren].filter(
      c => c.id !== obj.id
    )
  };
});

/**
 * My Application: Save Application
 */

addReducer("saveApplication", async (global, dispatch, submit) => {
  setGlobal({ loading: true });
  try {
    const ep = `${process.env.REACT_APP_API}/edu/application${
      submit ? "/submit" : ""
    }`;
    const data = {
      myApplicationProfileInfo: global.myApplicationProfileInfo,
      myApplicationProfileAddress: global.myApplicationProfileAddress,
      myApplicationChildren: global.myApplicationChildren,
      myApplicationDocumentation: global.myApplicationDocumentation,
      myApplicationHomeInfo: global.myApplicationHomeInfo,
      myApplicationHomeRegulars: global.myApplicationHomeRegulars,
      myApplicationReferences: global.myApplicationReferences,
      myApplicationDocumentationStatus: global.myApplicationDocumentationStatus
    };
    const res = await axios.post(ep, data);
    if (res.data.success && submit) {
      toast("Your Application has been submitted!");
    }
    return {
      ...global,
      ...res.data.data,
      loading: false,
      lastAPICall: res
    };
  } catch (err) {
    console.error(err.message);
    return { ...global, loading: false, lastAPICall: null };
  }
});

/**
 * My Application: Save Application
 */

addReducer("saveApplicationAdmin", async (global, dispatch, userId) => {
  setGlobal({ loading: true });
  try {
    const ep = `${process.env.REACT_APP_API}/edu/application`;
    const data = {
      myApplicationProfileInfo: {
        ...global.myApplicationProfileInfo,
        dsUserId: userId
      },
      myApplicationProfileAddress: {
        ...global.myApplicationProfileAddress,
        dsUserId: userId
      },
      myApplicationChildren: [
        ...global.myApplicationChildren.map(r => {
          return { ...r, dsUserId: userId };
        })
      ],
      myApplicationDocumentation: {
        ...global.myApplicationDocumentation,
        dsUserId: userId
      },
      myApplicationHomeInfo: {
        ...global.myApplicationHomeInfo,
        dsUserId: userId
      },
      myApplicationHomeRegulars: [
        ...global.myApplicationHomeRegulars.map(r => {
          return { ...r, dsUserId: userId };
        })
      ],
      myApplicationReferences: [
        ...global.myApplicationReferences.map(r => {
          return { ...r, dsUserId: userId };
        })
      ],
      myApplicationDocumentationStatus: {
        ...global.myApplicationDocumentationStatus,
        dsUserId: userId
      },
      forUser: userId
    };
    const res = await axios.post(ep, data);
    if (!res.data.success) throw new Error("Failed to save application");

    return {
      ...global,
      ...res.data.data,
      loading: false,
      lastAPICall: res
    };
  } catch (err) {
    toast.error(err.message);
    console.error(err.message);
    return { ...global, loading: false, lastAPICall: null };
  }
});

addReducer("getApplication", async (global, dispatch) => {
  setGlobal({ loading: true });
  try {
    const ep = `${process.env.REACT_APP_API}/edu/application`;
    const res = await axios.get(ep);
    if (res.data.data) {
      res.data.data.myApplicationProfileInfo.fullName =
        res.data.data.myApplicationProfileInfo.fullName ||
        global.dsUser.displayName;
    }
    return {
      ...global,
      ...res.data.data,
      loading: false,
      lastAPICall: res
    };
  } catch (err) {
    console.error(err.message);
    return { ...global, loading: false, lastAPICall: null };
  }
});

/**
 * My Application DEV MODE Data
 */
if (process.env.REACT_APP_MODE === "dev") {
  setGlobal({
    myApplicationProfileInfo: {
      fullName: "Jesse",
      homePhone: "555-555-5555",
      cellPhone: "",
      birthDate: new Date("November 24, 1990"),
      languages: [{ name: "English", value: "English" }],
      ageGroups: [{ name: "Toddler (2-3 yrs)", value: "Toddler" }]
    }
  });

  setGlobal({
    myApplicationProfileAddress: {
      addressLineOne: "123 Fake Street",
      addressLineTwo: "",
      city: "Gotham",
      province: "New York",
      country: "America",
      postalCode: "N5M7A2",
      numYearsAt: "8",
      previousAddress: ""
    }
  });

  setGlobal({
    myApplicationHomeInfo: {
      homeType: "Detached House",
      ownership: "Own",
      outdoorPlayArea: "Private Backyard",
      childProofed: "Yes",
      smoker: "No",
      pets: "No"
    }
  });

  setGlobal({
    myApplicationDocumentation: {
      vsc: "Yes",
      criminalCheck: "Yes",
      firstAid: "Yes",
      childCPR: "Yes",
      immunization: "Yes",
      registeredECE: "Yes",
      registeredEducator: "No"
    }
  });
}
