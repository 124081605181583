import React from "react";
import PropTypes from "prop-types";

const EventSubtotal = props => {
  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px",
        borderBottom: "1px solid #dedede"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif",
          fontWeight: 600
        }}
      >
        Subtotal
      </p>
      <span style={{ fontWeight: "bold" }}>
        $
        {parseFloat(
          parseFloat(
            JSON.parse(props.program.tickets[props.selected]).ticket_price || 0
          )
        ).toFixed(2)}
      </span>
    </li>
  );
};

EventSubtotal.propTypes = {
  program: PropTypes.object.isRequired,
  selected: PropTypes.number.isRequired
};

export default EventSubtotal;
