import React, { useEffect, useState, useGlobal } from "reactn";
import PropTypes from "prop-types";

import {
  getSelectedPartyTicket,
  isPartyPlanTaxEnabled,
  coupon_discount,
  applyGift,
  promotion_discount
} from "./utils";

const PartyPromotionTotal = props => {
  const [total, setTotal] = useState("Loading");
  const [_, setPreGiftTotal] = useGlobal("preGiftTotal");
  const giftBalance = useGlobal("giftBalance")[0];

  const calculateTotal = plan => {
    const addOnItem = 0;
    let addOnTotal = 0;

    if (plan.addOn) {
      Object.values(props.program.partyDetails.addOnAmounts).map(item => {
        addOnTotal += parseInt(item.price) * parseInt(item.amount);
      });
    }

    const total_price = parseFloat(plan.ticket_price) + parseFloat(addOnTotal);

    let tot = 0;
    const taxRate = plan.isTaxEnabled
      ? parseFloat(plan.taxInfo[0].percentage / 100)
      : 0;
    // const tax_amount = Math.max(0, total_price) * taxRate;
    const discount_amount = props.promotion
      ? promotion_discount(props.promotion, parseFloat(total_price))
      : 0;
    const tax_amount = Math.max(0, total_price - discount_amount) * taxRate;
    console.log(
      "program regular semester total values",
      tax_amount,
      total_price,
      props.coupon
    );
    const creditCardPercent = props.program.transaction_cost
      ? parseFloat(1.5) / 100
      : 1;
    // dont add the credit card fee here
    // only after it is paid for, if the payment method is credit card then add the fee in
    // const creditCardFee = props.program.transaction_cost
    //   ? Math.max(0.5, total_price - discount_amount) * creditCardPercent
    //   : 0;
    const creditCardFee = 0;

    console.log("the credit card fee is 68", creditCardFee, props);

    tot =
      (total_price !== 0 ? Math.max(0, total_price - discount_amount) : 0) +
      tax_amount +
      addOnItem +
      creditCardFee;

    setPreGiftTotal(tot);
    ({ amount: tot } = applyGift(
      props.program.manual_invoices,
      giftBalance,
      tot
    ));
    props.totalAmount(tot.toFixed(2));

    setTotal(tot.toFixed(2));

  };

  useEffect(() => {
    const plan = getSelectedPartyTicket(props.program, props.selected);
    if (plan.method === "Online" || plan.type === "Free") {
      setTotal("0.00");
    } else {
      calculateTotal(plan);
    }
  }, [props.coupon, giftBalance]);

  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px",
        fontSize: "14px"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif",
          fontWeight: 600
        }}
      >
        Total
      </p>
      <span style={{ fontWeight: "bold" }}>${total}</span>
    </li>
  );
};

PartyPromotionTotal.propTypes = {
  program: PropTypes.shape({
    tickets: PropTypes.array.isRequired
  }).isRequired,
  selected: PropTypes.number.isRequired,
  promotion: PropTypes.object
};

export default PartyPromotionTotal;
