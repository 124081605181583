import React, { useEffect, useState, useGlobal } from "reactn";
import PropTypes from "prop-types";

import {
  getSelectedProgramPlan,
  isProgramPlanTaxEnabled,
  coupon_discount,
  applyGift
} from "./utils";

const ProgramRegularSemesterTotal = props => {
  const [total, setTotal] = useState("Loading");
  const [_, setPreGiftTotal] = useGlobal("preGiftTotal");
  const giftBalance = useGlobal("giftBalance")[0];

  const calculateTotal = plan => {
    const addOnItem = 0;
    let addOnTotal = 0;

    if (plan.addOn) {
      plan.addOnItems.map(item => {
        addOnTotal += parseFloat(item.value);
      });
    }

    const total_price = parseFloat(plan.total_price) + parseFloat(addOnTotal);

    if (props.installments) {
      const something1 = parseFloat(
        total_price *
          (props.installments
            ? 1 + parseInt(plan.installments_interest) / 100
            : 1) || 0
      );
      const something_about_tax = isProgramPlanTaxEnabled(
        props.program,
        props.selected
      )
        ? parseFloat(
            parseFloat(
              total_price *
                (props.installments
                  ? 1 + parseInt(plan.installments_interest) / 100
                  : 1) || 0
            ) *
              (plan.taxInfo[0].percentage / 100)
          )
        : 0;

      let tot = parseFloat(something1 + something_about_tax + addOnItem);

      setPreGiftTotal(tot);
      tot = giftBalance > tot ? 0 : tot - giftBalance;
      props.totalAmount(tot.toFixed(2));
      setTotal(tot.toFixed(2));
    } else {
      // not an installment
      let tot = 0;
      const taxRate = plan.isTaxEnabled
        ? parseFloat(plan.taxInfo[0].percentage / 100)
        : 0;
      // const tax_amount = Math.max(0, total_price) * taxRate;
      const discount_amount = props.coupon
        ? coupon_discount(props.coupon, parseFloat(total_price))
        : 0;
      const tax_amount = Math.max(0, total_price - discount_amount) * taxRate;
      console.log(
        "program regular semester total values",
        tax_amount,
        total_price,
        props.coupon
      );
      const creditCardPercent = props.program.transaction_cost
        ? parseFloat(1.5) / 100
        : 1;
      // dont add the credit card fee here
      // only after it is paid for, if the payment method is credit card then add the fee in
      // const creditCardFee = props.program.transaction_cost
      //   ? Math.max(0.5, total_price - discount_amount) * creditCardPercent
      //   : 0;
      const creditCardFee = 0;

      console.log("the credit card fee is 68", creditCardFee, props);

      tot =
        (total_price !== 0 ? Math.max(0, total_price - discount_amount) : 0) +
        tax_amount +
        addOnItem +
        creditCardFee;

      setPreGiftTotal(tot);
      ({ amount: tot } = applyGift(
        props.program.manual_invoices,
        giftBalance,
        tot
      ));
      props.totalAmount(tot.toFixed(2));

      setTotal(tot.toFixed(2));
    }
  };

  useEffect(() => {
    const plan = getSelectedProgramPlan(props.program, props.selected);
    if (plan.method === "Online" || plan.type === "Free" || plan.type === "Free Trial") {
      setTotal("0.00");
    } else {
      calculateTotal(plan);
    }
  }, [props.coupon, giftBalance]);

  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px",
        fontSize: "14px"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif",
          fontWeight: 600
        }}
      >
        Total
      </p>
      <span style={{ fontWeight: "bold" }}>${total}</span>
    </li>
  );
};

ProgramRegularSemesterTotal.propTypes = {
  program: PropTypes.shape({
    plans: PropTypes.array.isRequired
  }).isRequired,
  selected: PropTypes.number.isRequired,
  installments: PropTypes.number.isRequired,
  coupon: PropTypes.object
};

export default ProgramRegularSemesterTotal;
