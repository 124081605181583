import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  promotion_discount,
  coupon_discount,
  getSelectedProgramPlan
} from "./utils";

const TaxableProgramSemesterInstallmentsAll = props => {
  const [taxName, setTaxName] = useState("Loading");
  const [taxAmount, setTaxAmount] = useState("Loading");
  const [depositTaxAmount, setDepositTaxAmount] = useState("0");
  const getTaxName = selected_plan => {
    const nm = `${selected_plan.taxInfo[0].display_name} (${selected_plan.taxInfo[0].percentage}%)`;
    setTaxName(nm);
  };
  const calculateTaxAmount = selected_plan => {
    let total_price = parseFloat(selected_plan.total_price);

    if (selected_plan.addOnItems) {
      selected_plan.addOnItems.map(item => {
        total_price += parseFloat(item.value);
      });
    }
    const installments_breakdown = parseInt(
      selected_plan.installments_breakdown
    );
    const installments_interest = parseInt(selected_plan.installments_interest ? selected_plan.installments_interest : 0);
    const taxRate = parseFloat(selected_plan.taxInfo[0].percentage) / 100;
    const installments =
      (props.installments ? 1 + installments_interest / 100 : 1) || 0;

    const tot = total_price * installments;
    if (installments && installments !== 0) {
      total_price = total_price * installments;
    }
    if (props.promotion) {
      let discount_amount = promotion_discount(props.promotion, tot);
      if (
        selected_plan.includeDeposit &&
        selected_plan.deposit &&
        selected_plan.deposit !== "0"
      ) {
        discount_amount = promotion_discount(
          props.promotion,
          parseFloat(selected_plan.deposit)
        );
        const depositTax =
          Math.max(0, parseFloat(selected_plan.deposit) - discount_amount) *
          taxRate;
        setDepositTaxAmount(0);
        discount_amount = 0;
      }
      const amnt = Math.max(0, tot - discount_amount) * taxRate;

      props.taxAmount(amnt.toFixed(2));
      setTaxAmount(amnt.toFixed(2));
    } else {
      let discount_amount = props.coupon
        ? coupon_discount(props.coupon, parseFloat(total_price))
        : 0;
      let discount_amount_deposit = 0;
      if (
        selected_plan.includeDeposit &&
        selected_plan.deposit &&
        selected_plan.deposit !== "0"
      ) {
        discount_amount_deposit = 0;

        const depositTax =
          Math.max(
            0,
            parseFloat(selected_plan.deposit) - discount_amount_deposit
          ) * taxRate;

        setDepositTaxAmount(0);
        discount_amount_deposit = 0;
      }

      console.log("LOOK HERE 123", tot, discount_amount, total_price);
      const amnt = Math.max(0, tot - discount_amount) * taxRate;

      props.taxAmount(amnt.toFixed(2));
      setTaxAmount(amnt.toFixed(2));
    }
  };
  useEffect(() => {
    const plan = getSelectedProgramPlan(props.program, props.selected);
    getTaxName(plan);
    calculateTaxAmount(plan);
  }, [props.coupon]);

  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px",
        borderBottom: "1px solid #dedede"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif",
          fontWeight: 500,
          fontSize: "14px"
        }}
      >
        {taxName}
      </p>
      <span style={{ fontWeight: 500 }}>
        $
        {parseFloat(
          parseFloat(taxAmount) + parseFloat(depositTaxAmount)
        ).toFixed(2)}
      </span>
    </li>
  );
};

TaxableProgramSemesterInstallmentsAll.propTypes = {
  program: PropTypes.shape({
    plans: PropTypes.array.isRequired
  }).isRequired,
  selected: PropTypes.number.isRequired,
  installments: PropTypes.number.isRequired,
  coupon: PropTypes.object
};

export default TaxableProgramSemesterInstallmentsAll;
