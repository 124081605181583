import React from "reactn";
import "./RequestInfo.css";
import axios from "axios";
import { toast } from "react-toastify";
import { formTypes } from "../../components/UI/form/index";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { isThisHour } from "date-fns";
import { ModalTemplate, ModalFields } from "../UI/modalComponents";
import BaseModal from "./baseModal/BaseModal";
import { GET_HTML } from "./baseModal/FieldTypes";
import { BLUE_BUTTON, WHITE } from "./baseModal/colours";
class EditChildInfoModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      "Full Name": { component: "input", value: "" },
      "Birth Date": { component: formTypes.DateAddChildModal, value: "" },
      Gender: { component: formTypes.GenderDropdownSelect, value: "" },
      Allergies: { component: formTypes.MultiInput, value: [] },
      Medication: { component: formTypes.MultiInput, value: [] },
      Immunized: { component: formTypes.YesNoDropdownSelect, value: "", hidden: true },
      "Special Food": { component: formTypes.MultiInput, value: [], hidden: true },
      "Potty Trained": { component: formTypes.YesNoDropdownSelect, value: "", hidden: true },
      "Health Concerns": { component: formTypes.MultiInput, value: [] },
      Likes: { component: formTypes.MultiInput, value: [], hidden: true },
      Dislikes: { component: formTypes.MultiInput, value: [], hidden: true },
      Comments: { component: "input", value: "" },
      isArchived: {value: false, hidden: true}
    };
    this.required = [
      { name: "Full Name", validation: e => !e.value },
      { name: "Birth Date", validation: e => !e.value },
      { name: "Gender", validation: e => !e.value },
      { name: "Allergies", validation: e => !e.value },
      // { name: "Immunized", validation: e => !e.value },
      { name: "Medication", validation: e => !e.value }
    ];

    this.mappings = {
      fullName: "Full Name",
      birthDate: "Birth Date",
      gender: "Gender",
      allergies: "Allergies",
      immunized: "Immunized",
      medication: "Medication",
      specialFood: "Special Food",
      pottyTrained: "Potty Trained",
      healthConcerns: "Health Concerns",
      likes: "Likes",
      dislikes: "Dislikes",
      comments: "Comments",
      isArchived: "isArchived"
    };
  }

  requestTour = async e => {
    e.preventDefault();
  };
  reverseMap() {
    const obj = { ...this.mappings };
    Object.keys(this.mappings).forEach(
      e => (obj[e] = this.state[this.mappings[e]].value)
    );
    return obj;
  }
  async submitForm(e) {
    e.preventDefault();
    let cancel = false;
    for (let requiredField of this.required) {
      if (requiredField.validation(this.state[requiredField.name])) {
        document.getElementsByName(requiredField.name)[0].style.boxShadow =
          "none";
        document.getElementsByName(requiredField.name)[0].style.border =
          "1px solid red";
        cancel = true;
      }
    }
    if (cancel) return;
    const endp = `${process.env.REACT_APP_API}/par/profile/child`;
    const childObj = {
      ...this.reverseMap(),
      id: this.props.params.id
    };
    const result = await axios.put(endp, childObj);
    if (result.data.success) {
      toast.success("Child Updated");
      this.props.update(childObj);
      this.props.onClose();
    }
  }
  async componentDidMount() {
    const ep = `${process.env.REACT_APP_API}/par/profile/child/${this.props.params.id}`;
    const results = await axios.get(ep);

    if (results.data.success) {
      const obj = { ...this.state };
      Object.keys(results.data.data).forEach(e => {
        if (this.state[this.mappings[e]] !== undefined) {
          obj[this.mappings[e]].value = results.data.data[e];
        }
      });
      this.setState({ ...obj }, _ => this.forceUpdate());
    }
  }

  editchildmodal = () => {
    console.log("this.state", this.state);
    return (
      <div className="modal-main">
        {Object.keys(this.state).map((e, i) => {
          if (!this.state[e].hidden) {
          const MyComp = this.state[e].component;
          const style = {
            width: "70%",
            fontSize: "1.5rem",
            color: "#626262"
          };
          if (e === "Birth Date") {
            style.border = "1px solid #c5c5c5";
            style.borderRadius = "2px";
          }
          return (
            <ModalFields.custom
              label={e}
              name={e}
              required={this.required.find(v => v.name === e)}
              key={i}
              titleStyle={{
                width: "25%",
                textAlign: "left",
                fontSize: "1.8rem",
                fontFamily: "futura-pt, sans-serif",
                fontWeight: "500",
                color: "#626262"
              }}
            >
              {MyComp === "input" ? (
                <input
                  name={e}
                  type="text"
                  className="form-control"
                  style={{
                    width: "70%",
                    fontFamily: "futura-pt, sans-serif",
                    fontSize: "1.5rem",
                    color: "#626262",
                    border: "1px solid #c5c5c5",
                    borderRadius: "4px",
                    paddingLeft: "14px"
                  }}
                  placeholder={`${e}...`}
                  onChange={v => {
                    this.setState({
                      [e]: {
                        ...this.state[e],
                        value: v.target.value
                      }
                    });
                  }}
                  value={this.state[e].value}
                />
              ) : (
                <div style={style} name={e}>
                  {/* Make the rest 1.5rem */}
                  <MyComp
                    value={this.state[e].value}
                    name={e}
                    onChange={(_, v) =>
                      this.setState({
                        [e]: { ...this.state[e], value: v }
                      })
                    }
                  />
                </div>
              )}
            </ModalFields.custom>
          );
          }
        })}
      </div>
    );
  };

  getBaseModalFields() {
    const editClient = {
      type: GET_HTML,
      data: {
        gethtml: this.editchildmodal
      }
    };

    const fields = [];
    fields.push(editClient);

    return fields;
  }

  getBaseModalButtons = () => {
    const addButton = {
      name: "Save",
      buttonColour: BLUE_BUTTON,
      textColour: WHITE,
      validatorKey: "validateSubmit",
      handleClick: e => this.submitForm(e)
    };

    return [addButton];
  };

  getBaseModalProps = () => {
    return {
      title: "Edit Child",
      fields: this.getBaseModalFields(),
      buttons: this.getBaseModalButtons(),
      handleClose: this.props.onClose,
      width: "550px",
      height: "75vh",
      midSectionHeight: "52vh"
    };
  };

  render() {
    return <BaseModal {...this.getBaseModalProps()} />;
    return (
      <div className="request-modal-cont">
        <div className="request-modal lesspadding">
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "48px",
                marginBottom: "1.5rem"
              }}
            >
              <h3>Edit Child</h3>
              <IconButton
                style={{
                  borderRadius: "50%",
                  color: "#2880FF",
                  height: "50px",
                  width: "50px",
                  marginTop: "20px"
                }}
                onClick={_ => this.props.onClose()}
              >
                <Close />
              </IconButton>
            </div>
            <form onSubmit={e => this.submitForm(e)}>
              {Object.keys(this.state).map((e, i) => {
                const MyComp = this.state[e].component;
                return (
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 0
                    }}
                    key={i}
                  >
                    <label
                      htmlFor="displayName"
                      style={{ width: "25%", textAlign: "left" }}
                    >
                      {e} {this.required.find(v => v.name === e) && "*"}
                    </label>
                    {MyComp === "input" ? (
                      <input
                        name={e}
                        type="text"
                        className="form-control"
                        placeholder={`${e}...`}
                        style={{ width: "70%" }}
                        onChange={e => {
                          this.setState({
                            [e.target.name]: e.target.value
                          });
                        }}
                        value={this.state[e].value}
                      />
                    ) : (
                      <div style={{ width: "70%" }}>
                        <MyComp
                          value={this.state[e].value}
                          name={e}
                          onChange={(_, v) =>
                            this.setState({
                              [e]: { ...this.state[e], value: v }
                            })
                          }
                          className="react-dropdown-select"
                          style={{
                            minWidth: "100%"
                          }}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
              <div className="modal-footer">
                <button
                  type="submit"
                  onClick={this.cancelThis}
                  className="btn profile-btn"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(EditChildInfoModal);
