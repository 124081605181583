import React from "react";
import ReactTooltip from "react-tooltip";

import "./ModalFormGroup.css";

/**
 * Label of form groups inside a modal.
 *
 * @param label Display Label of the form group
 * @param name HtmlFor attribute of the label.
 *
 * @param tooltip Text of tooltip (optional).
 * @param required Whether the field is required.
 */
const ModalFormLabel = ({ label, name, tooltip, required, titleStyle }) => (
  <label htmlFor={name} className="modal-label" style={titleStyle}>
    {label}
    {required && <span className="requiredField">*</span>}{" "}
    {tooltip && (
      <>
        <i
          data-tip
          data-for={name}
          className="fas fa-info-circle"
          style={{ color: "#DCDCDC" }}
        />
        <ReactTooltip id={name} place="top" effect="solid" type="info">
          {tooltip}
        </ReactTooltip>
      </>
    )}
  </label>
);

export default ModalFormLabel;
