import React from "react";
import { Switch, withStyles } from "@material-ui/core";

const StyledSwitch = withStyles(() => ({
  "& .MuiSwitch-sizeSmall": {
    padding: "4px !important"
  },
  "&  .MuiSwitch-switchBase": {
    padding: "4px !important"
  }
}))(Switch);

const SwitchToggle = props => {
  return (
    <div className="w-100">
      <Switch
        color="primary"
        onClick={() => props.onChange(props.name, !props.val)}
        checked={props.val}
        disabled={props.isDisabled ? true : false}
      />
    </div>
  );
};

export default SwitchToggle;
