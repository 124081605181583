import React from "reactn";
import { BoxForm, formTypes } from "../../../../components/UI/form";
import { MiniPlus } from "../../../../assets";
import Geosuggest from "react-geosuggest";
import { format, addMonths } from "date-fns";
import "../../../../assets/css/componentSpecificCss/kidProgram.css";

class SectionTwo extends React.PureComponent {
  constructor(props) {
    super(props);
    /*this.setAddress = this.setAddress.bind(this);
    this.customForm = this.customForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this._geoSuggest = null;
    this._geoSuggestPrev = null;*/
  }
  handleChange = (e, v) => {
    this.setGlobal({
      organizationInfo: {
        ...this.global.organizationInfo,
        [e]: v
      }
    });
  };
  uploadNew() {
    const uploadEle = document.getElementById("uploadPhoto");
    uploadEle.click();
  }

  fileChange(e) {
    if (e.target.files.length && e.target.files[0].type.includes("image/")) {
      const fd = new FormData();
      fd.set("type", "gallery");
      fd.set("image", e.target.files[0]);
      this.dispatch.uploadProgramPhoto(fd);
    }
  }

  rotateImage = async index => {
    let newRotation =
      this.global.organizationInfo[`gallery_image_${index}`] + 90;
    if (newRotation >= 360) {
      newRotation = -360;
    }

    this.setGlobal({
      organizationInfo: {
        ...this.global.organizationInfo,
        [`gallery_image_${index}`]: newRotation
      }
    });

    await this.dispatch.saveKids(true);
  };

  customForm = () => {
    const vals = this.global.myProgramLocationHours;

    return (
      <div className="formbody">
        {/* {!this.state.timeChangeShown ? null : (
          <ConfirmTimeChangeForm onClose={this.setTime} />
        )} */}
        <form>
          <div className="row">
            <div className="col-12">
              <h3 className="label-names">
                Organization Description<span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-12 d-flex">
              <div className={`w-100`}>
                <textarea
                  rows="7"
                  style={{ width: "100%" }}
                  className="input-fields scrollbar-mini text-border-color"
                  name="description"
                  value={this.global.organizationInfo.description}
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.value)
                  }
                ></textarea>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-5">
              <h3 className="label-names">
                Re-Opening <span className="requiredField">*</span>
              </h3>
            </div>
            <div className="col-7 d-flex">
              <select
                className="input-fields"
                style={{ width: "100%" }}
                name="reopening"
                onChange={e => this.handleChange(e.target.name, e.target.value)}
                value={this.global.organizationInfo.reopening}
              >
                <option value="" selected hidden disabled>
                  Please select
                </option>
                <option>Live / Active</option>
                <option>Coming Soon</option>
                <option>Taking Waitlist Applications</option>
                {new Array(8).fill(0).map((e, i) => (
                  <option>
                    {format(addMonths(new Date(), i + 1), "LLLL yyyy")}
                    {i === 7 && "- Onwards"}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="row">
            <div className="col-5">
              <h3 className="label-names">Gallery</h3>
            </div>
            <div
              className="col-12 d-flex"
              style={{
                flexWrap: "wrap",
                justifyContent: "space-between",
                display: "flex",
                maxWidth: "70%"
              }}
            >
              {this.global.organizationInfo.gallery.map((item, index) => {
                return (
                  <figure
                    className="photoContainer"
                    style={{
                      width: "23%",
                      textAlign: "center",
                      marginLeft: "6px",
                      height: "80px"
                    }}
                    key={index}
                  >
                    <div
                      style={{
                        padding: "2px",
                        width: "80px",
                        height: "80px",
                        border: "1px solid #e3e3e3",
                        display: "inline-block",
                        borderRadius: "8px",
                        cursor: "pointer",
                        backgroundImage: `url(${process.env.REACT_APP_DS_FILES_S3}/${item})`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        transform: `rotate(${
                          this.global.organizationInfo[`gallery_image_${index}`]
                        }deg)`
                      }}
                      id={index}
                    ></div>
                    <div
                      className="photoContainerX"
                      onClick={_ =>
                        this.setGlobal({
                          organizationInfo: {
                            ...this.global.organizationInfo,
                            gallery:
                              this.global.organizationInfo.gallery.filter(
                                (_, i) => i !== index
                              )
                          }
                        })
                      }
                    >
                      <div className="innerX">
                        <i className="far fa-times-circle"></i>
                      </div>
                    </div>

                    <div
                      className="photoContainerY"
                      onClick={_ => this.rotateImage(index)}
                    >
                      <div className="innerY">
                        <i
                          style={{ color: "blue" }}
                          className="fas fa-redo"
                        ></i>
                      </div>
                    </div>
                  </figure>
                );
              })}
              {new Array(6 - this.global.organizationInfo.gallery.length)
                .fill(7)
                .map((e, i) => (
                  <figure
                    className="photoContainer"
                    style={{
                      width: "23%",
                      textAlign: "center",
                      marginLeft: "6px"
                    }}
                    onClick={this.uploadNew}
                    key={i}
                  >
                    <div
                      style={{
                        padding: "2px",
                        width: "80px",
                        height: "80px",
                        border: "1px solid #e3e3e3",
                        display: "inline-block",
                        borderRadius: "8px",
                        cursor: "pointer"
                      }}
                    >
                      <img
                        src={MiniPlus}
                        alt="Add"
                        style={{ marginTop: "calc(50% - 4.5px)" }}
                      />
                    </div>
                  </figure>
                ))}
              <input
                type="file"
                style={{ display: "none" }}
                id="uploadPhoto"
                onChange={e => this.fileChange(e)}
                accept="image/x-png,image/gif,image/jpeg"
              />
            </div>
          </div>
        </form>
      </div>
    );
  };

  render() {
    return (
      <BoxForm
        handleChange={this.handleChange}
        name={"Organization Info"}
        customForm={this.customForm()}
      />
    );
  }
}

export default SectionTwo;
