import React, { useState, useEffect } from "reactn";
import PropTypes from "prop-types";

import { getSelectedProgramPlan } from "./utils";

const ProgramSemesterInstallmentsAllSubtotal = props => {
  const [subtotal, setSubtotal] = useState("Loading");
  const [depositSubTotal, setDepositSubTotal] = useState("0");
  const [installmentInterest, setInstallmentInterest] = useState(0);
  const calculateSubTotal = () => {
    const plan = getSelectedProgramPlan(props.program, props.selected);
    const installments_breakdown = plan.installments_breakdown;
    let total_price = parseFloat(plan.total_price);
    const installments_interest = parseInt(plan.installments_interest ? plan.installments_interest : 0);

    if (plan.addOnItems) {
      plan.addOnItems.map(item => {
        total_price += parseFloat(item.value);
      });
    }

    let amnt = parseFloat(
      parseFloat(
        total_price *
          (props.installments ? 1 + installments_interest / 100 : 1) || 0
      )
    ).toFixed(2);
    props.itemAmount(amnt);
    setSubtotal(amnt);

    console.log("INSTALLMENT AMOUNT", total_price, amnt);
    if (plan.includeDeposit && plan.deposit && plan.deposit !== "0") {
      const depositAmount = parseFloat(plan.deposit).toFixed(2);
      setDepositSubTotal(depositAmount);
    }
    setInstallmentInterest(parseFloat(installments_interest));
  };
  useEffect(() => {
    calculateSubTotal();
  }, []);
  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px",
        borderBottom: "1px solid #dedede"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif",
          fontWeight: 700,
          fontSize: "14px"
        }}
      >
        Subtotal{" "}
        {installmentInterest
          ? `- Installment interest included (${installmentInterest}%)`
          : ""}
      </p>
      <span style={{ fontWeight: 700 }}>
        ${parseFloat(subtotal).toFixed(2)}
      </span>
    </li>
  );
};

ProgramSemesterInstallmentsAllSubtotal.propTypes = {
  program: PropTypes.shape({
    plans: PropTypes.array.isRequired
  }).isRequired,
  selected: PropTypes.number.isRequired,
  installments: PropTypes.bool.isRequired
};

export default ProgramSemesterInstallmentsAllSubtotal;
