import React from "reactn";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import Header from "../components/front/Header";
import PublicFooter from "../components/front/PublicFooter";
import Footer from "../components/front/Footer";
import IFrameHeader from "../components/front/IFrameHeader";
class RoutePublic extends React.PureComponent {
  constructor(props) {
    super(props);
    document.getElementsByTagName("html")[0].className = "main-pages";
    document.getElementsByTagName("html")[0].style.fontSize = "10px";
    document.getElementsByTagName("html")[0].style.lineHeight = "11.5px";
  }

  async componentDidMount() {
    try {
      if (localStorage.getItem("ds_token") && !this.global.dsUser) {
        await this.dispatch.validateToken();
      }
    } catch (e) {
      console.debug(
        `RoutePublic.componentDidMount() - probably denied access to "localStorage"`
      );
      console.log(e);
    }
  }

  render() {
    const {
      component: Component,
      isAuthenticated,
      iFrame,
      to,
      ...rest
    } = this.props;
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            <Redirect to={to} />
          ) : (
            <>
              {iFrame || this.global.navbar === false ? (
                this.global.dsUser ? (
                  <IFrameHeader />
                ) : null
              ) : (
                <Header />
              )}
              <div
                id="page-content"
                style={{
                  paddingTop: iFrame && !this.global.dsUser ? "20px" : "100px"
                }}
              >
                <Component {...props} />
              </div>
              {iFrame || this.global.footer === false ? null : this.global.dsUser?.accountType === "Parent" ||
                this.global.dsUser?.accountType === "Student" ||
                this.global.dsUser?.accountType === "Volunteer" ? (
                <Footer />
              ) : (
                <PublicFooter />
              )}
            </>
          )
        }
      />
    );
  }
}

RoutePublic.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  to: PropTypes.string
};

RoutePublic.defaultProps = {
  to: "/private"
};

export default RoutePublic;
