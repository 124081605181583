import React from "react";
import { Pin, GrayPin, OrangePin } from "../../../assets";
import FindMapMarkerInfoWindow from "./FindMapMarkerInfoWindow";
import PropTypes from "prop-types";

class FindMapMarker extends React.Component {
  render() {
    const { school } = this.props;

    const style = {
      backgroundImage: `url(${
        school.programCoverPhoto
          ? Pin
          : school.clickable
          ? Pin
          : school.isLive
          ? OrangePin
          : GrayPin
      })`,
      backgroundSize: "cover",
      height: school.programCoverPhoto ? "37px" : "30px",
      width: school.programCoverPhoto ? 35 * 0.7 : 35 * 0.6,
      cursor: "pointer",
      zIndex: school.programCoverPhoto || school.isLive ? 15 : 10,
      position: "relative",
      bottom: 15,
      right: 15
    };
    return (
      <>
        <div style={style} />
        {school.active && <FindMapMarkerInfoWindow school={school} />}
      </>
    );
  }
}

FindMapMarker.propTypes = {
  school: PropTypes.object
};

FindMapMarker.defaultProps = {
  school: {
    active: false
  }
};

export default FindMapMarker;
