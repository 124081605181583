import React, { Component } from "reactn";
import StripeCmp from "../../components/Payments/stripe_component";
import { StripeProvider, Elements } from "react-stripe-elements";
import { toast } from "react-toastify";
import axios from "axios";
import Switch from "@material-ui/core/Switch";
import { Link } from "react-router-dom";
class GiftCardPurchase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image_map: [
        "partners/Gift Cards/zeus-01.png",
        "partners/Gift Cards/zeus-02.png",
        "partners/Gift Cards/zeus-03.png",
        "partners/Gift Cards/zeus-04.png",
        "partners/Gift Cards/zeus-05.png",
        "partners/Gift Cards/zeus-06.png",
        "partners/Gift Cards/zeus-07.png",
        "partners/Gift Cards/zeus-08.png"
      ],
      isForMyself: true
    };
    this.required = [
      { name: "me_name", validation: e => !e },
      { name: "me_email", validation: e => !e }
    ];
    this.recipientRequired = [
      { name: "rec_name", validation: e => !e },
      { name: "rec_email", validation: e => !e }
    ];
  }
  componentDidMount() {
    const Ele = document.getElementsByClassName("StripeElement")[0];
    Ele.style.backgroundColor = "white";
    Ele.style.padding = "10px";
    Ele.style.boxShadow = "0 1px 3px 0 #e6ebf1";
    Ele.style.borderRadius = "5px";
    Ele.style.marginTop = "20px";
    const user = this.global.dsUser;
    if (this.global.dsUser) {
      this.setState({
        me_name: user.displayName,
        me_email: user.email,
        me_phone: user.phone
      });
    } else {
      this.setState({
        isForMyself: false
      });
    }
  }

  validate() {
    let isInvalid = false;
    let required = this.required;

    // If the gift card if for a separate recipient, additional required fields.
    if (!this.state.isForMyself) {
      required = [...this.required, ...this.recipientRequired];
    }
    for (let requiredField of required) {
      if (requiredField.validation(this.state[requiredField.name])) {
        document.getElementsByName(requiredField.name)[0].style.boxShadow =
          "0 1px 6px 0 #e63f3f";

        isInvalid = true;
      }
    }
    return isInvalid;
  }
  async buyCard() {
    this.setGlobal({ loading: true });

    const isInvalid = this.validate();

    if (isInvalid) {
      this.setGlobal({ loading: false });
      toast.error("Please complete the highlighted fields.");
      return;
    }

    if (!this.state.cc_token) {
      this.setGlobal({ loading: false });
      toast.error("Please complete your credit card information.");
      return;
    }
    const ep = `${process.env.REACT_APP_API}/partners/gifts/buy`;
    console.log({
      state: this.state,
      giftCard: this.props.giftCard,
      global: this.global
    });
    const result = await axios.post(ep, {
      ...this.state,
      giftCard: this.props.gift_card
    });
    if (result.data.success) {
      toast.success(`Gift card purchase successful!`);
      this.setState({
        rec_name: "",
        rec_phone: "",
        rec_email: "",
        message: "",
        cc_token: ""
      });
      this.props.onBack();
    } else {
      toast.error("Credit Card Declined");
    }
    this.setGlobal({ loading: false });
  }
  render() {
    const { gift_card } = this.props;
    const inputStyle = {
      margin: "5px 20px 5px 0",
      padding: "5px",
      border: "1px solid white",
      boxShadow: "rgb(230, 235, 241) 0px 1px 3px 0px",
      borderRadius: "5px",
      width: "100%",
      fontFamily: "Century Gothic,CenturyGothic,AppleGothic,sans-serif"
    };
    return (
      <div
        style={{
          display: "flex",
          width: "80%",
          margin: "0 auto",
          fontFamily: "Century Gothic,CenturyGothic,AppleGothic,sans-serif"
        }}
      >
        <div
          style={{
            width: "50%",
            borderRadius: "14px",
            marginRight: "30px",
            marginTop: "10px",
            objectFit: "contain"
          }}
        >
          <div style={{ position: "relative", height: "min-content" }}>
            <img
              src={`${process.env.REACT_APP_DS_FILES_S3}/${
                this.state.image_map[gift_card.image - 1]
              }`}
              style={{
                width: "100%"
              }}
            />
            <p
              style={{
                position: "absolute",
                fontSize: gift_card.image < 4 ? "36px" : "44px",
                fontWeight: "bold",
                top:
                  gift_card.image === 1
                    ? "15%"
                    : gift_card.image < 4
                    ? "90%"
                    : "50%",
                left:
                  gift_card.image === 1
                    ? "15%"
                    : gift_card.image < 4
                    ? "85%"
                    : "50%",
                transform: "translate(-50%,-50%)",
                color: gift_card.image < 4 ? "#1f3b89" : "white",
                fontFamily: "Montserrat"
              }}
            >
              ${gift_card.amount}
            </p>
          </div>
          <button
            className="savebtn"
            onClick={_ => this.props.onBack()}
            style={{
              background: "#FF6663",
              border: "none",
              color: "#FFFFFF",
              padding: "15px 32px",
              textAlign: "center",
              transitionDuration: "0.4s",
              marginTop: "16px",
              textDecoration: "none",
              fontSize: "16px",
              cursor: "pointer"
            }}
          >
            Back
          </button>
        </div>
        <div>
          <h4
            style={{
              fontFamily: "Century Gothic,CenturyGothic,AppleGothic,sans-serif",
              fontWeight: "700"
            }}
          >
            {gift_card.gift_name || "Amount"}: $
            {gift_card ? gift_card.amount : ""}
          </h4>
          <div id="gift_own_info">
            <input
              type="text"
              placeholder="Your Name..."
              name="me_name"
              value={this.state.me_name}
              onChange={e => this.setState({ [e.target.name]: e.target.value })}
              style={inputStyle}
              disabled={this.global.dsUser}
            />
            <input
              type="text"
              placeholder="Your Phone (optional)"
              name="me_phone"
              value={this.state.me_phone}
              onChange={e => this.setState({ [e.target.name]: e.target.value })}
              style={inputStyle}
              disabled={this.global.dsUser}
            />
            <br />
            <input
              type="email"
              placeholder="Your Email..."
              name="me_email"
              value={this.state.me_email}
              onChange={e => this.setState({ [e.target.name]: e.target.value })}
              style={inputStyle}
              disabled={this.global.dsUser}
            />
          </div>
          {this.global.dsUser ? (
            <div style={{ marginTop: "10px" }}>
              <label
                htmlFor="isForMyself"
                style={{
                  fontFamily:
                    "Century Gothic,CenturyGothic,AppleGothic,sans-serif",
                  marginRight: "15px"
                }}
              >
                Are you buying this gift card for someone else?
              </label>
              <Switch
                id="isForMyself"
                name="isForMyself"
                checked={!this.state.isForMyself} // TODO: Buying for oneself is not supported yet
                onClick={e =>
                  this.setState({ [e.target.name]: !e.target.checked })
                }
                color="primary"
              />
            </div>
          ) : (
            <p
              style={{
                fontFamily:
                  "Century Gothic,CenturyGothic,AppleGothic,sans-serif"
              }}
            >
              To purchase a gift card for your own account, please{" "}
              <b>
                <Link to="/sign-in">log in first</Link>
              </b>
              .
            </p>
          )}
          {this.state.isForMyself || (
            <>
              <input
                type="text"
                placeholder="Recipient's name..."
                name="rec_name"
                value={this.state.rec_name}
                onChange={e =>
                  this.setState({ [e.target.name]: e.target.value })
                }
                style={inputStyle}
              />
              <input
                type="text"
                placeholder="Recipient's Phone (optional)"
                name="rec_phone"
                value={this.state.rec_phone}
                onChange={e =>
                  this.setState({ [e.target.name]: e.target.value })
                }
                style={inputStyle}
              />
              <br />
              <input
                type="email"
                placeholder="Recipient's email..."
                name="rec_email"
                value={this.state.rec_email}
                onChange={e =>
                  this.setState({ [e.target.name]: e.target.value })
                }
                style={inputStyle}
              />
              <br />
              <textarea
                placeholder="Message for Recipient... (optional)"
                name="message"
                value={this.state.message}
                onChange={e =>
                  this.setState({ [e.target.name]: e.target.value })
                }
                style={inputStyle}
                rows={4}
                columns={10}
              />
            </>
          )}
          <div style={{ width: "143%" }}>
            <StripeProvider apiKey={process.env.REACT_APP_STRIPE}>
              <Elements>
                <StripeCmp
                  setToken={token => this.setState({ cc_token: token })}
                />
              </Elements>
            </StripeProvider>
          </div>

          <div style={{ textAlign: "right" }}>
            <button
              className="savebtn"
              onClick={_ => this.buyCard()}
              style={{
                backgroundColor: "#FF6663",
                border: "none",
                color: "#FFFFFF",
                padding: "15px 32px",
                textAlign: "center",

                transitionDuration: "0.4s",
                marginTop: "16px",
                textDecoration: "none",
                fontSize: "16px",
                cursor: "pointer"
              }}
            >
              Purchase
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default GiftCardPurchase;
