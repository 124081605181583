import React from "reactn";
import "../../assets/css/componentSpecificCss/signInModal.css";
import "./RequestInfo.css";
import axios from "axios";
import { toast } from "react-toastify";
import { setParents } from "../../store";
import { withRouter, Link } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
class SignUpNewModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      displayName: "",
      accountType: props.program?.addChild ? "Parent" : "",
      postalCode: "",
      connection: true,
      partnerId: props.program.partnerId,
      came_from:
        props.program.membership_name ||
        props.program.event_title ||
        props.program.program_name,
      showPassword: false
    };
    this.required = [
      { name: "accountType", validation: e => !e },
      { name: "displayName", validation: e => !e },
      { name: "email", validation: e => !e },
      { name: "password", validation: e => !e }
      // { name: "postalCode", validation: e => !e }
    ];
  }

  requestTour = async e => {
    e.preventDefault();
  };

  async performLogin(e) {
    e.preventDefault();
    let cancel = false;
    for (let requiredField of this.required) {
      if (requiredField.validation(this.state[requiredField.name])) {
        document.getElementsByName(requiredField.name)[0].style.boxShadow =
          "none";
        document.getElementsByName(requiredField.name)[0].style.border =
          "1px solid red";
        cancel = true;
      } else {
        document.getElementsByName(requiredField.name)[0].style.boxShadow = "";
        document.getElementsByName(requiredField.name)[0].style.border = "";
      }
    }
    if (cancel) return;
    const ep = `${process.env.REACT_APP_API}/auth/mod-register`;
    console.log("sign up event e is", e);
    console.log("sign up the state is", this.state);
    console.log("sign up props are", this.props);
    const results = await axios.post(ep, {
      email: this.state.email,
      password: this.state.password,
      displayName: this.state.displayName,
      accountType: this.state.accountType,
      partnerId: this.state.partnerId,
      came_from: `Initiated Booking: ${this.state.came_from}`
      // postalCode: this.state.postalCode
    });

    console.log("result is", results);

    if (results.data.success) {
      setParents();
      this.setGlobal({
        dsUser: results.data.data.user,
        parentGuardians: results.data.data.parentGuardians,
        children: []
      });
      localStorage.setItem("ds_token", results.data.data.token);
    } else {
      toast.error("An Account is already registered with this email");
      if (this.props.setEmail) {
        this.props.setEmail(this.state.email);
      }
      this.props.onClose();
      this.props.openSignInModal();
    }
    if (
      this.global.dsUser &&
      (this.global.dsUser.accountType === "Parent" ||
        this.global.dsUser.accountType === "Volunteer" ||
        this.global.dsUser.accountType === "Student")
    ) {
      this.props.checkout();
    } else {
      // this.props.onClose();
    }
  }

  render() {
    console.log("STATEPROPS", this.state, this.props);
    return (
      <div className="request-modal-cont">
        <div className="request-modal signup-modal" style={{ padding: 0 }}>
          <div className="first-level-div">
            <h4 className="modal-title">Sign Up</h4>
            <p className="intro-text">
              <b>{this.props.program.organization_title}</b> partners with
              Dreamschools for registration so that you can safely and quickly
              book programs & activities.
            </p>
            <form onSubmit={e => this.performLogin(e)}>
              <div className="form-group">
                <input
                  name="displayName"
                  id="displayName"
                  type="text"
                  autocomplete="name"
                  className="form-control"
                  placeholder="Full Name"
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                />
              </div>
              <div className="form-group">
                <input
                  name="email"
                  id="email"
                  type="email"
                  className="form-control"
                  placeholder="Email Address"
                  value={this.state.email}
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value.toLowerCase()
                    });
                  }}
                />
              </div>
              <div className="form-group" style={{ position: "relative" }}>
                <input
                  name="password"
                  id="password"
                  type={this.state.showPassword ? "text" : "password"}
                  className="form-control"
                  placeholder="Password"
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                />
                <button
                  onClick={e => {
                    e.preventDefault();
                    this.setState({ showPassword: !this.state.showPassword });
                  }}
                  className="btn show-password-toggle"
                >
                  <i
                    className={`bi ${
                      this.state.showPassword ? "bi-eye-slash" : "bi-eye"
                    }`}
                  />
                </button>
              </div>
              <div className="form-group">
                <select
                  name="accountType"
                  id="accountType"
                  className="form-control"
                  style={{ width: "70%", zIndex: 1000, color: "#8c8ea0" }}
                  onChange={e => {
                    this.setState({
                      [e.target.name]: e.target.value
                    });
                  }}
                  // defaultValue={this.state.accountType}
                  value={this.state.accountType}
                  required
                >
                  <option value="" disabled selected hidden>
                    Select User Type
                  </option>
                  {!this.props.program.addChild &&
                    this.props.program.participant_type &&
                    this.props.program.participant_type.parent === true && (
                      <option value="Parent">Parent</option>
                    )}
                  {this.props.program.participant_type &&
                    this.props.program.participant_type.volunteer === true && (
                      <option value="Volunteer">Volunteer</option>
                    )}
                  {this.props.program.participant_type &&
                    this.props.program.participant_type.student === true && (
                      <option value="Student">Student</option>
                    )}
                  {this.props.program.addChild ? (
                    <option value="Parent">Parent</option>
                  ) : !this.props.program.participant_type ||
                    (this.props.program.participant_type &&
                      !this.props.program.participant_type.student &&
                      !this.props.program.participant_type.parent &&
                      !this.props.program.participant_type.volunteer) ? (
                    <>
                      <option value="Parent">Parent</option>
                      <option value="Volunteer">Volunteer</option>
                      <option value="Student">Student</option>
                    </>
                  ) : null}
                </select>
              </div>
              <div style={{ textAlign: "center" }}>
                <button type="submit" className="btn profile-btn submit-button">
                  Sign-up
                </button>
              </div>
            </form>
            <div className="signup-terms">
              <p>
                {/*empty squiggly brackets are used to fool React's trimming of white space before or after a line of text.*/}
                By performing this action, you agree to our {}
                <a href="https://dreamschools.com/terms-of-use" target="blank">
                  terms of use
                </a>
                {} and {}
                <a
                  href="https://dreamschools.com/privacy-policy"
                  target="blank"
                >
                  privacy policy
                </a>
                .
              </p>
            </div>
            {
              <IconButton
                className="close-button"
                onClick={_ => this.props.onClose()}
              >
                <Close />
              </IconButton>
            }
          </div>
          <div className="switch-to-signin">
            Already a member? {}
            <span
              className="clickable"
              onClick={_ => {
                this.props.onClose();
                this.props.openSignInModal();
              }}
            >
              Sign In here
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(SignUpNewModal);
