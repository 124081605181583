export const DROPDOWN = "_dropdown";
export const STRING_INPUT = "_string_input";
export const CONTENT_BLOCK = "_content_block";
export const DATE_PICKER = "_date_picker";
export const IMAGE_DROPDOWN = "_image_dropdown";
export const IMAGE_CONTENT = "_image_content";
export const TEXT_IMAGE_LIST = "_text_image_list";
export const BUTTON = "_button";
export const TEXT_AREA = "_text_area";
export const TITLE = "_title";
export const SWITCH = "_switch";
export const SELECT_MULTIPLE_DROPDOWN = "_select_multiple_dropdown";
export const ITEM_CONTENT = "_item_content";
export const GET_HTML = "_get_html";