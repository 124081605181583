import React, { useGlobal } from "reactn";
import Select from "react-dropdown-select";
import {
  yesNoOptions,
  genderOptions,
  main_tags_option,
  MultiProgramCats,
  Main_tags,
  membershipTypes,
  programTypeOptions,
  paymentAccountTypes,
  ageGroups,
  formGroups,
  centerAgeGroups,
  languages,
  percentageAmounts,
  percentageHalves,
  program_structures
} from "./dropdownOptions";

const DropdownSelect = props => {
  return (
    <div className={props.className ? props.className + " w-100" : "w-100"}>
      <Select
        multi={!!props.multi}
        options={props.options}
        onDropdownOpen={props.open}
        values={
          props.value && Array === props.value.constructor
            ? props.value
            : [props.value]
        }
        name={props.name}
        onChange={values => {
          props.onChange(
            props.name,
            props.multi
              ? values
              : values[0][props.valueField ? props.valueField : "name"]
          );
        }}
        style={{
          top: "0px"
        }}
        sortBy={
          props.sortBy
            ? props.sortBy
            : props.labelField
            ? props.labelField
            : null
        }
        // noDataLabel={props.noDataLabel}
        labelField={props.labelField ? props.labelField : "name"}
        valueField={props.valueField ? props.valueField : "name"}
        disabled={!!props.isDisabled}
        closeOnSelect={true}
      />
    </div>
  );
};

const YesNoDropdownSelect = props => {
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: yesNoOptions,
          valueField: "value",
          value: yesNoOptions.find(o => o.value === props.value)
        }
      }}
    />
  );
};

const GenderDropdownSelect = props => {
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: genderOptions,
          value: genderOptions.find(o => o.value === props.value) || ""
        }
      }}
    />
  );
};

const ChildMultiSelect = props => {
  const [useChildren] = useGlobal("children");
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: useChildren.filter(e => e.isArchived === false),
          labelField: "fullName",
          valueField: "id",
          multi: true,
          value: props.value
        }
      }}
    />
  );
};

const MultiProgramCategories = props => {
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: MultiProgramCats,
          valueField: "value",
          multi: true,
          value: props.value
        }
      }}
    />
  );
};

const ProgramTypeSelect = props => {
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: programTypeOptions,
          value: programTypeOptions.find(o => o.value === props.value) || "",
          valueField: "value"
        }
      }}
    />
  );
};
const programTypeOptionsMulti = [
  { name: "Please Select", value: "" },
  {
    name: "Preschool",
    value: "Preschool"
  },
  {
    name: "Summer Camp",
    value: "Summer Camp"
  },
  { name: "Virtual Classes", value: "Virtual Classes" },
  { name: "Kids Athletic", value: "Kids Athletic" },
  { name: "Academics", value: "Academics" },
  { name: "After School", value: "After School" },
  { name: "Athletics", value: "Athletics" },
  { name: "Music", value: "Music" },
  { name: "Mixed", value: "Mixed" },
  { name: "Outdoors", value: "Outdoors" },
  { name: "Leadership", value: "Leadership" },
  { name: "Before & After School", value: "Before & After School" },
  { name: "STEM", value: "STEM" },
  { name: "Theatre", value: "Theatre" },
  { name: "Arts", value: "Arts" },
  { name: "Playgrounds", value: "Playgrounds" },
  { name: "Creative", value: "Creative" },
  { name: "Drama", value: "Drama" },
  { name: "Dance", value: "Dance" }
];
const ProgramTypeSelectMulti = props => {
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: programTypeOptionsMulti,
          value: props.value,
          valueField: "value",
          multi: true
        }
      }}
    />
  );
};
const ProgramStructures = props => {
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: program_structures,
          value: props.value,
          valueField: "value",
          multi: true
        }
      }}
    />
  );
};

const PaymentAccountTypeSelect = props => {
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: paymentAccountTypes,
          value: paymentAccountTypes.find(o => o.value === props.value) || "",
          valueField: "value"
        }
      }}
    />
  );
};

const FormGroupSelect = props => {
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: formGroups,
          valueField: "value",
          value: props.value,
          multi: true
        }
      }}
    />
  );
};

const ChildAgeGroupSelect = props => {
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: ageGroups,
          valueField: "value",
          value: props.value,
          multi: true
        }
      }}
    />
  );
};

const ChildCenterAgeGroupSelect = props => {
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: centerAgeGroups,
          valueField: "value",
          value: props.value,
          multi: true
        }
      }}
    />
  );
};
const MemberShipSelect = props => {
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: membershipTypes,
          valueField: "value",
          value: props.value,
          multi: true
        }
      }}
    />
  );
};

const LanguageInput = props => {
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: languages,
          valueField: "value",
          value: props.value,
          multi: true
        }
      }}
    />
  );
};

const PercentageOffDropdownSelect = props => {
  const newOpts = percentageAmounts.map(o => ({
    ...o,
    name: o.name + " (off payment)"
  }));
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: newOpts,
          value: newOpts.find(o => o.value === props.value) || 0,
          valueField: "value"
        }
      }}
    />
  );
};

const PercentageExtraDropdownSelect = props => {
  const newOpts = percentageAmounts.map(o => o.name + " (extra charge)");
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: newOpts,
          value: newOpts.find(o => o.value === props.value) || 0,
          valueField: "value"
        }
      }}
    />
  );
};

const DateMultiSelect = props => {
  // onClick={_ => document.getElementById("datenumber2").click()}

  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          valueField: "value",
          multi: true,
          value: props.value
        }
      }}
    />
  );
};

const PercentageHalvesDropdownSelect = props => {
  const newOpts = percentageHalves.map(o => ({
    ...o,
    name: o.name + " (off payment)"
  }));
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: newOpts,
          value: newOpts.find(o => o.value === props.value) || 0,
          valueField: "value"
        }
      }}
    />
  );
};

const timeRanges = [
  { name: "Please Select", value: -1 },
  { name: "5:00 AM", value: 5 },
  { name: "5:30 AM", value: 5.5 },
  { name: "6:00 AM", value: 6 },
  { name: "6:30 AM", value: 6.5 },
  { name: "7:00 AM", value: 7 },
  { name: "7:30 AM", value: 7.5 },
  { name: "8:00 AM", value: 8 },
  { name: "8:30 AM", value: 8.5 },
  { name: "9:00 AM", value: 9 },
  { name: "9:30 AM", value: 9.5 },
  { name: "10:00 AM", value: 10 },
  { name: "10:30 AM", value: 10.5 },
  { name: "11:00 AM", value: 11 },
  { name: "11:30 AM", value: 11.5 },
  { name: "12:00 PM", value: 12 },
  { name: "12:30 PM", value: 12.5 },
  { name: "1:00 PM", value: 13 },
  { name: "1:30 PM", value: 13.5 },
  { name: "2:00 PM", value: 14 },
  { name: "2:30 PM", value: 14.5 },
  { name: "3:00 PM", value: 15 },
  { name: "3:30 PM", value: 15.5 },
  { name: "4:00 PM", value: 16 },
  { name: "4:30 PM", value: 16.5 },
  { name: "5:00 PM", value: 17 },
  { name: "5:30 PM", value: 17.5 },
  { name: "6:00 PM", value: 18 },
  { name: "6:30 PM", value: 18.5 },
  { name: "7:00 PM", value: 19 },
  { name: "7:30 PM", value: 19.5 },
  { name: "8:00 PM", value: 20 },
  { name: "8:30 PM", value: 20.5 },
  { name: "9:00 PM", value: 21 },
  { name: "9:30 PM", value: 21.5 },
  { name: "10:00 PM", value: 22 },
  { name: "10:30 PM", value: 22.5 },
  { name: "11:00 PM", value: 23 }
];

const TimeDropdownSelect = props => {
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: timeRanges,
          valueField: "value",
          value: timeRanges.find(o => o.value === props.value) || -1
        }
      }}
    />
  );
};

const daysOfWeekRanges = [
  { name: "Please Select", value: "" },
  { name: "Monday - Friday", value: "Monday - Friday" },
  { name: "Monday - Saturday", value: "Monday - Saturday" },
  { name: "Saturday - Sunday", value: "Saturday - Sunday" }
];

const DaysOfWeekRangeDropdownSelect = props => {
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: daysOfWeekRanges,
          valueField: "value",
          value: daysOfWeekRanges.find(o => o.value === props.value) || ""
        }
      }}
    />
  );
};

const daysOptions = [
  { name: "Sunday", value: "Sunday" },
  { name: "Monday", value: "Monday" },
  { name: "Tuesday", value: "Tuesday" },
  { name: "Wednesday", value: "Wednesday" },
  { name: "Thursday", value: "Thursday" },
  { name: "Friday", value: "Friday" },
  { name: "Saturday", value: "Saturday" }
];

const DaysDropdownSelect = props => {
  let days = daysOptions;
  if (props.range) {
    switch (props.range) {
      case "Monday - Friday":
        days = days.slice(1, 6);
        break;
      case "Monday - Saturday":
        days = days.slice(1, 7);
        break;
      case "Monday - Sunday":
        break;
      case "Weekend Only":
        days = [daysOptions[0], daysOptions[daysOptions.length - 1]];
        break;
      default:
        days = days.slice(1, 6);
    }
  }

  if (props.toremove) {
    days = days.filter(d => {
      return (
        props.toremove.findIndex(ed => {
          return ed.value === d.value;
        }) === -1
      );
    });
  }
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: days,
          valueField: "value",
          value: props.value,
          multi: true
        }
      }}
    />
  );
};

const mealOptions = [
  { name: "Breakfast", value: "Breakfast", order: 1 },
  { name: "Lunch", value: "Lunch", order: 2 },
  { name: "Dinner", value: "Dinner", order: 3 }
];

const MealDropdownSelect = props => {
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: mealOptions,
          valueField: "value",
          value: props.value,
          multi: true
        }
      }}
    />
  );
};
const MainTags = props => {
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: props.max ? [] : Main_tags,
          valueField: "value",
          value: props.value,
          multi: true,
          noDataLabel: "You've reached the maximum amount of tags allowed!"
        }
      }}
    />
  );
};
const SecondaryTags = props => {
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: props.max ? [] : main_tags_option,
          valueField: "value",
          value: props.value,
          multi: true,
          noDataLabel: "You've reached the maximum amount of tags allowed!"
        }
      }}
    />
  );
};

const outdoorPlayAreas = [
  { name: "N/A", value: "N/A" },
  { name: "Private Backyard", value: "Private Backyard" },
  { name: "Shared Backyard", value: "Shared Backyard" },
  { name: "Park", value: "Park" }
];

const OutdoorPlayAreaDropdownSelect = props => {
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: outdoorPlayAreas,
          valueField: "value",
          value: props.value,
          multi: true
        }
      }}
    />
  );
};

const petsOptions = [
  { name: "N/A", value: "N/A" },
  { name: "Cat(s)", value: "Cat" },
  { name: "Dog(s)", value: "Dog" },
  { name: "Other", value: "Other" }
];

const PetsDropdownSelect = props => {
  return (
    <DropdownSelect
      {...{
        ...props,
        ...{
          options: petsOptions,
          valueField: "value",
          value: props.value,
          multi: true
        }
      }}
    />
  );
};

export {
  DropdownSelect,
  YesNoDropdownSelect,
  GenderDropdownSelect,
  ChildMultiSelect,
  ProgramTypeSelect,
  ProgramTypeSelectMulti,
  PaymentAccountTypeSelect,
  FormGroupSelect,
  ChildAgeGroupSelect,
  ChildCenterAgeGroupSelect,
  LanguageInput,
  PercentageOffDropdownSelect,
  PercentageExtraDropdownSelect,
  PercentageHalvesDropdownSelect,
  TimeDropdownSelect,
  DaysOfWeekRangeDropdownSelect,
  DaysDropdownSelect,
  MealDropdownSelect,
  OutdoorPlayAreaDropdownSelect,
  PetsDropdownSelect,
  MemberShipSelect,
  MainTags,
  DateMultiSelect,
  MultiProgramCategories,
  ProgramStructures,
  SecondaryTags
};
