import React from "react";
import PropTypes from "prop-types";

import { getSelectedMembershipPrices } from "./utils";
import MembershipPrice from "./MembershipPrice";
import MembershipSubtotal from "./MembershipSubtotal";
import CheckoutCouponDisplay from "./CheckoutCouponDisplay";
import CheckoutPromotionDisplay from "./CheckoutPromotionDisplay";
import TaxableMembership from "./TaxableMembership";
import MembershipPromotionsTotal from "./MembershipPromotionsTotal";
import MembershipTotal from "./MembershipTotal";
import TaxablePromotionMembership from "./TaxablePromotionMembership";

import CheckoutGiftCard from "./CheckoutGiftCard";

const CheckoutMembership = ({
  program,
  selected,
  promotion,
  coupon,
  type,
  giftBalance,
  itemAmount,
  promotionAmount,
  couponAmount,
  taxAmount,
  totalAmount
}) => {
  let balance = giftBalance;
  if (!giftBalance) {
    balance = 0;
  }
  // console.log("program", program);
  // console.log("selected", selected);
  // console.log("type", type);
  return (
    <>
      <MembershipPrice
        program={program}
        selected={selected}
        itemAmount={e => itemAmount(e)}
      />
      <MembershipSubtotal program={program} selected={selected} />

      {getSelectedMembershipPrices(program, selected).isTaxEnabled && (
        <>
          {promotion ? (
            <>
              <TaxablePromotionMembership
                program={program}
                selected={selected}
                promotion={promotion}
                taxAmount={e => taxAmount(e)}
              />
            </>
          ) : (
            <>
              <TaxableMembership
                coupon={coupon}
                program={program}
                selected={selected}
                taxAmount={e => taxAmount(e)}
              />
            </>
          )}
        </>
      )}

      {promotion && (
        <CheckoutPromotionDisplay
          promotion={promotion}
          program={program}
          selected={selected}
          promotionAmount={e => promotionAmount(e)}
        />
      )}

      {coupon && (
        <>
          <CheckoutCouponDisplay
            type={type}
            program={program}
            coupon={coupon}
            selected={selected}
            couponAmount={e => couponAmount(e)}
          />
        </>
      )}


      {!!giftBalance && (
        <CheckoutGiftCard
          giftBalance={giftBalance}
          manual={program.manual_invoices}
        />
      )}

      {promotion ? (
        <>
          <MembershipPromotionsTotal
            program={program}
            selected={selected}
            promotion={promotion}
            totalAmount={e => totalAmount(e)}
          />
        </>
      ) : (
        <>
          <MembershipTotal
            coupon={coupon}
            program={program}
            selected={selected}
            totalAmount={e => totalAmount(e)}
          />{" "}
        </>
      )}
    </>
  );
};

CheckoutMembership.propTypes = {
  program: PropTypes.object.isRequired,
  selected: PropTypes.number.isRequired,
  promotion: PropTypes.object,
  coupon: PropTypes.object,
  type: PropTypes.string.isRequired
};

export default CheckoutMembership;
